import { React, useState, useRef } from "react";
import { Card, Container, Nav } from "react-bootstrap";
import { SwiperSlide } from "swiper/react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faStar as emptyStar,
} from "@fortawesome/free-regular-svg-icons";
import Rating from "react-rating";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import ButtonStyled from "../ButtonStyled";

const OurSpecialistsSection = ({ specialists = [] }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1000 },
      items: 4,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1000, min: 800 },
      items: 3,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 800, min: 0 },
      items: 2, // Adjusted to 1 item for better visibility on smaller screens
      slidesToSlide: 1,
    },
  };

  const Slider = () => {
    const [index, setIndex] = useState(0);
    const ref = useRef(null);

    const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);
    };

    const onPrevClick = () => {
      ref.current.previous();
    };

    const onNextClick = () => {
      ref.current.next();
    };

    return (
      <section className="HomeSpecialists">
        <Container>
          <div className="NavigationCover">
            <div>
              <p className="therapist" style={{fontStyle:'italic'}}>
                WHO WE ARE
              </p>
              <div className="custumizebtn">
                <h1 className="text-start">Our Specialists</h1>
                <div className="" style={{ marginLeft: "auto" }}>
                  {/* <Row  className='btn-position justify-content-end flex-driection-row no-gutters'> */}
                  {/* <Col className='col-lg-1'> */}

                  {/* </Col> */}

                  {/* <Col className=' prev col-lg-1'> */}
                  {/* <Button className='btn-navigate'>
        <i className="fas fa-chevron-right"></i>
        </Button> */}
                  <ButtonStyled
                    handler={onPrevClick}
                    title=""
                    isLeft={true}
                    className="btn-navigate"
                  />
                  {/* </Col>  <Col className=' next col-lg-1'> */}
                  <ButtonStyled
                    handler={onNextClick}
                    title=""
                    isRight={true}
                    className="btn-navigate"
                  />
                  {/* <Button className='btn-navigate'>
        <i className="fas fa-chevron-right"></i>
        </Button>
         */}
                  {/* </Col> 
          </Row> */}
                </div>
              </div>

              <div className="separator"></div>
            </div>
          </div>
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={false}
            indicators={false} // Turned off the default dots
            showArrows={false} // Turned off the default controls
            centerMode={false}
            responsive={responsive}
            ssr={true}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={5000}
            keyBoardControl={true}
            customTransition="transform 600ms ease-in-out"
            transitionDuration={1000}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
            className="pt-5 pb-5"
            onSelect={handleSelect}
            ref={ref}
          >
            {specialists.map((specialist, index) => (
              <SwiperSlide key={index} className="swiper-slide ">
                <div
                  className="card mx-4"
                  style={{ marginTop: "360px", paddingTop: "40px" }}
                ></div>
                <div className="card mx-3" style={{ marginTop: "-425px" }}>
                  <Card.Img
                    src={specialist.image}
                    className="img-fluid width"
                    alt=""
                  />
                  <div className="farvrete">
                    <div className="heart-icon-container">
                      <div className={"heart-icon"}>
                        <FontAwesomeIcon
                          icon={faHeart}
                          style={{ color: "#121212" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <h4 className="card-title">{specialist.name}</h4>
                    <p className="card-text">{specialist.designation}</p>
                    <div className="rating">
                      <Rating
                        readonly={true}
                        quiet={true}
                        emptySymbol={
                          <FontAwesomeIcon icon={emptyStar} color="#818181" />
                        }
                        fullSymbol={
                          <FontAwesomeIcon icon={faStar} color="#FDB133" />
                        }
                        initialRating={specialist.rating}
                      />
                    </div>
                    <Nav className="social_links" as="ul">
                      {specialist.socialMedia.map((social, index) => (
                        <Nav.Item as="li">
                          <Nav.Link href={social.link}>
                            <FontAwesomeIcon
                              icon={social.icon}
                              color="#86BAD0"
                            />
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Carousel>
        </Container>
      </section>
    );
  };
  return <Slider />;
};

export default OurSpecialistsSection;
