import React from "react";


import AdminLayout from "../../../layouts/AdminLayout";
import { Container,Col, Row, Card } from "react-bootstrap";
import "./clientapp.scss";
import Images from "../../../constants/Images";
import SchedulingComponent from "./SchedulingComponent";

export const ClientAppointment = () => {
  return (
    <AdminLayout>
      <div className="w-100 bg-white" style={{minHeight:'100vh'}}>
      <Container>
    <div className="index">
      <div className="div">
       <Row className="my-4">
       <Col lg={8} sm={12}>
        <div className="reconmend-trainer ">
          <div className="heading">
            <div className="buttons">
              <div className="text-wrapper-6">Create New +</div>
            </div>
            <div className="text-wrapper-7">Your Appointments</div>
            <div className="buttons-2">
              <div className="text-wrapper-8">This Week</div>
              <img
                className="dorp-down"
                alt="Dorp down"
                src="https://anima-uploads.s3.amazonaws.com/projects/64d688f84f3fd913c192ab8a/releases/64d68e8cdf9e1904b08c35dd/img/dorp-down.svg"
              />
            </div>
          </div>
        </div>
        <div className="calender py-2 bg-white">
        <SchedulingComponent/>
        
        </div>
        </Col>
        <Col lg={4} sm={12} className="mt-3">
        <div className="  w-100">
          <div className="heading py-3">
            <div className="text-wrapper-7">Today (3)</div>
            <div className="buttons">
              <div className="text-wrapper-6">See All</div>
            </div>
          </div>
          <div className="">
            <Card className="card-appointment my-2">
<div className="profileimgnameimp d-flex">
  <div className="profileimgname">
    <img src={Images.girlprof} alt="" />
    <div className="profilename">Patient 1</div>
    <div className="profileid">#ID1234</div>
  </div>
  <div className="profileimp">
    Important
  </div>
</div>
<div className="notestime">
Mental Therapy For Live a good Life
<p><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.8337 6.99935C12.8337 10.2193 10.2203 12.8327 7.00033 12.8327C3.78033 12.8327 1.16699 10.2193 1.16699 6.99935C1.16699 3.77935 3.78033 1.16602 7.00033 1.16602C10.2203 1.16602 12.8337 3.77935 12.8337 6.99935Z" stroke="url(#paint0_linear_5028_938)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.16418 8.85503L7.35585 7.77586C7.04085 7.58919 6.78418 7.14003 6.78418 6.77253V4.38086" stroke="url(#paint1_linear_5028_938)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<defs>
<linearGradient id="paint0_linear_5028_938" x1="3.65588" y1="4.45213" x2="10.9475" y2="10.0521" gradientUnits="userSpaceOnUse">
<stop stop-color="#3A6FFD"/>
<stop offset="1" stop-color="#D63ACA"/>
</linearGradient>
<linearGradient id="paint1_linear_5028_938" x1="7.29191" y1="5.64108" x2="9.31854" y2="6.46902" gradientUnits="userSpaceOnUse">
<stop stop-color="#3A6FFD"/>
<stop offset="1" stop-color="#D63ACA"/>
</linearGradient>
</defs>
</svg>
12 : 00 TO 02 : 00</p>
</div>
            </Card>
            <Card className="card-appointment my-2">
<div className="profileimgnameimp d-flex">
  <div className="profileimgname">
    <img src={Images.girlprof} alt="" />
    <div className="profilename">Patient 1</div>
    <div className="profileid">#ID1234</div>
  </div>
  
</div>
<div className="notestime">
Mental Therapy For Live a good Life
<p><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.8337 6.99935C12.8337 10.2193 10.2203 12.8327 7.00033 12.8327C3.78033 12.8327 1.16699 10.2193 1.16699 6.99935C1.16699 3.77935 3.78033 1.16602 7.00033 1.16602C10.2203 1.16602 12.8337 3.77935 12.8337 6.99935Z" stroke="url(#paint0_linear_5028_938)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.16418 8.85503L7.35585 7.77586C7.04085 7.58919 6.78418 7.14003 6.78418 6.77253V4.38086" stroke="url(#paint1_linear_5028_938)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<defs>
<linearGradient id="paint0_linear_5028_938" x1="3.65588" y1="4.45213" x2="10.9475" y2="10.0521" gradientUnits="userSpaceOnUse">
<stop stop-color="#3A6FFD"/>
<stop offset="1" stop-color="#D63ACA"/>
</linearGradient>
<linearGradient id="paint1_linear_5028_938" x1="7.29191" y1="5.64108" x2="9.31854" y2="6.46902" gradientUnits="userSpaceOnUse">
<stop stop-color="#3A6FFD"/>
<stop offset="1" stop-color="#D63ACA"/>
</linearGradient>
</defs>
</svg>
12 : 00 TO 02 : 00</p>
</div>
            </Card>
          </div>
        </div>
        <div className=" w-100">
          <div className="heading py-3">
            <div className="text-wrapper-7">Tomorrow</div>
            <div className="buttons">
              <div className="text-wrapper-6">See All</div>
            </div>
          </div>
          <div className="">
            <Card className="card-appointment my-2">
<div className="profileimgnameimp d-flex">
  <div className="profileimgname">
    <img src={Images.girlprof} alt="" />
    <div className="profilename">Patient 1</div>
    <div className="profileid">#ID1234</div>
  </div>
  
</div>
<div className="notestime">
Mental Therapy For Live a good Life
<p><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.8337 6.99935C12.8337 10.2193 10.2203 12.8327 7.00033 12.8327C3.78033 12.8327 1.16699 10.2193 1.16699 6.99935C1.16699 3.77935 3.78033 1.16602 7.00033 1.16602C10.2203 1.16602 12.8337 3.77935 12.8337 6.99935Z" stroke="url(#paint0_linear_5028_938)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.16418 8.85503L7.35585 7.77586C7.04085 7.58919 6.78418 7.14003 6.78418 6.77253V4.38086" stroke="url(#paint1_linear_5028_938)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<defs>
<linearGradient id="paint0_linear_5028_938" x1="3.65588" y1="4.45213" x2="10.9475" y2="10.0521" gradientUnits="userSpaceOnUse">
<stop stop-color="#3A6FFD"/>
<stop offset="1" stop-color="#D63ACA"/>
</linearGradient>
<linearGradient id="paint1_linear_5028_938" x1="7.29191" y1="5.64108" x2="9.31854" y2="6.46902" gradientUnits="userSpaceOnUse">
<stop stop-color="#3A6FFD"/>
<stop offset="1" stop-color="#D63ACA"/>
</linearGradient>
</defs>
</svg>
12 : 00 TO 02 : 00</p>
</div>
            </Card>

          </div>
        </div>
        
        </Col>
        </Row>
      </div>
    </div>
    </Container>
    </div>
    </AdminLayout>
  );
};
export default ClientAppointment;