import React from "react";
import { Container, Row, Col, Image, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import Images from "../constants/Images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faTelegram,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

const Footer = () => {
  return (
   
    <>
     <footer id="footer">
      <Container>
        <div className="widgets">
          <Row>
            <Col lg={4}>
              <Link to="/">
                <Image src={Images.symapathiq_logo} fluid/>
              </Link>
            </Col>
            <Col lg={8}>
              <Nav id="footer-menu" as="ul">
                <Nav.Item as="li">
                  <Link to="/" className="nav-link">
                    Home
                  </Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Link to="/" className="nav-link">
                    Marketplace
                  </Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Link to="/" className="nav-link">
                    Forum
                  </Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Link to="/" className="nav-link">
                    Specialists
                  </Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Link to="/" className="nav-link">
                    Courses
                  </Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Link to="/" className="nav-link">
                    News
                  </Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
        </div>
        <div className="copyright">
          <p>
            © 2015-2022{" "}
            <Link to="/">
              <span>SYMPATHIQ</span>
            </Link>
            , All Rights Reserved.
          </p>
          <Row className="align-items-center">
            <Col lg={3} xs={12} className="icon">
              <Nav className="social_links" as="ul">
                <Nav.Item as="li">
                  <Link to="/" className="nav-link">
                    <FontAwesomeIcon icon={faFacebookF} color="#86BAD0" />
                  </Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Link to="/" className="nav-link">
                    <FontAwesomeIcon icon={faTwitter} color="#86BAD0" />
                  </Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Link to="/" className="nav-link">
                    <FontAwesomeIcon icon={faYoutube} color="#FF0302" />
                  </Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Link to="/" className="nav-link">
                    <FontAwesomeIcon icon={faInstagram} color="#86BAD0" />
                  </Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Link to="/" className="nav-link">
                    <FontAwesomeIcon icon={faTelegram} color="#86BAD0" />
                  </Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col lg={3} xs={12}>
              <Nav as="ul" className="justify-content-start">
                <Nav.Item as="li">
                  <Link to="/" className="nav-link">
                    <FontAwesomeIcon icon={faLocationDot} color="#86BAD0" />
                    Адрес компании..................................
                  </Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col lg={3} xs={12}>
              <Nav as="ul" className="justify-content-center">
                <Nav.Item as="li">
                  <Nav.Link href="mailto:sales@bioagrotop.ru">
                    <FontAwesomeIcon icon={faEnvelope} color="#86BAD0" />
                    sales@bioagrotop.ru
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col lg={3} xs={12}>
              <Nav as="ul" className="justify-content-end">
                <Nav.Item as="li">
                  <Nav.Link href="tel:+33 695 12 03 33">
                    <FontAwesomeIcon icon={faPhone} color="#86BAD0" />
                    <b>+33 695 12 03 33</b>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
        </div>
      </Container>
    </footer>
     <div className="box">
     <Nav className="social_links flex-column justify-content-between h-100" as="ul">
                <Nav.Item as="li">
                  <Link to="/" className="nav-link">
                    <FontAwesomeIcon icon={faFacebookF} color="#86BAD0" />
                  </Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Link to="/" className="nav-link">
                    <FontAwesomeIcon icon={faTwitter} color="#86BAD0" />
                  </Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Link to="/" className="nav-link">
                    <FontAwesomeIcon icon={faYoutube} color="#FF0302" />
                  </Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Link to="/" className="nav-link">
                    <FontAwesomeIcon icon={faInstagram} color="#86BAD0" />
                  </Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Link to="/" className="nav-link">
                    <FontAwesomeIcon icon={faTelegram} color="#86BAD0" />
                  </Link>
                </Nav.Item>
              </Nav>


        </div>
    </>
  );
};

export default Footer;
