import React from "react";
import { Button, Col, Image, Row, Form } from "react-bootstrap";

const GroupedOptions2 = ({ values, isImage }) => {
    const paragraphStyle = {
        background: 'linear-gradient(127.52deg, #3A6FFD 24.3%, #D63ACA 80.5%)',
        WebkitBackgroundClip: 'text',
        backgroundClip: 'text',
        color: 'transparent',
        marginTop:'10px'
      };
  return (
    <div className="GroupedOptions1 " style={{justifyContent:'start'}}>
      {values &&
        values.map((value, index) => {
          return (
            <>
            <Form.Check
              type={value.type}
              name={value.name}
              value={value.text}
              label={
                <>
                  {isImage ? (
                    <>{value.image && <Image src={value.image} style={{width: '37.06px',height: '37.03px',marginRight:'7px'}}/>}</>
                  ) : null}
                  <span>{value.text}</span>
                  
                </>
              }
              id={`${value.name}-${index}`}
            />
            </>
          );
        })}
    </div>
  );
};

export default GroupedOptions2;
