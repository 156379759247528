import React from "react";
import AdminLayout from "../../../layouts/AdminLayout";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import "./bookappointment.scss";
import CustomDatePicker from "./CustomDatepicker";
import TimeSelector from "./timeselector/TimeSelector";
import { Link } from "react-router-dom";

export const Bookappointment = () => {
  return (
    <AdminLayout>
      <div className="bg-white w-100" style={{ minHeight: "100vh" }}>
        <Container className="p-3">
          
          <Row className="gap-3">
            <Col xs={12}>
              <Card style={{ border:'none',boxShadow: '0 2px 13px rgba(0, 0, 0, 0.101)',borderRadius:'21px'}}>
                <Card.Body className="p-4">
                  <div className="select-date-time-text">Select Date and time</div>
                  
                  <div className="w-100 my-3"><CustomDatePicker /></div>
      
                  <div className="select-time-text my-2">Select Time</div>
<div className="">
    <Row>
    <Col md={6}>
    <TimeSelector labell={'From'}/>
    </Col>
    <Col md={6}>
    <TimeSelector labell={'To'}/>

    </Col>
    </Row>
    </div>





                </Card.Body>
              </Card>
            </Col>
            <Col xs={12}>
              <Card style={{ border:'none',boxShadow: '0 2px 13px rgba(0, 0, 0, 0.101)',borderRadius:'21px'}}>
                <Card.Body className="p-4">
                  <h2 className="payment-method">Payment</h2>
                  <div>
                    <div className="method-of-payment-div w-100 my-2">
                      <p className="method-of-payment-text">
                        Method of payment
                      </p>
                      <Link to='/change_payment_method' style={{textDecoration:'none'}}>
                      <div className="method-of-payment-button">
                        {" "}
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.84006 2.4008L3.36673 8.19413C3.16006 8.41413 2.96006 8.84746 2.92006 9.14746L2.6734 11.3075C2.58673 12.0875 3.14673 12.6208 3.92006 12.4875L6.06673 12.1208C6.36673 12.0675 6.78673 11.8475 6.9934 11.6208L12.4667 5.82746C13.4134 4.82746 13.8401 3.68746 12.3667 2.29413C10.9001 0.914129 9.78673 1.4008 8.84006 2.4008Z"
                            fill="url(#paint0_linear_5333_3851)"
                          />
                          <path
                            d="M7.92676 3.36719C8.21342 5.20719 9.70676 6.61385 11.5601 6.80052L7.92676 3.36719Z"
                            fill="url(#paint1_linear_5333_3851)"
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_5333_3851"
                              x1="4.93949"
                              y1="4.55847"
                              x2="11.7858"
                              y2="9.62512"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#3A6FFD" />
                              <stop offset="1" stop-color="#D63ACA" />
                            </linearGradient>
                            <linearGradient
                              id="paint1_linear_5333_3851"
                              x1="8.70187"
                              y1="4.33424"
                              x2="10.876"
                              y2="6.10124"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#3A6FFD" />
                              <stop offset="1" stop-color="#D63ACA" />
                            </linearGradient>
                          </defs>
                        </svg>
                        <span>Change</span>
                      </div></Link>
                    </div>
                    <div className="debit-card w-100">
                      <div className="debit-card-text">
                        Credit or Debit card<p>Online payment</p>{" "}
                      </div>
                      <div className="d-flex">
                        <div className="debit-card-icons-div">
                          <svg
                            width="36"
                            height="26"
                            viewBox="0 0 36 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.7104 6.18359H14.2698V19.811H21.7104V6.18359Z"
                              fill="#FF5F00"
                            />
                            <path
                              d="M14.7423 12.9987C14.7412 11.6863 15.033 10.3908 15.5957 9.21028C16.1584 8.02979 16.9772 6.99525 17.9902 6.18497C16.7357 5.18003 15.2292 4.55507 13.6427 4.38153C12.0562 4.20799 10.4539 4.49285 9.01875 5.20358C7.58362 5.91431 6.37365 7.02222 5.52712 8.40067C4.6806 9.77912 4.23169 11.3725 4.23169 12.9987C4.23169 14.6249 4.6806 16.2182 5.52712 17.5967C6.37365 18.9752 7.58362 20.0831 9.01875 20.7938C10.4539 21.5045 12.0562 21.7894 13.6427 21.6158C15.2292 21.4423 16.7357 20.8173 17.9902 19.8124C16.9772 19.0021 16.1584 17.9676 15.5957 16.7871C15.033 15.6066 14.7412 14.3111 14.7423 12.9987Z"
                              fill="#EB001B"
                            />
                            <path
                              d="M31.7483 12.9987C31.7484 14.6248 31.2995 16.2182 30.453 17.5967C29.6066 18.9751 28.3966 20.083 26.9615 20.7938C25.5264 21.5045 23.9241 21.7894 22.3376 21.6158C20.7512 21.4423 19.2447 20.8173 17.9902 19.8124C19.0023 19.0013 19.8205 17.9666 20.3831 16.7863C20.9457 15.606 21.2381 14.3109 21.2381 12.9987C21.2381 11.6865 20.9457 10.3914 20.3831 9.21108C19.8205 8.03077 19.0023 6.99606 17.9902 6.18496C19.2447 5.18002 20.7512 4.55507 22.3376 4.38153C23.9241 4.20799 25.5264 4.49286 26.9615 5.2036C28.3966 5.91433 29.6066 7.02224 30.453 8.4007C31.2995 9.77915 31.7484 11.3725 31.7483 12.9987Z"
                              fill="#F79E1B"
                            />
                            <path
                              d="M30.9371 18.3676V18.0886H31.0475V18.0318H30.7664V18.0886H30.8768V18.3676H30.9371ZM31.4829 18.3676V18.0312H31.3967L31.2976 18.2626L31.1984 18.0312H31.1122V18.3676H31.1731V18.1139L31.266 18.3327H31.3291L31.4221 18.1133V18.3676H31.4829Z"
                              fill="#F79E1B"
                            />
                          </svg>
                        </div>
                        <div className="debit-card-icons-div">
                          <svg
                            width="38"
                            height="12"
                            viewBox="0 0 38 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_5236_20014)">
                              <path
                                d="M24.7451 0.0195312C22.0496 0.0195312 19.6409 1.37836 19.6409 3.88891C19.6409 6.76803 23.9129 6.96689 23.9129 8.41328C23.9129 9.02229 23.1953 9.56746 21.9697 9.56746C20.2303 9.56746 18.9303 8.8057 18.9303 8.8057L18.3741 11.3391C18.3741 11.3391 19.8717 11.9826 21.86 11.9826C24.8069 11.9826 27.1259 10.557 27.1259 8.00355C27.1259 4.96124 22.836 4.76829 22.836 3.42579C22.836 2.94872 23.4251 2.42599 24.6472 2.42599C26.0261 2.42599 27.1511 2.97999 27.1511 2.97999L27.6955 0.53314C27.6955 0.53314 26.4714 0.0195312 24.7451 0.0195312ZM0.0843106 0.2042L0.019043 0.573536C0.019043 0.573536 1.15302 0.775388 2.17436 1.17804C3.48941 1.63975 3.58309 1.90854 3.80456 2.74339L6.21798 11.7921H9.45318L14.4372 0.2042H11.2095L8.00691 8.0829L6.70007 1.40454C6.58022 0.640211 5.97315 0.2042 5.23007 0.2042H0.0843106ZM15.7352 0.2042L13.2031 11.7921H16.2811L18.8042 0.2042H15.7352ZM32.902 0.2042C32.1598 0.2042 31.7666 0.590679 31.478 1.26604L26.9686 11.7921H30.1964L30.8209 10.0378H34.7533L35.133 11.7921H37.981L35.4964 0.2042H32.902ZM33.3218 3.3349L34.2786 7.68326H31.7154L33.3218 3.3349Z"
                                fill="white"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_5236_20014">
                                <rect width="38" height="12" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <Form>
                      <Row className="my-2">
                        <div>
                          <label className="payment-label my-1">Card number</label>
                          <div className="w-100 text-field-payment">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M22 7.54844C22 8.20844 21.46 8.74844 20.8 8.74844H3.2C2.54 8.74844 2 8.20844 2 7.54844V7.53844C2 5.24844 3.85 3.39844 6.14 3.39844H17.85C20.14 3.39844 22 5.25844 22 7.54844Z"
                                fill="#CBD5E1"
                              />
                              <path
                                d="M2 11.45V16.46C2 18.75 3.85 20.6 6.14 20.6H17.85C20.14 20.6 22 18.74 22 16.45V11.45C22 10.79 21.46 10.25 20.8 10.25H3.2C2.54 10.25 2 10.79 2 11.45ZM8 17.25H6C5.59 17.25 5.25 16.91 5.25 16.5C5.25 16.09 5.59 15.75 6 15.75H8C8.41 15.75 8.75 16.09 8.75 16.5C8.75 16.91 8.41 17.25 8 17.25ZM14.5 17.25H10.5C10.09 17.25 9.75 16.91 9.75 16.5C9.75 16.09 10.09 15.75 10.5 15.75H14.5C14.91 15.75 15.25 16.09 15.25 16.5C15.25 16.91 14.91 17.25 14.5 17.25Z"
                                fill="#CBD5E1"
                              />
                            </svg>

                            <input
                              type="text"
                              placeholder="0000 0000 0000 0000"
                              className="w-100 h-100"
                            />
                          </div>
                        </div>
                      </Row>
                      <Row className="my-2">
                        <Col lg={6}>
                        <div>
                          <label className="payment-label my-1">Expiry date&nbsp;<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1ZM8 13.8125C4.79063 13.8125 2.1875 11.2094 2.1875 8C2.1875 4.79063 4.79063 2.1875 8 2.1875C11.2094 2.1875 13.8125 4.79063 13.8125 8C13.8125 11.2094 11.2094 13.8125 8 13.8125Z" fill="#94A3B8"/>
<path d="M9.74375 4.94844C9.275 4.5375 8.65625 4.3125 8 4.3125C7.34375 4.3125 6.725 4.53906 6.25625 4.94844C5.76875 5.375 5.5 5.94844 5.5 6.5625V6.68125C5.5 6.75 5.55625 6.80625 5.625 6.80625H6.375C6.44375 6.80625 6.5 6.75 6.5 6.68125V6.5625C6.5 5.87344 7.17344 5.3125 8 5.3125C8.82656 5.3125 9.5 5.87344 9.5 6.5625C9.5 7.04844 9.15625 7.49375 8.62344 7.69844C8.29219 7.825 8.01094 8.04687 7.80937 8.3375C7.60469 8.63437 7.49844 8.99062 7.49844 9.35156V9.6875C7.49844 9.75625 7.55469 9.8125 7.62344 9.8125H8.37344C8.44219 9.8125 8.49844 9.75625 8.49844 9.6875V9.33281C8.49925 9.18113 8.54574 9.03321 8.63187 8.90834C8.71799 8.78347 8.83975 8.68746 8.98125 8.63281C9.90312 8.27813 10.4984 7.46563 10.4984 6.5625C10.5 5.94844 10.2312 5.375 9.74375 4.94844ZM7.375 11.4375C7.375 11.6033 7.44085 11.7622 7.55806 11.8794C7.67527 11.9967 7.83424 12.0625 8 12.0625C8.16576 12.0625 8.32473 11.9967 8.44194 11.8794C8.55915 11.7622 8.625 11.6033 8.625 11.4375C8.625 11.2717 8.55915 11.1128 8.44194 10.9956C8.32473 10.8783 8.16576 10.8125 8 10.8125C7.83424 10.8125 7.67527 10.8783 7.55806 10.9956C7.44085 11.1128 7.375 11.2717 7.375 11.4375Z" fill="#94A3B8"/>
</svg>
</label>
                          <div className="w-100 text-field-payment">
                            

                            <input
                              type="text"
                              placeholder="MM/YY"
                              className="w-100 h-100"
                            />
                          </div>
                        </div>
                        </Col>
                        <Col lg={6}>
                        <div>
                          <label className="payment-label my-1">CVC&nbsp;<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1ZM8 13.8125C4.79063 13.8125 2.1875 11.2094 2.1875 8C2.1875 4.79063 4.79063 2.1875 8 2.1875C11.2094 2.1875 13.8125 4.79063 13.8125 8C13.8125 11.2094 11.2094 13.8125 8 13.8125Z" fill="#94A3B8"/>
<path d="M9.74375 4.94844C9.275 4.5375 8.65625 4.3125 8 4.3125C7.34375 4.3125 6.725 4.53906 6.25625 4.94844C5.76875 5.375 5.5 5.94844 5.5 6.5625V6.68125C5.5 6.75 5.55625 6.80625 5.625 6.80625H6.375C6.44375 6.80625 6.5 6.75 6.5 6.68125V6.5625C6.5 5.87344 7.17344 5.3125 8 5.3125C8.82656 5.3125 9.5 5.87344 9.5 6.5625C9.5 7.04844 9.15625 7.49375 8.62344 7.69844C8.29219 7.825 8.01094 8.04687 7.80937 8.3375C7.60469 8.63437 7.49844 8.99062 7.49844 9.35156V9.6875C7.49844 9.75625 7.55469 9.8125 7.62344 9.8125H8.37344C8.44219 9.8125 8.49844 9.75625 8.49844 9.6875V9.33281C8.49925 9.18113 8.54574 9.03321 8.63187 8.90834C8.71799 8.78347 8.83975 8.68746 8.98125 8.63281C9.90312 8.27813 10.4984 7.46563 10.4984 6.5625C10.5 5.94844 10.2312 5.375 9.74375 4.94844ZM7.375 11.4375C7.375 11.6033 7.44085 11.7622 7.55806 11.8794C7.67527 11.9967 7.83424 12.0625 8 12.0625C8.16576 12.0625 8.32473 11.9967 8.44194 11.8794C8.55915 11.7622 8.625 11.6033 8.625 11.4375C8.625 11.2717 8.55915 11.1128 8.44194 10.9956C8.32473 10.8783 8.16576 10.8125 8 10.8125C7.83424 10.8125 7.67527 10.8783 7.55806 10.9956C7.44085 11.1128 7.375 11.2717 7.375 11.4375Z" fill="#94A3B8"/>
</svg>
</label>
                          <div className="w-100 text-field-payment">
                           
                            <input
                              type="text"
                              placeholder="000"
                              className="w-100 h-100"
                            />
                          </div>
                        </div>
                        </Col>
                      </Row>
                      <Row className="my-2">
                        <div>
                          <label className="payment-label my-1">Card holder</label>
                          <div className="w-100 text-field-payment">
                          
                            <input
                              type="text"
                              placeholder="Full name"
                              className="w-100 h-100"
                            />
                          </div>
                        </div>
                      </Row>
                    </Form>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12}>
            <button className="add-new-card-btn">Add New Card</button>
            </Col>
          </Row>
        </Container>
      </div>
    </AdminLayout>
  );
};
