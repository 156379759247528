/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Basket12 = ({ className }) => {
  return (
    <svg
      className={`basket-12 ${className}`}
      fill="none"
      height="26"
      viewBox="0 0 26 26"
      width="26"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path className="path" d="M13 5.4165L13 3.24984" stroke="white" strokeLinecap="round" strokeWidth="2" />
      <path className="path" d="M10.834 17.3335L10.2923 14.0835" stroke="white" strokeLinecap="round" strokeWidth="2" />
      <path className="path" d="M15.166 17.3335L15.7077 14.0835" stroke="white" strokeLinecap="round" strokeWidth="2" />
      <path
        className="path"
        d="M2.16602 9.75H23.8327V9.75C22.3231 9.75 21.5683 9.75 21.0277 10.1684C20.9889 10.1984 20.9512 10.2299 20.9147 10.2627C20.4064 10.7197 20.2714 11.4623 20.0013 12.9476L19.0132 18.3822C18.7277 19.9525 18.5849 20.7377 18.0284 21.2022C17.4718 21.6667 16.6738 21.6667 15.0777 21.6667H10.921C9.32493 21.6667 8.52691 21.6667 7.97034 21.2022C7.41377 20.7377 7.27102 19.9525 6.98551 18.3822L5.99739 12.9476C5.72735 11.4623 5.59232 10.7197 5.08397 10.2627C5.04749 10.2299 5.00981 10.1984 4.97101 10.1684C4.43044 9.75 3.67563 9.75 2.16602 9.75V9.75Z"
        stroke="white"
        strokeLinecap="round"
        strokeWidth="2"
      />
    </svg>
  );
};
