import React from "react";
import "./style.scss";
import { Button, Container, Card, Image, Row, Col } from "react-bootstrap";
import Images from "../../constants/Images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
<style>
  @import
  url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;400;500;600;700;900&family=Jost:wght@100&family=Montserrat&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
</style>;
function SavedProduct() {
  return (
    <Container className="d-flex justify-content-left gap-4 card-container">
      <Card className="carditem rounded-4">
        <div className="saved">
          <div className="heart-icon-container">
            <div className={"heart-icon"}>
              <FontAwesomeIcon
                icon={faHeart}
                style={{
                  color: "#FFFFFF",
                  backgroundColor: "rgba(68, 191, 229, 1)",
                  width: "15px",
                  height: "15px",
                  padding: "10px",
                  borderRadius: "50%",
                }}
              />
            </div>
          </div>
        </div>
        <div className="saved-image-div">
          <Image src={Images.Product_1} className="product-img" />
        </div>

        <Card.Body>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="81"
              height="12"
              viewBox="0 0 81 12"
              fill="none"
            >
              <path
                d="M4.45889 2.65037C4.94044 1.44986 5.18122 0.849599 5.57241 0.766406C5.67526 0.744531 5.78157 0.744531 5.88443 0.766406C6.27562 0.849599 6.51639 1.44986 6.99795 2.65037C7.2718 3.33308 7.40873 3.67444 7.66494 3.90661C7.7368 3.97173 7.81481 4.02973 7.89787 4.0798C8.194 4.25828 8.56367 4.29138 9.30301 4.3576C10.5546 4.46969 11.1804 4.52573 11.3715 4.88254C11.411 4.95644 11.438 5.03645 11.4511 5.11924C11.5144 5.51901 11.0544 5.93756 10.1343 6.77465L9.87879 7.0071C9.44863 7.39846 9.23355 7.59414 9.10914 7.83834C9.03452 7.98483 8.98448 8.14259 8.96103 8.30531C8.92195 8.57657 8.98493 8.86044 9.1109 9.42818L9.1559 9.63103C9.3818 10.6492 9.49475 11.1583 9.35375 11.4085C9.2271 11.6332 8.99382 11.7771 8.73612 11.7894C8.44923 11.8032 8.04499 11.4738 7.23651 10.815C6.70385 10.3809 6.43752 10.1639 6.14187 10.0791C5.87168 10.0017 5.58516 10.0017 5.31497 10.0791C5.01932 10.1639 4.75299 10.3809 4.22033 10.815C3.41185 11.4738 3.00761 11.8032 2.72072 11.7894C2.46302 11.7771 2.22973 11.6332 2.10309 11.4085C1.96209 11.1583 2.07504 10.6492 2.30094 9.63103L2.34594 9.42818C2.47191 8.86044 2.53489 8.57657 2.4958 8.30531C2.47236 8.14259 2.42232 7.98483 2.3477 7.83834C2.22329 7.59414 2.00821 7.39846 1.57805 7.0071L1.32255 6.77465C0.402456 5.93756 -0.0575894 5.51901 0.00576574 5.11924C0.0188871 5.03645 0.0457965 4.95644 0.0853749 4.88254C0.276474 4.52573 0.90226 4.46969 2.15383 4.3576C2.89317 4.29138 3.26284 4.25828 3.55897 4.0798C3.64203 4.02973 3.72004 3.97173 3.7919 3.90661C4.04811 3.67444 4.18504 3.33308 4.45889 2.65037Z"
                fill="#818181"
              />
              <path
                d="M4.45889 2.65037C4.94044 1.44986 5.18122 0.849599 5.57241 0.766406C5.67526 0.744531 5.78157 0.744531 5.88443 0.766406C6.27562 0.849599 6.51639 1.44986 6.99795 2.65037C7.2718 3.33308 7.40873 3.67444 7.66494 3.90661C7.7368 3.97173 7.81481 4.02973 7.89787 4.0798C8.194 4.25828 8.56367 4.29138 9.30301 4.3576C10.5546 4.46969 11.1804 4.52573 11.3715 4.88254C11.411 4.95644 11.438 5.03645 11.4511 5.11924C11.5144 5.51901 11.0544 5.93756 10.1343 6.77465L9.87879 7.0071C9.44863 7.39846 9.23355 7.59414 9.10914 7.83834C9.03452 7.98483 8.98448 8.14259 8.96103 8.30531C8.92195 8.57657 8.98493 8.86044 9.1109 9.42818L9.1559 9.63103C9.3818 10.6492 9.49475 11.1583 9.35375 11.4085C9.2271 11.6332 8.99382 11.7771 8.73612 11.7894C8.44923 11.8032 8.04499 11.4738 7.23651 10.815C6.70385 10.3809 6.43752 10.1639 6.14187 10.0791C5.87168 10.0017 5.58516 10.0017 5.31497 10.0791C5.01932 10.1639 4.75299 10.3809 4.22033 10.815C3.41185 11.4738 3.00761 11.8032 2.72072 11.7894C2.46302 11.7771 2.22973 11.6332 2.10309 11.4085C1.96209 11.1583 2.07504 10.6492 2.30094 9.63103L2.34594 9.42818C2.47191 8.86044 2.53489 8.57657 2.4958 8.30531C2.47236 8.14259 2.42232 7.98483 2.3477 7.83834C2.22329 7.59414 2.00821 7.39846 1.57805 7.0071L1.32255 6.77465C0.402456 5.93756 -0.0575894 5.51901 0.00576574 5.11924C0.0188871 5.03645 0.0457965 4.95644 0.0853749 4.88254C0.276474 4.52573 0.90226 4.46969 2.15383 4.3576C2.89317 4.29138 3.26284 4.25828 3.55897 4.0798C3.64203 4.02973 3.72004 3.97173 3.7919 3.90661C4.04811 3.67444 4.18504 3.33308 4.45889 2.65037Z"
                fill="#FDB133"
              />
              <path
                d="M4.45889 2.65037C4.94044 1.44986 5.18122 0.849599 5.57241 0.766406C5.67526 0.744531 5.78157 0.744531 5.88443 0.766406C6.27562 0.849599 6.51639 1.44986 6.99795 2.65037C7.2718 3.33308 7.40873 3.67444 7.66494 3.90661C7.7368 3.97173 7.81481 4.02973 7.89787 4.0798C8.194 4.25828 8.56367 4.29138 9.30301 4.3576C10.5546 4.46969 11.1804 4.52573 11.3715 4.88254C11.411 4.95644 11.438 5.03645 11.4511 5.11924C11.5144 5.51901 11.0544 5.93756 10.1343 6.77465L9.87879 7.0071C9.44863 7.39846 9.23355 7.59414 9.10914 7.83834C9.03452 7.98483 8.98448 8.14259 8.96103 8.30531C8.92195 8.57657 8.98493 8.86044 9.1109 9.42818L9.1559 9.63103C9.3818 10.6492 9.49475 11.1583 9.35375 11.4085C9.2271 11.6332 8.99382 11.7771 8.73612 11.7894C8.44923 11.8032 8.04499 11.4738 7.23651 10.815C6.70385 10.3809 6.43752 10.1639 6.14187 10.0791C5.87168 10.0017 5.58516 10.0017 5.31497 10.0791C5.01932 10.1639 4.75299 10.3809 4.22033 10.815C3.41185 11.4738 3.00761 11.8032 2.72072 11.7894C2.46302 11.7771 2.22973 11.6332 2.10309 11.4085C1.96209 11.1583 2.07504 10.6492 2.30094 9.63103L2.34594 9.42818C2.47191 8.86044 2.53489 8.57657 2.4958 8.30531C2.47236 8.14259 2.42232 7.98483 2.3477 7.83834C2.22329 7.59414 2.00821 7.39846 1.57805 7.0071L1.32255 6.77465C0.402456 5.93756 -0.0575894 5.51901 0.00576574 5.11924C0.0188871 5.03645 0.0457965 4.95644 0.0853749 4.88254C0.276474 4.52573 0.90226 4.46969 2.15383 4.3576C2.89317 4.29138 3.26284 4.25828 3.55897 4.0798C3.64203 4.02973 3.72004 3.97173 3.7919 3.90661C4.04811 3.67444 4.18504 3.33308 4.45889 2.65037Z"
                stroke="white"
              />
              <path
                d="M21.7089 2.65037C22.1904 1.44986 22.4312 0.849599 22.8224 0.766406C22.9253 0.744531 23.0316 0.744531 23.1344 0.766406C23.5256 0.849599 23.7664 1.44986 24.2479 2.65037C24.5218 3.33308 24.6587 3.67444 24.9149 3.90661C24.9868 3.97173 25.0648 4.02973 25.1479 4.0798C25.444 4.25828 25.8137 4.29138 26.553 4.3576C27.8046 4.46969 28.4304 4.52573 28.6215 4.88254C28.661 4.95644 28.688 5.03645 28.7011 5.11924C28.7644 5.51901 28.3044 5.93756 27.3843 6.77465L27.1288 7.0071C26.6986 7.39846 26.4835 7.59414 26.3591 7.83834C26.2845 7.98483 26.2345 8.14259 26.211 8.30531C26.1719 8.57657 26.2349 8.86044 26.3609 9.42818L26.4059 9.63103C26.6318 10.6492 26.7447 11.1583 26.6038 11.4085C26.4771 11.6332 26.2438 11.7771 25.9861 11.7894C25.6992 11.8032 25.295 11.4738 24.4865 10.815C23.9539 10.3809 23.6875 10.1639 23.3919 10.0791C23.1217 10.0017 22.8352 10.0017 22.565 10.0791C22.2693 10.1639 22.003 10.3809 21.4703 10.815C20.6618 11.4738 20.2576 11.8032 19.9707 11.7894C19.713 11.7771 19.4797 11.6332 19.3531 11.4085C19.2121 11.1583 19.325 10.6492 19.5509 9.63103L19.5959 9.42818C19.7219 8.86044 19.7849 8.57657 19.7458 8.30531C19.7224 8.14259 19.6723 7.98483 19.5977 7.83834C19.4733 7.59414 19.2582 7.39846 18.8281 7.0071L18.5725 6.77465C17.6525 5.93756 17.1924 5.51901 17.2558 5.11924C17.2689 5.03645 17.2958 4.95644 17.3354 4.88254C17.5265 4.52573 18.1523 4.46969 19.4038 4.3576C20.1432 4.29138 20.5128 4.25828 20.809 4.0798C20.892 4.02973 20.97 3.97173 21.0419 3.90661C21.2981 3.67444 21.435 3.33308 21.7089 2.65037Z"
                fill="#818181"
              />
              <path
                d="M21.7089 2.65037C22.1904 1.44986 22.4312 0.849599 22.8224 0.766406C22.9253 0.744531 23.0316 0.744531 23.1344 0.766406C23.5256 0.849599 23.7664 1.44986 24.2479 2.65037C24.5218 3.33308 24.6587 3.67444 24.9149 3.90661C24.9868 3.97173 25.0648 4.02973 25.1479 4.0798C25.444 4.25828 25.8137 4.29138 26.553 4.3576C27.8046 4.46969 28.4304 4.52573 28.6215 4.88254C28.661 4.95644 28.688 5.03645 28.7011 5.11924C28.7644 5.51901 28.3044 5.93756 27.3843 6.77465L27.1288 7.0071C26.6986 7.39846 26.4835 7.59414 26.3591 7.83834C26.2845 7.98483 26.2345 8.14259 26.211 8.30531C26.1719 8.57657 26.2349 8.86044 26.3609 9.42818L26.4059 9.63103C26.6318 10.6492 26.7447 11.1583 26.6038 11.4085C26.4771 11.6332 26.2438 11.7771 25.9861 11.7894C25.6992 11.8032 25.295 11.4738 24.4865 10.815C23.9539 10.3809 23.6875 10.1639 23.3919 10.0791C23.1217 10.0017 22.8352 10.0017 22.565 10.0791C22.2693 10.1639 22.003 10.3809 21.4703 10.815C20.6618 11.4738 20.2576 11.8032 19.9707 11.7894C19.713 11.7771 19.4797 11.6332 19.3531 11.4085C19.2121 11.1583 19.325 10.6492 19.5509 9.63103L19.5959 9.42818C19.7219 8.86044 19.7849 8.57657 19.7458 8.30531C19.7224 8.14259 19.6723 7.98483 19.5977 7.83834C19.4733 7.59414 19.2582 7.39846 18.8281 7.0071L18.5725 6.77465C17.6525 5.93756 17.1924 5.51901 17.2558 5.11924C17.2689 5.03645 17.2958 4.95644 17.3354 4.88254C17.5265 4.52573 18.1523 4.46969 19.4038 4.3576C20.1432 4.29138 20.5128 4.25828 20.809 4.0798C20.892 4.02973 20.97 3.97173 21.0419 3.90661C21.2981 3.67444 21.435 3.33308 21.7089 2.65037Z"
                fill="#FDB133"
              />
              <path
                d="M21.7089 2.65037C22.1904 1.44986 22.4312 0.849599 22.8224 0.766406C22.9253 0.744531 23.0316 0.744531 23.1344 0.766406C23.5256 0.849599 23.7664 1.44986 24.2479 2.65037C24.5218 3.33308 24.6587 3.67444 24.9149 3.90661C24.9868 3.97173 25.0648 4.02973 25.1479 4.0798C25.444 4.25828 25.8137 4.29138 26.553 4.3576C27.8046 4.46969 28.4304 4.52573 28.6215 4.88254C28.661 4.95644 28.688 5.03645 28.7011 5.11924C28.7644 5.51901 28.3044 5.93756 27.3843 6.77465L27.1288 7.0071C26.6986 7.39846 26.4835 7.59414 26.3591 7.83834C26.2845 7.98483 26.2345 8.14259 26.211 8.30531C26.1719 8.57657 26.2349 8.86044 26.3609 9.42818L26.4059 9.63103C26.6318 10.6492 26.7447 11.1583 26.6038 11.4085C26.4771 11.6332 26.2438 11.7771 25.9861 11.7894C25.6992 11.8032 25.295 11.4738 24.4865 10.815C23.9539 10.3809 23.6875 10.1639 23.3919 10.0791C23.1217 10.0017 22.8352 10.0017 22.565 10.0791C22.2693 10.1639 22.003 10.3809 21.4703 10.815C20.6618 11.4738 20.2576 11.8032 19.9707 11.7894C19.713 11.7771 19.4797 11.6332 19.3531 11.4085C19.2121 11.1583 19.325 10.6492 19.5509 9.63103L19.5959 9.42818C19.7219 8.86044 19.7849 8.57657 19.7458 8.30531C19.7224 8.14259 19.6723 7.98483 19.5977 7.83834C19.4733 7.59414 19.2582 7.39846 18.8281 7.0071L18.5725 6.77465C17.6525 5.93756 17.1924 5.51901 17.2558 5.11924C17.2689 5.03645 17.2958 4.95644 17.3354 4.88254C17.5265 4.52573 18.1523 4.46969 19.4038 4.3576C20.1432 4.29138 20.5128 4.25828 20.809 4.0798C20.892 4.02973 20.97 3.97173 21.0419 3.90661C21.2981 3.67444 21.435 3.33308 21.7089 2.65037Z"
                stroke="white"
              />
              <path
                d="M38.9589 2.65037C39.4404 1.44986 39.6812 0.849599 40.0724 0.766406C40.1753 0.744531 40.2816 0.744531 40.3844 0.766406C40.7756 0.849599 41.0164 1.44986 41.4979 2.65037C41.7718 3.33308 41.9087 3.67444 42.1649 3.90661C42.2368 3.97173 42.3148 4.02973 42.3979 4.0798C42.694 4.25828 43.0637 4.29138 43.803 4.3576C45.0546 4.46969 45.6804 4.52573 45.8715 4.88254C45.911 4.95644 45.938 5.03645 45.9511 5.11924C46.0144 5.51901 45.5544 5.93756 44.6343 6.77465L44.3788 7.0071C43.9486 7.39846 43.7335 7.59414 43.6091 7.83834C43.5345 7.98483 43.4845 8.14259 43.461 8.30531C43.4219 8.57657 43.4849 8.86044 43.6109 9.42818L43.6559 9.63103C43.8818 10.6492 43.9947 11.1583 43.8538 11.4085C43.7271 11.6332 43.4938 11.7771 43.2361 11.7894C42.9492 11.8032 42.545 11.4738 41.7365 10.815C41.2039 10.3809 40.9375 10.1639 40.6419 10.0791C40.3717 10.0017 40.0852 10.0017 39.815 10.0791C39.5193 10.1639 39.253 10.3809 38.7203 10.815C37.9118 11.4738 37.5076 11.8032 37.2207 11.7894C36.963 11.7771 36.7297 11.6332 36.6031 11.4085C36.4621 11.1583 36.575 10.6492 36.8009 9.63103L36.8459 9.42818C36.9719 8.86044 37.0349 8.57657 36.9958 8.30531C36.9724 8.14259 36.9223 7.98483 36.8477 7.83834C36.7233 7.59414 36.5082 7.39846 36.0781 7.0071L35.8225 6.77465C34.9025 5.93756 34.4424 5.51901 34.5058 5.11924C34.5189 5.03645 34.5458 4.95644 34.5854 4.88254C34.7765 4.52573 35.4023 4.46969 36.6538 4.3576C37.3932 4.29138 37.7628 4.25828 38.059 4.0798C38.142 4.02973 38.22 3.97173 38.2919 3.90661C38.5481 3.67444 38.685 3.33308 38.9589 2.65037Z"
                fill="#818181"
              />
              <path
                d="M38.9589 2.65037C39.4404 1.44986 39.6812 0.849599 40.0724 0.766406C40.1753 0.744531 40.2816 0.744531 40.3844 0.766406C40.7756 0.849599 41.0164 1.44986 41.4979 2.65037C41.7718 3.33308 41.9087 3.67444 42.1649 3.90661C42.2368 3.97173 42.3148 4.02973 42.3979 4.0798C42.694 4.25828 43.0637 4.29138 43.803 4.3576C45.0546 4.46969 45.6804 4.52573 45.8715 4.88254C45.911 4.95644 45.938 5.03645 45.9511 5.11924C46.0144 5.51901 45.5544 5.93756 44.6343 6.77465L44.3788 7.0071C43.9486 7.39846 43.7335 7.59414 43.6091 7.83834C43.5345 7.98483 43.4845 8.14259 43.461 8.30531C43.4219 8.57657 43.4849 8.86044 43.6109 9.42818L43.6559 9.63103C43.8818 10.6492 43.9947 11.1583 43.8538 11.4085C43.7271 11.6332 43.4938 11.7771 43.2361 11.7894C42.9492 11.8032 42.545 11.4738 41.7365 10.815C41.2039 10.3809 40.9375 10.1639 40.6419 10.0791C40.3717 10.0017 40.0852 10.0017 39.815 10.0791C39.5193 10.1639 39.253 10.3809 38.7203 10.815C37.9118 11.4738 37.5076 11.8032 37.2207 11.7894C36.963 11.7771 36.7297 11.6332 36.6031 11.4085C36.4621 11.1583 36.575 10.6492 36.8009 9.63103L36.8459 9.42818C36.9719 8.86044 37.0349 8.57657 36.9958 8.30531C36.9724 8.14259 36.9223 7.98483 36.8477 7.83834C36.7233 7.59414 36.5082 7.39846 36.0781 7.0071L35.8225 6.77465C34.9025 5.93756 34.4424 5.51901 34.5058 5.11924C34.5189 5.03645 34.5458 4.95644 34.5854 4.88254C34.7765 4.52573 35.4023 4.46969 36.6538 4.3576C37.3932 4.29138 37.7628 4.25828 38.059 4.0798C38.142 4.02973 38.22 3.97173 38.2919 3.90661C38.5481 3.67444 38.685 3.33308 38.9589 2.65037Z"
                fill="#FDB133"
              />
              <path
                d="M38.9589 2.65037C39.4404 1.44986 39.6812 0.849599 40.0724 0.766406C40.1753 0.744531 40.2816 0.744531 40.3844 0.766406C40.7756 0.849599 41.0164 1.44986 41.4979 2.65037C41.7718 3.33308 41.9087 3.67444 42.1649 3.90661C42.2368 3.97173 42.3148 4.02973 42.3979 4.0798C42.694 4.25828 43.0637 4.29138 43.803 4.3576C45.0546 4.46969 45.6804 4.52573 45.8715 4.88254C45.911 4.95644 45.938 5.03645 45.9511 5.11924C46.0144 5.51901 45.5544 5.93756 44.6343 6.77465L44.3788 7.0071C43.9486 7.39846 43.7335 7.59414 43.6091 7.83834C43.5345 7.98483 43.4845 8.14259 43.461 8.30531C43.4219 8.57657 43.4849 8.86044 43.6109 9.42818L43.6559 9.63103C43.8818 10.6492 43.9947 11.1583 43.8538 11.4085C43.7271 11.6332 43.4938 11.7771 43.2361 11.7894C42.9492 11.8032 42.545 11.4738 41.7365 10.815C41.2039 10.3809 40.9375 10.1639 40.6419 10.0791C40.3717 10.0017 40.0852 10.0017 39.815 10.0791C39.5193 10.1639 39.253 10.3809 38.7203 10.815C37.9118 11.4738 37.5076 11.8032 37.2207 11.7894C36.963 11.7771 36.7297 11.6332 36.6031 11.4085C36.4621 11.1583 36.575 10.6492 36.8009 9.63103L36.8459 9.42818C36.9719 8.86044 37.0349 8.57657 36.9958 8.30531C36.9724 8.14259 36.9223 7.98483 36.8477 7.83834C36.7233 7.59414 36.5082 7.39846 36.0781 7.0071L35.8225 6.77465C34.9025 5.93756 34.4424 5.51901 34.5058 5.11924C34.5189 5.03645 34.5458 4.95644 34.5854 4.88254C34.7765 4.52573 35.4023 4.46969 36.6538 4.3576C37.3932 4.29138 37.7628 4.25828 38.059 4.0798C38.142 4.02973 38.22 3.97173 38.2919 3.90661C38.5481 3.67444 38.685 3.33308 38.9589 2.65037Z"
                stroke="white"
              />
              <path
                d="M56.2089 2.65037C56.6904 1.44986 56.9312 0.849599 57.3224 0.766406C57.4253 0.744531 57.5316 0.744531 57.6344 0.766406C58.0256 0.849599 58.2664 1.44986 58.7479 2.65037C59.0218 3.33308 59.1587 3.67444 59.4149 3.90661C59.4868 3.97173 59.5648 4.02973 59.6479 4.0798C59.944 4.25828 60.3137 4.29138 61.053 4.3576C62.3046 4.46969 62.9304 4.52573 63.1215 4.88254C63.161 4.95644 63.188 5.03645 63.2011 5.11924C63.2644 5.51901 62.8044 5.93756 61.8843 6.77465L61.6288 7.0071C61.1986 7.39846 60.9835 7.59414 60.8591 7.83834C60.7845 7.98483 60.7345 8.14259 60.711 8.30531C60.6719 8.57657 60.7349 8.86044 60.8609 9.42818L60.9059 9.63103C61.1318 10.6492 61.2447 11.1583 61.1038 11.4085C60.9771 11.6332 60.7438 11.7771 60.4861 11.7894C60.1992 11.8032 59.795 11.4738 58.9865 10.815C58.4539 10.3809 58.1875 10.1639 57.8919 10.0791C57.6217 10.0017 57.3352 10.0017 57.065 10.0791C56.7693 10.1639 56.503 10.3809 55.9703 10.815C55.1618 11.4738 54.7576 11.8032 54.4707 11.7894C54.213 11.7771 53.9797 11.6332 53.8531 11.4085C53.7121 11.1583 53.825 10.6492 54.0509 9.63103L54.0959 9.42818C54.2219 8.86044 54.2849 8.57657 54.2458 8.30531C54.2224 8.14259 54.1723 7.98483 54.0977 7.83834C53.9733 7.59414 53.7582 7.39846 53.3281 7.0071L53.0725 6.77465C52.1525 5.93756 51.6924 5.51901 51.7558 5.11924C51.7689 5.03645 51.7958 4.95644 51.8354 4.88254C52.0265 4.52573 52.6523 4.46969 53.9038 4.3576C54.6432 4.29138 55.0128 4.25828 55.309 4.0798C55.392 4.02973 55.47 3.97173 55.5419 3.90661C55.7981 3.67444 55.935 3.33308 56.2089 2.65037Z"
                fill="#818181"
              />
              <path
                d="M56.2089 2.65037C56.6904 1.44986 56.9312 0.849599 57.3224 0.766406C57.4253 0.744531 57.5316 0.744531 57.6344 0.766406C58.0256 0.849599 58.2664 1.44986 58.7479 2.65037C59.0218 3.33308 59.1587 3.67444 59.4149 3.90661C59.4868 3.97173 59.5648 4.02973 59.6479 4.0798C59.944 4.25828 60.3137 4.29138 61.053 4.3576C62.3046 4.46969 62.9304 4.52573 63.1215 4.88254C63.161 4.95644 63.188 5.03645 63.2011 5.11924C63.2644 5.51901 62.8044 5.93756 61.8843 6.77465L61.6288 7.0071C61.1986 7.39846 60.9835 7.59414 60.8591 7.83834C60.7845 7.98483 60.7345 8.14259 60.711 8.30531C60.6719 8.57657 60.7349 8.86044 60.8609 9.42818L60.9059 9.63103C61.1318 10.6492 61.2447 11.1583 61.1038 11.4085C60.9771 11.6332 60.7438 11.7771 60.4861 11.7894C60.1992 11.8032 59.795 11.4738 58.9865 10.815C58.4539 10.3809 58.1875 10.1639 57.8919 10.0791C57.6217 10.0017 57.3352 10.0017 57.065 10.0791C56.7693 10.1639 56.503 10.3809 55.9703 10.815C55.1618 11.4738 54.7576 11.8032 54.4707 11.7894C54.213 11.7771 53.9797 11.6332 53.8531 11.4085C53.7121 11.1583 53.825 10.6492 54.0509 9.63103L54.0959 9.42818C54.2219 8.86044 54.2849 8.57657 54.2458 8.30531C54.2224 8.14259 54.1723 7.98483 54.0977 7.83834C53.9733 7.59414 53.7582 7.39846 53.3281 7.0071L53.0725 6.77465C52.1525 5.93756 51.6924 5.51901 51.7558 5.11924C51.7689 5.03645 51.7958 4.95644 51.8354 4.88254C52.0265 4.52573 52.6523 4.46969 53.9038 4.3576C54.6432 4.29138 55.0128 4.25828 55.309 4.0798C55.392 4.02973 55.47 3.97173 55.5419 3.90661C55.7981 3.67444 55.935 3.33308 56.2089 2.65037Z"
                fill="#FDB133"
              />
              <path
                d="M56.2089 2.65037C56.6904 1.44986 56.9312 0.849599 57.3224 0.766406C57.4253 0.744531 57.5316 0.744531 57.6344 0.766406C58.0256 0.849599 58.2664 1.44986 58.7479 2.65037C59.0218 3.33308 59.1587 3.67444 59.4149 3.90661C59.4868 3.97173 59.5648 4.02973 59.6479 4.0798C59.944 4.25828 60.3137 4.29138 61.053 4.3576C62.3046 4.46969 62.9304 4.52573 63.1215 4.88254C63.161 4.95644 63.188 5.03645 63.2011 5.11924C63.2644 5.51901 62.8044 5.93756 61.8843 6.77465L61.6288 7.0071C61.1986 7.39846 60.9835 7.59414 60.8591 7.83834C60.7845 7.98483 60.7345 8.14259 60.711 8.30531C60.6719 8.57657 60.7349 8.86044 60.8609 9.42818L60.9059 9.63103C61.1318 10.6492 61.2447 11.1583 61.1038 11.4085C60.9771 11.6332 60.7438 11.7771 60.4861 11.7894C60.1992 11.8032 59.795 11.4738 58.9865 10.815C58.4539 10.3809 58.1875 10.1639 57.8919 10.0791C57.6217 10.0017 57.3352 10.0017 57.065 10.0791C56.7693 10.1639 56.503 10.3809 55.9703 10.815C55.1618 11.4738 54.7576 11.8032 54.4707 11.7894C54.213 11.7771 53.9797 11.6332 53.8531 11.4085C53.7121 11.1583 53.825 10.6492 54.0509 9.63103L54.0959 9.42818C54.2219 8.86044 54.2849 8.57657 54.2458 8.30531C54.2224 8.14259 54.1723 7.98483 54.0977 7.83834C53.9733 7.59414 53.7582 7.39846 53.3281 7.0071L53.0725 6.77465C52.1525 5.93756 51.6924 5.51901 51.7558 5.11924C51.7689 5.03645 51.7958 4.95644 51.8354 4.88254C52.0265 4.52573 52.6523 4.46969 53.9038 4.3576C54.6432 4.29138 55.0128 4.25828 55.309 4.0798C55.392 4.02973 55.47 3.97173 55.5419 3.90661C55.7981 3.67444 55.935 3.33308 56.2089 2.65037Z"
                stroke="white"
              />
              <path
                d="M73.4589 2.65037C73.9404 1.44986 74.1812 0.849599 74.5724 0.766406C74.6753 0.744531 74.7816 0.744531 74.8844 0.766406C75.2756 0.849599 75.5164 1.44986 75.9979 2.65037C76.2718 3.33308 76.4087 3.67444 76.6649 3.90661C76.7368 3.97173 76.8148 4.02973 76.8979 4.0798C77.194 4.25828 77.5637 4.29138 78.303 4.3576C79.5546 4.46969 80.1804 4.52573 80.3715 4.88254C80.411 4.95644 80.438 5.03645 80.4511 5.11924C80.5144 5.51901 80.0544 5.93756 79.1343 6.77465L78.8788 7.0071C78.4486 7.39846 78.2335 7.59414 78.1091 7.83834C78.0345 7.98483 77.9845 8.14259 77.961 8.30531C77.9219 8.57657 77.9849 8.86044 78.1109 9.42818L78.1559 9.63103C78.3818 10.6492 78.4947 11.1583 78.3538 11.4085C78.2271 11.6332 77.9938 11.7771 77.7361 11.7894C77.4492 11.8032 77.045 11.4738 76.2365 10.815C75.7039 10.3809 75.4375 10.1639 75.1419 10.0791C74.8717 10.0017 74.5852 10.0017 74.315 10.0791C74.0193 10.1639 73.753 10.3809 73.2203 10.815C72.4118 11.4738 72.0076 11.8032 71.7207 11.7894C71.463 11.7771 71.2297 11.6332 71.1031 11.4085C70.9621 11.1583 71.075 10.6492 71.3009 9.63103L71.3459 9.42818C71.4719 8.86044 71.5349 8.57657 71.4958 8.30531C71.4724 8.14259 71.4223 7.98483 71.3477 7.83834C71.2233 7.59414 71.0082 7.39846 70.5781 7.0071L70.3225 6.77465C69.4025 5.93756 68.9424 5.51901 69.0058 5.11924C69.0189 5.03645 69.0458 4.95644 69.0854 4.88254C69.2765 4.52573 69.9023 4.46969 71.1538 4.3576C71.8932 4.29138 72.2628 4.25828 72.559 4.0798C72.642 4.02973 72.72 3.97173 72.7919 3.90661C73.0481 3.67444 73.185 3.33308 73.4589 2.65037Z"
                fill="#818181"
              />
              <path
                d="M73.4589 2.65037C73.9404 1.44986 74.1812 0.849599 74.5724 0.766406C74.6753 0.744531 74.7816 0.744531 74.8844 0.766406C75.2756 0.849599 75.5164 1.44986 75.9979 2.65037C76.2718 3.33308 76.4087 3.67444 76.6649 3.90661C76.7368 3.97173 76.8148 4.02973 76.8979 4.0798C77.194 4.25828 77.5637 4.29138 78.303 4.3576C79.5546 4.46969 80.1804 4.52573 80.3715 4.88254C80.411 4.95644 80.438 5.03645 80.4511 5.11924C80.5144 5.51901 80.0544 5.93756 79.1343 6.77465L78.8788 7.0071C78.4486 7.39846 78.2335 7.59414 78.1091 7.83834C78.0345 7.98483 77.9845 8.14259 77.961 8.30531C77.9219 8.57657 77.9849 8.86044 78.1109 9.42818L78.1559 9.63103C78.3818 10.6492 78.4947 11.1583 78.3538 11.4085C78.2271 11.6332 77.9938 11.7771 77.7361 11.7894C77.4492 11.8032 77.045 11.4738 76.2365 10.815C75.7039 10.3809 75.4375 10.1639 75.1419 10.0791C74.8717 10.0017 74.5852 10.0017 74.315 10.0791C74.0193 10.1639 73.753 10.3809 73.2203 10.815C72.4118 11.4738 72.0076 11.8032 71.7207 11.7894C71.463 11.7771 71.2297 11.6332 71.1031 11.4085C70.9621 11.1583 71.075 10.6492 71.3009 9.63103L71.3459 9.42818C71.4719 8.86044 71.5349 8.57657 71.4958 8.30531C71.4724 8.14259 71.4223 7.98483 71.3477 7.83834C71.2233 7.59414 71.0082 7.39846 70.5781 7.0071L70.3225 6.77465C69.4025 5.93756 68.9424 5.51901 69.0058 5.11924C69.0189 5.03645 69.0458 4.95644 69.0854 4.88254C69.2765 4.52573 69.9023 4.46969 71.1538 4.3576C71.8932 4.29138 72.2628 4.25828 72.559 4.0798C72.642 4.02973 72.72 3.97173 72.7919 3.90661C73.0481 3.67444 73.185 3.33308 73.4589 2.65037Z"
                fill="#DEDEDE"
              />
              <path
                d="M73.4589 2.65037C73.9404 1.44986 74.1812 0.849599 74.5724 0.766406C74.6753 0.744531 74.7816 0.744531 74.8844 0.766406C75.2756 0.849599 75.5164 1.44986 75.9979 2.65037C76.2718 3.33308 76.4087 3.67444 76.6649 3.90661C76.7368 3.97173 76.8148 4.02973 76.8979 4.0798C77.194 4.25828 77.5637 4.29138 78.303 4.3576C79.5546 4.46969 80.1804 4.52573 80.3715 4.88254C80.411 4.95644 80.438 5.03645 80.4511 5.11924C80.5144 5.51901 80.0544 5.93756 79.1343 6.77465L78.8788 7.0071C78.4486 7.39846 78.2335 7.59414 78.1091 7.83834C78.0345 7.98483 77.9845 8.14259 77.961 8.30531C77.9219 8.57657 77.9849 8.86044 78.1109 9.42818L78.1559 9.63103C78.3818 10.6492 78.4947 11.1583 78.3538 11.4085C78.2271 11.6332 77.9938 11.7771 77.7361 11.7894C77.4492 11.8032 77.045 11.4738 76.2365 10.815C75.7039 10.3809 75.4375 10.1639 75.1419 10.0791C74.8717 10.0017 74.5852 10.0017 74.315 10.0791C74.0193 10.1639 73.753 10.3809 73.2203 10.815C72.4118 11.4738 72.0076 11.8032 71.7207 11.7894C71.463 11.7771 71.2297 11.6332 71.1031 11.4085C70.9621 11.1583 71.075 10.6492 71.3009 9.63103L71.3459 9.42818C71.4719 8.86044 71.5349 8.57657 71.4958 8.30531C71.4724 8.14259 71.4223 7.98483 71.3477 7.83834C71.2233 7.59414 71.0082 7.39846 70.5781 7.0071L70.3225 6.77465C69.4025 5.93756 68.9424 5.51901 69.0058 5.11924C69.0189 5.03645 69.0458 4.95644 69.0854 4.88254C69.2765 4.52573 69.9023 4.46969 71.1538 4.3576C71.8932 4.29138 72.2628 4.25828 72.559 4.0798C72.642 4.02973 72.72 3.97173 72.7919 3.90661C73.0481 3.67444 73.185 3.33308 73.4589 2.65037Z"
                stroke="white"
              />
            </svg>
          </div>
          <h2 className="title">
            XS™ Sports Protein Shakes –<br /> Rich Chocolate
          </h2>
          <div className="price-bucket-div">
            <p className="card-text">
              <del className="del-price">$53.99</del>
              <span className="span-price">$35.99</span>
            </p>
            <div>
              <Image src={Images.Basket} className="card-image ms-5" alt="" />
            </div>
          </div>
        </Card.Body>
      </Card>

      <Card className=" carditem rounded-4">
        <div className="saved">
          <div className="heart-icon-container">
            <div className={"heart-icon"}>
              <FontAwesomeIcon
                icon={faHeart}
                style={{
                  color: "#FFFFFF",
                  backgroundColor: "rgba(68, 191, 229, 1)",
                  width: "15px",
                  height: "15px",
                  padding: "10px",
                  borderRadius: "50%",
                }}
              />
            </div>
          </div>
        </div>
        <div className="saved-image-div">
          <Image
            src={Images.Product_2}
            fluid
            className="product-img"
            // width={250}
            // height={250}
          />
        </div>

        <Card.Body>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="81"
              height="12"
              viewBox="0 0 81 12"
              fill="none"
            >
              <path
                d="M4.45889 2.65037C4.94044 1.44986 5.18122 0.849599 5.57241 0.766406C5.67526 0.744531 5.78157 0.744531 5.88443 0.766406C6.27562 0.849599 6.51639 1.44986 6.99795 2.65037C7.2718 3.33308 7.40873 3.67444 7.66494 3.90661C7.7368 3.97173 7.81481 4.02973 7.89787 4.0798C8.194 4.25828 8.56367 4.29138 9.30301 4.3576C10.5546 4.46969 11.1804 4.52573 11.3715 4.88254C11.411 4.95644 11.438 5.03645 11.4511 5.11924C11.5144 5.51901 11.0544 5.93756 10.1343 6.77465L9.87879 7.0071C9.44863 7.39846 9.23355 7.59414 9.10914 7.83834C9.03452 7.98483 8.98448 8.14259 8.96103 8.30531C8.92195 8.57657 8.98493 8.86044 9.1109 9.42818L9.1559 9.63103C9.3818 10.6492 9.49475 11.1583 9.35375 11.4085C9.2271 11.6332 8.99382 11.7771 8.73612 11.7894C8.44923 11.8032 8.04499 11.4738 7.23651 10.815C6.70385 10.3809 6.43752 10.1639 6.14187 10.0791C5.87168 10.0017 5.58516 10.0017 5.31497 10.0791C5.01932 10.1639 4.75299 10.3809 4.22033 10.815C3.41185 11.4738 3.00761 11.8032 2.72072 11.7894C2.46302 11.7771 2.22973 11.6332 2.10309 11.4085C1.96209 11.1583 2.07504 10.6492 2.30094 9.63103L2.34594 9.42818C2.47191 8.86044 2.53489 8.57657 2.4958 8.30531C2.47236 8.14259 2.42232 7.98483 2.3477 7.83834C2.22329 7.59414 2.00821 7.39846 1.57805 7.0071L1.32255 6.77465C0.402456 5.93756 -0.0575894 5.51901 0.00576574 5.11924C0.0188871 5.03645 0.0457965 4.95644 0.0853749 4.88254C0.276474 4.52573 0.90226 4.46969 2.15383 4.3576C2.89317 4.29138 3.26284 4.25828 3.55897 4.0798C3.64203 4.02973 3.72004 3.97173 3.7919 3.90661C4.04811 3.67444 4.18504 3.33308 4.45889 2.65037Z"
                fill="#818181"
              />
              <path
                d="M4.45889 2.65037C4.94044 1.44986 5.18122 0.849599 5.57241 0.766406C5.67526 0.744531 5.78157 0.744531 5.88443 0.766406C6.27562 0.849599 6.51639 1.44986 6.99795 2.65037C7.2718 3.33308 7.40873 3.67444 7.66494 3.90661C7.7368 3.97173 7.81481 4.02973 7.89787 4.0798C8.194 4.25828 8.56367 4.29138 9.30301 4.3576C10.5546 4.46969 11.1804 4.52573 11.3715 4.88254C11.411 4.95644 11.438 5.03645 11.4511 5.11924C11.5144 5.51901 11.0544 5.93756 10.1343 6.77465L9.87879 7.0071C9.44863 7.39846 9.23355 7.59414 9.10914 7.83834C9.03452 7.98483 8.98448 8.14259 8.96103 8.30531C8.92195 8.57657 8.98493 8.86044 9.1109 9.42818L9.1559 9.63103C9.3818 10.6492 9.49475 11.1583 9.35375 11.4085C9.2271 11.6332 8.99382 11.7771 8.73612 11.7894C8.44923 11.8032 8.04499 11.4738 7.23651 10.815C6.70385 10.3809 6.43752 10.1639 6.14187 10.0791C5.87168 10.0017 5.58516 10.0017 5.31497 10.0791C5.01932 10.1639 4.75299 10.3809 4.22033 10.815C3.41185 11.4738 3.00761 11.8032 2.72072 11.7894C2.46302 11.7771 2.22973 11.6332 2.10309 11.4085C1.96209 11.1583 2.07504 10.6492 2.30094 9.63103L2.34594 9.42818C2.47191 8.86044 2.53489 8.57657 2.4958 8.30531C2.47236 8.14259 2.42232 7.98483 2.3477 7.83834C2.22329 7.59414 2.00821 7.39846 1.57805 7.0071L1.32255 6.77465C0.402456 5.93756 -0.0575894 5.51901 0.00576574 5.11924C0.0188871 5.03645 0.0457965 4.95644 0.0853749 4.88254C0.276474 4.52573 0.90226 4.46969 2.15383 4.3576C2.89317 4.29138 3.26284 4.25828 3.55897 4.0798C3.64203 4.02973 3.72004 3.97173 3.7919 3.90661C4.04811 3.67444 4.18504 3.33308 4.45889 2.65037Z"
                fill="#FDB133"
              />
              <path
                d="M4.45889 2.65037C4.94044 1.44986 5.18122 0.849599 5.57241 0.766406C5.67526 0.744531 5.78157 0.744531 5.88443 0.766406C6.27562 0.849599 6.51639 1.44986 6.99795 2.65037C7.2718 3.33308 7.40873 3.67444 7.66494 3.90661C7.7368 3.97173 7.81481 4.02973 7.89787 4.0798C8.194 4.25828 8.56367 4.29138 9.30301 4.3576C10.5546 4.46969 11.1804 4.52573 11.3715 4.88254C11.411 4.95644 11.438 5.03645 11.4511 5.11924C11.5144 5.51901 11.0544 5.93756 10.1343 6.77465L9.87879 7.0071C9.44863 7.39846 9.23355 7.59414 9.10914 7.83834C9.03452 7.98483 8.98448 8.14259 8.96103 8.30531C8.92195 8.57657 8.98493 8.86044 9.1109 9.42818L9.1559 9.63103C9.3818 10.6492 9.49475 11.1583 9.35375 11.4085C9.2271 11.6332 8.99382 11.7771 8.73612 11.7894C8.44923 11.8032 8.04499 11.4738 7.23651 10.815C6.70385 10.3809 6.43752 10.1639 6.14187 10.0791C5.87168 10.0017 5.58516 10.0017 5.31497 10.0791C5.01932 10.1639 4.75299 10.3809 4.22033 10.815C3.41185 11.4738 3.00761 11.8032 2.72072 11.7894C2.46302 11.7771 2.22973 11.6332 2.10309 11.4085C1.96209 11.1583 2.07504 10.6492 2.30094 9.63103L2.34594 9.42818C2.47191 8.86044 2.53489 8.57657 2.4958 8.30531C2.47236 8.14259 2.42232 7.98483 2.3477 7.83834C2.22329 7.59414 2.00821 7.39846 1.57805 7.0071L1.32255 6.77465C0.402456 5.93756 -0.0575894 5.51901 0.00576574 5.11924C0.0188871 5.03645 0.0457965 4.95644 0.0853749 4.88254C0.276474 4.52573 0.90226 4.46969 2.15383 4.3576C2.89317 4.29138 3.26284 4.25828 3.55897 4.0798C3.64203 4.02973 3.72004 3.97173 3.7919 3.90661C4.04811 3.67444 4.18504 3.33308 4.45889 2.65037Z"
                stroke="white"
              />
              <path
                d="M21.7089 2.65037C22.1904 1.44986 22.4312 0.849599 22.8224 0.766406C22.9253 0.744531 23.0316 0.744531 23.1344 0.766406C23.5256 0.849599 23.7664 1.44986 24.2479 2.65037C24.5218 3.33308 24.6587 3.67444 24.9149 3.90661C24.9868 3.97173 25.0648 4.02973 25.1479 4.0798C25.444 4.25828 25.8137 4.29138 26.553 4.3576C27.8046 4.46969 28.4304 4.52573 28.6215 4.88254C28.661 4.95644 28.688 5.03645 28.7011 5.11924C28.7644 5.51901 28.3044 5.93756 27.3843 6.77465L27.1288 7.0071C26.6986 7.39846 26.4835 7.59414 26.3591 7.83834C26.2845 7.98483 26.2345 8.14259 26.211 8.30531C26.1719 8.57657 26.2349 8.86044 26.3609 9.42818L26.4059 9.63103C26.6318 10.6492 26.7447 11.1583 26.6038 11.4085C26.4771 11.6332 26.2438 11.7771 25.9861 11.7894C25.6992 11.8032 25.295 11.4738 24.4865 10.815C23.9539 10.3809 23.6875 10.1639 23.3919 10.0791C23.1217 10.0017 22.8352 10.0017 22.565 10.0791C22.2693 10.1639 22.003 10.3809 21.4703 10.815C20.6618 11.4738 20.2576 11.8032 19.9707 11.7894C19.713 11.7771 19.4797 11.6332 19.3531 11.4085C19.2121 11.1583 19.325 10.6492 19.5509 9.63103L19.5959 9.42818C19.7219 8.86044 19.7849 8.57657 19.7458 8.30531C19.7224 8.14259 19.6723 7.98483 19.5977 7.83834C19.4733 7.59414 19.2582 7.39846 18.8281 7.0071L18.5725 6.77465C17.6525 5.93756 17.1924 5.51901 17.2558 5.11924C17.2689 5.03645 17.2958 4.95644 17.3354 4.88254C17.5265 4.52573 18.1523 4.46969 19.4038 4.3576C20.1432 4.29138 20.5128 4.25828 20.809 4.0798C20.892 4.02973 20.97 3.97173 21.0419 3.90661C21.2981 3.67444 21.435 3.33308 21.7089 2.65037Z"
                fill="#818181"
              />
              <path
                d="M21.7089 2.65037C22.1904 1.44986 22.4312 0.849599 22.8224 0.766406C22.9253 0.744531 23.0316 0.744531 23.1344 0.766406C23.5256 0.849599 23.7664 1.44986 24.2479 2.65037C24.5218 3.33308 24.6587 3.67444 24.9149 3.90661C24.9868 3.97173 25.0648 4.02973 25.1479 4.0798C25.444 4.25828 25.8137 4.29138 26.553 4.3576C27.8046 4.46969 28.4304 4.52573 28.6215 4.88254C28.661 4.95644 28.688 5.03645 28.7011 5.11924C28.7644 5.51901 28.3044 5.93756 27.3843 6.77465L27.1288 7.0071C26.6986 7.39846 26.4835 7.59414 26.3591 7.83834C26.2845 7.98483 26.2345 8.14259 26.211 8.30531C26.1719 8.57657 26.2349 8.86044 26.3609 9.42818L26.4059 9.63103C26.6318 10.6492 26.7447 11.1583 26.6038 11.4085C26.4771 11.6332 26.2438 11.7771 25.9861 11.7894C25.6992 11.8032 25.295 11.4738 24.4865 10.815C23.9539 10.3809 23.6875 10.1639 23.3919 10.0791C23.1217 10.0017 22.8352 10.0017 22.565 10.0791C22.2693 10.1639 22.003 10.3809 21.4703 10.815C20.6618 11.4738 20.2576 11.8032 19.9707 11.7894C19.713 11.7771 19.4797 11.6332 19.3531 11.4085C19.2121 11.1583 19.325 10.6492 19.5509 9.63103L19.5959 9.42818C19.7219 8.86044 19.7849 8.57657 19.7458 8.30531C19.7224 8.14259 19.6723 7.98483 19.5977 7.83834C19.4733 7.59414 19.2582 7.39846 18.8281 7.0071L18.5725 6.77465C17.6525 5.93756 17.1924 5.51901 17.2558 5.11924C17.2689 5.03645 17.2958 4.95644 17.3354 4.88254C17.5265 4.52573 18.1523 4.46969 19.4038 4.3576C20.1432 4.29138 20.5128 4.25828 20.809 4.0798C20.892 4.02973 20.97 3.97173 21.0419 3.90661C21.2981 3.67444 21.435 3.33308 21.7089 2.65037Z"
                fill="#FDB133"
              />
              <path
                d="M21.7089 2.65037C22.1904 1.44986 22.4312 0.849599 22.8224 0.766406C22.9253 0.744531 23.0316 0.744531 23.1344 0.766406C23.5256 0.849599 23.7664 1.44986 24.2479 2.65037C24.5218 3.33308 24.6587 3.67444 24.9149 3.90661C24.9868 3.97173 25.0648 4.02973 25.1479 4.0798C25.444 4.25828 25.8137 4.29138 26.553 4.3576C27.8046 4.46969 28.4304 4.52573 28.6215 4.88254C28.661 4.95644 28.688 5.03645 28.7011 5.11924C28.7644 5.51901 28.3044 5.93756 27.3843 6.77465L27.1288 7.0071C26.6986 7.39846 26.4835 7.59414 26.3591 7.83834C26.2845 7.98483 26.2345 8.14259 26.211 8.30531C26.1719 8.57657 26.2349 8.86044 26.3609 9.42818L26.4059 9.63103C26.6318 10.6492 26.7447 11.1583 26.6038 11.4085C26.4771 11.6332 26.2438 11.7771 25.9861 11.7894C25.6992 11.8032 25.295 11.4738 24.4865 10.815C23.9539 10.3809 23.6875 10.1639 23.3919 10.0791C23.1217 10.0017 22.8352 10.0017 22.565 10.0791C22.2693 10.1639 22.003 10.3809 21.4703 10.815C20.6618 11.4738 20.2576 11.8032 19.9707 11.7894C19.713 11.7771 19.4797 11.6332 19.3531 11.4085C19.2121 11.1583 19.325 10.6492 19.5509 9.63103L19.5959 9.42818C19.7219 8.86044 19.7849 8.57657 19.7458 8.30531C19.7224 8.14259 19.6723 7.98483 19.5977 7.83834C19.4733 7.59414 19.2582 7.39846 18.8281 7.0071L18.5725 6.77465C17.6525 5.93756 17.1924 5.51901 17.2558 5.11924C17.2689 5.03645 17.2958 4.95644 17.3354 4.88254C17.5265 4.52573 18.1523 4.46969 19.4038 4.3576C20.1432 4.29138 20.5128 4.25828 20.809 4.0798C20.892 4.02973 20.97 3.97173 21.0419 3.90661C21.2981 3.67444 21.435 3.33308 21.7089 2.65037Z"
                stroke="white"
              />
              <path
                d="M38.9589 2.65037C39.4404 1.44986 39.6812 0.849599 40.0724 0.766406C40.1753 0.744531 40.2816 0.744531 40.3844 0.766406C40.7756 0.849599 41.0164 1.44986 41.4979 2.65037C41.7718 3.33308 41.9087 3.67444 42.1649 3.90661C42.2368 3.97173 42.3148 4.02973 42.3979 4.0798C42.694 4.25828 43.0637 4.29138 43.803 4.3576C45.0546 4.46969 45.6804 4.52573 45.8715 4.88254C45.911 4.95644 45.938 5.03645 45.9511 5.11924C46.0144 5.51901 45.5544 5.93756 44.6343 6.77465L44.3788 7.0071C43.9486 7.39846 43.7335 7.59414 43.6091 7.83834C43.5345 7.98483 43.4845 8.14259 43.461 8.30531C43.4219 8.57657 43.4849 8.86044 43.6109 9.42818L43.6559 9.63103C43.8818 10.6492 43.9947 11.1583 43.8538 11.4085C43.7271 11.6332 43.4938 11.7771 43.2361 11.7894C42.9492 11.8032 42.545 11.4738 41.7365 10.815C41.2039 10.3809 40.9375 10.1639 40.6419 10.0791C40.3717 10.0017 40.0852 10.0017 39.815 10.0791C39.5193 10.1639 39.253 10.3809 38.7203 10.815C37.9118 11.4738 37.5076 11.8032 37.2207 11.7894C36.963 11.7771 36.7297 11.6332 36.6031 11.4085C36.4621 11.1583 36.575 10.6492 36.8009 9.63103L36.8459 9.42818C36.9719 8.86044 37.0349 8.57657 36.9958 8.30531C36.9724 8.14259 36.9223 7.98483 36.8477 7.83834C36.7233 7.59414 36.5082 7.39846 36.0781 7.0071L35.8225 6.77465C34.9025 5.93756 34.4424 5.51901 34.5058 5.11924C34.5189 5.03645 34.5458 4.95644 34.5854 4.88254C34.7765 4.52573 35.4023 4.46969 36.6538 4.3576C37.3932 4.29138 37.7628 4.25828 38.059 4.0798C38.142 4.02973 38.22 3.97173 38.2919 3.90661C38.5481 3.67444 38.685 3.33308 38.9589 2.65037Z"
                fill="#818181"
              />
              <path
                d="M38.9589 2.65037C39.4404 1.44986 39.6812 0.849599 40.0724 0.766406C40.1753 0.744531 40.2816 0.744531 40.3844 0.766406C40.7756 0.849599 41.0164 1.44986 41.4979 2.65037C41.7718 3.33308 41.9087 3.67444 42.1649 3.90661C42.2368 3.97173 42.3148 4.02973 42.3979 4.0798C42.694 4.25828 43.0637 4.29138 43.803 4.3576C45.0546 4.46969 45.6804 4.52573 45.8715 4.88254C45.911 4.95644 45.938 5.03645 45.9511 5.11924C46.0144 5.51901 45.5544 5.93756 44.6343 6.77465L44.3788 7.0071C43.9486 7.39846 43.7335 7.59414 43.6091 7.83834C43.5345 7.98483 43.4845 8.14259 43.461 8.30531C43.4219 8.57657 43.4849 8.86044 43.6109 9.42818L43.6559 9.63103C43.8818 10.6492 43.9947 11.1583 43.8538 11.4085C43.7271 11.6332 43.4938 11.7771 43.2361 11.7894C42.9492 11.8032 42.545 11.4738 41.7365 10.815C41.2039 10.3809 40.9375 10.1639 40.6419 10.0791C40.3717 10.0017 40.0852 10.0017 39.815 10.0791C39.5193 10.1639 39.253 10.3809 38.7203 10.815C37.9118 11.4738 37.5076 11.8032 37.2207 11.7894C36.963 11.7771 36.7297 11.6332 36.6031 11.4085C36.4621 11.1583 36.575 10.6492 36.8009 9.63103L36.8459 9.42818C36.9719 8.86044 37.0349 8.57657 36.9958 8.30531C36.9724 8.14259 36.9223 7.98483 36.8477 7.83834C36.7233 7.59414 36.5082 7.39846 36.0781 7.0071L35.8225 6.77465C34.9025 5.93756 34.4424 5.51901 34.5058 5.11924C34.5189 5.03645 34.5458 4.95644 34.5854 4.88254C34.7765 4.52573 35.4023 4.46969 36.6538 4.3576C37.3932 4.29138 37.7628 4.25828 38.059 4.0798C38.142 4.02973 38.22 3.97173 38.2919 3.90661C38.5481 3.67444 38.685 3.33308 38.9589 2.65037Z"
                fill="#FDB133"
              />
              <path
                d="M38.9589 2.65037C39.4404 1.44986 39.6812 0.849599 40.0724 0.766406C40.1753 0.744531 40.2816 0.744531 40.3844 0.766406C40.7756 0.849599 41.0164 1.44986 41.4979 2.65037C41.7718 3.33308 41.9087 3.67444 42.1649 3.90661C42.2368 3.97173 42.3148 4.02973 42.3979 4.0798C42.694 4.25828 43.0637 4.29138 43.803 4.3576C45.0546 4.46969 45.6804 4.52573 45.8715 4.88254C45.911 4.95644 45.938 5.03645 45.9511 5.11924C46.0144 5.51901 45.5544 5.93756 44.6343 6.77465L44.3788 7.0071C43.9486 7.39846 43.7335 7.59414 43.6091 7.83834C43.5345 7.98483 43.4845 8.14259 43.461 8.30531C43.4219 8.57657 43.4849 8.86044 43.6109 9.42818L43.6559 9.63103C43.8818 10.6492 43.9947 11.1583 43.8538 11.4085C43.7271 11.6332 43.4938 11.7771 43.2361 11.7894C42.9492 11.8032 42.545 11.4738 41.7365 10.815C41.2039 10.3809 40.9375 10.1639 40.6419 10.0791C40.3717 10.0017 40.0852 10.0017 39.815 10.0791C39.5193 10.1639 39.253 10.3809 38.7203 10.815C37.9118 11.4738 37.5076 11.8032 37.2207 11.7894C36.963 11.7771 36.7297 11.6332 36.6031 11.4085C36.4621 11.1583 36.575 10.6492 36.8009 9.63103L36.8459 9.42818C36.9719 8.86044 37.0349 8.57657 36.9958 8.30531C36.9724 8.14259 36.9223 7.98483 36.8477 7.83834C36.7233 7.59414 36.5082 7.39846 36.0781 7.0071L35.8225 6.77465C34.9025 5.93756 34.4424 5.51901 34.5058 5.11924C34.5189 5.03645 34.5458 4.95644 34.5854 4.88254C34.7765 4.52573 35.4023 4.46969 36.6538 4.3576C37.3932 4.29138 37.7628 4.25828 38.059 4.0798C38.142 4.02973 38.22 3.97173 38.2919 3.90661C38.5481 3.67444 38.685 3.33308 38.9589 2.65037Z"
                stroke="white"
              />
              <path
                d="M56.2089 2.65037C56.6904 1.44986 56.9312 0.849599 57.3224 0.766406C57.4253 0.744531 57.5316 0.744531 57.6344 0.766406C58.0256 0.849599 58.2664 1.44986 58.7479 2.65037C59.0218 3.33308 59.1587 3.67444 59.4149 3.90661C59.4868 3.97173 59.5648 4.02973 59.6479 4.0798C59.944 4.25828 60.3137 4.29138 61.053 4.3576C62.3046 4.46969 62.9304 4.52573 63.1215 4.88254C63.161 4.95644 63.188 5.03645 63.2011 5.11924C63.2644 5.51901 62.8044 5.93756 61.8843 6.77465L61.6288 7.0071C61.1986 7.39846 60.9835 7.59414 60.8591 7.83834C60.7845 7.98483 60.7345 8.14259 60.711 8.30531C60.6719 8.57657 60.7349 8.86044 60.8609 9.42818L60.9059 9.63103C61.1318 10.6492 61.2447 11.1583 61.1038 11.4085C60.9771 11.6332 60.7438 11.7771 60.4861 11.7894C60.1992 11.8032 59.795 11.4738 58.9865 10.815C58.4539 10.3809 58.1875 10.1639 57.8919 10.0791C57.6217 10.0017 57.3352 10.0017 57.065 10.0791C56.7693 10.1639 56.503 10.3809 55.9703 10.815C55.1618 11.4738 54.7576 11.8032 54.4707 11.7894C54.213 11.7771 53.9797 11.6332 53.8531 11.4085C53.7121 11.1583 53.825 10.6492 54.0509 9.63103L54.0959 9.42818C54.2219 8.86044 54.2849 8.57657 54.2458 8.30531C54.2224 8.14259 54.1723 7.98483 54.0977 7.83834C53.9733 7.59414 53.7582 7.39846 53.3281 7.0071L53.0725 6.77465C52.1525 5.93756 51.6924 5.51901 51.7558 5.11924C51.7689 5.03645 51.7958 4.95644 51.8354 4.88254C52.0265 4.52573 52.6523 4.46969 53.9038 4.3576C54.6432 4.29138 55.0128 4.25828 55.309 4.0798C55.392 4.02973 55.47 3.97173 55.5419 3.90661C55.7981 3.67444 55.935 3.33308 56.2089 2.65037Z"
                fill="#818181"
              />
              <path
                d="M56.2089 2.65037C56.6904 1.44986 56.9312 0.849599 57.3224 0.766406C57.4253 0.744531 57.5316 0.744531 57.6344 0.766406C58.0256 0.849599 58.2664 1.44986 58.7479 2.65037C59.0218 3.33308 59.1587 3.67444 59.4149 3.90661C59.4868 3.97173 59.5648 4.02973 59.6479 4.0798C59.944 4.25828 60.3137 4.29138 61.053 4.3576C62.3046 4.46969 62.9304 4.52573 63.1215 4.88254C63.161 4.95644 63.188 5.03645 63.2011 5.11924C63.2644 5.51901 62.8044 5.93756 61.8843 6.77465L61.6288 7.0071C61.1986 7.39846 60.9835 7.59414 60.8591 7.83834C60.7845 7.98483 60.7345 8.14259 60.711 8.30531C60.6719 8.57657 60.7349 8.86044 60.8609 9.42818L60.9059 9.63103C61.1318 10.6492 61.2447 11.1583 61.1038 11.4085C60.9771 11.6332 60.7438 11.7771 60.4861 11.7894C60.1992 11.8032 59.795 11.4738 58.9865 10.815C58.4539 10.3809 58.1875 10.1639 57.8919 10.0791C57.6217 10.0017 57.3352 10.0017 57.065 10.0791C56.7693 10.1639 56.503 10.3809 55.9703 10.815C55.1618 11.4738 54.7576 11.8032 54.4707 11.7894C54.213 11.7771 53.9797 11.6332 53.8531 11.4085C53.7121 11.1583 53.825 10.6492 54.0509 9.63103L54.0959 9.42818C54.2219 8.86044 54.2849 8.57657 54.2458 8.30531C54.2224 8.14259 54.1723 7.98483 54.0977 7.83834C53.9733 7.59414 53.7582 7.39846 53.3281 7.0071L53.0725 6.77465C52.1525 5.93756 51.6924 5.51901 51.7558 5.11924C51.7689 5.03645 51.7958 4.95644 51.8354 4.88254C52.0265 4.52573 52.6523 4.46969 53.9038 4.3576C54.6432 4.29138 55.0128 4.25828 55.309 4.0798C55.392 4.02973 55.47 3.97173 55.5419 3.90661C55.7981 3.67444 55.935 3.33308 56.2089 2.65037Z"
                fill="#FDB133"
              />
              <path
                d="M56.2089 2.65037C56.6904 1.44986 56.9312 0.849599 57.3224 0.766406C57.4253 0.744531 57.5316 0.744531 57.6344 0.766406C58.0256 0.849599 58.2664 1.44986 58.7479 2.65037C59.0218 3.33308 59.1587 3.67444 59.4149 3.90661C59.4868 3.97173 59.5648 4.02973 59.6479 4.0798C59.944 4.25828 60.3137 4.29138 61.053 4.3576C62.3046 4.46969 62.9304 4.52573 63.1215 4.88254C63.161 4.95644 63.188 5.03645 63.2011 5.11924C63.2644 5.51901 62.8044 5.93756 61.8843 6.77465L61.6288 7.0071C61.1986 7.39846 60.9835 7.59414 60.8591 7.83834C60.7845 7.98483 60.7345 8.14259 60.711 8.30531C60.6719 8.57657 60.7349 8.86044 60.8609 9.42818L60.9059 9.63103C61.1318 10.6492 61.2447 11.1583 61.1038 11.4085C60.9771 11.6332 60.7438 11.7771 60.4861 11.7894C60.1992 11.8032 59.795 11.4738 58.9865 10.815C58.4539 10.3809 58.1875 10.1639 57.8919 10.0791C57.6217 10.0017 57.3352 10.0017 57.065 10.0791C56.7693 10.1639 56.503 10.3809 55.9703 10.815C55.1618 11.4738 54.7576 11.8032 54.4707 11.7894C54.213 11.7771 53.9797 11.6332 53.8531 11.4085C53.7121 11.1583 53.825 10.6492 54.0509 9.63103L54.0959 9.42818C54.2219 8.86044 54.2849 8.57657 54.2458 8.30531C54.2224 8.14259 54.1723 7.98483 54.0977 7.83834C53.9733 7.59414 53.7582 7.39846 53.3281 7.0071L53.0725 6.77465C52.1525 5.93756 51.6924 5.51901 51.7558 5.11924C51.7689 5.03645 51.7958 4.95644 51.8354 4.88254C52.0265 4.52573 52.6523 4.46969 53.9038 4.3576C54.6432 4.29138 55.0128 4.25828 55.309 4.0798C55.392 4.02973 55.47 3.97173 55.5419 3.90661C55.7981 3.67444 55.935 3.33308 56.2089 2.65037Z"
                stroke="white"
              />
              <path
                d="M73.4589 2.65037C73.9404 1.44986 74.1812 0.849599 74.5724 0.766406C74.6753 0.744531 74.7816 0.744531 74.8844 0.766406C75.2756 0.849599 75.5164 1.44986 75.9979 2.65037C76.2718 3.33308 76.4087 3.67444 76.6649 3.90661C76.7368 3.97173 76.8148 4.02973 76.8979 4.0798C77.194 4.25828 77.5637 4.29138 78.303 4.3576C79.5546 4.46969 80.1804 4.52573 80.3715 4.88254C80.411 4.95644 80.438 5.03645 80.4511 5.11924C80.5144 5.51901 80.0544 5.93756 79.1343 6.77465L78.8788 7.0071C78.4486 7.39846 78.2335 7.59414 78.1091 7.83834C78.0345 7.98483 77.9845 8.14259 77.961 8.30531C77.9219 8.57657 77.9849 8.86044 78.1109 9.42818L78.1559 9.63103C78.3818 10.6492 78.4947 11.1583 78.3538 11.4085C78.2271 11.6332 77.9938 11.7771 77.7361 11.7894C77.4492 11.8032 77.045 11.4738 76.2365 10.815C75.7039 10.3809 75.4375 10.1639 75.1419 10.0791C74.8717 10.0017 74.5852 10.0017 74.315 10.0791C74.0193 10.1639 73.753 10.3809 73.2203 10.815C72.4118 11.4738 72.0076 11.8032 71.7207 11.7894C71.463 11.7771 71.2297 11.6332 71.1031 11.4085C70.9621 11.1583 71.075 10.6492 71.3009 9.63103L71.3459 9.42818C71.4719 8.86044 71.5349 8.57657 71.4958 8.30531C71.4724 8.14259 71.4223 7.98483 71.3477 7.83834C71.2233 7.59414 71.0082 7.39846 70.5781 7.0071L70.3225 6.77465C69.4025 5.93756 68.9424 5.51901 69.0058 5.11924C69.0189 5.03645 69.0458 4.95644 69.0854 4.88254C69.2765 4.52573 69.9023 4.46969 71.1538 4.3576C71.8932 4.29138 72.2628 4.25828 72.559 4.0798C72.642 4.02973 72.72 3.97173 72.7919 3.90661C73.0481 3.67444 73.185 3.33308 73.4589 2.65037Z"
                fill="#818181"
              />
              <path
                d="M73.4589 2.65037C73.9404 1.44986 74.1812 0.849599 74.5724 0.766406C74.6753 0.744531 74.7816 0.744531 74.8844 0.766406C75.2756 0.849599 75.5164 1.44986 75.9979 2.65037C76.2718 3.33308 76.4087 3.67444 76.6649 3.90661C76.7368 3.97173 76.8148 4.02973 76.8979 4.0798C77.194 4.25828 77.5637 4.29138 78.303 4.3576C79.5546 4.46969 80.1804 4.52573 80.3715 4.88254C80.411 4.95644 80.438 5.03645 80.4511 5.11924C80.5144 5.51901 80.0544 5.93756 79.1343 6.77465L78.8788 7.0071C78.4486 7.39846 78.2335 7.59414 78.1091 7.83834C78.0345 7.98483 77.9845 8.14259 77.961 8.30531C77.9219 8.57657 77.9849 8.86044 78.1109 9.42818L78.1559 9.63103C78.3818 10.6492 78.4947 11.1583 78.3538 11.4085C78.2271 11.6332 77.9938 11.7771 77.7361 11.7894C77.4492 11.8032 77.045 11.4738 76.2365 10.815C75.7039 10.3809 75.4375 10.1639 75.1419 10.0791C74.8717 10.0017 74.5852 10.0017 74.315 10.0791C74.0193 10.1639 73.753 10.3809 73.2203 10.815C72.4118 11.4738 72.0076 11.8032 71.7207 11.7894C71.463 11.7771 71.2297 11.6332 71.1031 11.4085C70.9621 11.1583 71.075 10.6492 71.3009 9.63103L71.3459 9.42818C71.4719 8.86044 71.5349 8.57657 71.4958 8.30531C71.4724 8.14259 71.4223 7.98483 71.3477 7.83834C71.2233 7.59414 71.0082 7.39846 70.5781 7.0071L70.3225 6.77465C69.4025 5.93756 68.9424 5.51901 69.0058 5.11924C69.0189 5.03645 69.0458 4.95644 69.0854 4.88254C69.2765 4.52573 69.9023 4.46969 71.1538 4.3576C71.8932 4.29138 72.2628 4.25828 72.559 4.0798C72.642 4.02973 72.72 3.97173 72.7919 3.90661C73.0481 3.67444 73.185 3.33308 73.4589 2.65037Z"
                fill="#DEDEDE"
              />
              <path
                d="M73.4589 2.65037C73.9404 1.44986 74.1812 0.849599 74.5724 0.766406C74.6753 0.744531 74.7816 0.744531 74.8844 0.766406C75.2756 0.849599 75.5164 1.44986 75.9979 2.65037C76.2718 3.33308 76.4087 3.67444 76.6649 3.90661C76.7368 3.97173 76.8148 4.02973 76.8979 4.0798C77.194 4.25828 77.5637 4.29138 78.303 4.3576C79.5546 4.46969 80.1804 4.52573 80.3715 4.88254C80.411 4.95644 80.438 5.03645 80.4511 5.11924C80.5144 5.51901 80.0544 5.93756 79.1343 6.77465L78.8788 7.0071C78.4486 7.39846 78.2335 7.59414 78.1091 7.83834C78.0345 7.98483 77.9845 8.14259 77.961 8.30531C77.9219 8.57657 77.9849 8.86044 78.1109 9.42818L78.1559 9.63103C78.3818 10.6492 78.4947 11.1583 78.3538 11.4085C78.2271 11.6332 77.9938 11.7771 77.7361 11.7894C77.4492 11.8032 77.045 11.4738 76.2365 10.815C75.7039 10.3809 75.4375 10.1639 75.1419 10.0791C74.8717 10.0017 74.5852 10.0017 74.315 10.0791C74.0193 10.1639 73.753 10.3809 73.2203 10.815C72.4118 11.4738 72.0076 11.8032 71.7207 11.7894C71.463 11.7771 71.2297 11.6332 71.1031 11.4085C70.9621 11.1583 71.075 10.6492 71.3009 9.63103L71.3459 9.42818C71.4719 8.86044 71.5349 8.57657 71.4958 8.30531C71.4724 8.14259 71.4223 7.98483 71.3477 7.83834C71.2233 7.59414 71.0082 7.39846 70.5781 7.0071L70.3225 6.77465C69.4025 5.93756 68.9424 5.51901 69.0058 5.11924C69.0189 5.03645 69.0458 4.95644 69.0854 4.88254C69.2765 4.52573 69.9023 4.46969 71.1538 4.3576C71.8932 4.29138 72.2628 4.25828 72.559 4.0798C72.642 4.02973 72.72 3.97173 72.7919 3.90661C73.0481 3.67444 73.185 3.33308 73.4589 2.65037Z"
                stroke="white"
              />
            </svg>
          </div>
          <h2 className="title">
            XS™ Sports Protein Shakes –<br /> Rich Chocolate
          </h2>
          <div className="price-bucket-div">
            <p className="card-text">
              <del className="del-price">$53.99</del>
              <span className="span-price">$35.99</span>
            </p>
            <div>
              <Image src={Images.Basket} className="card-image ms-5" alt="" />
            </div>
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default SavedProduct;
