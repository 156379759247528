import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./style.scss";
import person from "../../../assets/img/person.png";

import ButtonStyled from "../../../components/ButtonStyled";
import { Container } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { Row, Col } from "react-bootstrap";

function Popup_6() {
  const [lgShow, setLgShow] = useState(false);

  return (
    <>
      <Button onClick={() => setLgShow(true)}>Join Screen</Button>

      <Modal
      size="sm"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        
        <Modal.Body className="journypopup">
          <div className="joinscreendp">
            <div className="dp">
              <img src={person} alt="" />
            </div>
            <div className="profileid">
              Patient 1
              <br />
              <span>ID#12345</span>
            </div>

            <div>
              <div className="status">Mental Therapy For Live a good Life</div>
              <div className="timing"> <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.8337 7.0013C12.8337 10.2213 10.2203 12.8346 7.00033 12.8346C3.78033 12.8346 1.16699 10.2213 1.16699 7.0013C1.16699 3.7813 3.78033 1.16797 7.00033 1.16797C10.2203 1.16797 12.8337 3.7813 12.8337 7.0013Z" stroke="url(#paint0_linear_4947_7565)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.16418 8.85307L7.35585 7.77391C7.04085 7.58724 6.78418 7.13807 6.78418 6.77057V4.37891" stroke="url(#paint1_linear_4947_7565)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<defs>
<linearGradient id="paint0_linear_4947_7565" x1="3.65588" y1="4.45408" x2="10.9475" y2="10.0541" gradientUnits="userSpaceOnUse">
<stop stop-color="#3A6FFD"/>
<stop offset="1" stop-color="#D63ACA"/>
</linearGradient>
<linearGradient id="paint1_linear_4947_7565" x1="7.29191" y1="5.63913" x2="9.31854" y2="6.46707" gradientUnits="userSpaceOnUse">
<stop stop-color="#3A6FFD"/>
<stop offset="1" stop-color="#D63ACA"/>
</linearGradient>
</defs>
</svg>
12:00 to 2:00</div>

              <Button className="button ">
              <div class="btn-detaile">
              Join Now
              
            </div>
              
                </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Popup_6;
