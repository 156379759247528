import React, { useState } from "react";
import { Button, Col, Image, Row, Form } from "react-bootstrap";

const GroupedOptions1 = ({ values, isImage }) => {
  const [showAll, setShowAll] = useState(false);
  const paragraphStyle = {

    background: 'linear-gradient(180deg, #1A47BF 0%, #D63ACA 100%)',
    WebkitBackgroundClip: 'text',
    backgroundClip: 'text',
    color: 'transparent',
    marginTop: '10px',
    cursor:'pointer'
  };

  const visibleValues = showAll ? values : values.slice(0, 9);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <div className="GroupedOptions1">
      {visibleValues &&
        visibleValues.map((value, index) => {
          return (
            <Form.Check
              type={value.type}
              name={value.name}
              value={value.text}
              label={
                <>
                  {isImage ? (
                    <>
                      {value.image && (
                        <Image
                          src={value.image}
                          style={{
                            width: '37.06px',
                            height: '37.03px',
                            marginRight: '7px',
                          }}
                        />
                      )}
                    </>
                  ) : null}
                  <span>{value.text}</span>
                </>
              }
              id={`${value.name}-${index}`}
            />
          );
        })}

      {values.length > 9 && !showAll && (
        <p style={paragraphStyle} onClick={toggleShowAll}>
          See More
        </p>
      )}
    </div>
  );
};

export default GroupedOptions1;
