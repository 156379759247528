import React from "react";
import { Basket } from "../MarketPlace/MarketPlace_Two/icons/Basket";
import { Basket1, Basket12 } from "../MarketPlace/MarketPlace_Two/icons/Basket12";
import { ExpandDownLight } from "../MarketPlace/MarketPlace_Two/icons/ExpandDownLight";
import { Favorite } from "../MarketPlace/MarketPlace_Two/icons/Favorite";
import { Youtube, Youtube1 } from "../MarketPlace/MarketPlace_Two/icons/Youtube1";

import { Container } from "react-bootstrap";
import Navigation from "../../components/Navigation";
import Images from "../../constants/Images";

export const MarketPlace_Three = () => {
  return (

  <div className="marketplace_3">
      <div className="div">
        <div className="overlap">
          <div className="ellipse" />
          <div className="group">
            <div className="overlap-group">
              <div className="rectangle" />
              <div className="rectangle-2" />
              <div className="rectangle-3" />
              <div className="rectangle-4" />
              <div className="rectangle-5" />
              <div className="rectangle-6" />
              <div className="rectangle-7" />
              <div className="overlap-group-wrapper">
                <div className="overlap-group-2">
                  <Basket className="basket-5" />
                  <div className="text-wrapper">ADD TO CART</div>
                </div>
              </div>
              <div className="group-2" />
              <img
                className="mask-group"
                alt="Mask group"
                src={Images.Market_1}
              />
              <div className="rectangle-8" />
              <img
                className="img"
                alt="Mask group"
                src={Images.Market_3}
              />
              <p className="we-are-the-best">
                <span className="span">We Are The Best </span>
                <span className="text-wrapper-2">Support for You</span>
              </p>
              <div className="group-3">
                <div className="text-wrapper-3">In stock</div>
                <img
                  className="subtract"
                  alt="Subtract"
                  src="https://generation-sessions.s3.amazonaws.com/626c02ae7a1e714fe1161582bf0efd30/img/subtract.svg"
                />
                <div className="group-4">
                  <div className="text-wrapper-4">Rating:</div>
                  <img
                    className="group-5"
                    alt="Group"
                    src="https://generation-sessions.s3.amazonaws.com/626c02ae7a1e714fe1161582bf0efd30/img/group-180-1@2x.png"
                  />
                </div>
              </div>
              <div className="text-wrapper-5">B YOGA</div>
              <div className="group-6">
                <div className="text-wrapper-6">$53.99</div>
                <div className="US">Us $128.00</div>
                <div className="overlap-2">
                  <img
                    className="vector"
                    alt="Vector"
                    src="https://generation-sessions.s3.amazonaws.com/626c02ae7a1e714fe1161582bf0efd30/img/vector-17.svg"
                  />
                  <div className="text-wrapper-7">FREE SHIPPING!</div>
                </div>
              </div>
              <div className="group-wrapper">
                <div className="div-wrapper">
                  <div className="overlap-group-3">
                    <div className="text-wrapper-8">-40%</div>
                  </div>
                </div>
              </div>
              <div className="favorite-wrapper">
                <Favorite className="icon-instance-node" color="#041625" />
              </div>
              <div className="text-wrapper-9">PRODUCT DETAILS</div>
              <ExpandDownLight className="expand-down-light" color="#1F1E28" />
              <div className="text-wrapper-10">ABOUT THE BRAND</div>
              <ExpandDownLight className="expand-down-light-1" color="#1F1E28" />
              <div className="SHIPPING-RETURNS">SHIPPING &amp; RETURNS</div>
              <ExpandDownLight className="expand-down-light-1-instance" color="#1F1E28" />
              <p className="p">
                This yoga set is stacked with a lightweight cork block, a flexible strap that’s soft to the touch, and B
                Yoga’s cult-status B Mat, which might be the grippiest surface you’ve ever down-dogged on. It’s all
                packed into a pretty set that’s perfect for anyone building out their at-home practice, beginners
                included. And it comes with a 1-month online studio membership to The Class, a blend of high-energy
                cardio and slow, steady, Pilates-based movements—so you can stream a solid workout whenever you want.
              </p>
              <div className="text-wrapper-11">Related Product</div>
              <div className="rectangle-9" />
              <div className="group-7">
                <div className="group-8">
                  <div className="ellipse-2" />
                  <img
                    className="vector-2"
                    alt="Vector"
                    src="https://generation-sessions.s3.amazonaws.com/626c02ae7a1e714fe1161582bf0efd30/img/vector-207-1.svg"
                  />
                </div>
              </div>
              <div className="group-9">
                <div className="group-10">
                  <div className="ellipse-3" />
                  <img
                    className="vector-3"
                    alt="Vector"
                    src="https://generation-sessions.s3.amazonaws.com/626c02ae7a1e714fe1161582bf0efd30/img/vector-207.svg"
                  />
                </div>
              </div>
              <div className="overlap-wrapper">
                <div className="overlap-3">
                  <div className="rectangle-10" />
                  <div className="group-11">
                    <div className="overlap-group-4">
                      <div className="rectangle-11" />
                      <div className="rectangle-12" />
                    </div>
                  </div>
                  <div className="overlap-4">
                    <div className="text-wrapper-12">1</div>
                  </div>
                </div>
              </div>
              <div className="rectangle-13" />
              <p className="text-wrapper-13">XS™ Sports Protein Shakes – Rich Chocolate</p>
              <div className="text-wrapper-14">$53.99</div>
              <div className="text-wrapper-15">$35.99</div>
              <img
                className="group-12"
                alt="Group"
                src="https://generation-sessions.s3.amazonaws.com/626c02ae7a1e714fe1161582bf0efd30/img/group-436@2x.png"
              />
              <div className="group-13">
                <div className="div-wrapper">
                  <div className="overlap-group-5">
                    <div className="text-wrapper-8">-40%</div>
                  </div>
                </div>
              </div>
              <div className="ellipse-4" />
              <div className="basket-wrapper">
                <Basket12 className="icon-instance-node" />
              </div>
              <Favorite className="favorite-3" color="#1F1E28" />
              <div className="rectangle-14" />
              <div className="rectangle-15" />
              <div className="picture">
                <div className="image" />
              </div>
              <p className="text-wrapper-16">XS™ Sports Protein Shakes – Rich Chocolate</p>
              <div className="text-wrapper-17">$53.99</div>
              <div className="text-wrapper-18">$35.99</div>
              <img
                className="group-14"
                alt="Group"
                src={Images.Product_2}
              />
              <div className="ellipse-5" />
              <div className="basket-1-wrapper">
                <Basket12 className="icon-instance-node" />
              </div>
              <Favorite className="favorite-1" color="white" />
              <div className="rectangle-16" />
              <img
                className="image-2"
                alt="Image"
                src={Images.Product_3}
              />
              <p className="text-wrapper-19">XS™ Sports Protein Shakes – Rich Chocolate</p>
              <div className="text-wrapper-20">$53.99</div>
              <div className="text-wrapper-21">$35.99</div>
              <img
                className="group-15"
                alt="Group"
                src="https://generation-sessions.s3.amazonaws.com/626c02ae7a1e714fe1161582bf0efd30/img/group-436@2x.png"
              />
              <div className="ellipse-6" />
              <div className="basket-1-instance-wrapper">
                <Basket12 className="icon-instance-node" />
              </div>
              <Favorite className="favorite-5" color="#1F1E28" />
              <div className="rectangle-17" />
              <div className="picture-and-rating">
                <img
                  className="image-3"
                  alt="Image"
                  src={Images.Product_4}
                />
              </div>
              <p className="text-wrapper-22">XS™ Sports Protein Shakes – Rich Chocolate</p>
              <div className="text-wrapper-23">$53.99</div>
              <div className="text-wrapper-24">$35.99</div>
              <img
                className="group-16"
                alt="Group"
                src="https://generation-sessions.s3.amazonaws.com/626c02ae7a1e714fe1161582bf0efd30/img/group-436@2x.png"
              />
              <div className="ellipse-7" />
              <div className="group-17">
                <Basket12 className="icon-instance-node" />
              </div>
              <Favorite className="favorite-instance" color="#1F1E28" />
              <img
                className="image-4"
                alt="Image"
                src={Images.Product_1}
              />
              <div className="group-18">
                <div className="div-wrapper">
                  <div className="overlap-group-6">
                    <div className="text-wrapper-8">-40%</div>
                  </div>
                </div>
              </div>
              <div className="rectangle-18" />
              <div className="rectangle-19" />
            </div>
          </div>
        </div>
        <img
          className="img-2"
          alt="Img"
          src="https://generation-sessions.s3.amazonaws.com/626c02ae7a1e714fe1161582bf0efd30/img/8c30bc99c92af80e8fff48270abd1445-1.png"
        />
           <Navigation/>
     
        <div className="footer">
          <div className="footer-2">
            <div className="overlap-7">
              <p className="element-SYMPATHIQ">
                <span className="text-wrapper-28">© 2015-2022 </span>
                <span className="text-wrapper-29">SYMPATHIQ</span>
                <span className="text-wrapper-28">, All Rights Reserved.</span>
              </p>
              <div className="rectangle-26" />
            </div>
            <div className="overlap-8">
              <div className="privacy-policy">PRIVACY POLICY</div>
              <div className="group-20">
                <div className="vector-wrapper">
                  <img
                    className="vector-4"
                    alt="Vector"
                    src="https://generation-sessions.s3.amazonaws.com/626c02ae7a1e714fe1161582bf0efd30/img/vector-3.svg"
                  />
                </div>
              </div>
              <div className="rectangle-27" />
            </div>
            <div className="overlap-9">
              <div className="cookie-policy">COOKIE POLICY</div>
              <div className="text-wrapper-30">sales@bioagrotop.ru</div>
            </div>
            <p className="home-marketplace-2">
              <span className="text-wrapper-25">Home&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className="text-wrapper-2">
                Marketplace&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                Forum&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Specialists&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Courses&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;News
              </span>
            </p>
            <div className="overlap-10">
              <p className="text-wrapper-31">Join the thousands that have become happier!</p>
              <div className="group-21">
                <div className="overlap-group-7">
                  <div className="text-wrapper-32">Register</div>
                  <img
                    className="vector-5"
                    alt="Vector"
                    src="https://generation-sessions.s3.amazonaws.com/626c02ae7a1e714fe1161582bf0efd30/img/vector-15.svg"
                  />
                </div>
              </div>
            </div>
            <div className="text-wrapper-33">Адрес компании..................................</div>
            <div className="group-22">
              <div className="subtract-wrapper">
                <img
                  className="subtract-2"
                  alt="Subtract"
                  src="https://generation-sessions.s3.amazonaws.com/626c02ae7a1e714fe1161582bf0efd30/img/subtract-2.svg"
                />
              </div>
            </div>
            <img
              className="vector-6"
              alt="Vector"
              src="https://generation-sessions.s3.amazonaws.com/626c02ae7a1e714fe1161582bf0efd30/img/vector-189.svg"
            />
            <div className="rectangle-28" />
            <div className="rectangle-29" />
            <p className="text-wrapper-34">+33 695 12 03 33</p>
            <img
              className="vector-7"
              alt="Vector"
              src="https://generation-sessions.s3.amazonaws.com/626c02ae7a1e714fe1161582bf0efd30/img/vector-1.svg"
            />
            <img
              className="group-23"
              alt="Group"
              src="https://generation-sessions.s3.amazonaws.com/626c02ae7a1e714fe1161582bf0efd30/img/group@2x.png"
            />
            <img
              className="vector-8"
              alt="Vector"
              src="https://generation-sessions.s3.amazonaws.com/626c02ae7a1e714fe1161582bf0efd30/img/vector.svg"
            />
            <img
              className="subtract-3"
              alt="Subtract"
              src="https://generation-sessions.s3.amazonaws.com/626c02ae7a1e714fe1161582bf0efd30/img/subtract-1.svg"
            />
            <Youtube1 className="you-tube" />
            <img
              className="frame-2"
              alt="Frame"
              src="https://generation-sessions.s3.amazonaws.com/626c02ae7a1e714fe1161582bf0efd30/img/frame-1.svg"
            />
          </div>
        </div>
      </div>
    </div>

  
  );
};

export default MarketPlace_Three;

