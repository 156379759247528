/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const DropDown = ({ className }) => {
  return (
    <svg
      className={`drop-down ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 21 20"
      width="21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M5.49036 7.69141L10.8369 12.3068L16.2596 7.69141"
        stroke="url(#paint0_linear_3481_6629)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <defs className="defs">
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_3481_6629"
          x1="7.78779"
          x2="10.3288"
          y1="8.99141"
          y2="13.5448"
        >
          <stop className="stop" stopColor="#3A6FFD" />
          <stop className="stop" offset="1" stopColor="#D63ACA" />
        </linearGradient>
      </defs>
    </svg>
  );
};
