/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Icon6 } from "../../icons/Icon6";
import "./style.css";

export const ButtonWrapper = ({ text = "Shop Now", showIcon = true, className, divClassName }) => {
  return (
    <button className={`button-wrapper ${className}`}>
      {showIcon && <Icon6 className="icon-6" />}

      <div className={`shop-now ${divClassName}`}>{text}</div>
    </button>
  );
};

ButtonWrapper.propTypes = {
  text: PropTypes.string,
  showIcon: PropTypes.bool,
};
