import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.scss"; // Create this CSS file for custom styling
import { startOfMonth, endOfMonth, isBefore, isAfter } from "date-fns"; // Import date-fns functions

const CustomDatePicker = () => {
  const [selectedDate, setSelectedDate] = useState(null);

  const customStyles = {
    // Add your custom styles here
    // For example:
    calendarContainer: {
      background: "rgba(0, 0, 0, 0.8)",
    },
    day: {
      color: "white",
    },
    selected: {
      background: "blue",
    },
  };

  const customWeekHeader = ["S", "M", "T", "W", "T", "F", "S"];

  // Function to apply custom styles to dates that are not part of the current month
  const dayStyle = (date) => {
    if (
      isBefore(date, startOfMonth(date)) ||
      isAfter(date, endOfMonth(date))
    ) {
      return { color: "silver" }; // Apply silver color to out-of-month dates
    }
    return {};
  };

  return (
    <div className="date-picker">
      <div className="date-picker-wrapper"> {/* Add a wrapper div */}
        <DatePicker
          selected={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          calendarClassName="custom-calendar" // Use this class for custom styling
          customInput={<CustomInput />}
          styles={customStyles}
          showYearDropdown
          style={{ width: "100%" }}
          inline // Make the calendar always visible
          renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
            <div className="header-of-date">
            <div className="date-header-text">Select Date</div>
            <div className="custom-header">
              {date.toLocaleDateString("en-US", { month: "long", year: "numeric" })} &nbsp;
              <button className="btn-div-date" onClick={decreaseMonth}><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.6375 2.175C12.825 2.3625 12.9187 2.5845 12.9187 2.841C12.9187 3.097 12.825 3.31875 12.6375 3.50625L7.1437 9L12.6562 14.5125C12.8312 14.6875 12.9187 14.9062 12.9187 15.1687C12.9187 15.4312 12.825 15.6562 12.6375 15.8437C12.45 16.0312 12.228 16.125 11.9715 16.125C11.7155 16.125 11.4937 16.0312 11.3062 15.8437L5.0062 9.525C4.9312 9.45 4.87795 9.36875 4.84645 9.28125C4.81545 9.19375 4.79995 9.1 4.79995 9C4.79995 8.9 4.81545 8.80625 4.84645 8.71875C4.87795 8.63125 4.9312 8.55 5.0062 8.475L11.325 2.15625C11.5 1.98125 11.7155 1.89375 11.9715 1.89375C12.228 1.89375 12.45 1.9875 12.6375 2.175Z" fill="#1F1E28"/>
</svg>
</button>
              <button className="btn-div-date" onClick={increaseMonth}><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.36255 15.825C5.17505 15.6375 5.0813 15.4155 5.0813 15.159C5.0813 14.903 5.17505 14.6813 5.36255 14.4938L10.8563 9L5.3438 3.4875C5.1688 3.3125 5.0813 3.09375 5.0813 2.83125C5.0813 2.56875 5.17505 2.34375 5.36255 2.15625C5.55005 1.96875 5.77205 1.875 6.02855 1.875C6.28455 1.875 6.5063 1.96875 6.6938 2.15625L12.9938 8.475C13.0688 8.55 13.122 8.63125 13.1535 8.71875C13.1845 8.80625 13.2 8.9 13.2 9C13.2 9.1 13.1845 9.19375 13.1535 9.28125C13.122 9.36875 13.0688 9.45 12.9938 9.525L6.67505 15.8438C6.50005 16.0188 6.28455 16.1063 6.02855 16.1063C5.77205 16.1063 5.55005 16.0125 5.36255 15.825Z" fill="#1F1E28"/>
</svg>
</button>
            </div>
            </div>
          )}
          weekdays={customWeekHeader}
          dayStyle={dayStyle} // Apply custom styles to out-of-month dates
        />
      </div>
    </div>
  );
};

const CustomInput = ({ value, onClick }) => (
  <button className="custom-input" onClick={onClick}>
    {value}
  </button>
);

export default CustomDatePicker;
