import React, { useState } from "react";
import { Nav, NavDropdown, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Images from "../../../constants/Images";
import routes from "../../../utils/Routes";
import "./Sidebar.scss";

export const Sidebarphone = ({}) => {
  // State to keep track of the currently selected button
  const [selectedButton, setSelectedButton] = useState(null);

  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const toggleSidebar = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };
  const handleButtonClick = (buttonKey) => {
    setSelectedButton(buttonKey);
    console.log(buttonKey);
  };

  const getAdminRoutesChild = (routes) => {
    return routes.map((prop, key) => {
      if (prop.show === "yes") {
        if (prop?.subMenu) {
          return (
            <NavDropdown
              key={key}
              renderMenuOnMount
              as="li"
              title={prop.name}
              id={`basic-nav-dropdown-${key}`}
            ></NavDropdown>
          );
        } else {
          return (
            <Nav.Item as="li" key={key}>
              <Link
                className={`nav-link ${
                  selectedButton === key ? "selected" : ""
                }`}
                to={prop.path}
                onClick={() => handleButtonClick(key)}
              >
                <Row className="justify-content-center">
                  <Col className="col-2">{prop.icon}</Col>
                  <Col className="content col-8">{prop.name}</Col>
                </Row>
              </Link>
            </Nav.Item>
          );
        }
      }
    });
  };

  const getAdminRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.show === "yes") {
        if (prop?.subMenu) {
          return (
            <NavDropdown
              key={key}
              menuRole="menu"
              as="li"
              renderMenuOnMount
              title={prop.name}
              id={`basic-nav-dropdown-${key}`}
            >
              {getAdminRoutesChild(prop.subMenu)}
            </NavDropdown>
          );
        } else {
          return (
            <Nav.Item as="li" key={key}>
              <Link
                className={`nav-link ${
                  selectedButton === key ? "selected" : ""
                }`}
                to={prop.path}
                onClick={() => handleButtonClick(key)}
              >
                {prop.icon + prop.name}
              </Link>
            </Nav.Item>
          );
        }
      }
    });
  };

  return (
    <div className={`sidebarphone ${sidebarCollapsed ? "collapsed" : ""}`}>
      <Button className="sidebar-toggle" onClick={toggleSidebar}>
        {/* You can use an icon or "Bars" text for your button */}
        {sidebarCollapsed ? "X" : "="}
      </Button>
      <div className="sidebar">
      <div className="logo-details">
          <img src={Images.Logo} alt="" />
        </div>
        <Nav id="main_menu" as="ul" className="me-auto flex-column">
          {getAdminRoutesChild(routes)}
        </Nav>
      </div>
    </div>
  );
};
