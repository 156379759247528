import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Product } from "./components/Product";

function Example() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button>

      <Modal show={show} onHide={handleClose} className='add-product-in-cart'>
        <Modal.Header closeButton>
          <Modal.Title  >Favouriteg</Modal.Title>
        </Modal.Header>
        <Modal.Body>   <div className="div-2">
        <Product
          className="product-instance"
          image="https://generation-sessions.s3.amazonaws.com/6f32ef045a41a221ff9d4f9c10ea4b44/img/image-13-3@2x.png"
          property1="fav"
        />
        <Product
          className="product-instance"
          image="https://generation-sessions.s3.amazonaws.com/6f32ef045a41a221ff9d4f9c10ea4b44/img/image-13-2@2x.png"
          property1="fav"
        />
      </div></Modal.Body>
    
      </Modal>
    </>
  );
}

export default Example;