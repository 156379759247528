import React, { useState } from 'react'
import AdminLayout from '../../../layouts/AdminLayout'
import { Container } from 'react-bootstrap'
import { Card } from 'react-bootstrap'
import Modal from "react-bootstrap/Modal";


import { Row } from 'react-bootstrap'
import { Col ,Image} from 'react-bootstrap'
import Images from '../../../constants/Images'
import ButtonStyled from '../../../components/ButtonStyled';
import "./clientprofile.scss";


function Clientprofile() {
  const [lgShow, setLgShow] = useState(false);

  return (
   <AdminLayout>
    <div className="bg-white" style={{minHeight:'100vh'}}>
        <Container className='d-flex mt-1 pb-4'>
          <Row className=' w-100'>
            <Col lg={8} className='mt-3'>
        <Card className='clientcard rounded-4 mb-4  pb-1 px-3 pt-4' style={{ border:'none',boxShadow: '0 2px 13px rgba(0, 0, 0, 0.101)'}}>
            
         
              <Card.Body className='clientcard '>
                <div className="profileinfo ">
              <img src={Images.Myappointment_1} className="client-img1 " alt="" />
                <div className='text-center pt-1'>
                <div className=''>    
                <h4 className="nameprof">
                Uanice Khan
                </h4>
                  <span className='idprof'>ID0002312</span></div>
                <div className='iconsprof mt-2'>
                  <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                    <path d="M20.42 9.50047C20.28 9.49047 20.1 9.48047 19.9 9.48047C19.51 9.48047 19.2 9.79047 19.2 10.1805V14.8305C19.2 15.2205 19.51 15.5305 19.9 15.5305C20.1 15.5305 20.27 15.5205 20.44 15.5105C22 15.3305 22 14.2305 22 13.4305V11.5705C22 10.7705 22 9.67047 20.42 9.50047Z" fill="url(#paint0_linear_2830_939)" />
                    <path d="M12.93 5.28906H7.35C3.25 5.28906 2 6.53906 2 10.6391V14.3591C2 18.4591 3.25 19.7091 7.35 19.7091H12.93C17.03 19.7091 18.28 18.4591 18.28 14.3591V10.6391C18.28 6.53906 17.03 5.28906 12.93 5.28906ZM7.1 14.6791C7.02 15.0191 6.71 15.2491 6.37 15.2491C6.31 15.2491 6.25 15.2391 6.19 15.2291C5.79 15.1291 5.54 14.7191 5.64 14.3191C5.94 13.1291 5.94 11.8691 5.64 10.6791C5.54 10.2791 5.78 9.86906 6.19 9.76906C6.59 9.66906 7 9.91906 7.1 10.3191C7.46 11.7491 7.46 13.2491 7.1 14.6791Z" fill="url(#paint1_linear_2830_939)" />
                    <defs>
                      <linearGradient id="paint0_linear_2830_939" x1="19.7973" y1="11.1846" x2="22.2674" y2="12.0625" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#3A6FFD" />
                        <stop offset="1" stop-color="#D63ACA" />
                      </linearGradient>
                      <linearGradient id="paint1_linear_2830_939" x1="5.47307" y1="9.3507" x2="14.7073" y2="17.3573" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#3A6FFD" />
                        <stop offset="1" stop-color="#D63ACA" />
                      </linearGradient>
                    </defs>
                  </svg></div> <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                    <path d="M17 2.5H7C4.24 2.5 2 4.73 2 7.48V13.46V14.46C2 17.21 4.24 19.44 7 19.44H8.5C8.77 19.44 9.13 19.62 9.3 19.84L10.8 21.83C11.46 22.71 12.54 22.71 13.2 21.83L14.7 19.84C14.89 19.59 15.19 19.44 15.5 19.44H17C19.76 19.44 22 17.21 22 14.46V7.48C22 4.73 19.76 2.5 17 2.5ZM8 12.5C7.44 12.5 7 12.05 7 11.5C7 10.95 7.45 10.5 8 10.5C8.55 10.5 9 10.95 9 11.5C9 12.05 8.56 12.5 8 12.5ZM12 12.5C11.44 12.5 11 12.05 11 11.5C11 10.95 11.45 10.5 12 10.5C12.55 10.5 13 10.95 13 11.5C13 12.05 12.56 12.5 12 12.5ZM16 12.5C15.44 12.5 15 12.05 15 11.5C15 10.95 15.45 10.5 16 10.5C16.55 10.5 17 10.95 17 11.5C17 12.05 16.56 12.5 16 12.5Z" fill="url(#paint0_linear_2830_2572)" />
                    <defs>
                      <linearGradient id="paint0_linear_2830_2572" x1="6.26667" y1="8.13052" x2="18.762" y2="17.7318" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#3A6FFD" />
                        <stop offset="1" stop-color="#D63ACA" />
                      </linearGradient>
                    </defs>
                  </svg></div> <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                    <path d="M17.62 11.2516C17.19 11.2516 16.85 10.9016 16.85 10.4816C16.85 10.1116 16.48 9.34156 15.86 8.67156C15.25 8.02156 14.58 7.64156 14.02 7.64156C13.59 7.64156 13.25 7.29156 13.25 6.87156C13.25 6.45156 13.6 6.10156 14.02 6.10156C15.02 6.10156 16.07 6.64156 16.99 7.61156C17.85 8.52156 18.4 9.65156 18.4 10.4716C18.4 10.9016 18.05 11.2516 17.62 11.2516Z" fill="url(#paint0_linear_2830_1214)" />
                    <path d="M21.23 11.25C20.8 11.25 20.46 10.9 20.46 10.48C20.46 6.93 17.57 4.05 14.03 4.05C13.6 4.05 13.26 3.7 13.26 3.28C13.26 2.86 13.6 2.5 14.02 2.5C18.42 2.5 22 6.08 22 10.48C22 10.9 21.65 11.25 21.23 11.25Z" fill="url(#paint1_linear_2830_1214)" />
                    <path d="M11.05 15.45L9.2 17.3C8.81 17.69 8.19 17.69 7.79 17.31C7.68 17.2 7.57 17.1 7.46 16.99C6.43 15.95 5.5 14.86 4.67 13.72C3.85 12.58 3.19 11.44 2.71 10.31C2.24 9.17 2 8.08 2 7.04C2 6.36 2.12 5.71 2.36 5.11C2.6 4.5 2.98 3.94 3.51 3.44C4.15 2.81 4.85 2.5 5.59 2.5C5.87 2.5 6.15 2.56 6.4 2.68C6.66 2.8 6.89 2.98 7.07 3.24L9.39 6.51C9.57 6.76 9.7 6.99 9.79 7.21C9.88 7.42 9.93 7.63 9.93 7.82C9.93 8.06 9.86 8.3 9.72 8.53C9.59 8.76 9.4 9 9.16 9.24L8.4 10.03C8.29 10.14 8.24 10.27 8.24 10.43C8.24 10.51 8.25 10.58 8.27 10.66C8.3 10.74 8.33 10.8 8.35 10.86C8.53 11.19 8.84 11.62 9.28 12.14C9.73 12.66 10.21 13.19 10.73 13.72C10.83 13.82 10.94 13.92 11.04 14.02C11.44 14.41 11.45 15.05 11.05 15.45Z" fill="url(#paint2_linear_2830_1214)" />
                    <path d="M21.97 18.8291C21.97 19.1091 21.92 19.3991 21.82 19.6791C21.79 19.7591 21.76 19.8391 21.72 19.9191C21.55 20.2791 21.33 20.6191 21.04 20.9391C20.55 21.4791 20.01 21.8691 19.4 22.1191C19.39 22.1191 19.38 22.1291 19.37 22.1291C18.78 22.3691 18.14 22.4991 17.45 22.4991C16.43 22.4991 15.34 22.2591 14.19 21.7691C13.04 21.2791 11.89 20.6191 10.75 19.7891C10.36 19.4991 9.96998 19.2091 9.59998 18.8991L12.87 15.6291C13.15 15.8391 13.4 15.9991 13.61 16.1091C13.66 16.1291 13.72 16.1591 13.79 16.1891C13.87 16.2191 13.95 16.2291 14.04 16.2291C14.21 16.2291 14.34 16.1691 14.45 16.0591L15.21 15.3091C15.46 15.0591 15.7 14.8691 15.93 14.7491C16.16 14.6091 16.39 14.5391 16.64 14.5391C16.83 14.5391 17.03 14.5791 17.25 14.6691C17.47 14.7591 17.7 14.8891 17.95 15.0591L21.26 17.4091C21.52 17.5891 21.7 17.7991 21.81 18.0491C21.91 18.2991 21.97 18.5491 21.97 18.8291Z" fill="url(#paint3_linear_2830_1214)" />
                    <defs>
                      <linearGradient id="paint0_linear_2830_1214" x1="14.3487" y1="7.55215" x2="17.5674" y2="10.0241" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#3A6FFD" />
                        <stop offset="1" stop-color="#D63ACA" />
                      </linearGradient>
                      <linearGradient id="paint1_linear_2830_1214" x1="15.1245" y1="4.96458" x2="20.5917" y2="9.15854" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#3A6FFD" />
                        <stop offset="1" stop-color="#D63ACA" />
                      </linearGradient>
                      <linearGradient id="paint2_linear_2830_1214" x1="3.99361" y1="6.75141" x2="11.5669" y2="10.3525" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#3A6FFD" />
                        <stop offset="1" stop-color="#D63ACA" />
                      </linearGradient>
                      <linearGradient id="paint3_linear_2830_1214" x1="12.2389" y1="16.7811" x2="17.3087" y2="22.8319" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#3A6FFD" />
                        <stop offset="1" stop-color="#D63ACA" />
                      </linearGradient>
                    </defs>
                  </svg></div>
               

                </div>


                </div>

                </div>
                <Row className="mt-3" style={{ width: '100%' }}>
                <h2 className="clientinterest">Interest</h2>
      <div className="clientinterestcontainer">
        <div className="clientinterestdiv">Therapist</div>
        <div className="clientinterestdiv">Good Surgeon</div>
        <div className="clientinterestdiv">Good Surgeon</div>
        <div className="clientinterestdiv">Therapist</div>
        <div className="clientinterestdiv">Therapist</div>
        <div className="clientinterestdiv">Therapiest</div>
        <div className="clientinterestdiv">Mental Therapist</div>
        <div className="clientinterestdiv">Good Surgeon</div>
      </div>
                </Row>
                <Row className='mt-4'>

                  <Col lg={6} className='occupation'>
                    <h2 className='occupation'> Occupation</h2>
                    <p><b style={{color:'black',fontWeight:'400'}}>Licence</b> / loremipsum 123431445 </p>
                  </Col>
                  <Col lg={6} className='occupation'>
                    <h2 className='occupation'>Language</h2>
                    <p>Brattish Engllish</p>
                  </Col>
                </Row>
                
              </Card.Body>
            </Card>

            <Card className='notes p-3 pt-4 mt-2' style={{ border:'none',boxShadow: '0 2px 13px rgba(0, 0, 0, 0.101)'}}>
              <Row><Container>
              <h1 className='addnotesheading'>Add Note</h1> </Container></Row>
<Row className=''>
  <input type='text' className='addnotesinput' placeholder='Write here'/>
</Row>

  <div className="d-flex overflow-auto" style={{ width: '98%' }}>
    <div className='py-4 d-flex'>
      <Card className='notes1card ms-2'>
        <Col><p className='notestext'>Lorem ipsum dolor sit amettetur adipiscing elit, sed do eiusmod tempor ncnt ut labore et dolore magna aliqua.</p></Col>
        <Col><p className='notescount'><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.00033 1.16797C3.78616 1.16797 1.16699 3.78714 1.16699 7.0013C1.16699 10.2155 3.78616 12.8346 7.00033 12.8346C10.2145 12.8346 12.8337 10.2155 12.8337 7.0013C12.8337 3.78714 10.2145 1.16797 7.00033 1.16797ZM9.53783 9.0838C9.45616 9.2238 9.31032 9.29963 9.15866 9.29963C9.08283 9.29963 9.00699 9.28214 8.93699 9.23547L7.12866 8.1563C6.67949 7.88797 6.34699 7.2988 6.34699 6.77963V4.38797C6.34699 4.1488 6.54533 3.95047 6.78449 3.95047C7.02366 3.95047 7.22199 4.1488 7.22199 4.38797V6.77963C7.22199 6.98963 7.39699 7.2988 7.57783 7.4038L9.38616 8.48297C9.59616 8.60547 9.66616 8.8738 9.53783 9.0838Z" fill="url(#paint0_linear_4947_2924)"/>
<defs>
<linearGradient id="paint0_linear_4947_2924" x1="3.65588" y1="4.45408" x2="10.9475" y2="10.0541" gradientUnits="userSpaceOnUse">
<stop stop-color="#3A6FFD"/>
<stop offset="1" stop-color="#D63ACA"/>
</linearGradient>
</defs>
</svg>
 Note 3 <span>(saved 3 - 04 - 2023)</span></p></Col>
      </Card>

      <Card className='notes1card ms-2'>
        <Col><p className='notestext'>Lorem ipsum dolor sit amettetur adipiscing elit, sed do eiusmod tempor ncnt ut labore et dolore magna aliqua.</p></Col>
        <Col><p className='notescount'><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.00033 1.16797C3.78616 1.16797 1.16699 3.78714 1.16699 7.0013C1.16699 10.2155 3.78616 12.8346 7.00033 12.8346C10.2145 12.8346 12.8337 10.2155 12.8337 7.0013C12.8337 3.78714 10.2145 1.16797 7.00033 1.16797ZM9.53783 9.0838C9.45616 9.2238 9.31032 9.29963 9.15866 9.29963C9.08283 9.29963 9.00699 9.28214 8.93699 9.23547L7.12866 8.1563C6.67949 7.88797 6.34699 7.2988 6.34699 6.77963V4.38797C6.34699 4.1488 6.54533 3.95047 6.78449 3.95047C7.02366 3.95047 7.22199 4.1488 7.22199 4.38797V6.77963C7.22199 6.98963 7.39699 7.2988 7.57783 7.4038L9.38616 8.48297C9.59616 8.60547 9.66616 8.8738 9.53783 9.0838Z" fill="url(#paint0_linear_4947_2924)"/>
<defs>
<linearGradient id="paint0_linear_4947_2924" x1="3.65588" y1="4.45408" x2="10.9475" y2="10.0541" gradientUnits="userSpaceOnUse">
<stop stop-color="#3A6FFD"/>
<stop offset="1" stop-color="#D63ACA"/>
</linearGradient>
</defs>
</svg>
 Note 3 <span>(saved 3 - 04 - 2023)</span></p></Col>
      </Card>

      <Card className='notes1card ms-2'>
        <Col><p className='notestext'>Lorem ipsum dolor sit amettetur adipiscing elit, sed do eiusmod tempor ncnt ut labore et dolore magna aliqua.</p></Col>
        <Col><p className='notescount'><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.00033 1.16797C3.78616 1.16797 1.16699 3.78714 1.16699 7.0013C1.16699 10.2155 3.78616 12.8346 7.00033 12.8346C10.2145 12.8346 12.8337 10.2155 12.8337 7.0013C12.8337 3.78714 10.2145 1.16797 7.00033 1.16797ZM9.53783 9.0838C9.45616 9.2238 9.31032 9.29963 9.15866 9.29963C9.08283 9.29963 9.00699 9.28214 8.93699 9.23547L7.12866 8.1563C6.67949 7.88797 6.34699 7.2988 6.34699 6.77963V4.38797C6.34699 4.1488 6.54533 3.95047 6.78449 3.95047C7.02366 3.95047 7.22199 4.1488 7.22199 4.38797V6.77963C7.22199 6.98963 7.39699 7.2988 7.57783 7.4038L9.38616 8.48297C9.59616 8.60547 9.66616 8.8738 9.53783 9.0838Z" fill="url(#paint0_linear_4947_2924)"/>
<defs>
<linearGradient id="paint0_linear_4947_2924" x1="3.65588" y1="4.45408" x2="10.9475" y2="10.0541" gradientUnits="userSpaceOnUse">
<stop stop-color="#3A6FFD"/>
<stop offset="1" stop-color="#D63ACA"/>
</linearGradient>
</defs>
</svg>
 Note 3 <span>(saved 3 - 04 - 2023)</span></p></Col>
      </Card>

      {/* Add more cards as needed */}
    </div>
  </div>



            </Card>
            </Col>
            <Col lg={4} className='mt-3'>
<Row className='gap-2'>
  <Col lg={12} className='d-flex'>
    <div>
    <h2 className='headingaboutpatient'>About Patient</h2>
    <p className='subheadaboutpatient'>This information stored confidentialy:</p>
    </div>
    <div style={{marginLeft:'auto'}}>
    <div className="client-detaile-div px-3">
            {/* <ButtonStyled title="Today" className="btn-detaile" isDown={true} /> */}
            <div class="client-detail-button">
              Save 
              
            </div>
            </div>
    </div>
  </Col>
  <Col lg={12} style={{marginTop:'-5px'}}>
    <div className="cardaboutpatient " style={{ border:'none',boxShadow: '0 2px 13px rgba(0, 0, 0, 0.101)'}}>
      <p className='textaboutpatient'>
      Lorem Ipsum arena iliamina losem loremLorem Ipsum arena iliamina losem lorLorem Ipsum arena iliamina losem loremLorem Ipsum arena iliamina losem loremLorem Ipsum arena iliamina losem lorLorem Ipsum arena iliamina losem Ipsum arena iliamina losem iliamina losem 
      </p>
    </div>
  </Col>

  <Col lg={12} className='d-flex'>
    <div>
    <h2 className='headingaboutpatient mt-2'>Appointments</h2>
    <p className='subheadaboutpatient'></p>
    </div>
    <div style={{marginLeft:'auto'}}>
    <div className="client-detaile-div px-3">
            {/* <ButtonStyled title="Today" className="btn-detaile" isDown={true} /> */}
            <div class="client-detail-button">
              See All 
              
            </div>
            </div>
    </div>
  </Col>

  <Col lg={12}>
    <div className="cardappointment " >
      <p className='textappointment'>
      Mental Therapy For Live a good Life
      </p>
      <p className='timeappointment'>
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.8337 7.0013C12.8337 10.2213 10.2203 12.8346 7.00033 12.8346C3.78033 12.8346 1.16699 10.2213 1.16699 7.0013C1.16699 3.7813 3.78033 1.16797 7.00033 1.16797C10.2203 1.16797 12.8337 3.7813 12.8337 7.0013Z" stroke="url(#paint0_linear_4947_7565)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.16418 8.85307L7.35585 7.77391C7.04085 7.58724 6.78418 7.13807 6.78418 6.77057V4.37891" stroke="url(#paint1_linear_4947_7565)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<defs>
<linearGradient id="paint0_linear_4947_7565" x1="3.65588" y1="4.45408" x2="10.9475" y2="10.0541" gradientUnits="userSpaceOnUse">
<stop stop-color="#3A6FFD"/>
<stop offset="1" stop-color="#D63ACA"/>
</linearGradient>
<linearGradient id="paint1_linear_4947_7565" x1="7.29191" y1="5.63913" x2="9.31854" y2="6.46707" gradientUnits="userSpaceOnUse">
<stop stop-color="#3A6FFD"/>
<stop offset="1" stop-color="#D63ACA"/>
</linearGradient>
</defs>
</svg>
12 : 00 TO 02 : 00
      </p>
      
    </div>
  </Col>
  <Col lg={12}>
    <div className="cardappointment ">
      <p className='textappointment'>
      Mental Therapy For Live a good Life
      </p>
      <p className='timeappointment'>
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.8337 7.0013C12.8337 10.2213 10.2203 12.8346 7.00033 12.8346C3.78033 12.8346 1.16699 10.2213 1.16699 7.0013C1.16699 3.7813 3.78033 1.16797 7.00033 1.16797C10.2203 1.16797 12.8337 3.7813 12.8337 7.0013Z" stroke="url(#paint0_linear_4947_7565)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.16418 8.85307L7.35585 7.77391C7.04085 7.58724 6.78418 7.13807 6.78418 6.77057V4.37891" stroke="url(#paint1_linear_4947_7565)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<defs>
<linearGradient id="paint0_linear_4947_7565" x1="3.65588" y1="4.45408" x2="10.9475" y2="10.0541" gradientUnits="userSpaceOnUse">
<stop stop-color="#3A6FFD"/>
<stop offset="1" stop-color="#D63ACA"/>
</linearGradient>
<linearGradient id="paint1_linear_4947_7565" x1="7.29191" y1="5.63913" x2="9.31854" y2="6.46707" gradientUnits="userSpaceOnUse">
<stop stop-color="#3A6FFD"/>
<stop offset="1" stop-color="#D63ACA"/>
</linearGradient>
</defs>
</svg>
12 : 00 TO 02 : 00
      </p>
      
    </div>
  </Col>
  <Col lg={12}>
    <div className="cardappointment ">
      <p className='textappointment'>
      Mental Therapy For Live a good Life
      </p>
      <p className='timeappointment'>
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.8337 7.0013C12.8337 10.2213 10.2203 12.8346 7.00033 12.8346C3.78033 12.8346 1.16699 10.2213 1.16699 7.0013C1.16699 3.7813 3.78033 1.16797 7.00033 1.16797C10.2203 1.16797 12.8337 3.7813 12.8337 7.0013Z" stroke="url(#paint0_linear_4947_7565)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.16418 8.85307L7.35585 7.77391C7.04085 7.58724 6.78418 7.13807 6.78418 6.77057V4.37891" stroke="url(#paint1_linear_4947_7565)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<defs>
<linearGradient id="paint0_linear_4947_7565" x1="3.65588" y1="4.45408" x2="10.9475" y2="10.0541" gradientUnits="userSpaceOnUse">
<stop stop-color="#3A6FFD"/>
<stop offset="1" stop-color="#D63ACA"/>
</linearGradient>
<linearGradient id="paint1_linear_4947_7565" x1="7.29191" y1="5.63913" x2="9.31854" y2="6.46707" gradientUnits="userSpaceOnUse">
<stop stop-color="#3A6FFD"/>
<stop offset="1" stop-color="#D63ACA"/>
</linearGradient>
</defs>
</svg>
12 : 00 TO 02 : 00
      </p>
      
    </div>
  </Col>


  <Col lg={12} className='d-flex'>
    <div>
    <h2 className='headingaboutpatient mt-3'>Journal</h2>
    <p className='subheadaboutpatient'></p>
    </div>
    <div style={{marginLeft:'auto'}}>
    <div className="client-detaile-div px-3">
           
            <div class="client-detail-button">
              See All 
              
            </div>
            </div>
    </div>
  </Col>
  <Col lg={12}>
    <div className="cardappointment d-flex w-100 ">
      <div className='' >
      <p className='textappointment'>
      Journal 1
      </p>
      <p className='timeappointment'>
      
      17 - May - 2023
      </p></div>
      <div className='d-flex' style={{marginLeft:'auto', marginTop:'auto',marginBottom:'auto'}}>
      <button class="btn-detaile" style={{marginTop:'-13px'}} onClick={() => setLgShow(true)}>
              Click to See
              
            </button>
            <Modal
        size="xl"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        className="journypopup"
      >
       
        <Modal.Body className="journypopup px-3 py-4">
          <Container>
            <Container className="d-flex headingjournal mb-4">
              <Row className="d-flex headingjournal">
                <Col md={4}>
              <h1 className="journalh1">Journal 1</h1>{" "}</Col><Col md={4}>
              <div className="datejournal">
                <span>Date</span>&nbsp;&nbsp; <span>10=May-2023</span>
              </div></Col>
              <Col md={4}><div className="d-flex downloadbutton py-0">
                <ButtonStyled title="Download" className="btn-detaile fontdown my-0" />
              </div></Col>
              </Row>
            </Container>

            <Container>
              <Container>
               
                <Row className="d-flex ">
                  <Col lg={6}>
                  <Card className="journycardpop border-0 mb-4">
                    <Card.Body className="journycardpop p-4">
                      <h4>TO=DO</h4>
                      <p className="textjourney" style={{fontFamily:'Poppins, sans-serif'}}> Lorem Ipsum arena iliamina losem loremLorem Ipsum arena iliamina losem lorLorem Ipsum arena iliamina losem loremLorem Ipsum arena iliamina losem loremLorem Ipsum arena iliamina losem lorLorem Ipsum arena iliamina losem  </p>
                    </Card.Body>
                  </Card>
                  </Col><Col lg={6}>
                  <Card className="journycard1pop border-0 mb-4">
                    <Card.Body className="journycard1pop p-4">
                      <h4>GOALS</h4>
                      <p className="textjourney" style={{fontFamily:'Poppins, sans-serif'}}> Lorem Ipsum arena iliamina losem loremLorem Ipsum arena iliamina losem lorLorem Ipsum arena iliamina losem loremLorem Ipsum arena iliamina losem loremLorem Ipsum arena iliamina losem lorLorem Ipsum arena iliamina losem </p>
                    </Card.Body>
                  </Card></Col>
                </Row>
                
              </Container>
              <Container>
              <h2 className="journalh1"> MEAL TRACKER</h2>
              <Row className="d-flex">
                <Col md={6}>
                <Card className="journycard2pop border-0 mb-4">
                  <Card.Body className="journycard2pop p-4">
                  <p className="textcard2"> Eggs. Greek yogurt. Coffee, Oatmeal. </p>
                  </Card.Body>
                </Card>
                </Col><Col md={6}>
                <Card className="journycard3pop border-0 mb-4">
                  <Card.Body className="journycard3pop p-4">
                    <p className="textcard2">Breakfast</p>
                  </Card.Body>
                </Card></Col>
              </Row>
              <Row className="d-flex">
              <Col md={6}>
                <Card className="journycard4pop border-0 mb-4">
                  <Card.Body className="journycard4pop p-4">
                  <p className="textcard2">Breakfast</p>
                  </Card.Body>
                </Card>
                </Col>
                <Col md={6}>
                <Card className="journycard5pop border-0 mb-4">
                  <Card.Body className="journycard5pop p-4">
                  <p className="textcard2">Breakfast</p>
                  </Card.Body>
                </Card>
                </Col>
              </Row>
              </Container>
              <Container>
              <Card className="mb-4 journycard7pop border-0">
                <Card.Body className="journycard7pop p-4">
                  <h4>TODAY I AM GRATEFUL FOR</h4>
                  <p className="textcard2">Lorem Ipsum arena iliamina losem loremLorem Ipsum arena iliamina losem lorLorem Ipsum arena iliamina losem loremLorem Ipsum arena iliamina losem loremLorem Ipsum arena iliamina losem lorLorem Ipsum arena iliamina losem </p>
                </Card.Body>
              </Card></Container>
            </Container>
          </Container>
        </Modal.Body>
      </Modal>
      </div>
    </div>
  </Col>
</Row>

            </Col>
            </Row> 
        </Container>
        </div>
   </AdminLayout>
  )
}

export default Clientprofile
