/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const Icon81 = ({ color = "#F6FAFB", className }) => {
  return (
    <svg
      className={`icon-8-1 ${className}`}
      fill="none"
      height="26"
      viewBox="0 0 26 26"
      width="26"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path className="path" d="M4 12.9084L22 12.9084" stroke={color} strokeWidth="4" />
      <path className="path" d="M13.0914 22V3.99995" stroke={color} strokeWidth="4" />
    </svg>
  );
};

Icon81.propTypes = {
  color: PropTypes.string,
};
