import React from "react";
import Navigation from "../../components/Navigation";
import RegisterationSection from "../../components/Home/RegisterationSection";
import Footer from "../../components/Footer";
import Images from "../../constants/Images";
import { Container } from "react-bootstrap";


export const BlogDetails = () => {
  return (
    <div className="BLOG-DETAILS">
      <div className="div">
        <Navigation/>
        {/* <img
          className="image"
          alt="Image"
          src={Images.News_2}
        /> */}
        <div className="image">

        </div>
       
      
       
       
       
        <div className="frame-4">
          <div className="frame-5">
            <div className="text-wrapper-11">12.11.2022</div>
            <p className="text-wrapper-12">Identifying And Treating Pain From Nerve Tension</p>
          </div>
          <p className="lorem-ipsum-dolor">
            Lorem ipsum dolor sit amet, consectetur adipiscng elit, sed do eiusmod tempor Lorem ipsum dolor sit ame
            Lorem ipsum dolor sit amet, consecte elit, sed do&nbsp;&nbsp;tempor Lorem ipsum dolor sit amet...Lorem ipsum
            dolor sit amet, consectetur adipiscing elit, sed do&nbsp;&nbsp;tempor Lor dolor sit ame Lorem ipsum dolor
            sit amet, consectetur adipiscing elit, sed do eiusmod tempor Lorem ipsum dolor sit ...
          </p>
          <p className="lorem-ipsum-dolor">
            Lorem ipsum dolor sit amet, consectetur adipiscng elit, sed do eiusmod tempor Lorem ipsum dolor sit ame
            Lorem ipsum dolor sit amet, consecte elit, sed do&nbsp;&nbsp;tempor Lorem ipsum dolor sit amet...Lorem ipsum
            dolor sit amet, consectetur adipiscing elit, sed do&nbsp;&nbsp;tempor Lor dolor sit ame Lorem ipsum dolor
            sit amet, consectetur adipiscing elit, sed do eiusmod tempor Lorem ipsum dolor sit Lorem ipsum dolor sit
            amet, consectetur adipiscng elit, sed do eiusmod tempor Lorem ipsum dolor sit ame Lorem ipsum dolor sit
            amet, consecte elit, sed do&nbsp;&nbsp;tempor Lorem ipsum dolor sit amet...Lorem ipsum dolor sit amet,
            consectetur adipiscing elit, sed do&nbsp;&nbsp;tempor Lor dolor sit ame Lorem ipsum dolor sit amet,
            consectetur adipiscing elit, sed do eiusmod tempor Lorem ipsum dolor sit ...
          </p>
          <p className="lorem-ipsum-dolor">
            Lorem ipsum dolor sit amet, consectetur adipiscng elit, sed do eiusmod tempor Lorem ipsum dolor sit ame
            Lorem ipsum dolor sit amet, consecte elit, sed do&nbsp;&nbsp;tempor Lorem ipsum dolor sit amet...Lorem ipsum
            dolor sit amet, consectetur adipiscing elit, sed do&nbsp;&nbsp;tempor Lor dolor sit ame Lorem ipsum dolor
            sit amet, consectetur adipiscing elit, sed do eiusmod tempor Lorem ipsum dolor sit Lorem ipsum dolor sit
            amet, consectetur adipiscng elit, sed do eiusmod tempor Lorem ipsum dolor sit ame Lorem ipsum
          </p>
        </div>
        <div className="frame-6 ">
          <div className="related-article-s">Related Article&#39;s</div>
          <div className="frame-7">
            <div className="frame-8">
             
              <div className="frame-9">
            
                
              </div>
            </div>
            <div className="frame-8">
            
              <div className="frame-9">
             
               
              </div>
            </div>
            <div className="frame-8">
              
              <div className="frame-9">
             
               
              </div>
            </div>
          </div>
        </div>
       
        <div className="footer">
        <RegisterationSection/>
        <Footer/>
        </div>
      </div>
    </div>
  );
};
export default BlogDetails;
