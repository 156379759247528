import React from "react";
import { Button, Col, Image, Row, Form } from "react-bootstrap";

const GroupedOptions = ({ values, isImage }) => {
  return (
    <div className="GroupedOptions">
      {values &&
        values.map((value, index) => {
          return (
            <Form.Check
              type={value.type}
              name={value.name}
              value={value.text}
              label={
                <>
                  {isImage ? (
                    <>{value.image && <Image src={value.image} style={{width: '37.06px',height: '37.03px',marginRight:'7px'}}/>}</>
                  ) : null}
                  <span><div className="vtext">{value.text}</div></span>
                </>
              }
              id={`${value.name}-${index}`}
            />
          );
        })}
    </div>
  );
};

export default GroupedOptions;
