import React from "react";
import AdminLayout from "../../layouts/AdminLayout";

import ButtonStyled from "../../components/ButtonStyled";
import { Container } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { Row, Col } from "react-bootstrap";
import { DropDown1 } from "../../View/Myspecllist.js/icons/DropDown1";
import "./myjourny.scss";

function MyJourny() {
  return (
    <AdminLayout>
      <div className="bg-white w-100">
      <Container className="bg-white main-journey">
        <Container>
          <Container>
          <div className="journyhead">
            <div>
              <h1 className="journy-header">MyJournal</h1>{" "}
            </div>        
            <div className="date1">
              <div>
              <span>Date</span>
              </div>
              <div>
              <span>10=May-2023</span>
              </div>
              <div>
              <DropDown1/>
              </div>
              
            </div>
            <div className="d-flex last2button">
              <div className="myjourny-submit">
                <button className="myjourny-submit-button"> &nbsp;&nbsp;Submit</button>
              </div>
                <span className="jouryheaderbutton"> See Pervious Submit</span>
            </div>
          </div>
          </Container>
       
        </Container>

        <Container>
          <Container>
            <Row className="todo-row">
              <Col className="col-6 todo-card">
                {" "}
                
                <Card className="journycard border-0 mb-4">
                  <Card.Body className="journycard ">
                    <div className="todo-content">
                      <p className="todo-1">TO=DO</p>
                      <p className="todo-2">Write Here What You Want To write </p>
                    </div>
                    
                  </Card.Body>
                </Card>
              </Col>
              <Col className="col-6">
                <Card className="journycard1 border-0 mb-4">
                  <Card.Body className="journycard1">
                  <div className="todo-content">
                    <p className="todo-1">GOALS</p>
                    <p className="todo-2">Write Here What You Want To write </p>
                  </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
          <Container>
          <h1 className="meal-tracker"> MEAL TRACKER</h1>  
          </Container>
          
          <Row>
            <Col className="col-6">
              <Card className="journycard2 border-0 mb-4">
                <Card.Body className="journycard2">
                  <div className="breakfast">
                    <h4 className="breakfast-text">Breakfast</h4>
                  </div>
                  
                </Card.Body>
              </Card>
            </Col>
            <Col className="col-6">
              <Card className="journycard3 border-0 mb-4">
                <Card.Body className="journycard3">
                <div className="breakfast">
                  <h4 className="breakfast-text">Lunch</h4>
                </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-6">
              <Card className="journycard4 border-0 mb-4">
                <Card.Body className="journycard4">
                <div className="breakfast">
                  <h4 className="breakfast-text">Dinner</h4>
                </div>
                </Card.Body>
              </Card>
            </Col>
            <Col className="col-6">
              <Card className="journycard5 border-0 mb-4">
                <Card.Body className="journycard5">
                <div className="breakfast">
                  <h4 className="breakfast-text">Snacks</h4>
                </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
                <Card className="mb-4 journycard7 border-0 mb-4">
                <Card.Body className="journycard7">
                  <div className="today-div">
                    <h4 className="today-i-am">TODAY I AM GRATEFUL FOR</h4>
                    <p className="write-here">Write Here What You Want To write </p>
                  </div>
                  
                </Card.Body>
              </Card>
            </Col>
          </Row>
          
          <h2 className="exercise">EXERCISE</h2>
          <Row>
            <Col className="col-6">
              <Card className="journycard8 border-0 mb-4">
                <Card.Body className="journycard81">
                  <p className="total-minutes">Total minutes</p>
                </Card.Body>
              </Card>
            </Col>
            <Col className="col-6">
              <Card className="journycard9 border-0 mb-4">
                <Card.Body className="journycard91">
                  <p className="total-minutes">Total Steps</p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
            <Row className="self-care-row">
              <Col className="col-lg-4 selfcare col-sm-12">
                <Card className="journycard10 border-0 mb-4">
                  <Card.Body className="journycard10">
                    <div className="self-div">
                      <h4 className="self-text">SELF CARE</h4>
                      <p className="self-text-write">Write Here What You Want To write </p>
                    </div>
                    
                  </Card.Body>
                </Card>
              </Col>
              <Col className="col-lg-4 selfcare col-sm-12">
                <Card className="journycard11 border-0 mb-4">
                  <Card.Body className="journycard11">
                  <div className="self-div">
                    <h4 className="self-text">NOTES</h4>
                    <p className="self-text-write"> Write Here What You Want To write </p>
                  </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="col-lg-4 selfcare col-sm-12">
                <Card className="journycard12 border-0 mb-4">
                  <Card.Body className="journycard12">
                  <div className="self-div">
                    <h4 className="self-text">FOR TOMMORROW</h4>
                    <p className="self-text-write">Write Here What You Want To write </p>
                  </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
        </Container>
      </Container></div>
    </AdminLayout>
  );
}

export default MyJourny;
