import React from "react";
import Navigation from "../../components/Navigation";
import Images from "../../constants/Images";
import "./style.scss";
export const Errorpage = () => {
  return (
    <div className="">
      <Navigation />
      <div className="errorpage">
        <img
          src={Images.notfound}
          alt="page not found"
          className="pagenotfound"
        />{" "}
      </div>
    </div>
  );
};
