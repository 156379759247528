import React, { useState } from 'react';
import {MdExpandMore} from "react-icons/md";

function TimeSelector({labell}) {
  const [selectedHour, setSelectedHour] = useState('10');
  const [selectedMinute, setSelectedMinute] = useState('00');
  const [selectedPeriod, setSelectedPeriod] = useState('PM');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleHourChange = (e) => {
    setSelectedHour(e.target.value);
  };

  const handleMinuteChange = (e) => {
    setSelectedMinute(e.target.value);
  };

  const handlePeriodChange = (e) => {
    setSelectedPeriod(e.target.value);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div>
      <label htmlFor="timePicker" className='labelfortime'>{labell}</label>
      <div className="time-selector">
      <div className="w-100 text-field-time" onClick={toggleDropdown}>
        <input
          id="timePicker"
          type="text"
          
          value={`${selectedHour}:${selectedMinute} ${selectedPeriod}`}
          readOnly
          onClick={toggleDropdown}
          className='w-100 h-100'
        />
       < MdExpandMore/>
        </div>
        {isDropdownOpen && (
          <div className="time-dropdown">
            <select value={selectedHour} onChange={handleHourChange}>
              {Array.from({ length: 12 }, (_, i) => (
                <option key={i} value={String(i + 1).padStart(2, '0')}>
                  {String(i + 1).padStart(2, '0')}
                </option>
             ) )}
            </select>
            :
            <select value={selectedMinute} onChange={handleMinuteChange}>
              {Array.from({ length: 60 }, (_, i) => (
                <option key={i} value={String(i).padStart(2, '0')}>
                  {String(i).padStart(2, '0')}
                </option>
               ) )}
            </select>
            <select value={selectedPeriod} onChange={handlePeriodChange}>
              <option value="AM">AM</option>
              <option value="PM">PM</option>
            </select>
          </div>
        )}
      </div>
    </div>
  );
}

export default TimeSelector;
