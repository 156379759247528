/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const Favorite = ({ color = "#041625", className }) => {
  return (
    <svg
      className={`favorite ${className}`}
      fill="none"
      height="26"
      viewBox="0 0 26 26"
      width="26"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M4.82222 15.0674L12.3542 22.1429C12.6415 22.4128 12.7852 22.5477 12.9586 22.5624C12.9866 22.5647 13.0147 22.5647 13.0427 22.5624C13.2161 22.5477 13.3598 22.4128 13.6471 22.1429L21.1791 15.0674C23.2983 13.0766 23.5556 9.80065 21.7733 7.5034L21.4381 7.07145C19.306 4.32329 15.0261 4.78418 13.5279 7.92328C13.3162 8.3667 12.6851 8.3667 12.4734 7.92328C10.9752 4.78418 6.69535 4.32329 4.56316 7.07145L4.22802 7.5034C2.44568 9.80065 2.70302 13.0766 4.82222 15.0674Z"
        stroke={color}
      />
    </svg>
  );
};

Favorite.propTypes = {
  color: PropTypes.string,
};
