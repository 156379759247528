import { Button, Container, Form } from "react-bootstrap";
import Input from "../../components/Input";
import ButtonStyled from "../../components/ButtonStyled";
import Images from "../../constants/Images";
import GroupedOptions from "../../components/GroupedOptions";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthLayout from "../../layouts/AuthLayout";
import GroupedOptions1 from "../../components/Groupedoptions1";
import GroupedOptions2 from "../../components/GroupedOptions2";
import {FaLongArrowAltRight} from 'react-icons/fa';

const SignUpStepTwo = () => {
  const navigate = useNavigate();

  const [values, setValues] = useState([
    {
      text: "Male",
      image: null,
      type: "radio",
      name: "gender",
    },
    {
      text: "Female",
      image: null,
      type: "radio",
      name: "gender",
    },
    {
      text: "Rather To say",
      image: null,
      type: "radio",
      name: "gender",
    },
  ]);
  return (
    <AuthLayout>
      <section
        className="LoginSection"
        style={{
          backgroundImage: `url(${Images.SignUpBG})`,
        }}
      >
        <Container fluid className="main-div">
          <div className="login_form">
            <div>
              <h1>
                Add Your<span>{" "}Details</span>
              </h1>
              <p className="text-center continue">Lorem Ipsum ipsum Lorem</p>
              <Form>
                <Input label="Date of Birth" inputType="date" />
                <Form.Group
                  className="mb-3 form-group"
                  controlId="exampleFormControlInput2"
                >
                  <Form.Label className="mb-2">Select Your Gender</Form.Label>
                  <GroupedOptions2 values={values} isImage={false} />
                </Form.Group>
                <Form.Group
                  className="mb-3 form-group"
                  controlId="exampleFormControlSelect1"
                >
                  <Form.Label className="form-group">Country</Form.Label>
                  <Form.Select>
                    <option value="Afghanistan">Afghanistan</option>
                    <option value="Albania">Albania</option>
                    <option value="Algeria">Algeria</option>
                    <option value="American Samoa">American Samoa</option>
                    <option value="Andorra">Andorra</option>
                    <option value="Angola">Angola</option>
                    <option value="Anguilla">Anguilla</option>
                    <option value="Antartica">Antarctica</option>
                    <option value="Antigua and Barbuda">
                      Antigua and Barbuda
                    </option>
                    <option value="Argentina">Argentina</option>
                    <option value="Armenia">Armenia</option>
                    <option value="Aruba">Aruba</option>
                    <option value="Australia">Australia</option>
                    <option value="Austria">Austria</option>
                    <option value="Azerbaijan">Azerbaijan</option>
                    <option value="Bahamas">Bahamas</option>
                    <option value="Bahrain">Bahrain</option>
                    <option value="Bangladesh">Bangladesh</option>
                    <option value="Barbados">Barbados</option>
                    <option value="Belarus">Belarus</option>
                    <option value="Belgium">Belgium</option>
                    <option value="Belize">Belize</option>
                    <option value="Benin">Benin</option>
                    <option value="Bermuda">Bermuda</option>
                    <option value="Bhutan">Bhutan</option>
                    <option value="Bolivia">Bolivia</option>
                    <option value="Bosnia and Herzegowina">
                      Bosnia and Herzegowina
                    </option>
                    <option value="Botswana">Botswana</option>
                    <option value="Bouvet Island">Bouvet Island</option>
                    <option value="Brazil">Brazil</option>
                    <option value="British Indian Ocean Territory">
                      British Indian Ocean Territory
                    </option>
                    <option value="Brunei Darussalam">Brunei Darussalam</option>
                    <option value="Bulgaria">Bulgaria</option>
                    <option value="Burkina Faso">Burkina Faso</option>
                    <option value="Burundi">Burundi</option>
                    <option value="Cambodia">Cambodia</option>
                    <option value="Cameroon">Cameroon</option>
                    <option value="Canada">Canada</option>
                    <option value="Cape Verde">Cape Verde</option>
                    <option value="Cayman Islands">Cayman Islands</option>
                    <option value="Central African Republic">
                      Central African Republic
                    </option>
                    <option value="Chad">Chad</option>
                    <option value="Chile">Chile</option>
                    <option value="China">China</option>
                    <option value="Christmas Island">Christmas Island</option>
                    <option value="Cocos Islands">
                      Cocos (Keeling) Islands
                    </option>
                    <option value="Colombia">Colombia</option>
                    <option value="Comoros">Comoros</option>
                    <option value="Congo">Congo</option>
                    <option value="Congo">
                      Congo, the Democratic Republic of the
                    </option>
                    <option value="Cook Islands">Cook Islands</option>
                    <option value="Costa Rica">Costa Rica</option>
                    <option value="Cota D'Ivoire">Cote d'Ivoire</option>
                    <option value="Croatia">Croatia (Hrvatska)</option>
                    <option value="Cuba">Cuba</option>
                    <option value="Cyprus">Cyprus</option>
                    <option value="Czech Republic">Czech Republic</option>
                    <option value="Denmark">Denmark</option>
                    <option value="Djibouti">Djibouti</option>
                    <option value="Dominica">Dominica</option>
                    <option value="Dominican Republic">
                      Dominican Republic
                    </option>
                    <option value="East Timor">East Timor</option>
                    <option value="Ecuador">Ecuador</option>
                    <option value="Egypt">Egypt</option>
                    <option value="El Salvador">El Salvador</option>
                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                    <option value="Eritrea">Eritrea</option>
                    <option value="Estonia">Estonia</option>
                    <option value="Ethiopia">Ethiopia</option>
                    <option value="Falkland Islands">
                      Falkland Islands (Malvinas)
                    </option>
                    <option value="Faroe Islands">Faroe Islands</option>
                    <option value="Fiji">Fiji</option>
                    <option value="Finland">Finland</option>
                    <option value="France">France</option>
                    <option value="France Metropolitan">
                      France, Metropolitan
                    </option>
                    <option value="French Guiana">French Guiana</option>
                    <option value="French Polynesia">French Polynesia</option>
                    <option value="French Southern Territories">
                      French Southern Territories
                    </option>
                    <option value="Gabon">Gabon</option>
                    <option value="Gambia">Gambia</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Germany">Germany</option>
                    <option value="Ghana">Ghana</option>
                    <option value="Gibraltar">Gibraltar</option>
                    <option value="Greece">Greece</option>
                    <option value="Greenland">Greenland</option>
                    <option value="Grenada">Grenada</option>
                    <option value="Guadeloupe">Guadeloupe</option>
                    <option value="Guam">Guam</option>
                    <option value="Guatemala">Guatemala</option>
                    <option value="Guinea">Guinea</option>
                    <option value="Guinea-Bissau">Guinea-Bissau</option>
                    <option value="Guyana">Guyana</option>
                    <option value="Haiti">Haiti</option>
                    <option value="Heard and McDonald Islands">
                      Heard and Mc Donald Islands
                    </option>
                    <option value="Holy See">
                      Holy See (Vatican City State)
                    </option>
                    <option value="Honduras">Honduras</option>
                    <option value="Hong Kong">Hong Kong</option>
                    <option value="Hungary">Hungary</option>
                    <option value="Iceland">Iceland</option>
                    <option value="India">India</option>
                    <option value="Indonesia">Indonesia</option>
                    <option value="Iran">Iran (Islamic Republic of)</option>
                    <option value="Iraq">Iraq</option>
                    <option value="Ireland">Ireland</option>
                    <option value="Israel">Israel</option>
                    <option value="Italy">Italy</option>
                    <option value="Jamaica">Jamaica</option>
                    <option value="Japan">Japan</option>
                    <option value="Jordan">Jordan</option>
                    <option value="Kazakhstan">Kazakhstan</option>
                    <option value="Kenya">Kenya</option>
                    <option value="Kiribati">Kiribati</option>
                    <option value="Democratic People's Republic of Korea">
                      Korea, Democratic People's Republic of
                    </option>
                    <option value="Korea">Korea, Republic of</option>
                    <option value="Kuwait">Kuwait</option>
                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                    <option value="Lao">
                      Lao People's Democratic Republic
                    </option>
                    <option value="Latvia">Latvia</option>
                    <option value="Lebanon" selected>
                      Lebanon
                    </option>
                    <option value="Lesotho">Lesotho</option>
                    <option value="Liberia">Liberia</option>
                    <option value="Libyan Arab Jamahiriya">
                      Libyan Arab Jamahiriya
                    </option>
                    <option value="Liechtenstein">Liechtenstein</option>
                    <option value="Lithuania">Lithuania</option>
                    <option value="Luxembourg">Luxembourg</option>
                    <option value="Macau">Macau</option>
                    <option value="Macedonia">
                      Macedonia, The Former Yugoslav Republic of
                    </option>
                    <option value="Madagascar">Madagascar</option>
                    <option value="Malawi">Malawi</option>
                    <option value="Malaysia">Malaysia</option>
                    <option value="Maldives">Maldives</option>
                    <option value="Mali">Mali</option>
                    <option value="Malta">Malta</option>
                    <option value="Marshall Islands">Marshall Islands</option>
                    <option value="Martinique">Martinique</option>
                    <option value="Mauritania">Mauritania</option>
                    <option value="Mauritius">Mauritius</option>
                    <option value="Mayotte">Mayotte</option>
                    <option value="Mexico">Mexico</option>
                    <option value="Micronesia">
                      Micronesia, Federated States of
                    </option>
                    <option value="Moldova">Moldova, Republic of</option>
                    <option value="Monaco">Monaco</option>
                    <option value="Mongolia">Mongolia</option>
                    <option value="Montserrat">Montserrat</option>
                    <option value="Morocco">Morocco</option>
                    <option value="Mozambique">Mozambique</option>
                    <option value="Myanmar">Myanmar</option>
                    <option value="Namibia">Namibia</option>
                    <option value="Nauru">Nauru</option>
                    <option value="Nepal">Nepal</option>
                    <option value="Netherlands">Netherlands</option>
                    <option value="Netherlands Antilles">
                      Netherlands Antilles
                    </option>
                    <option value="New Caledonia">New Caledonia</option>
                    <option value="New Zealand">New Zealand</option>
                    <option value="Nicaragua">Nicaragua</option>
                    <option value="Niger">Niger</option>
                    <option value="Nigeria">Nigeria</option>
                    <option value="Niue">Niue</option>
                    <option value="Norfolk Island">Norfolk Island</option>
                    <option value="Northern Mariana Islands">
                      Northern Mariana Islands
                    </option>
                    <option value="Norway">Norway</option>
                    <option value="Oman">Oman</option>
                    <option value="Pakistan">Pakistan</option>
                    <option value="Palau">Palau</option>
                    <option value="Panama">Panama</option>
                    <option value="Papua New Guinea">Papua New Guinea</option>
                    <option value="Paraguay">Paraguay</option>
                    <option value="Peru">Peru</option>
                    <option value="Philippines">Philippines</option>
                    <option value="Pitcairn">Pitcairn</option>
                    <option value="Poland">Poland</option>
                    <option value="Portugal">Portugal</option>
                    <option value="Puerto Rico">Puerto Rico</option>
                    <option value="Qatar">Qatar</option>
                    <option value="Reunion">Reunion</option>
                    <option value="Romania">Romania</option>
                    <option value="Russia">Russian Federation</option>
                    <option value="Rwanda">Rwanda</option>
                    <option value="Saint Kitts and Nevis">
                      Saint Kitts and Nevis
                    </option>
                    <option value="Saint LUCIA">Saint LUCIA</option>
                    <option value="Saint Vincent">
                      Saint Vincent and the Grenadines
                    </option>
                    <option value="Samoa">Samoa</option>
                    <option value="San Marino">San Marino</option>
                    <option value="Sao Tome and Principe">
                      Sao Tome and Principe
                    </option>
                    <option value="Saudi Arabia">Saudi Arabia</option>
                    <option value="Senegal">Senegal</option>
                    <option value="Seychelles">Seychelles</option>
                    <option value="Sierra">Sierra Leone</option>
                    <option value="Singapore">Singapore</option>
                    <option value="Slovakia">Slovakia (Slovak Republic)</option>
                    <option value="Slovenia">Slovenia</option>
                    <option value="Solomon Islands">Solomon Islands</option>
                    <option value="Somalia">Somalia</option>
                    <option value="South Africa">South Africa</option>
                    <option value="South Georgia">
                      South Georgia and the South Sandwich Islands
                    </option>
                    <option value="Span">Spain</option>
                    <option value="SriLanka">Sri Lanka</option>
                    <option value="St. Helena">St. Helena</option>
                    <option value="St. Pierre and Miguelon">
                      St. Pierre and Miquelon
                    </option>
                    <option value="Sudan">Sudan</option>
                    <option value="Suriname">Suriname</option>
                    <option value="Svalbard">
                      Svalbard and Jan Mayen Islands
                    </option>
                    <option value="Swaziland">Swaziland</option>
                    <option value="Sweden">Sweden</option>
                    <option value="Switzerland">Switzerland</option>
                    <option value="Syria">Syrian Arab Republic</option>
                    <option value="Taiwan">Taiwan, Province of China</option>
                    <option value="Tajikistan">Tajikistan</option>
                    <option value="Tanzania">
                      Tanzania, United Republic of
                    </option>
                    <option value="Thailand">Thailand</option>
                    <option value="Togo">Togo</option>
                    <option value="Tokelau">Tokelau</option>
                    <option value="Tonga">Tonga</option>
                    <option value="Trinidad and Tobago">
                      Trinidad and Tobago
                    </option>
                    <option value="Tunisia">Tunisia</option>
                    <option value="Turkey">Turkey</option>
                    <option value="Turkmenistan">Turkmenistan</option>
                    <option value="Turks and Caicos">
                      Turks and Caicos Islands
                    </option>
                    <option value="Tuvalu">Tuvalu</option>
                    <option value="Uganda">Uganda</option>
                    <option value="Ukraine">Ukraine</option>
                    <option value="United Arab Emirates">
                      United Arab Emirates
                    </option>
                    <option value="United Kingdom">United Kingdom</option>
                    <option value="United States">United States</option>
                    <option value="United States Minor Outlying Islands">
                      United States Minor Outlying Islands
                    </option>
                    <option value="Uruguay">Uruguay</option>
                    <option value="Uzbekistan">Uzbekistan</option>
                    <option value="Vanuatu">Vanuatu</option>
                    <option value="Venezuela">Venezuela</option>
                    <option value="Vietnam">Viet Nam</option>
                    <option value="Virgin Islands (British)">
                      Virgin Islands (British)
                    </option>
                    <option value="Virgin Islands (U.S)">
                      Virgin Islands (U.S.)
                    </option>
                    <option value="Wallis and Futana Islands">
                      Wallis and Futuna Islands
                    </option>
                    <option value="Western Sahara">Western Sahara</option>
                    <option value="Yemen">Yemen</option>
                    <option value="Serbia">Serbia</option>
                    <option value="Zambia">Zambia</option>
                    <option value="Zimbabwe">Zimbabwe</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group
                  className="mb-3 form-group"
                  controlId="exampleFormControlSelect2"
                >
                  <Form.Label>Time Zone</Form.Label>
                  <Form.Select name="timezone">
                    <option>Write Here</option>
                    <option value="Etc/GMT+12">
                      (GMT-12:00) International Date Line West
                    </option>
                    <option value="Pacific/Midway">
                      (GMT-11:00) Midway Island, Samoa
                    </option>
                    <option value="Pacific/Honolulu">(GMT-10:00) Hawaii</option>
                    <option value="US/Alaska">(GMT-09:00) Alaska</option>
                    <option value="America/Los_Angeles">
                      (GMT-08:00) Pacific Time (US & Canada)
                    </option>
                    <option value="America/Tijuana">
                      (GMT-08:00) Tijuana, Baja California
                    </option>
                    <option value="US/Arizona">(GMT-07:00) Arizona</option>
                    <option value="America/Chihuahua">
                      (GMT-07:00) Chihuahua, La Paz, Mazatlan
                    </option>
                    <option value="US/Mountain">
                      (GMT-07:00) Mountain Time (US & Canada)
                    </option>
                    <option value="America/Managua">
                      (GMT-06:00) Central America
                    </option>
                    <option value="US/Central">
                      (GMT-06:00) Central Time (US & Canada)
                    </option>
                    <option value="America/Mexico_City">
                      (GMT-06:00) Guadalajara, Mexico City, Monterrey
                    </option>
                    <option value="Canada/Saskatchewan">
                      (GMT-06:00) Saskatchewan
                    </option>
                    <option value="America/Bogota">
                      (GMT-05:00) Bogota, Lima, Quito, Rio Branco
                    </option>
                    <option value="US/Eastern">
                      (GMT-05:00) Eastern Time (US & Canada)
                    </option>
                    <option value="US/East-Indiana">
                      (GMT-05:00) Indiana (East)
                    </option>
                    <option value="Canada/Atlantic">
                      (GMT-04:00) Atlantic Time (Canada)
                    </option>
                    <option value="America/Caracas">
                      (GMT-04:00) Caracas, La Paz
                    </option>
                    <option value="America/Manaus">(GMT-04:00) Manaus</option>
                    <option value="America/Santiago">
                      (GMT-04:00) Santiago
                    </option>
                    <option value="Canada/Newfoundland">
                      (GMT-03:30) Newfoundland
                    </option>
                    <option value="America/Sao_Paulo">
                      (GMT-03:00) Brasilia
                    </option>
                    <option value="America/Argentina/Buenos_Aires">
                      (GMT-03:00) Buenos Aires, Georgetown
                    </option>
                    <option value="America/Godthab">
                      (GMT-03:00) Greenland
                    </option>
                    <option value="America/Montevideo">
                      (GMT-03:00) Montevideo
                    </option>
                    <option value="America/Noronha">
                      (GMT-02:00) Mid-Atlantic
                    </option>
                    <option value="Atlantic/Cape_Verde">
                      (GMT-01:00) Cape Verde Is.
                    </option>
                    <option value="Atlantic/Azores">(GMT-01:00) Azores</option>
                    <option value="Africa/Casablanca">
                      (GMT+00:00) Casablanca, Monrovia, Reykjavik
                    </option>
                    <option value="Etc/Greenwich">
                      (GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh,
                      Lisbon, London
                    </option>
                    <option value="Europe/Amsterdam">
                      (GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm,
                      Vienna
                    </option>
                    <option value="Europe/Belgrade">
                      (GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana,
                      Prague
                    </option>
                    <option value="Europe/Brussels">
                      (GMT+01:00) Brussels, Copenhagen, Madrid, Paris
                    </option>
                    <option value="Europe/Sarajevo">
                      (GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb
                    </option>
                    <option value="Africa/Lagos">
                      (GMT+01:00) West Central Africa
                    </option>
                    <option value="Asia/Amman">(GMT+02:00) Amman</option>
                    <option value="Europe/Athens">
                      (GMT+02:00) Athens, Bucharest, Istanbul
                    </option>
                    <option value="Asia/Beirut">(GMT+02:00) Beirut</option>
                    <option value="Africa/Cairo">(GMT+02:00) Cairo</option>
                    <option value="Africa/Harare">
                      (GMT+02:00) Harare, Pretoria
                    </option>
                    <option value="Europe/Helsinki">
                      (GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius
                    </option>
                    <option value="Asia/Jerusalem">
                      (GMT+02:00) Jerusalem
                    </option>
                    <option value="Europe/Minsk">(GMT+02:00) Minsk</option>
                    <option value="Africa/Windhoek">
                      (GMT+02:00) Windhoek
                    </option>
                    <option value="Asia/Kuwait">
                      (GMT+03:00) Kuwait, Riyadh, Baghdad
                    </option>
                    <option value="Europe/Moscow">
                      (GMT+03:00) Moscow, St. Petersburg, Volgograd
                    </option>
                    <option value="Africa/Nairobi">(GMT+03:00) Nairobi</option>
                    <option value="Asia/Tbilisi">(GMT+03:00) Tbilisi</option>
                    <option value="Asia/Tehran">(GMT+03:30) Tehran</option>
                    <option value="Asia/Muscat">
                      (GMT+04:00) Abu Dhabi, Muscat
                    </option>
                    <option value="Asia/Baku">(GMT+04:00) Baku</option>
                    <option value="Asia/Yerevan">(GMT+04:00) Yerevan</option>
                    <option value="Asia/Kabul">(GMT+04:30) Kabul</option>
                    <option value="Asia/Yekaterinburg">
                      (GMT+05:00) Yekaterinburg
                    </option>
                    <option value="Asia/Karachi">
                      (GMT+05:00) Islamabad, Karachi, Tashkent
                    </option>
                    <option value="Asia/Calcutta">
                      (GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi
                    </option>
                    <option value="Asia/Calcutta">
                      (GMT+05:30) Sri Jayawardenapura
                    </option>
                    <option value="Asia/Katmandu">(GMT+05:45) Kathmandu</option>
                    <option value="Asia/Almaty">
                      (GMT+06:00) Almaty, Novosibirsk
                    </option>
                    <option value="Asia/Dhaka">
                      (GMT+06:00) Astana, Dhaka
                    </option>
                    <option value="Asia/Rangoon">
                      (GMT+06:30) Yangon (Rangoon)
                    </option>
                    <option value="Asia/Bangkok">
                      (GMT+07:00) Bangkok, Hanoi, Jakarta
                    </option>
                    <option value="Asia/Krasnoyarsk">
                      (GMT+07:00) Krasnoyarsk
                    </option>
                    <option value="Asia/Hong_Kong">
                      (GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi
                    </option>
                    <option value="Asia/Kuala_Lumpur">
                      (GMT+08:00) Kuala Lumpur, Singapore
                    </option>
                    <option value="Asia/Irkutsk">
                      (GMT+08:00) Irkutsk, Ulaan Bataar
                    </option>
                    <option value="Australia/Perth">(GMT+08:00) Perth</option>
                    <option value="Asia/Taipei">(GMT+08:00) Taipei</option>
                    <option value="Asia/Tokyo">
                      (GMT+09:00) Osaka, Sapporo, Tokyo
                    </option>
                    <option value="Asia/Seoul">(GMT+09:00) Seoul</option>
                    <option value="Asia/Yakutsk">(GMT+09:00) Yakutsk</option>
                    <option value="Australia/Adelaide">
                      (GMT+09:30) Adelaide
                    </option>
                    <option value="Australia/Darwin">(GMT+09:30) Darwin</option>
                    <option value="Australia/Brisbane">
                      (GMT+10:00) Brisbane
                    </option>
                    <option value="Australia/Canberra">
                      (GMT+10:00) Canberra, Melbourne, Sydney
                    </option>
                    <option value="Australia/Hobart">(GMT+10:00) Hobart</option>
                    <option value="Pacific/Guam">
                      (GMT+10:00) Guam, Port Moresby
                    </option>
                    <option value="Asia/Vladivostok">
                      (GMT+10:00) Vladivostok
                    </option>
                    <option value="Asia/Magadan">
                      (GMT+11:00) Magadan, Solomon Is., New Caledonia
                    </option>
                    <option value="Pacific/Auckland">
                      (GMT+12:00) Auckland, Wellington
                    </option>
                    <option value="Pacific/Fiji">
                      (GMT+12:00) Fiji, Kamchatka, Marshall Is.
                    </option>
                    <option value="Pacific/Tongatapu">
                      (GMT+13:00) Nuku'alofa
                    </option>
                  </Form.Select>
                </Form.Group>
                <Button
                className="w-100" style={{backgroundColor:'#0000001A',border:'1px solid white',color:'#68838E', borderRadius:'1000px',height:'55px'}}
                
                handler={() => {
                  navigate("/sign-up-step-3");
                }}
              >Next&nbsp; <FaLongArrowAltRight style={{marginTop:'-3px'}}/> </Button>
                
              </Form>
            </div>
          </div>
        </Container>
      </section>
    </AuthLayout>
  );
};

export default SignUpStepTwo;
