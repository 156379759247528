import React from 'react';
import "./changepaymentmethod.scss";
import { Card, Container } from 'react-bootstrap';
import AdminLayout from '../../../layouts/AdminLayout';

export const ChangeMethod = () => {
  const handleOptionClick = (event) => {
    // Find the radio input element associated with the clicked div
    const radioInput = event.currentTarget.querySelector('input[type="radio"]');

    if (radioInput) {
      // Check the radio button
      radioInput.checked = true;
    }
  };

  return (
    <AdminLayout>
        <div className='w-100 bg-white py-4' style={{minHeight:'100vh'}}>
      <Container className=''>

      <Card style={{ border:'none',boxShadow: '0 2px 13px rgba(0, 0, 0, 0.101)',borderRadius:'21px'}}>

          <Card.Body className='p-4'>
            <div className='label-for-method-of-payment'>Method of payment</div>
            <div className='change_method_option' onClick={handleOptionClick}>
              <input type="radio" id="creditcard" name="payment" defaultValue="creditcard" />
              <label htmlFor="creditcard"><svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.6665 15C17.8756 15 19.6665 13.2091 19.6665 11C19.6665 8.79086 17.8756 7 15.6665 7C13.4574 7 11.6665 8.79086 11.6665 11C11.6665 13.2091 13.4574 15 15.6665 15Z" stroke="#94A3B8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.9998 8.02C12.4244 7.50479 11.7121 7.16719 10.9491 7.04795C10.186 6.92872 9.40467 7.03296 8.69952 7.34808C7.99438 7.66321 7.39555 8.17574 6.97535 8.82378C6.55515 9.47182 6.33154 10.2277 6.33154 11C6.33154 11.7724 6.55515 12.5282 6.97535 13.1762C7.39555 13.8243 7.99438 14.3368 8.69952 14.6519C9.40467 14.967 10.186 15.0713 10.9491 14.9521C11.7121 14.8328 12.4244 14.4952 12.9998 13.98" stroke="#94A3B8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22.3333 1.66797H3.66667C2.19391 1.66797 1 2.86188 1 4.33464V17.668C1 19.1407 2.19391 20.3346 3.66667 20.3346H22.3333C23.8061 20.3346 25 19.1407 25 17.668V4.33464C25 2.86188 23.8061 1.66797 22.3333 1.66797Z" stroke="#94A3B8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
&nbsp;Credit/Debit Card</label>
            </div>
            <div className='change_method_option' onClick={handleOptionClick}>
              <input type="radio" id="paypal" name="payment" defaultValue="chocolate" />
              <label htmlFor="paypal"><svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.3335 6.6C21.6002 7.93333 22.6668 10.3333 22.6668 13C22.6668 16.3333 19.3335 19 16.0002 19H12.5335L11.7335 23.8C11.6712 24.1057 11.5036 24.3799 11.26 24.5748C11.0163 24.7697 10.7121 24.873 10.4002 24.8667H6.80016C6.70018 24.8687 6.60101 24.8482 6.51002 24.8067C6.41903 24.7652 6.33854 24.7038 6.27452 24.6269C6.21049 24.5501 6.16458 24.4598 6.14016 24.3629C6.11575 24.2659 6.11347 24.1646 6.1335 24.0667L6.40016 22.2M9.3335 14.3333H12.6668C16.0002 14.3333 19.3335 11 19.3335 7.66667C19.3335 3.66667 16.8002 1 12.6668 1H5.3335C4.66683 1 4.00016 1.66667 4.00016 2.33333L1.3335 21C1.3335 21.6667 2.00016 22.3333 2.66683 22.3333H6.40016L8.00016 15.6667C8.1335 14.8667 8.5335 14.3333 9.3335 14.3333Z" stroke="#94A3B8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
&nbsp;Paypal</label>
            </div>
            <div className='change_method_option' onClick={handleOptionClick}>
              <input
                type="radio"
                id="applepay"
                name="payment"
                defaultValue="applepay"
              />
              <label htmlFor="applepay"><svg width="22" height="27" viewBox="0 0 22 27" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.9998 4.33464C11.7071 4.33464 12.3854 4.05368 12.8855 3.55359C13.3856 3.05349 13.6665 2.37521 13.6665 1.66797C12.9593 1.66797 12.281 1.94892 11.7809 2.44902C11.2808 2.94911 10.9998 3.62739 10.9998 4.33464M6.99984 8.33464C2.99984 8.33464 1.6665 12.3346 1.6665 15.668C1.6665 19.668 4.33317 25.668 6.99984 25.668C8.4505 25.6066 9.2385 25.0013 10.9998 25.0013C12.7492 25.0013 12.9998 25.668 14.9998 25.668C16.9998 25.668 20.3332 21.668 20.3332 19.0013C20.2958 18.988 17.0372 18.464 16.9998 15.0013C16.9745 12.108 20.2212 11.0626 20.3332 11.0013C18.9692 9.01197 16.3985 8.38397 15.6665 8.33464C13.7558 8.18664 11.8932 9.66797 10.9998 9.66797C10.0932 9.66797 8.4665 8.33464 6.99984 8.33464Z" stroke="#94A3B8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
&nbsp;Apple Pay</label>
            </div>
            <div className='change_method_option' onClick={handleOptionClick}>
              <input
                type="radio"
                id="googlepay"
                name="payment"
                defaultValue="googlepay"
              />
              <label htmlFor="googlepay"><svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.7173 3.81073C18.6256 2.05404 15.9971 1.06355 13.2662 1.00296C10.5353 0.942356 7.86551 1.81526 5.6979 3.47744C3.53029 5.13961 1.99462 7.49157 1.34466 10.1447C0.694707 12.7978 0.969358 15.5932 2.12323 18.0691C3.27711 20.545 5.24115 22.5531 7.69081 23.7616C10.1405 24.9701 12.9291 25.3068 15.596 24.7158C18.2629 24.1249 20.6483 22.6418 22.3582 20.5116C24.0681 18.3814 25 15.7316 25 13.0001H14.3333" stroke="#94A3B8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
&nbsp;Google Pay</label>
            </div>

          </Card.Body>
        </Card>
      </Container>
      </div>
    </AdminLayout>
  );
};
