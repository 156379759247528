import React, { Component } from 'react';
import Chart from 'react-google-charts';
import { Row } from 'react-bootstrap';

const LineData = [
    ['x', 'Csk'],
    [1, 15],
    [2, 15],
    [3, 12],
    [4, 20],
    [5, 34],
    [6, 0],
    [7, 28],
    [8, 55],
    [9, 48],
    [10, 62],
    [11, 98],
    [12, 100],
];

const LineChartOptions = {
    hAxis: {
        title: '',
        minValue: 1,  // Set the minimum value for the x-axis
        maxValue: 12,
        baselineColor: 'black', // Set the maximum value for the x-axis
        gridlines: {
            count: 12,
            color: 'transparent', // Show 12 gridlines, one for each x value
        },
    },
    vAxis: {
        title: '',
        minValue: 10, // Set the minimum value for the y-axis
        maxValue: 100, // Set the maximum value for the y-axis
        baselineColor: 'black',
        gridlines: {
            count: 10,
            color: 'transparent', // Show 10 gridlines, one for each y value
        },
    },
    chartArea: {
        backgroundColor: 'white',
        // Set the gridlines options to customize gridlines appearance
        // To remove gridlines completely, set both properties to 'none'
        // To only remove horizontal gridlines, set hAxis.gridlines.color to 'none'
        gridlines: {
            color: 'none',
            count: 0,
        },
    },
    series: {
        1: { curveType: 'function' },
    },
};

export default class Linechart extends Component {
    render() {
        return (
            <div className="container mt-5">
                <Chart
                    width={'1009px'}
                    height={'281px'}
                    chartType="LineChart"
                    loader={<div>Loading Chart</div>}
                    data={LineData}
                    options={LineChartOptions}
                    rootProps={{ 'data-testid': '2' }}
                />
            </div>
        );
    }
}
