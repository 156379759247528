import React from "react";
import { Container } from "react-bootstrap";
import { Card, Col, Row } from "react-bootstrap";
import Images from "../../constants/Images";
import AdminLayout from "../../layouts/AdminLayout";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Routes,
} from "react-router-dom";

function SavedSpecialist() {
  const role='specialist'
  return (
    <AdminLayout>
    <div className="saved-div" style={{ backgroundColor: "white" }}>
      <Container>
        <Row className="d-flex mb-3">
          <Col sm={12} md={3} className="saved-col">
            <h1 style={{ paddingLeft: "25px" }} className="Saved-heading">
              Saved
            </h1>
          </Col>
          <Col sm={12} md={4} className="d-flex btn-positions">
          <Link to='/favorites'>     <button className="button-product" style={{background:'#68838E0D',color:'#68838E'}}>Products</button></Link>
          {role=='specialist'?<Link to='/favourite_specialists'>      <button className="button-product ms-4" >Friends</button></Link> :       <Link to='/favourite_specialists'>      <button className="button-product ms-4">Specialists</button></Link> 
}           {/* <Link to="/savedProduct">
              <ButtonStyled
                title="Products"
                className={isProductsActive ? "products active" : "products"}
                onClick={handleProductsClick}
              />
            </Link>
            <Link to="/favourite_specialists">
              <ButtonStyled
                title={<span>Specialist</span>}
                className={
                  isSpecialistActive ? "specialist" : "specialist active"
                }
                onClick={handleSpecialistClick}
              />
            </Link> */}
          </Col>
        </Row>

        <Container className="d-flex justify-content-left gap-4 px-4">
        <Row className="justify-content-left">
    <Col lg={6} sm={12}>
  <Card className="specilist-1 rounded-4">
    <Card.Body>
      <div className="d-flex">
        {" "}
        <Card.Img
          src={Images.fcard_2}
          className=" specialist-img1 "
          alt=""
        />
        <div className="star">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
          >
            <path
              d="M2.9125 11L4.075 7.2L1 5H4.8L6 1L7.2 5H11L7.925 7.2L9.0875 11L6 8.65L2.9125 11Z"
              fill="white"
            />
          </svg>
          <span className="star-rating"> 4.5 </span>
        </div>{" "}
      </div>
      <h4> Nelson Jhons</h4>
      <span className="ctext-1"> Mental Therapist</span> <br></br>
      <span className="ctext-1">Houston, USA 5+ Years of Exp</span>
      <div className="tag-div">
        <div className="tag1">
          <span className="tag-text">Therapiest</span>
        </div>
        <div className="tag">
          <span className="tag-text">Good Surgeon</span>
        </div>
        <div className="tag">
          <span className="tag-text">Mental Therapist</span>
        </div>
        <div className="tag">
          <span className="tag-text">+9</span>
        </div>
      </div>
      <Row className="mt-3">
        <Col>
          <Row>
            <span className="specialist-price">Price</span>
          </Row>
          <Row>
            <h5 className="price"> $ 120.00</h5>
          </Row>
        </Col>
        <Col className="specilistheader">
          <span className="booking"> Book Appointment</span>
        </Col>
      </Row>
    </Card.Body>
  </Card>
</Col>
<Col lg={6} sm={12}>
  <Card className="specilist-1 rounded-4">
    <Card.Body>
      <div className="d-flex">
        {" "}
        <Card.Img
          src={Images.fcard_2}
          className=" specialist-img1 "
          alt=""
        />
        <div className="star">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
          >
            <path
              d="M2.9125 11L4.075 7.2L1 5H4.8L6 1L7.2 5H11L7.925 7.2L9.0875 11L6 8.65L2.9125 11Z"
              fill="white"
            />
          </svg>
          <span className="star-rating"> 4.5 </span>
        </div>{" "}
      </div>
      <h4> Nelson Jhons</h4>
      <span className="ctext-1"> Mental Therapist</span> <br></br>
      <span className="ctext-1">Houston, USA 5+ Years of Exp</span>
      <div className="tag-div">
        <div className="tag1">
          <span className="tag-text">Therapiest</span>
        </div>
        <div className="tag">
          <span className="tag-text">Good Surgeon</span>
        </div>
        <div className="tag">
          <span className="tag-text">Mental Therapist</span>
        </div>
        <div className="tag">
          <span className="tag-text">+9</span>
        </div>
      </div>
      <Row className="mt-3">
        <Col>
          <Row>
            <span className="specialist-price">Price</span>
          </Row>
          <Row>
            <h5 className="price"> $ 120.00</h5>
          </Row>
        </Col>
        <Col className="specilistheader">
          <span className="booking"> Book Appointment</span>
        </Col>
      </Row>
    </Card.Body>
  </Card>
</Col>
</Row>
</Container>
      </Container>
    </div>
  </AdminLayout>
  );
}

export default SavedSpecialist;


