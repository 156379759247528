import { BestSection } from "../../components/Home/BestSection";
import { HelpSection } from "../../components/Home/HelpSection";
import { WhySection } from "../../components/Home/WhySection";
import AuthLayout from "../../layouts/AuthLayout";
import Images from "../../constants/Images";
import { useState } from "react";
import OurSpecialistsSection from "../../components/Home/OurSpecialistsSection";
import MarketPlaceSection from "../../components/Home/MarketPlaceSection";
import NewsSection from "../../components/Home/NewsSection";
import TestimonialSlider from "../../components/Home/TestimonialSlider";
import JoinCommunitySection from "../../components/Home/JoinCommunitySection";
import QuickTestSection from "../../components/Home/QuickTestSection";
import MarketPlace from "../../components/Home/MarketPlace";
import RegisterationSection from "../../components/Home/RegisterationSection";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

const Home = () => {
  const [specialists, setSpecialists] = useState([
    {
      name: "Robert Davis",
      image: Images.Specialist_1,
      designation: "Physical Therapist",
      rating: 4,
      socialMedia: [
        { link: "#", icon: faFacebookF },
        { link: "#", icon: faInstagram },
        { link: "#", icon: faLinkedinIn },
        { link: "#", icon: faTwitter },
      ],
    },
    {
      name: "Robert Davis",
      image: Images.Specialist_2,
      designation: "Physical Therapist",
      rating: 4,
      socialMedia: [
        { link: "#", icon: faFacebookF },
        { link: "#", icon: faInstagram },
        { link: "#", icon: faLinkedinIn },
        { link: "#", icon: faTwitter },
      ],
    },
    {
      name: "Robert Davis",
      image: Images.Specialist_3,
      designation: "Physical Therapist",
      rating: 4,
      socialMedia: [
        { link: "#", icon: faFacebookF },
        { link: "#", icon: faInstagram },
        { link: "#", icon: faLinkedinIn },
        { link: "#", icon: faTwitter },
      ],
    },
    {
      name: "Robert Davis",
      image: Images.Specialist_4,
      designation: "Physical Therapist",
      rating: 4,
      socialMedia: [
        { link: "#", icon: faFacebookF },
        { link: "#", icon: faInstagram },
        { link: "#", icon: faLinkedinIn },
        { link: "#", icon: faTwitter },
      ],
    },
  ]);
  return (
    <AuthLayout isHome={true}>
      <HelpSection />
      <BestSection />
      <WhySection />
      <OurSpecialistsSection specialists={specialists} />
      <MarketPlaceSection />
      <TestimonialSlider />
      <JoinCommunitySection />
      {/* <QuickTestSection /> */}
      <MarketPlace />
      <NewsSection />
      <RegisterationSection />
    </AuthLayout>
  );
};

export default Home;
