import React, { useRef } from "react";
import { Container, Carousel, Image, Card, Row, Col } from "react-bootstrap";
import Images from "../../constants/Images";
import ButtonStyled from "../ButtonStyled";

function TestimonialSlider() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1000 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1000, min: 800 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 800, min: 0 },
      items: 1, // Adjusted to 1 item for better visibility on smaller screens
      slidesToSlide: 1,
    },
  };
  const ref = useRef(null);
  const onPrevClick = () => {
    ref.current.previous();
  };

  const onNextClick = () => {
    ref.current.next();
  };
  return (
    <div>
      <section className="HomeTestimonialSlider">
        <Container>
          <div className="NavigationCover">
            <div>
              <p className="therapist" style={{fontStyle:'italic'}}>
                What we can do for you
              </p>
              <div className="custumizebtn">
                <h1 className="text-start">Our clients speak</h1>
                <div className="" style={{ marginLeft: "auto" }}>
                  <ButtonStyled
                    title=""
                    isLeft={true}
                    className="btn-navigate"
                  />

                  <ButtonStyled
                    handler={onNextClick}
                    title=""
                    isRight={true}
                    className="btn-navigate"
                  />
                </div>
              </div>

              <div className="separator"></div>
            </div>
          </div>

          <Carousel
            className="carousel carousel-dark slide"
            indicators={true}
            controls={false}
            ref={ref}
            responsive={responsive}
          >
            <Carousel.Item interval={10000}>
              <Container
                style={{
                  margin: "auto",
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                 <Card
                  style={{
                    width: "95%",
                    marginTop: "255px",
                    paddingTop: "40px",
                    overflow: "hidden",
                    
                  }}
                >
                  {" "}
                  <Card.Body>
                    <Row
                      className="align-items-center"
                      style={{
                        width: "95%",
                      }}
                    >
                      <Col
                        lg={3}
                        md={3}
                        xs={12}
                        className="m-auto px-auto"
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      ></Col>
                      <Col lg={9} md={9} xs={12}></Col>
                    </Row>
                  </Card.Body>
                </Card>
                <Card style={{marginTop:'-291px'}}>
                  <Card.Body>
                    <Row className="align-items-center">
                      <Col
                        lg={3}
                        md={3}
                        xs={12}
                        className="m-auto px-auto"
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Image
                          src={Images.Testimonial_1}
                          alt=""
                          style={{ marginLeft: "10%" }}
                        />
                      </Col>
                      <Col lg={9} md={9} xs={12}>
                        <h2>Robert Davis</h2>
                        <h6>
                          <i>Physical Therapist</i>
                        </h6>
                        <p>
                          Our team consists of highly qualified specialists with
                          international experience in the field of Web
                          development, mobile development, product management,
                          and design. We provide premium service at affordable
                          prices and are always focused on the success of our
                          customers. Our team consists of highly qualified
                          specialists with international experience in the field
                          of Web development, mobile development, product
                          management, and design.
                        </p>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
               
              </Container>
            </Carousel.Item>
            <Carousel.Item interval={10000}>
            <Container
                style={{
                  margin: "auto",
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                 <Card
                  style={{
                    width: "95%",
                    marginTop: "255px",
                    paddingTop: "40px",
                    overflow: "hidden",
                    
                  }}
                >
                  {" "}
                  <Card.Body>
                    <Row
                      className="align-items-center"
                      style={{
                        width: "95%",
                      }}
                    >
                      <Col
                        lg={3}
                        md={3}
                        xs={12}
                        className="m-auto px-auto"
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      ></Col>
                      <Col lg={9} md={9} xs={12}></Col>
                    </Row>
                  </Card.Body>
                </Card>
                <Card style={{marginTop:'-291px'}}>
                  <Card.Body>
                    <Row className="align-items-center">
                      <Col
                        lg={3}
                        md={3}
                        xs={12}
                        className="m-auto px-auto"
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Image
                          src={Images.Testimonial_1}
                          alt=""
                          style={{ marginLeft: "10%" }}
                        />
                      </Col>
                      <Col lg={9} md={9} xs={12}>
                        <h2>Robert Davis</h2>
                        <h6>
                          <i>Physical Therapist</i>
                        </h6>
                        <p>
                          Our team consists of highly qualified specialists with
                          international experience in the field of Web
                          development, mobile development, product management,
                          and design. We provide premium service at affordable
                          prices and are always focused on the success of our
                          customers. Our team consists of highly qualified
                          specialists with international experience in the field
                          of Web development, mobile development, product
                          management, and design.
                        </p>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
               
              </Container>
            </Carousel.Item>
            <Carousel.Item interval={10000}>
            <Container
                style={{
                  margin: "auto",
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                 <Card
                  style={{
                    width: "95%",
                    marginTop: "255px",
                    paddingTop: "40px",
                    overflow: "hidden",
                    
                  }}
                >
                  {" "}
                  <Card.Body>
                    <Row
                      className="align-items-center"
                      style={{
                        width: "95%",
                      }}
                    >
                      <Col
                        lg={3}
                        md={3}
                        xs={12}
                        className="m-auto px-auto"
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      ></Col>
                      <Col lg={9} md={9} xs={12}></Col>
                    </Row>
                  </Card.Body>
                </Card>
                <Card style={{marginTop:'-291px'}}>
                  <Card.Body>
                    <Row className="align-items-center">
                      <Col
                        lg={3}
                        md={3}
                        xs={12}
                        className="m-auto px-auto"
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Image
                          src={Images.Testimonial_1}
                          alt=""
                          style={{ marginLeft: "10%" }}
                        />
                      </Col>
                      <Col lg={9} md={9} xs={12}>
                        <h2>Robert Davis</h2>
                        <h6>
                          <i>Physical Therapist</i>
                        </h6>
                        <p>
                          Our team consists of highly qualified specialists with
                          international experience in the field of Web
                          development, mobile development, product management,
                          and design. We provide premium service at affordable
                          prices and are always focused on the success of our
                          customers. Our team consists of highly qualified
                          specialists with international experience in the field
                          of Web development, mobile development, product
                          management, and design.
                        </p>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
               
              </Container>
            </Carousel.Item>
          </Carousel>
        </Container>
      </section>
    </div>
  );
}

export default TestimonialSlider;
