import React from "react";
import { Article } from "./components/Article";
import { ExpandDownLight1 } from "./icons/ExpandDownLight1";
import { Youtube } from "./icons/Youtube";
import Navigation from "../../components/Navigation";
import RegisterationSection from "../../components/Home/RegisterationSection";
import Footer from "../../components/Footer";


export const BlogPage = () => {
  return (
    <div className="BLOG-PAGE">
      <div className="div-2">
        <div className="overlap1">
          <div className="overlap">
            {/* <img
              className="image"
              alt="Image"
              src="https://generation-sessions.s3.amazonaws.com/afa96db6e9e1a2c4ef7266442ae274fd/img/image-7.png"
            /> */}
            <div className="header-navbar">
            <Navigation/>
            </div>
                    
           
          </div>
        </div>
            <div className="frame-3">
              <div className="frame-4">
                <div className="join-our-forum">JOIN OUR FORUM</div>
                <p className="text-wrapper-6">
                  Lorem ipsum dolor sit amet, consectetur adipiscelit, sed do eiusmod tempor Lorem ipsum dolor amet,
                  consectetur elit, sed do eiusmod tempor Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod temporLorem ipsum dolor
                  sit amet, consectetur
                </p>
              </div>
              <div className="frame-5">
                <div className="text-wrapper-7">Read More</div>
                <img
                  className="vector-2"
                  alt="Vector"
                  src="https://generation-sessions.s3.amazonaws.com/afa96db6e9e1a2c4ef7266442ae274fd/img/vector-14-6.svg"
                />
              </div>
            </div>
          {/* </div> */}
          <div className="frame-6">
            <div className="frame-7">
              <p className="text-wrapper-8">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor Lorem ipsum dolor sit
                amet, consectetur elit,
              </p>
              <div className="frame-8">
                <div className="text-wrapper-9">Read More</div>
                <img
                  className="vector-2"
                  alt="Vector"
                  src="https://generation-sessions.s3.amazonaws.com/afa96db6e9e1a2c4ef7266442ae274fd/img/vector-14-5.svg"
                />
              </div>
            </div>
            <div className="frame-7">
              <p className="text-wrapper-8">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor Lorem ipsum dolor sit
                amet, consectetur elit,
              </p>
              <div className="frame-8">
                <div className="text-wrapper-9">Read More</div>
                <img
                  className="vector-2"
                  alt="Vector"
                  src="https://generation-sessions.s3.amazonaws.com/afa96db6e9e1a2c4ef7266442ae274fd/img/vector-14-5.svg"
                />
              </div>
            </div>
          {/* </div> */}
        </div>
        {/* <div className="ellipse" />
        <img
          className="img-2"
          alt="Img"
          src="https://generation-sessions.s3.amazonaws.com/afa96db6e9e1a2c4ef7266442ae274fd/img/8c30bc99c92af80e8fff48270abd1445-1.png"
        /> */}
       
        <div className="frame-10">
          <p className="ALL-WELLNESS-SEX">
            <span className="text-wrapper-18">ALL WELLNESS&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span className="text-wrapper-19">
              SEX TOYS&nbsp;&nbsp;&nbsp;&nbsp;AROMATHERAPY&nbsp;&nbsp;&nbsp;&nbsp;FOOD &amp;
              DRINK&nbsp;&nbsp;&nbsp;&nbsp;ORAL
              CARE&nbsp;&nbsp;&nbsp;&nbsp;WORKOUT&nbsp;&nbsp;&nbsp;&nbsp;TOOLS &amp;
              DEVICES&nbsp;&nbsp;&nbsp;&nbsp;VITAMINS &amp;
              SUPPLEMENTS&nbsp;&nbsp;&nbsp;&nbsp;IMMUNITY&nbsp;&nbsp;&nbsp;&nbsp;MINDFULNESS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              MINDFULNESS&nbsp;&nbsp;&nbsp;&nbsp; MINDF
            </span>
          </p>
          <div className="rectangle-13" />
        </div>
        <div className="frame-11">
          <div className="text-wrapper-20">All Articles</div>
          <div className="buttons">
            <div className="content">
              <div className="text-wrapper-21">See all</div>
              <img
                className="vector-2"
                alt="Vector"
                src="https://generation-sessions.s3.amazonaws.com/afa96db6e9e1a2c4ef7266442ae274fd/img/vector-9.svg"
              />
            </div>
          </div>
        </div>





        <div className="frame-12">
          <Article className="article-instance" />
          <Article
            className="article-instance"
            maskGroup="https://generation-sessions.s3.amazonaws.com/afa96db6e9e1a2c4ef7266442ae274fd/img/mask-group-1@2x.png"
          />
          <Article
            className="article-instance"
            maskGroup="https://generation-sessions.s3.amazonaws.com/afa96db6e9e1a2c4ef7266442ae274fd/img/mask-group@2x.png"
          />
        </div>
        <div className="footer">
          <div className="register">
            <RegisterationSection/>
          </div>
        
        <Footer/>
        </div>
      </div>
    </div>
  );
};
export default BlogPage;
