import { faArrowLeft, faArrowRight, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "react-bootstrap";
import { redirect } from "react-router-dom";
export default function ButtonStyled({
  title = "Next",
  isIcon = false,
  className = "",
  handler = () => { },
  isCentered = true,
  isRight=false,
  isLeft = false,
  isMore = false,
  isLike=false,
  isDown=false,
  
}) {

  return (
    // <div className={`${isCentered && "text-center"} mt-4`}>
      <Button
        className={`btn-default ${className}`}
        type="button"
        onClick={(e) => {
          e.preventDefault();
          handler();
        }}
      >
      <>
        {isLike && <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
  <path d="M3.33341 17.9486H4.16675V7.11523H3.33341C2.89139 7.11523 2.46746 7.29083 2.1549 7.60339C1.84234 7.91595 1.66675 8.33987 1.66675 8.7819V16.2819C1.66675 16.7239 1.84234 17.1479 2.1549 17.4604C2.46746 17.773 2.89139 17.9486 3.33341 17.9486ZM16.6667 7.11523H10.8334L11.7684 4.30857C11.8518 4.05808 11.8746 3.79136 11.8347 3.53036C11.7949 3.26937 11.6936 3.02158 11.5392 2.8074C11.3849 2.59321 11.1818 2.41877 10.9468 2.29843C10.7118 2.17809 10.4516 2.1153 10.1876 2.11523H10.0001L5.83341 6.6469V17.9486H15.0001L18.2601 10.7852L18.3334 10.4486V8.7819C18.3334 8.33987 18.1578 7.91595 17.8453 7.60339C17.5327 7.29083 17.1088 7.11523 16.6667 7.11523Z" fill="url(#paint0_linear_2749_7113)"/>
  <defs>
    {/* <linearGradient id="paint0_linear_2749_7113" x1="5.2223" y1="6.57496" x2="15.2376" y2="14.6715" gradientUnits="userSpaceOnUse">
      <stop stop-color="#3A6FFD"/>
      <stop offset="1" stop-color="#D63ACA"/>
    </linearGradient> */}
  </defs>
</svg> }   {title} {isIcon && <FontAwesomeIcon icon={faArrowRight} />} {isRight && <FontAwesomeIcon icon={faChevronRight} style={{color: "#180606",}}  />} {isLeft && <FontAwesomeIcon icon={faChevronRight} rotation={180} style={{color: "#180606",}}  />}
          {isMore && <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
  <path d="M10.0768 1.4835L10.0768 0.733499L10.8268 0.733499L10.8268 1.4835L10.0768 1.4835ZM2.01372 10.6072C1.72082 10.9001 1.24595 10.9001 0.953057 10.6072C0.660164 10.3143 0.660164 9.83944 0.953057 9.54654L2.01372 10.6072ZM1.10976 0.733499H10.0768L10.0768 2.2335H1.10976L1.10976 0.733499ZM10.8268 1.4835L10.8268 10.4505L9.32676 10.4505L9.32676 1.4835L10.8268 1.4835ZM10.6071 2.01383L2.01372 10.6072L0.953057 9.54654L9.54643 0.953169L10.6071 2.01383Z" fill="url(#paint0_linear_1461_37285)"/>
  <defs>
    {/* <linearGradient id="paint0_linear_1461_37285" x1="6.28571" y1="1.35896" x2="4.82354" y2="10.9738" gradientUnits="userSpaceOnUse">
      <stop stop-color="#3A6FFD"/>
      <stop offset="1" stop-color="#D63ACA"/>
    </linearGradient> */}
  </defs>
</svg>} {isDown && <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
  <path d="M13 16.6846L6.5 10.1846L8.01667 8.66797L13 13.6513L17.9833 8.66797L19.5 10.1846L13 16.6846Z" fill="url(#paint0_linear_2055_16298)"/>
  <defs>
    <linearGradient id="paint0_linear_2055_16298" x1="9.27333" y1="10.926" x2="14.3369" y2="17.2322" gradientUnits="userSpaceOnUse">
      <stop stop-color="#3A6FFD"/>
      <stop offset="1" stop-color="#D63ACA"/>
    </linearGradient>
  </defs>
</svg>}
        </> 
      </Button>
    // {/* </div> */}
  );
}
