import React from "react";
import "./style.scss";
export const Loader = () => {
  return (
    <div className="loader-container bg-white">
      <svg
      className="loader-svg"
        width="28"
        height="35"
        viewBox="0 0 28 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.3802 22.7155C16.9023 21.2713 16.8738 19.5627 15.879 18.8109C14.8843 18.0591 13.258 18.6891 11.5907 19.9482C9.92344 21.2074 14.9652 28.2657 19.5462 30.9102C23.3094 33.0825 25.5658 30.7307 24.8749 28.1524C24.295 25.9883 18.3802 22.7155 18.3802 22.7155Z"
          fill="url(#paint0_linear_52_702)"
        />
        <ellipse
          cx="0.449773"
          cy="0.449772"
          rx="0.449773"
          ry="0.449772"
          transform="matrix(-0.5 0.866026 -0.866026 -0.499998 22.9321 27.3807)"
          fill="white"
        />
        <ellipse
          cx="0.449773"
          cy="0.449772"
          rx="0.449773"
          ry="0.449772"
          transform="matrix(-0.5 0.866026 -0.866026 -0.499998 23.1953 28.972)"
          fill="white"
        />
        <ellipse
          cx="0.449773"
          cy="0.449772"
          rx="0.449773"
          ry="0.449772"
          transform="matrix(-0.5 0.866026 -0.866026 -0.499998 21.5723 28.7361)"
          fill="white"
        />
        <path
          d="M2.68907 5.62159C-5.1473 15.4306 6.93152 27.2122 13.0385 27.0771V0.97381C10.2498 0.757634 7.60708 1.9466 6.63429 2.56811C6.25598 1.39715 4.95892 -0.269201 3.01334 0.676568C0.877887 1.71464 1.96849 4.51369 2.68907 5.62159Z"
          fill="url(#paint1_linear_52_702)"
        />
        <path
          d="M23.3879 5.62159C31.2243 15.4306 19.1455 27.2122 13.0385 27.0771V0.97381C15.8272 0.757634 18.4699 1.9466 19.4427 2.56811C19.821 1.39715 21.1181 -0.269201 23.0637 0.676568C25.1991 1.71464 24.1085 4.51369 23.3879 5.62159Z"
          fill="url(#paint2_linear_52_702)"
        />
        <path
          d="M17.9619 13.6382C18.9618 18.0158 15.0736 19.1507 13.0439 19.1507C10.3688 19.1507 8.09893 17.6915 7.99083 14.6651C7.88274 11.6386 11.0143 8.28788 13.0439 8.28788C15.0736 8.28788 17.7187 10.7469 17.9619 13.6382Z"
          fill="white"
        />
        <path
          d="M16.125 14.7191C16.4822 15.9605 14.5037 16.2594 13.7471 17.9888C13.7471 17.3132 11.1376 15.7125 11.099 14.8542C11.0603 13.996 12.9367 13.7463 13.6618 13.7463C14.3869 13.7463 16.0382 13.8992 16.125 14.7191Z"
          fill="url(#paint3_linear_52_702)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_52_702"
            x1="20.858"
            y1="21.1024"
            x2="14.6927"
            y2="29.7853"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#3A6FFD" />
            <stop offset="1" stop-color="#D63ACA" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_52_702"
            x1="5.63616"
            y1="7.91254"
            x2="22.1633"
            y2="20.1969"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#3A6FFD" />
            <stop offset="1" stop-color="#D63ACA" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_52_702"
            x1="5.63616"
            y1="7.91254"
            x2="22.1633"
            y2="20.1969"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#3A6FFD" />
            <stop offset="1" stop-color="#D63ACA" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_52_702"
            x1="5.63616"
            y1="7.91254"
            x2="22.1633"
            y2="20.1969"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#3A6FFD" />
            <stop offset="1" stop-color="#D63ACA" />
          </linearGradient>
        </defs>
      </svg>

      <p>Sympathiq is Loading...</p>
    </div>
  );
};
