/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { LikedTrue } from "../../icons/LikedTrue";
import "./style.css";

export const Product = ({
  property1,
  className,
  image = "https://generation-sessions.s3.amazonaws.com/6f32ef045a41a221ff9d4f9c10ea4b44/img/image-13-1@2x.png",
}) => {
  return (
    <div className={`product ${property1} ${className}`}>
      <div className="picture">
        <img className="image" alt="Image" src={image} />
      </div>
      {property1 === "cart" && (
        <>
          <div className="content">
            <p className="XS-sports-protein">Xs™ Sports Protein Shakes – Rich Chocolate</p>
            <div className="price">
              <div className="text-wrapper">$35.99</div>
            </div>
          </div>
          <img
            className="iconoir-cancel"
            alt="Iconoir cancel"
            src="https://generation-sessions.s3.amazonaws.com/6f32ef045a41a221ff9d4f9c10ea4b44/img/iconoir-cancel.svg"
          />
        </>
      )}

      {property1 === "fav" && (
        <>
          <div className="div">
            <p className="p">Xs™ Sports Protein Shakes – Rich Chocolate</p>
            <div className="price">
              <div className="text-wrapper">$35.99</div>
            </div>
          </div>
          <LikedTrue className="heart" />
        </>
      )}
    </div>
  );
};

Product.propTypes = {
  property1: PropTypes.oneOf(["fav", "cart"]),
  image: PropTypes.string,
};
