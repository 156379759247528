import React from "react";
import { InputFields } from "./MarketPlace_Two/components/InputFields1";
import { UploadLight } from "./MarketPlace_Two/components/UploadLight";
import { ExpandDownLight4 } from "./MarketPlace_Two/icons/ExpandDownLight4";
import { Heart } from "./MarketPlace_Two/icons/Heart";


export const AdddProduct = () => {
  return (
  
          <div className="addd-product">
            <div className="div">
              <div className="overlap">
                <header className="header">
                  <div className="overlap-group-2">
                    <div className="rectangle" />
                    <div className="rectangle-2" />
                    <div className="rectangle-3" />
                    <div className="rectangle-4" />
                    <div className="rectangle-5" />
                    <div className="rectangle-6" />
                    <div className="rectangle-7" />
                    <div className="text-wrapper-2">Robert Davis</div>
                    <ExpandDownLight4 className="expand-down-light" color="#232527" />
                    <img
                      className="mask-group"
                      alt="Mask group"
                      src="https://generation-sessions.s3.amazonaws.com/f78f40393473e36d110752be385cbd69/img/mask-group@2x.png"
                    />
                    <div className="group">
                      <div className="ellipse" />
                    </div>
                    <img
                      className="frame"
                      alt="Frame"
                      src="https://generation-sessions.s3.amazonaws.com/f78f40393473e36d110752be385cbd69/img/frame.svg"
                    />
                    <div className="options">
                      <div className="dashboard_1">
                        <img
                          className="img"
                          alt="Group"
                          src="https://generation-sessions.s3.amazonaws.com/f78f40393473e36d110752be385cbd69/img/group-33839@2x.png"
                        />
                        <div className="text-wrapper-3">Dashboard</div>
                      </div>
                      <div className="option">
                        <Heart className="heart-instance" />
                        <div className="text-wrapper-4">Your Products</div>
                      </div>
                      <div className="option">
                        <Heart className="heart-instance" />
                        <div className="text-wrapper-4">Saved</div>
                      </div>
                      <div className="option">
                        <img
                          className="heart-instance"
                          alt="Uil meeting board"
                          src="https://generation-sessions.s3.amazonaws.com/f78f40393473e36d110752be385cbd69/img/uil-meeting-board.svg"
                        />
                        <div className="text-wrapper-4">Setting</div>
                      </div>
                    </div>
                  </div>
                </header>
                <div className="text-wrapper-5">Add Your Product</div>
              </div>
              <div className="iput-fields">
                <div className="frame-2">
                  <InputFields
                    className="INPUT-FIELDS-instance"
                    divClassName="design-component-instance-node"
                    divClassNameOverride="design-component-instance-node"
                    inputFieldText="E.G Health Medicine"
                    inputFields="non-active"
                    inputFieldsClassName="INPUT-FIELDS-2"
                    inputNameText="Write Your Product Name"
                    showForgetPassword={false}
                    showIcon={false}
                  />
                  <InputFields
                    className="INPUT-FIELDS-instance"
                    divClassName="design-component-instance-node"
                    divClassNameOverride="design-component-instance-node"
                    inputFieldText="28$"
                    inputFields="non-active"
                    inputFieldsClassName="INPUT-FIELDS-2"
                    inputNameText="Selling Price"
                    showForgetPassword={false}
                    showIcon={false}
                  />
                </div>
                <div className="frame-2">
                  <InputFields
                    className="INPUT-FIELDS-instance"
                    divClassName="design-component-instance-node"
                    divClassNameOverride="design-component-instance-node"
                    inputFieldText="Ex 120"
                    inputFields="non-active"
                    inputFieldsClassName="INPUT-FIELDS-2"
                    inputNameText="Quantity"
                    showForgetPassword={false}
                    showIcon={false}
                  />
                  <InputFields
                    className="INPUT-FIELDS-instance"
                    divClassName="design-component-instance-node"
                    divClassNameOverride="design-component-instance-node"
                    icon={<ExpandDownLight4 className="expand-down-light-4" color="#2A3336" />}
                    inputFieldText="Choose"
                    inputFields="non-active"
                    inputFieldsClassName="INPUT-FIELDS-2"
                    inputNameText="Select Product Category"
                    showForgetPassword={false}
                  />
                </div>
                <div className="frame-2">
                  <InputFields
                    className="INPUT-FIELDS-instance"
                    divClassName="design-component-instance-node"
                    divClassNameOverride="design-component-instance-node"
                    inputFieldText="E.G Health Medicine"
                    inputFields="non-active"
                    inputFieldsClassName="INPUT-FIELDS-2"
                    inputNameText="Quantity"
                    showForgetPassword={false}
                    showIcon={false}
                  />
                  <InputFields
                    className="INPUT-FIELDS-instance"
                    divClassName="design-component-instance-node"
                    divClassNameOverride="design-component-instance-node"
                    icon={<ExpandDownLight4 className="expand-down-light-4" color="#2A3336" />}
                    inputFieldText="Choose"
                    inputFields="non-active"
                    inputFieldsClassName="INPUT-FIELDS-2"
                    inputNameText="Select Product Category"
                    showForgetPassword={false}
                  />
                </div>
                <div className="frame-2">
                  <InputFields
                    className="INPUT-FIELDS-instance"
                    divClassName="design-component-instance-node"
                    divClassNameOverride="design-component-instance-node"
                    inputFieldText="EX -10%"
                    inputFields="non-active"
                    inputFieldsClassName="INPUT-FIELDS-2"
                    inputNameText="Add Discout"
                    showForgetPassword={false}
                    showIcon={false}
                  />
                  <InputFields
                    className="INPUT-FIELDS-instance"
                    divClassName="design-component-instance-node"
                    divClassNameOverride="design-component-instance-node"
                    inputFieldText="12 - May - 2024"
                    inputFields="non-active"
                    inputFieldsClassName="INPUT-FIELDS-2"
                    inputNameText="Add Expiry Date"
                    showForgetPassword={false}
                    showIcon={false}
                  />
                </div>
                <div className="INPUT-FIELDS-wrapper">
                  <InputFields
                    className="INPUT-FIELDS-3"
                    divClassName="design-component-instance-node"
                    divClassNameOverride="INPUT-FIELDS-5"
                    inputFieldText="EX -10%"
                    inputFields="non-active"
                    inputFieldsClassName="INPUT-FIELDS-4"
                    inputNameText="Add Discout"
                    showForgetPassword={false}
                    showIcon={false}
                  />
                </div>
                <div className="frame-3">
                  <div className="image">
                    <img
                      className="combined-shape"
                      alt="Combined shape"
                      src="https://generation-sessions.s3.amazonaws.com/f78f40393473e36d110752be385cbd69/img/combined-shape.svg"
                    />
                  </div>
                  <div className="description">
                    <div className="div-wrapper">
                      <div className="text-wrapper-6">Upload Image</div>
                    </div>
                    <div className="frame-4">
                      <p className="p">Upload a cover image for your product.</p>
                      <p className="file-format-jpeg-png">
                        <span className="span">File Format</span>
                        <span className="text-wrapper-7">&nbsp;</span>
                        <span className="text-wrapper-8">jpeg, png</span>
                        <span className="text-wrapper-7">&nbsp;</span>
                        <span className="span">Recommened Size </span>
                        <span className="text-wrapper-8">600x600 (1:1)</span>
                      </p>
                    </div>
                    <UploadLight
                      className="upload-light-instance"
                      subtract="https://generation-sessions.s3.amazonaws.com/f78f40393473e36d110752be385cbd69/img/subtract-2.svg"
                      vector="https://generation-sessions.s3.amazonaws.com/f78f40393473e36d110752be385cbd69/img/vector-10-1.svg"
                    />
                  </div>
                </div>
                <div className="frame-5">
                  <div className="frame-wrapper">
                    <div className="frame-6">
                      <UploadLight
                        className="upload-light-2"
                        subtract="https://generation-sessions.s3.amazonaws.com/f78f40393473e36d110752be385cbd69/img/subtract-1.svg"
                        vector="https://generation-sessions.s3.amazonaws.com/f78f40393473e36d110752be385cbd69/img/vector-10.svg"
                      />
                      <div className="text-wrapper-9">Upload Image</div>
                    </div>
                  </div>
                  <div className="frame-7">
                    <div className="frame-8">
                      <div className="text-wrapper-10">Main Picture</div>
                    </div>
                  </div>
                  <img
                    className="rectangle-8"
                    alt="Rectangle"
                    src="https://generation-sessions.s3.amazonaws.com/f78f40393473e36d110752be385cbd69/img/rectangle-4364@2x.png"
                  />
                  <img
                    className="rectangle-8"
                    alt="Rectangle"
                    src="https://generation-sessions.s3.amazonaws.com/f78f40393473e36d110752be385cbd69/img/rectangle-4364@2x.png"
                  />
                  <img
                    className="rectangle-8"
                    alt="Rectangle"
                    src="https://generation-sessions.s3.amazonaws.com/f78f40393473e36d110752be385cbd69/img/rectangle-4364@2x.png"
                  />
                  <img
                    className="rectangle-8"
                    alt="Rectangle"
                    src="https://generation-sessions.s3.amazonaws.com/f78f40393473e36d110752be385cbd69/img/rectangle-4364@2x.png"
                  />
                </div>
              </div>
              <div className="iput-fields-2">
                <div className="group-2">
                  <div className="overlap-2">
                    <img
                      className="image-2"
                      alt="Image"
                      src="https://generation-sessions.s3.amazonaws.com/f78f40393473e36d110752be385cbd69/img/image-13@2x.png"
                    />
                    <div className="group-wrapper">
                      <div className="overlap-group-wrapper">
                        <div className="overlap-group-3">
                          <div className="text-wrapper-11">-40%</div>
                        </div>
                      </div>
                    </div>
                    <div className="ellipse-2" />
                  </div>
                  <p className="text-wrapper-12">XS™ Sports Protein Shakes – Rich Chocolate</p>
                  <div className="group-3">
                    <div className="text-wrapper-13">$53.99</div>
                    <div className="text-wrapper-14">$35.99</div>
                  </div>
                </div>
                <div className="group-4">
                  <div className="frame-9">
                    <div className="group-5">
                      <div className="overlap-group-4">
                        <div className="rectangle-9" />
                        <div className="rectangle-10" />
                      </div>
                    </div>
                    <div className="text-wrapper-15">Add Product</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      };

export default AdddProduct;