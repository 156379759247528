import React from "react";
import AdminLayout from "../../layouts/AdminLayout";
import { Container } from "react-bootstrap";
import Images from "../../constants/Images";

import { Row, Col, Image, Card } from "react-bootstrap";
import ButtonStyled from "../../components/ButtonStyled";
import {BiMaleSign} from "react-icons/bi";

import "./myappointment.scss";
import { DropDown1 } from "../../View/Myspecllist.js/icons/DropDown1";
 
function  MyAppointment() {
  return (
    <AdminLayout>
      <div className="bg-white" style={{paddingBottom:'80px', margin:'0', minHeight:'100vh'}}>
        <Container style={{ paddingTop: "30px" }}>
          <Row className="d-flex align-items-end">
            <Col lg={4}>
              <div className="younus-card-div">
                <Card className="appoint-1 " style={{border:'none', borderRadius:'15px'}}>
                  <Card.Img
                    src={Images.Myappointment_1}
                    className=" myappoint-img "
                    alt=""
                  />
                  <Card.Body>
                  <img src={Images.profile} alt="profile" className="profile-img"/>

                    <div className="">
                      {" "}
                      <h4 className="younus-name">Younus Akram</h4>
                      
                    </div>
                    <div className="d-flex justify-content-center">
                      <img src={Images.age} alt="age" style={{width:'12px', height:'12px', marginTop:'8px', marginRight:'5px'}}/>
                      <p style={{color:'#93989A'}}>28</p>
                      <div className="d-flex" style={{paddingLeft:'13px'}}>
                      <BiMaleSign style={{color:'#68838E', marginRight:'3px', marginTop:'4px'}}/>
                      <p style={{color:'#93989A'}}>Male</p>
                      </div>
                      
                    </div>
                    <div className="">
                      <h4 className="ctext-1">
                        Yoga,Meditation,Exercise <br></br>{" "}
                        Sports,Gym,TrainerYoga,Medita <br></br>tion,Exercise Sports
                      </h4>
                    </div>
                    <div className="d-flex align-items-center justify-content-center button-svg-div">
                      {/* <ButtonStyled title="Edit Profile" className="appoint-btn" /> */}
                      <button className="button-product" >Edit Profile</button>
                    <div style={{border: '1.5px solid #F3F3F3',borderRadius:'8px', padding:'5px',marginLeft:'12px'}}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="18"
                        viewBox="0 0 19 18"
                        fill="none"
                      >
                        <path
                          d="M12.83 6.67578C15.53 6.90828 16.6325 8.29578 16.6325 11.3333V11.4308C16.6325 14.7833 15.29 16.1258 11.9375 16.1258H7.055C3.7025 16.1258 2.36 14.7833 2.36 11.4308V11.3333C2.36 8.31828 3.4475 6.93078 6.1025 6.68328"
                          stroke="#1F1E28"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M9.5 11.2498V2.71484"
                          stroke="#1F1E28"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12.0125 4.3875L9.5 1.875L6.9875 4.3875"
                          fill="#1F1E28"
                        />
                        <path
                          d="M12.0125 4.3875L9.5 1.875L6.9875 4.3875"
                          stroke="#1F1E28"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      </div>
                    </div>
                    <span className="join-november">Joined November 2021</span>
                  </Card.Body>
                </Card>
              </div>
            </Col>
            <Col lg={8}>
              <div className="your-mood-div">
                <Card className="your-mood-card">
                  <Card.Body>
                    <div className="d-flex justify-content-between align-item-center">
                      <h4 className="mt-3" style={{width:'50%'}}>{` Your Mood`}</h4>
                        
                        <div style={{alignContent:'flex-end'}}>
                          <div className="myprofile-submit">
                              <button className="myprofile-today-button">Today</button>
                              <DropDown1/>
                          </div>
                        </div>
                      
                    </div>
                  </Card.Body>
                  <Card className="roundded-4" style={{border:'none',boxShadow:'0px 4px 30px 0px #0000000D', borderRadius:'30px'}}>
                    {" "}
                    <div className="">
                      <div className="happy-score-div d-flex">
                      <div className="happy-face-div">
                            <div className="bg-white" style={{borderRadius: '15px',padding: '5px', boxShadow: '0px 2.435401201248169px 7.306204795837402px 0px #00000020'}}>
                                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.92314 18.2033C14.8923 18.2033 18.9205 14.1738 18.9205 9.20323C18.9205 4.23261 14.8923 0.203125 9.92314 0.203125C4.95404 0.203125 0.925781 4.23261 0.925781 9.20323C0.925781 14.1738 4.95404 18.2033 9.92314 18.2033Z" fill="#FFD18D"/>
      <path d="M6.92364 12.7242C3.18849 10.1854 3.11658 5.47164 4.90167 1.72266C3.76234 2.48791 2.81311 3.50372 2.1266 4.69237C1.4401 5.88103 1.03451 7.21102 0.940887 8.58058C0.847259 9.95014 1.06807 11.323 1.58642 12.594C2.10477 13.8651 2.90692 15.0007 3.9315 15.914C4.95608 16.8273 6.17594 17.494 7.49772 17.8632C8.81951 18.2323 10.2082 18.2941 11.5576 18.0439C12.9069 17.7936 14.1811 17.2379 15.2828 16.4193C16.3844 15.6007 17.2842 14.5408 17.9134 13.3208C16.1993 14.2262 14.2715 14.649 12.336 14.5439C10.4004 14.4388 8.52971 13.8099 6.92364 12.7242Z" fill="#FFC570"/>
      <path d="M15.9544 9.04665C15.9552 9.50962 15.8188 9.96244 15.5622 10.3478C15.3057 10.7332 14.9406 11.0337 14.5133 11.2115C14.0859 11.3892 13.6154 11.4362 13.1614 11.3464C12.7074 11.2566 12.2902 11.034 11.9626 10.707C11.635 10.3799 11.4118 9.96296 11.3212 9.50894C11.2306 9.05492 11.2767 8.58422 11.4536 8.1564C11.6305 7.72859 11.9303 7.36289 12.3151 7.10558C12.6999 6.84828 13.1523 6.71094 13.6152 6.71094C14.2348 6.71094 14.8292 6.95688 15.2678 7.39479C15.7063 7.8327 15.9533 8.42679 15.9544 9.04665Z" fill="url(#paint0_linear_2055_16311)"/>
      <path d="M13.2471 10.3201C13.7798 10.3201 14.2116 9.88819 14.2116 9.35538C14.2116 8.82256 13.7798 8.39062 13.2471 8.39062C12.7145 8.39062 12.2827 8.82256 12.2827 9.35538C12.2827 9.88819 12.7145 10.3201 13.2471 10.3201Z" fill="white"/>
      <path d="M14.5805 8.207C14.5805 8.26726 14.5626 8.32616 14.5292 8.37626C14.4957 8.42636 14.4481 8.46541 14.3925 8.48847C14.3368 8.51153 14.2756 8.51756 14.2165 8.5058C14.1574 8.49405 14.1032 8.46503 14.0606 8.42243C14.018 8.37982 13.989 8.32554 13.9772 8.26644C13.9655 8.20734 13.9715 8.14608 13.9946 8.09041C14.0176 8.03475 14.0566 7.98716 14.1067 7.95369C14.1568 7.92021 14.2157 7.90234 14.2759 7.90234C14.3567 7.90234 14.4342 7.93444 14.4913 7.99158C14.5484 8.04871 14.5805 8.1262 14.5805 8.207Z" fill="white"/>
      <path d="M14.7912 10.1963C14.7912 10.249 14.7755 10.3005 14.7463 10.3444C14.717 10.3882 14.6753 10.4224 14.6267 10.4425C14.578 10.4627 14.5244 10.468 14.4727 10.4577C14.421 10.4474 14.3735 10.422 14.3362 10.3848C14.299 10.3475 14.2736 10.3 14.2633 10.2483C14.253 10.1966 14.2583 10.143 14.2785 10.0942C14.2986 10.0455 14.3328 10.0039 14.3766 9.97462C14.4204 9.94532 14.472 9.92969 14.5247 9.92969C14.5954 9.92969 14.6631 9.95777 14.7131 10.0078C14.7631 10.0578 14.7912 10.1256 14.7912 10.1963Z" fill="white"/>
      <path d="M16.5258 8.05584L16.3862 7.92044C16.2652 7.79297 16.1367 7.67286 16.0013 7.56077C15.5743 7.17681 15.0604 6.90237 14.5038 6.76104C14.1966 6.69676 13.8814 6.67963 13.569 6.71027C13.3131 6.74087 13.0617 6.80196 12.8203 6.89222C12.5926 6.97222 12.3765 7.08176 12.1773 7.21803C12.3631 7.06102 12.5712 6.93266 12.7949 6.83721C13.0375 6.72861 13.2936 6.65318 13.5563 6.61295C13.8801 6.56784 14.2093 6.5793 14.5292 6.6468C15.1014 6.78637 15.6248 7.07889 16.0436 7.49307C16.2086 7.6454 16.3143 7.79773 16.3989 7.88659L16.5258 8.05584Z" fill="url(#paint1_linear_2055_16311)"/>
      <path d="M16.6783 6.49025L16.4625 6.29561C16.3855 6.21452 16.3036 6.13821 16.2172 6.06712L15.8661 5.80054C15.1748 5.33695 14.3543 5.10438 13.5226 5.13622C12.5582 5.18276 12.076 5.34356 12.0294 5.23354C12.0083 5.187 12.1056 5.08121 12.3467 4.96697C12.7143 4.80776 13.1094 4.72153 13.51 4.71308C14.4294 4.68088 15.3245 5.01228 16.0015 5.63552L16.3145 5.97403C16.387 6.06201 16.4548 6.15382 16.5175 6.24907L16.6783 6.49025Z" fill="url(#paint2_linear_2055_16311)"/>
      <path d="M3.81879 9.04665C3.81795 9.50981 3.95455 9.96279 4.21126 10.3482C4.46798 10.7337 4.83327 11.0343 5.26088 11.2119C5.68849 11.3895 6.15918 11.4362 6.61334 11.3461C7.0675 11.2559 7.4847 11.033 7.8121 10.7055C8.1395 10.378 8.36238 9.96064 8.45251 9.50634C8.54263 9.05204 8.49595 8.58121 8.31837 8.15347C8.1408 7.72573 7.84032 7.36032 7.45499 7.10353C7.06965 6.84673 6.61681 6.7101 6.15379 6.71094C5.53451 6.71094 4.9406 6.95702 4.5027 7.39506C4.0648 7.83309 3.81879 8.42718 3.81879 9.04665Z" fill="url(#paint3_linear_2055_16311)"/>
      <path d="M5.5572 9.35147C5.55636 9.54329 5.61246 9.73105 5.71839 9.89095C5.82432 10.0509 5.97532 10.1757 6.15225 10.2497C6.32918 10.3237 6.52408 10.3435 6.71226 10.3066C6.90045 10.2697 7.07345 10.1777 7.20934 10.0424C7.34524 9.90702 7.43792 9.73438 7.47564 9.5463C7.51336 9.35822 7.49442 9.16317 7.42123 8.98587C7.34804 8.80856 7.22389 8.65698 7.0645 8.55032C6.90511 8.44366 6.71765 8.38672 6.52589 8.38672C6.26971 8.38672 6.02396 8.48822 5.84241 8.66903C5.66087 8.84983 5.55832 9.09521 5.5572 9.35147Z" fill="white"/>
      <path d="M5.18994 8.207C5.18994 8.26726 5.2078 8.32616 5.24127 8.37626C5.27473 8.42636 5.3223 8.46541 5.37795 8.48847C5.43361 8.51153 5.49484 8.51756 5.55392 8.5058C5.613 8.49405 5.66727 8.46503 5.70986 8.42243C5.75246 8.37982 5.78147 8.32554 5.79322 8.26644C5.80497 8.20734 5.79894 8.14608 5.77589 8.09041C5.75284 8.03475 5.7138 7.98716 5.66371 7.95369C5.61363 7.92021 5.55474 7.90234 5.4945 7.90234C5.41373 7.90234 5.33626 7.93444 5.27914 7.99158C5.22203 8.04871 5.18994 8.1262 5.18994 8.207Z" fill="white"/>
      <path d="M4.96021 10.1963C4.96021 10.249 4.97583 10.3005 5.00512 10.3444C5.0344 10.3882 5.07602 10.4224 5.12472 10.4425C5.17341 10.4627 5.227 10.468 5.27869 10.4577C5.33039 10.4474 5.37787 10.422 5.41514 10.3848C5.45241 10.3475 5.47779 10.3 5.48807 10.2483C5.49836 10.1966 5.49308 10.143 5.47291 10.0942C5.45274 10.0455 5.41858 10.0039 5.37475 9.97462C5.33093 9.94532 5.27941 9.92969 5.2267 9.92969C5.15602 9.92969 5.08823 9.95777 5.03826 10.0078C4.98828 10.0578 4.96021 10.1256 4.96021 10.1963Z" fill="white"/>
      <path d="M3.24811 8.05596L3.38347 7.92056C3.50449 7.79309 3.63303 7.67298 3.76841 7.56089C4.19645 7.17606 4.71192 6.90154 5.27008 6.76117C5.57729 6.69668 5.89255 6.67956 6.20493 6.71039C6.46067 6.742 6.71191 6.80305 6.95365 6.89234C7.17975 6.97286 7.39446 7.08238 7.59239 7.21816C7.40665 7.06115 7.19848 6.93279 6.9748 6.83733C6.73355 6.7291 6.47889 6.65367 6.21762 6.61307C5.89381 6.5678 5.56459 6.57925 5.2447 6.64692C4.67217 6.78549 4.14846 7.07815 3.73034 7.49319C3.60317 7.61749 3.48313 7.74887 3.37078 7.88671L3.24811 8.05596Z" fill="url(#paint4_linear_2055_16311)"/>
      <path d="M3.09149 6.49034L3.30723 6.2957C3.38585 6.21469 3.46919 6.13839 3.5568 6.0672L3.90367 5.80063C4.5975 5.33558 5.42106 5.10296 6.25559 5.1363C7.22004 5.18285 7.69804 5.34364 7.74457 5.23363C7.74457 5.18708 7.66843 5.0813 7.42732 4.96705C7.06104 4.80817 6.66741 4.72195 6.26828 4.71317C5.34745 4.6803 4.45077 5.01172 3.77253 5.63561L3.45951 5.97411C3.38696 6.06209 3.31918 6.1539 3.25647 6.24915L3.09149 6.49034Z" fill="url(#paint5_linear_2055_16311)"/>
      <path d="M11.4078 12.2422C11.4078 13.3381 10.7691 14.616 9.97804 14.616C9.18702 14.616 8.54828 13.3466 8.54828 12.2422H11.4078Z" fill="#FF5757"/>
      <defs>
      <linearGradient id="paint0_linear_2055_16311" x1="12.274" y1="8.0291" x2="15.1987" y2="10.2746" gradientUnits="userSpaceOnUse">
      <stop stop-color="#3A6FFD"/>
      <stop offset="1" stop-color="#D63ACA"/>
      </linearGradient>
      <linearGradient id="paint1_linear_2055_16311" x1="13.105" y1="6.99996" x2="13.8062" y2="8.5931" gradientUnits="userSpaceOnUse">
      <stop stop-color="#3A6FFD"/>
      <stop offset="1" stop-color="#D63ACA"/>
      </linearGradient>
      <linearGradient id="paint2_linear_2055_16311" x1="13.019" y1="5.21211" x2="13.9377" y2="7.05663" gradientUnits="userSpaceOnUse">
      <stop stop-color="#3A6FFD"/>
      <stop offset="1" stop-color="#D63ACA"/>
      </linearGradient>
      <linearGradient id="paint3_linear_2055_16311" x1="4.81686" y1="8.0291" x2="7.74156" y2="10.2746" gradientUnits="userSpaceOnUse">
      <stop stop-color="#3A6FFD"/>
      <stop offset="1" stop-color="#D63ACA"/>
      </linearGradient>
      <linearGradient id="paint4_linear_2055_16311" x1="4.17489" y1="7" x2="4.87665" y2="8.59272" gradientUnits="userSpaceOnUse">
      <stop stop-color="#3A6FFD"/>
      <stop offset="1" stop-color="#D63ACA"/>
      </linearGradient>
      <linearGradient id="paint5_linear_2055_16311" x1="4.08415" y1="5.21214" x2="5.00274" y2="7.05693" gradientUnits="userSpaceOnUse">
      <stop stop-color="#3A6FFD"/>
      <stop offset="1" stop-color="#D63ACA"/>
      </linearGradient>
      </defs>
                                </svg>
                              </div>
                          </div>
                        <div className="score-div">
                          <p className="score-text"> Score</p>
                          <h5 className="happy-text"> 3456 Happy</h5>
                        </div>
                        

                      </div>
                      {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="19"
                        viewBox="0 0 19 19"
                        fill="none"
                        style={{
                          position: "relative",
                          left: "213px",
                          top: "157px",
                        }}
                      >
                        <path
                          d="M9.92314 18.2033C14.8923 18.2033 18.9205 14.1738 18.9205 9.20323C18.9205 4.23261 14.8923 0.203125 9.92314 0.203125C4.95404 0.203125 0.925781 4.23261 0.925781 9.20323C0.925781 14.1738 4.95404 18.2033 9.92314 18.2033Z"
                          fill="#FFD18D"
                        />
                        <path
                          d="M6.92364 12.7242C3.18849 10.1854 3.11658 5.47164 4.90167 1.72266C3.76234 2.48791 2.81311 3.50372 2.1266 4.69237C1.4401 5.88103 1.03451 7.21102 0.940887 8.58058C0.847259 9.95014 1.06807 11.323 1.58642 12.594C2.10477 13.8651 2.90692 15.0007 3.9315 15.914C4.95608 16.8273 6.17594 17.494 7.49772 17.8632C8.81951 18.2323 10.2082 18.2941 11.5576 18.0439C12.9069 17.7936 14.1811 17.2379 15.2828 16.4193C16.3844 15.6007 17.2842 14.5408 17.9134 13.3208C16.1993 14.2262 14.2715 14.649 12.336 14.5439C10.4004 14.4388 8.52971 13.8099 6.92364 12.7242Z"
                          fill="#FFC570"
                        />
                        <path
                          d="M15.9544 9.04665C15.9552 9.50962 15.8188 9.96244 15.5622 10.3478C15.3057 10.7332 14.9406 11.0337 14.5133 11.2115C14.0859 11.3892 13.6154 11.4362 13.1614 11.3464C12.7074 11.2566 12.2902 11.034 11.9626 10.707C11.635 10.3799 11.4118 9.96296 11.3212 9.50894C11.2306 9.05492 11.2767 8.58422 11.4536 8.1564C11.6305 7.72859 11.9303 7.36289 12.3151 7.10558C12.6999 6.84828 13.1523 6.71094 13.6152 6.71094C14.2348 6.71094 14.8292 6.95688 15.2678 7.39479C15.7063 7.8327 15.9533 8.42679 15.9544 9.04665Z"
                          fill="url(#paint0_linear_2055_16311)"
                        />
                        <path
                          d="M13.2471 10.3201C13.7798 10.3201 14.2116 9.88819 14.2116 9.35538C14.2116 8.82256 13.7798 8.39062 13.2471 8.39062C12.7145 8.39062 12.2827 8.82256 12.2827 9.35538C12.2827 9.88819 12.7145 10.3201 13.2471 10.3201Z"
                          fill="white"
                        />
                        <path
                          d="M14.5805 8.207C14.5805 8.26726 14.5626 8.32616 14.5292 8.37626C14.4957 8.42636 14.4481 8.46541 14.3925 8.48847C14.3368 8.51153 14.2756 8.51756 14.2165 8.5058C14.1574 8.49405 14.1032 8.46503 14.0606 8.42243C14.018 8.37982 13.989 8.32554 13.9772 8.26644C13.9655 8.20734 13.9715 8.14608 13.9946 8.09041C14.0176 8.03475 14.0566 7.98716 14.1067 7.95369C14.1568 7.92021 14.2157 7.90234 14.2759 7.90234C14.3567 7.90234 14.4342 7.93444 14.4913 7.99158C14.5484 8.04871 14.5805 8.1262 14.5805 8.207Z"
                          fill="white"
                        />
                        <path
                          d="M14.7912 10.1963C14.7912 10.249 14.7755 10.3005 14.7463 10.3444C14.717 10.3882 14.6753 10.4224 14.6267 10.4425C14.578 10.4627 14.5244 10.468 14.4727 10.4577C14.421 10.4474 14.3735 10.422 14.3362 10.3848C14.299 10.3475 14.2736 10.3 14.2633 10.2483C14.253 10.1966 14.2583 10.143 14.2785 10.0942C14.2986 10.0455 14.3328 10.0039 14.3766 9.97462C14.4204 9.94532 14.472 9.92969 14.5247 9.92969C14.5954 9.92969 14.6631 9.95777 14.7131 10.0078C14.7631 10.0578 14.7912 10.1256 14.7912 10.1963Z"
                          fill="white"
                        />
                        <path
                          d="M16.5258 8.05584L16.3862 7.92044C16.2652 7.79297 16.1367 7.67286 16.0013 7.56077C15.5743 7.17681 15.0604 6.90237 14.5038 6.76104C14.1966 6.69676 13.8814 6.67963 13.569 6.71027C13.3131 6.74087 13.0617 6.80196 12.8203 6.89222C12.5926 6.97222 12.3765 7.08176 12.1773 7.21803C12.3631 7.06102 12.5712 6.93266 12.7949 6.83721C13.0375 6.72861 13.2936 6.65318 13.5563 6.61295C13.8801 6.56784 14.2093 6.5793 14.5292 6.6468C15.1014 6.78637 15.6248 7.07889 16.0436 7.49307C16.2086 7.6454 16.3143 7.79773 16.3989 7.88659L16.5258 8.05584Z"
                          fill="url(#paint1_linear_2055_16311)"
                        />
                        <path
                          d="M16.6783 6.49025L16.4625 6.29561C16.3855 6.21452 16.3036 6.13821 16.2172 6.06712L15.8661 5.80054C15.1748 5.33695 14.3543 5.10438 13.5226 5.13622C12.5582 5.18276 12.076 5.34356 12.0294 5.23354C12.0083 5.187 12.1056 5.08121 12.3467 4.96697C12.7143 4.80776 13.1094 4.72153 13.51 4.71308C14.4294 4.68088 15.3245 5.01228 16.0015 5.63552L16.3145 5.97403C16.387 6.06201 16.4548 6.15382 16.5175 6.24907L16.6783 6.49025Z"
                          fill="url(#paint2_linear_2055_16311)"
                        />
                        <path
                          d="M3.81879 9.04665C3.81795 9.50981 3.95455 9.96279 4.21126 10.3482C4.46798 10.7337 4.83327 11.0343 5.26088 11.2119C5.68849 11.3895 6.15918 11.4362 6.61334 11.3461C7.0675 11.2559 7.4847 11.033 7.8121 10.7055C8.1395 10.378 8.36238 9.96064 8.45251 9.50634C8.54263 9.05204 8.49595 8.58121 8.31837 8.15347C8.1408 7.72573 7.84032 7.36032 7.45499 7.10353C7.06965 6.84673 6.61681 6.7101 6.15379 6.71094C5.53451 6.71094 4.9406 6.95702 4.5027 7.39506C4.0648 7.83309 3.81879 8.42718 3.81879 9.04665Z"
                          fill="url(#paint3_linear_2055_16311)"
                        />
                        <path
                          d="M5.5572 9.35147C5.55636 9.54329 5.61246 9.73105 5.71839 9.89095C5.82432 10.0509 5.97532 10.1757 6.15225 10.2497C6.32918 10.3237 6.52408 10.3435 6.71226 10.3066C6.90045 10.2697 7.07345 10.1777 7.20934 10.0424C7.34524 9.90702 7.43792 9.73438 7.47564 9.5463C7.51336 9.35822 7.49442 9.16317 7.42123 8.98587C7.34804 8.80856 7.22389 8.65698 7.0645 8.55032C6.90511 8.44366 6.71765 8.38672 6.52589 8.38672C6.26971 8.38672 6.02396 8.48822 5.84241 8.66903C5.66087 8.84983 5.55832 9.09521 5.5572 9.35147Z"
                          fill="white"
                        />
                        <path
                          d="M5.18994 8.207C5.18994 8.26726 5.2078 8.32616 5.24127 8.37626C5.27473 8.42636 5.3223 8.46541 5.37795 8.48847C5.43361 8.51153 5.49484 8.51756 5.55392 8.5058C5.613 8.49405 5.66727 8.46503 5.70986 8.42243C5.75246 8.37982 5.78147 8.32554 5.79322 8.26644C5.80497 8.20734 5.79894 8.14608 5.77589 8.09041C5.75284 8.03475 5.7138 7.98716 5.66371 7.95369C5.61363 7.92021 5.55474 7.90234 5.4945 7.90234C5.41373 7.90234 5.33626 7.93444 5.27914 7.99158C5.22203 8.04871 5.18994 8.1262 5.18994 8.207Z"
                          fill="white"
                        />
                        <path
                          d="M4.96021 10.1963C4.96021 10.249 4.97583 10.3005 5.00512 10.3444C5.0344 10.3882 5.07602 10.4224 5.12472 10.4425C5.17341 10.4627 5.227 10.468 5.27869 10.4577C5.33039 10.4474 5.37787 10.422 5.41514 10.3848C5.45241 10.3475 5.47779 10.3 5.48807 10.2483C5.49836 10.1966 5.49308 10.143 5.47291 10.0942C5.45274 10.0455 5.41858 10.0039 5.37475 9.97462C5.33093 9.94532 5.27941 9.92969 5.2267 9.92969C5.15602 9.92969 5.08823 9.95777 5.03826 10.0078C4.98828 10.0578 4.96021 10.1256 4.96021 10.1963Z"
                          fill="white"
                        />
                        <path
                          d="M3.24811 8.05596L3.38347 7.92056C3.50449 7.79309 3.63303 7.67298 3.76841 7.56089C4.19645 7.17606 4.71192 6.90154 5.27008 6.76117C5.57729 6.69668 5.89255 6.67956 6.20493 6.71039C6.46067 6.742 6.71191 6.80305 6.95365 6.89234C7.17975 6.97286 7.39446 7.08238 7.59239 7.21816C7.40665 7.06115 7.19848 6.93279 6.9748 6.83733C6.73355 6.7291 6.47889 6.65367 6.21762 6.61307C5.89381 6.5678 5.56459 6.57925 5.2447 6.64692C4.67217 6.78549 4.14846 7.07815 3.73034 7.49319C3.60317 7.61749 3.48313 7.74887 3.37078 7.88671L3.24811 8.05596Z"
                          fill="url(#paint4_linear_2055_16311)"
                        />
                        <path
                          d="M3.09149 6.49034L3.30723 6.2957C3.38585 6.21469 3.46919 6.13839 3.5568 6.0672L3.90367 5.80063C4.5975 5.33558 5.42106 5.10296 6.25559 5.1363C7.22004 5.18285 7.69804 5.34364 7.74457 5.23363C7.74457 5.18708 7.66843 5.0813 7.42732 4.96705C7.06104 4.80817 6.66741 4.72195 6.26828 4.71317C5.34745 4.6803 4.45077 5.01172 3.77253 5.63561L3.45951 5.97411C3.38696 6.06209 3.31918 6.1539 3.25647 6.24915L3.09149 6.49034Z"
                          fill="url(#paint5_linear_2055_16311)"
                        />
                        <path
                          d="M11.4078 12.2422C11.4078 13.3381 10.7691 14.616 9.97804 14.616C9.18702 14.616 8.54828 13.3466 8.54828 12.2422H11.4078Z"
                          fill="#FF5757"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_2055_16311"
                            x1="12.274"
                            y1="8.0291"
                            x2="15.1987"
                            y2="10.2746"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#3A6FFD" />
                            <stop offset="1" stop-color="#D63ACA" />
                          </linearGradient>
                          <linearGradient
                            id="paint1_linear_2055_16311"
                            x1="13.105"
                            y1="6.99996"
                            x2="13.8062"
                            y2="8.5931"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#3A6FFD" />
                            <stop offset="1" stop-color="#D63ACA" />
                          </linearGradient>
                          <linearGradient
                            id="paint2_linear_2055_16311"
                            x1="13.019"
                            y1="5.21211"
                            x2="13.9377"
                            y2="7.05663"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#3A6FFD" />
                            <stop offset="1" stop-color="#D63ACA" />
                          </linearGradient>
                          <linearGradient
                            id="paint3_linear_2055_16311"
                            x1="4.81686"
                            y1="8.0291"
                            x2="7.74156"
                            y2="10.2746"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#3A6FFD" />
                            <stop offset="1" stop-color="#D63ACA" />
                          </linearGradient>
                          <linearGradient
                            id="paint4_linear_2055_16311"
                            x1="4.17489"
                            y1="7"
                            x2="4.87665"
                            y2="8.59272"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#3A6FFD" />
                            <stop offset="1" stop-color="#D63ACA" />
                          </linearGradient>
                          <linearGradient
                            id="paint5_linear_2055_16311"
                            x1="4.08415"
                            y1="5.21214"
                            x2="5.00274"
                            y2="7.05693"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#3A6FFD" />
                            <stop offset="1" stop-color="#D63ACA" />
                          </linearGradient>
                        </defs>
                      </svg> */}
                      <img src={Images.graph} style={{width:'100%', paddingTop:'40px'}}/>
                      {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="267"
                        height="159"
                        viewBox="0 0 267 159"
                        fill="none"
                        style={{
                          position: "relative",
                          left: "189px",
                          top: "187px",
                        }}
                      >
                        <g filter="url(#filter0_d_2055_16312)">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M53.5292 24.5C52.1841 24.5 51.0938 25.5904 51.0938 26.9354V39.2393L43.5087 46.7745C43.0293 47.2507 43.0293 48.0261 43.5087 48.5023L51.0938 56.0376V95.1271C51.0938 96.4721 52.1841 97.5625 53.5292 97.5625H221.571C222.916 97.5625 224.006 96.4721 224.006 95.1271V26.9354C224.006 25.5904 222.916 24.5 221.571 24.5H53.5292Z"
                            fill="white"
                          />
                        </g>

                        <defs>
                          <filter
                            id="filter0_d_2055_16312"
                            x="0.529648"
                            y="0.145987"
                            width="266.096"
                            height="158.302"
                            filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB"
                          >
                            <feFlood
                              flood-opacity="0"
                              result="BackgroundImageFix"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset dy="18.2655" />
                            <feGaussianBlur stdDeviation="21.3098" />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0.597048 0 0 0 0 0.663834 0 0 0 0 0.735757 0 0 0 0.2 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_2055_16312"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow_2055_16312"
                              result="shape"
                            />
                          </filter>
                        </defs>
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="666"
                        height="236"
                        viewBox="0 0 666 236"
                        fill="none"
                        style={{}}
                      >
                        <path
                          d="M-375.421 -32.9283L-457.978 -46.9935V280.537H723.784V33.6763L640.002 78.8302L639.995 78.8342L639.987 78.838L557.836 120.208L557.568 120.343L557.298 120.213L471.531 78.9276L388.308 93.4202L388.207 93.4379L388.105 93.4212L299.725 78.9099L216.4 90.2656L216.266 90.2838L216.137 90.2437L132.722 64.3081L132.657 64.288L132.599 64.2542L46.9286 14.8819L-34.9367 52.6256L-35.0298 52.6685L-35.1317 52.6786L-119.811 61.0558L-119.979 61.0725L-120.132 60.9998L-204.644 20.8138L-287.875 33.2587L-288.129 33.2966L-288.333 33.1416L-375.421 -32.9283Z"
                          stroke="url(#paint0_linear_2055_16306)"
                          stroke-width="1.21769"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_2055_16306"
                            x1="472.024"
                            y1="44.9142"
                            x2="335.854"
                            y2="421.105"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#3A6FFD" />
                            <stop offset="1" stop-color="#D63ACA" />
                          </linearGradient>
                        </defs>
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="665"
                        height="2"
                        viewBox="0 0 665 2"
                        fill="none"
                      >
                        <path
                          d="M0.340545 0.753641H664.243"
                          stroke="#E4E6E8"
                          stroke-width="0.608844"
                          stroke-linecap="square"
                        />
                      </svg> */}
                      
                      <div className="w-100 d-flex justify-content-around jan-div">
                        <span className="date">Jan 15</span>
                        <span className="date">Jan 16</span>
                        <span className="date">Jan 17</span>
                        <span className="date">Jan 18</span>
                        <span className="date">Jan 19</span>
                        <span className="date">Jan 20</span>
                        <span className="date">Jan 21</span>
                      </div>
                    </div>
                  </Card>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="mt-3">
          <div className="d-flex">
            {" "}
           <div className="your-appointment-div d-flex justify-content-between w-100">
              <div>
                <h1 className="your-appointment-text">Your Appointments</h1>
              </div>
              <div className=" appointmentheader" style={{width:'218px'}}>
                <span className=" Appointment"> Add New Appointment +</span>
              </div>
            </div>
          </div>
          <Card className="mt-3 rounded-4 your-appointment-card" style={{border:'none'}}>
            <Card.Body>
              <div className="important-div">
                <p className="important-text">Important </p>
              </div>
              <h4> Mental Therapy For Live a good Life</h4>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    d="M12.8333 7.0013C12.8333 10.2213 10.22 12.8346 7 12.8346C3.78 12.8346 1.16667 10.2213 1.16667 7.0013C1.16667 3.7813 3.78 1.16797 7 1.16797C10.22 1.16797 12.8333 3.7813 12.8333 7.0013Z"
                    stroke="url(#paint0_linear_2781_2227)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.16417 8.85307L7.35583 7.77391C7.04083 7.58724 6.78417 7.13807 6.78417 6.77057V4.37891"
                    stroke="url(#paint1_linear_2781_2227)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_2781_2227"
                      x1="3.65556"
                      y1="4.45408"
                      x2="10.9472"
                      y2="10.0541"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#3A6FFD" />
                      <stop offset="1" stop-color="#D63ACA" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_2781_2227"
                      x1="7.2919"
                      y1="5.63913"
                      x2="9.31852"
                      y2="6.46707"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#3A6FFD" />
                      <stop offset="1" stop-color="#D63ACA" />
                    </linearGradient>
                  </defs>
                </svg>
                <span> {`12 : 00 TO 02 : 00`}</span>
                <div className="d-flex justify-content-between">
                  <Image
                    src={Images.fcard_2}
                    className=""
                    alt=""
                    style={{ marginLeft: "auto", width: "50px", height: "50px" }}
                  />
                  <div className="">
                    <h5> Dr Nelson </h5>
                    <div className="specialist">
                      {" "}
                      <p>Specialist</p>
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
          <Card className="mt-3 rounded-4 your-appointment-card" style={{border:'none'}}>
            <Card.Body>
              <h4> Mental Therapy For Live a good Life</h4>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    d="M12.8333 7.0013C12.8333 10.2213 10.22 12.8346 7 12.8346C3.78 12.8346 1.16667 10.2213 1.16667 7.0013C1.16667 3.7813 3.78 1.16797 7 1.16797C10.22 1.16797 12.8333 3.7813 12.8333 7.0013Z"
                    stroke="url(#paint0_linear_2781_2227)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.16417 8.85307L7.35583 7.77391C7.04083 7.58724 6.78417 7.13807 6.78417 6.77057V4.37891"
                    stroke="url(#paint1_linear_2781_2227)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_2781_2227"
                      x1="3.65556"
                      y1="4.45408"
                      x2="10.9472"
                      y2="10.0541"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#3A6FFD" />
                      <stop offset="1" stop-color="#D63ACA" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_2781_2227"
                      x1="7.2919"
                      y1="5.63913"
                      x2="9.31852"
                      y2="6.46707"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#3A6FFD" />
                      <stop offset="1" stop-color="#D63ACA" />
                    </linearGradient>
                  </defs>
                </svg>
                <span> {`12 : 00 TO 02 : 00`}</span>
                <div className="d-flex justify-content-between">
                  <Image
                    src={Images.fcard_2}
                    className=""
                    alt=""
                    style={{ marginLeft: "auto", width: "50px", height: "50px" }}
                  />
                  <div className="">
                    <h5> Dr Nelson </h5>
                    <div className="specialist">
                      {" "}
                      <p>Specialist</p>
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Container>
      </div>
    </AdminLayout>
  );
}

export default MyAppointment;
