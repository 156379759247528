/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Forget1 = ({ className }) => {
  return (
    <svg
      className={`forget-1 ${className}`}
      fill="none"
      height="27"
      viewBox="0 0 26 27"
      width="26"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M13 5.375C7.58334 5.375 2.95751 8.74417 1.08334 13.5C2.95751 18.2558 7.58334 21.625 13 21.625C18.4167 21.625 23.0425 18.2558 24.9167 13.5C23.0425 8.74417 18.4167 5.375 13 5.375ZM13 18.9167C10.01 18.9167 7.58334 16.49 7.58334 13.5C7.58334 10.51 10.01 8.08333 13 8.08333C15.99 8.08333 18.4167 10.51 18.4167 13.5C18.4167 16.49 15.99 18.9167 13 18.9167ZM13 10.25C11.2017 10.25 9.75001 11.7017 9.75001 13.5C9.75001 15.2983 11.2017 16.75 13 16.75C14.7983 16.75 16.25 15.2983 16.25 13.5C16.25 11.7017 14.7983 10.25 13 10.25Z"
        fill="#00903D"
      />
    </svg>
  );
};
