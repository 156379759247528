/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const FrameWrapper = ({
  className,
  frame = "https://generation-sessions.s3.amazonaws.com/5e66f080cc2b7aa215e8b54c595b9855/img/frame-77.svg",
}) => {
  return <img className={`frame-wrapper ${className}`} alt="Frame" src={frame} />;
};

FrameWrapper.propTypes = {
  frame: PropTypes.string,
};
