/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Download = ({ className }) => {
  return (
    <svg
      className={`download ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M17.5 12L17.1464 12.3536L17.5 12.7071L17.8536 12.3536L17.5 12ZM18 0.999999C18 0.723857 17.7761 0.499999 17.5 0.499999C17.2239 0.499999 17 0.723857 17 0.999999L18 0.999999ZM11.6464 6.85355L17.1464 12.3536L17.8536 11.6464L12.3536 6.14645L11.6464 6.85355ZM17.8536 12.3536L23.3536 6.85355L22.6464 6.14645L17.1464 11.6464L17.8536 12.3536ZM18 12L18 0.999999L17 0.999999L17 12L18 12Z"
        fill="#2A3336"
      />
      <rect className="rect" fill="#2A3336" height="1" width="13" x="11" y="15" />
    </svg>
  );
};
