import React from 'react';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const RegisterationSection = () => {
  return (
    <section className="RegisterationSection">
      <Container>
        
        <div className="d-flex flex-column flex-md-row justify-content-center">
          <div className="text-center">
            <p>
              Join the thousands that have become happier!
            </p>
          </div>
          <div className="text-center ms-4 mt-1">
            <a href="#">
              Register
              <FontAwesomeIcon icon={faArrowRight} className='ps-2' />
            </a>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default RegisterationSection;
