/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const ProfileCircle4 = ({ className }) => {
  return (
    <svg
      className={`profile-circle-4 ${className}`}
      fill="none"
      height="25"
      viewBox="0 0 24 25"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M12.1207 13.28C12.0507 13.27 11.9607 13.27 11.8807 13.28C10.1207 13.22 8.7207 11.78 8.7207 10.01C8.7207 8.19998 10.1807 6.72998 12.0007 6.72998C13.8107 6.72998 15.2807 8.19998 15.2807 10.01C15.2707 11.78 13.8807 13.22 12.1207 13.28Z"
        stroke="#93989A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        className="path"
        d="M18.7398 19.8801C16.9598 21.5101 14.5998 22.5001 11.9998 22.5001C9.39977 22.5001 7.03977 21.5101 5.25977 19.8801C5.35977 18.9401 5.95977 18.0201 7.02977 17.3001C9.76977 15.4801 14.2498 15.4801 16.9698 17.3001C18.0398 18.0201 18.6398 18.9401 18.7398 19.8801Z"
        stroke="#93989A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        className="path"
        d="M12 22.5C17.5228 22.5 22 18.0228 22 12.5C22 6.97715 17.5228 2.5 12 2.5C6.47715 2.5 2 6.97715 2 12.5C2 18.0228 6.47715 22.5 12 22.5Z"
        stroke="#93989A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};
