import React from "react";
import { Navbar, Nav, Image, Container, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Images from "../constants/Images";
import Profile from "./Profile";
const Navigation = () => {
  return (
    <Navbar expand="lg">
      <Container>
        <Navbar.Brand href="/">
          <Image src={Images.symapathiq_logo} width={"160vw"} height={"35vh"} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="main-menu" />
        <Navbar.Collapse id="main-menu">
          <Nav className=" mx-auto mb-2 mb-lg-0" as="ul">
            <Nav.Item
              as="li"
              style={{ paddingLeft: "0", paddingRight: "0", fontSize: "9px" }}
            >
              <Link className="nav-link active" to="/">
                HOME
              </Link>
            </Nav.Item>
            <Nav.Item
              as="li"
              style={{ paddingLeft: "0", paddingRight: "0", fontSize: "9px" }}
            >
              <Link className="nav-link" to="/marketplace">
                MARKETPLACE
              </Link>
            </Nav.Item>
            <Nav.Item
              as="li"
              style={{ paddingLeft: "0", paddingRight: "0", fontSize: "9px" }}
            >
              <Link className="nav-link" to="/#forum">
                FORUM
              </Link>
            </Nav.Item>
            <Nav.Item
              as="li"
              style={{ paddingLeft: "0", paddingRight: "0", fontSize: "9px" }}
            >
              <Link className="nav-link" to="/specialist">
              SPECIALISTS
              </Link>
            </Nav.Item>
            <Nav.Item
              as="li"
              style={{ paddingLeft: "0", paddingRight: "0", fontSize: "9px" }}
            >
              <Link className="nav-link" to="/courses">
                COURSES
              </Link>
            </Nav.Item>
            <Nav.Item
              as="li"
              style={{ paddingLeft: "0", paddingRight: "0", fontSize: "9px" }}
            >
              <Link className="nav-link" to="/news">
                NEWS
              </Link>
            </Nav.Item>
          </Nav>
          <Profile />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
