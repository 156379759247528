/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Icon6 = ({ className }) => {
  return (
    <svg
      className={`icon-6 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M13.6134 8.94036L3.95423 8.94036C3.47171 8.94036 3.07692 9.33515 3.07692 9.81767C3.07692 10.3002 3.47171 10.695 3.95423 10.695L13.6134 10.695L13.6134 12.2654C13.6134 12.6602 14.0872 12.8532 14.3591 12.5724L16.7981 10.1247C16.9648 9.94927 16.9648 9.6773 16.7981 9.50184L14.3591 7.05414C14.0872 6.7734 13.6134 6.97519 13.6134 7.3612L13.6134 8.94036Z"
        fill="white"
      />
    </svg>
  );
};
