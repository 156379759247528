import React from "react";
import Header from '../components/Header';
import Footer from '../components/Footer';

function AuthLayout({ children, isHome = false }) {
  return (
    <>
      {isHome && <Header />}
      <main id="main">{children}</main>
      {isHome && <Footer />}
    </>
  );
}

export default AuthLayout;
