import React from "react";
import { Container, Card, Row } from "react-bootstrap";
import Images from "../../constants/Images";

const Banner = () => {
  return (
    <section className="HomeBanner">
      <Container>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ width: "100%" }}>
          {" "}
          <p className="therapist" style={{color:"rgba(115, 213, 255, 1)"}}>
            YOUR PROFESSIONAL THERAPIST
          </p>
          <h1>
            Strong Image of
            <span>
              <br />
              Yourself is Our Success
            </span>
          </h1>
          <div className="separator"></div>
          <p className="letting">
            Instead of letting cooking vapours and odours rise in the first
            place, BORA
            <br />
            cooktop extractors draw them away where they are created
          </p>
          <div className="card_cover">
            <Row className="flex-column flex-sm-row">
              {" "}
              {/* Use flex-column for small screens */}
              <div className="col-md-4 mt-2">
                <Card>
                  <Card.Img variant="top" src={Images.HomeCard_1} />
                  <Card.Body>
                    <Card.Title>Individual Therapy</Card.Title>
                    <Card.Text>
                      Mobile applications are now essential and allow you.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-md-4 mt-2">
                <Card>
                  <Card.Img variant="top" src={Images.HomeCard_2} />
                  <Card.Body>
                    <Card.Title>Individual Therapy</Card.Title>
                    <Card.Text>
                      Mobile applications are now essential and allow you.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-md-4 mt-2">
                <Card>
                  <Card.Img variant="top" src={Images.HomeCard_3} />
                  <Card.Body>
                    <Card.Title>Individual Therapy</Card.Title>
                    <Card.Text>
                      Mobile applications are now essential and allow you.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            </Row>
          </div>
        </div>
      </div>
      </Container>
    </section>
  );
};

export default Banner;
