/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LikedTrue = ({ className }) => {
  return (
    <svg
      className={`liked-true ${className}`}
      fill="none"
      height="52"
      viewBox="0 0 52 52"
      width="52"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect className="rect" fill="white" height="52" rx="10" width="52" />
      <path
        className="path"
        clipRule="evenodd"
        d="M20.1121 13.7249C17.7825 13.7779 15.5514 14.9332 14.1872 16.9287C12.3683 19.5894 12.4973 23.1483 14.5038 25.6703L25.9985 38.2778L37.4647 25.712C39.495 23.1997 39.6479 19.6365 37.8389 16.9605C36.4821 14.9538 34.2512 13.7854 31.9173 13.7247C31.1393 13.7044 30.3491 13.808 29.5768 14.0445C28.1283 14.4877 26.9059 15.3634 26.0021 16.4904C25.1018 15.3671 23.8852 14.4961 22.4432 14.0509C21.6731 13.813 20.8888 13.7072 20.1121 13.7249ZM20.1791 16.9927C20.6109 16.9837 21.0468 17.042 21.4773 17.1748C22.0698 17.3577 22.5894 17.6704 23.0248 18.067C24.0634 19.0688 25.1206 20.1499 26.0018 21.0311C26.9877 20.0452 27.9997 19.046 28.9787 18.067C29.4172 17.6685 29.94 17.3541 30.5358 17.1717C32.2628 16.6433 34.1129 17.2972 35.1242 18.7927C36.1353 20.2884 36.0544 22.2518 34.9196 23.6558L25.9988 33.4049L17.065 23.6301C15.9435 22.2203 15.8724 20.2634 16.889 18.7763C17.6515 17.6611 18.8838 17.0196 20.1791 16.9927Z"
        fill="#FF0000"
        fillRule="evenodd"
      />
      <path
        className="path"
        d="M20.1791 16.9927C20.6109 16.9837 21.0468 17.042 21.4773 17.1748C22.0698 17.3577 22.5894 17.6704 23.0248 18.067C24.0634 19.0688 25.1206 20.1499 26.0018 21.0311C26.9877 20.0452 27.9997 19.046 28.9787 18.067C29.4172 17.6685 29.94 17.3541 30.5358 17.1717C32.2628 16.6433 34.1129 17.2972 35.1242 18.7927C36.1353 20.2884 36.0544 22.2518 34.9196 23.6558L25.9988 33.4049L17.065 23.6301C15.9435 22.2203 15.8724 20.2634 16.889 18.7763C17.6515 17.6611 18.8838 17.0196 20.1791 16.9927Z"
        fill="#FF0000"
      />
    </svg>
  );
};
