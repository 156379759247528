import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import Images from "../../constants/Images";
import ButtonStyled from "../ButtonStyled";
const NewsSection = () => {
  return (
    <section className="NewsSection">
      <Container>
        <div className="d-flex flex-column align-items-start">
          <p className="text-start who">WHO WE ARE</p>
          <div className="d-flex flex-row w-100">
            <h1 className="text-start">
              <b>News</b>
            </h1>
            <div className="" style={{ marginLeft: "auto" }}>
            <div className="btn-detaile-div "style={{marginRight:'0px'}}>
            {/* <ButtonStyled title="Today" className="btn-detaile" isDown={true} /> */}
            <button class="btn-detaile">
              See all
              <i class="fas fa-arrow-right button-icon1"></i>
              
            </button>
            </div>
            </div>
          </div>
          <div className="separator"></div>
        </div>
        <Row>
          <Col lg={4} md={4} sm={12}>
            <Card>
              <Card.Img
                src={Images.News_1}
                className="card-img-top"
                alt="..."
              />
              <Card.Body>
                <p className="card-text">12.11.2022</p>
                <h5 className="card-title">
                  Identifying And Treating Pain From Nerve Tension
                </h5>
                <p className="card-text-two">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor Lorem ipsum dolor sit amet...
                </p>

                <a href="#" className="ReadMoreBtn btn">
                  Read more{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                  >
                    <path
                      d="M10.9082 1.11134L10.9082 0.461344L11.5582 0.461344L11.5582 1.11134L10.9082 1.11134ZM1.5711 11.3677C1.31725 11.6215 0.905698 11.6215 0.651857 11.3677C0.398016 11.1139 0.398016 10.7023 0.651857 10.4485L1.5711 11.3677ZM0.685531 0.461344H10.9082L10.9082 1.76134H0.685531L0.685531 0.461344ZM11.5582 1.11134L11.5582 11.334H10.2582L10.2582 1.11134L11.5582 1.11134ZM11.3678 1.57096L1.5711 11.3677L0.651857 10.4485L10.4486 0.651724L11.3678 1.57096Z"
                      fill="#3B3A43"
                    />
                  </svg>
                </a>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} md={4} sm={12}>
            <Card>
              <Card.Img
                src={Images.News_2}
                className="card-img-top"
                alt="..."
              />
              <Card.Body>
                <p className="card-text">12.11.2022</p>
                <h5 className="card-title">
                  Identifying And Treating Pain From Nerve Tension
                </h5>
                <p className="card-text-two">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor Lorem ipsum dolor sit amet...
                </p>
                <a href="#" className="ReadMoreBtn btn">
                  Read more{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                  >
                    <path
                      d="M10.9082 1.11134L10.9082 0.461344L11.5582 0.461344L11.5582 1.11134L10.9082 1.11134ZM1.5711 11.3677C1.31725 11.6215 0.905698 11.6215 0.651857 11.3677C0.398016 11.1139 0.398016 10.7023 0.651857 10.4485L1.5711 11.3677ZM0.685531 0.461344H10.9082L10.9082 1.76134H0.685531L0.685531 0.461344ZM11.5582 1.11134L11.5582 11.334H10.2582L10.2582 1.11134L11.5582 1.11134ZM11.3678 1.57096L1.5711 11.3677L0.651857 10.4485L10.4486 0.651724L11.3678 1.57096Z"
                      fill="#3B3A43"
                    />
                  </svg>
                </a>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} md={4} sm={12}>
            <Card>
              <Card.Img
                src={Images.News_3}
                className="card-img-top"
                alt="..."
              />
              <Card.Body>
                <p className="card-text">12.11.2022</p>
                <h5 className="card-title">
                  Identifying And Treating Pain From Nerve Tension
                </h5>
                <p className="card-text-two">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor Lorem ipsum dolor sit amet...
                </p>
                <a href="#" className="ReadMoreBtn btn">
                  Read more{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                  >
                    <path
                      d="M10.9082 1.11134L10.9082 0.461344L11.5582 0.461344L11.5582 1.11134L10.9082 1.11134ZM1.5711 11.3677C1.31725 11.6215 0.905698 11.6215 0.651857 11.3677C0.398016 11.1139 0.398016 10.7023 0.651857 10.4485L1.5711 11.3677ZM0.685531 0.461344H10.9082L10.9082 1.76134H0.685531L0.685531 0.461344ZM11.5582 1.11134L11.5582 11.334H10.2582L10.2582 1.11134L11.5582 1.11134ZM11.3678 1.57096L1.5711 11.3677L0.651857 10.4485L10.4486 0.651724L11.3678 1.57096Z"
                      fill="#3B3A43"
                    />
                  </svg>
                </a>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default NewsSection;
