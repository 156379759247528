/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import { Forget1 } from "../../icons/Forget1";
import "./style.css";

export const InputFields = ({
  showForgetPassword = true,
  inputNameText = "Enter Text",
  showIcon = true,
  inputFieldText = "Enter your Text",
  inputFields,
  className,
  divClassName,
  inputFieldsClassName,
  divClassNameOverride,
  icon = <Forget1 className="forget" />,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    inputFields: inputFields || "non-active",
  });

  return (
    <div
      className={`INPUT-FIELDS ${className}`}
      onClick={() => {
        dispatch("click");
      }}
    >
      <div className={`enter-text ${state.inputFields} ${divClassName}`}>{inputNameText}</div>
      <div className={`input-fields input-fields-${state.inputFields} ${inputFieldsClassName}`}>
        <div className={`enter-your-text ${divClassNameOverride}`}>{inputFieldText}</div>
        {showIcon && <>{icon}</>}
      </div>
      {showForgetPassword && <div className="text-wrapper">Forgot Password ?</div>}
    </div>
  );
};

function reducer(state, action) {
  if (state.inputFields === "non-active") {
    switch (action) {
      case "click":
        return {
          inputFields: "active",
        };
    }
  }

  if (state.inputFields === "active") {
    switch (action) {
      case "click":
        return {
          inputFields: "non-active",
        };
    }
  }

  return state;
}

InputFields.propTypes = {
  showForgetPassword: PropTypes.bool,
  inputNameText: PropTypes.string,
  showIcon: PropTypes.bool,
  inputFieldText: PropTypes.string,
  inputFields: PropTypes.oneOf(["non-active", "active"]),
};
