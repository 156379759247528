import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { InputFields } from "./components/InputFields";
import { Forget3 } from "./icons/Forget3";

function Popup_4() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                Launch demo modal
            </Button>
          
                <Modal show={show} onHide={handleClose}>
                 
                    <Modal.Body className='login'>
            
      <div className="heading">
        <div className="div">Register Yourself</div>
        <p className="p">Add Info to Create account</p>
      </div>
      <div className="iput-fields">
        <InputFields
          className="INPUT-FIELDS-instance"
          divClassName="design-component-instance-node"
          divClassNameOverride="design-component-instance-node"
          inputFieldText="e.g. Uanice"
          inputFields="non-active"
          inputFieldsClassName="INPUT-FIELDS-2"
          inputNameText="Write Your name"
          showForgetPassword={false}
          showIcon={false}
        />
        <InputFields
          className="INPUT-FIELDS-instance"
          divClassName="design-component-instance-node"
          divClassNameOverride="design-component-instance-node"
          inputFieldText="Demo@gmail.com"
          inputFields="non-active"
          inputFieldsClassName="INPUT-FIELDS-2"
          inputNameText="Email"
          showForgetPassword={false}
          showIcon={false}
        />
        <InputFields
          className="INPUT-FIELDS-instance"
          divClassName="design-component-instance-node"
          divClassNameOverride="design-component-instance-node"
          icon={<Forget3 className="forget-3" />}
          inputFieldText="*******"
          inputFields="non-active"
          inputFieldsClassName="INPUT-FIELDS-2"
          inputNameText="Add Password"
          showForgetPassword={false}
        />
      </div>
      <div className="button">
        <div className="text-wrapper-2">Create Account</div>
      </div>
   </Modal.Body>
              
                </Modal>
            
        </>
    );
}

export default Popup_4;