/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Location1 = ({ className }) => {
  return (
    <svg
      className={`location-1 ${className}`}
      fill="none"
      height="25"
      viewBox="0 0 24 25"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M12.0009 13.9299C13.724 13.9299 15.1209 12.5331 15.1209 10.8099C15.1209 9.08681 13.724 7.68994 12.0009 7.68994C10.2777 7.68994 8.88086 9.08681 8.88086 10.8099C8.88086 12.5331 10.2777 13.9299 12.0009 13.9299Z"
        stroke="#93989A"
        strokeWidth="1.5"
      />
      <path
        className="path"
        d="M3.61971 8.99C5.58971 0.330002 18.4197 0.340003 20.3797 9C21.5297 14.08 18.3697 18.38 15.5997 21.04C13.5897 22.98 10.4097 22.98 8.38971 21.04C5.62971 18.38 2.46971 14.07 3.61971 8.99Z"
        stroke="#93989A"
        strokeWidth="1.5"
      />
    </svg>
  );
};
