import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import Images from "../../constants/Images";
export const WhySection = () => {
  return (
    <section className="HomeWhySection">
      <Container>
        <p className="therapist" style={{fontStyle:'italic'}}>
          WHO WE ARE
        </p>
        <h1>
          Why should I <br />
          <span>choose SYMPATHIQ</span>
        </h1>
        <div className="separator"></div>
        <Row>
          <Col lg={4} md={4} xs={12} className="mb-3">
            <Card>
              
<div className="" style={{width:'fit-content',borderRadius:'50%',margin: '0 auto',boxShadow: '0 2px 13px rgba(0, 0, 0, 0.11)'}}>
              <div className="card-img-cover">
                <Card.Img src={Images.HomeWhy_1} />
              </div>
              </div>
              <Card.Body>
                <Card.Title>Orientation</Card.Title>
                <Card.Text>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor Lorem ipsum dolor
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} md={4} xs={12} className="mb-3">
            <Card >

<div className="" style={{width:'fit-content',borderRadius:'50%',margin: '0 auto',boxShadow: '0 2px 13px rgba(0, 0, 0, 0.11)'}}>
              <div className="card-img-cover" >
                <Card.Img src={Images.HomeWhy_2} className=""/>
              </div>
              </div>
             
              <Card.Body>
                <Card.Title>Complete Toolbox</Card.Title>
                <Card.Text>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor Lorem ipsum dolor
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} md={4} xs={12} className="mb-3">
            <Card>
              
<div className="" style={{width:'fit-content',borderRadius:'50%',margin: '0 auto',boxShadow: '0 2px 13px rgba(0, 0, 0, 0.11)'}}>
              <div className="card-img-cover">
                <Card.Img src={Images.HomeWhy_3} />
              </div>
              </div>
              <Card.Body>
                <Card.Title>To Be Added</Card.Title>
                <Card.Text>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor Lorem ipsum dolor
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} md={4} xs={12} className="mb-3">
            <Card>
              
<div className="" style={{width:'fit-content',borderRadius:'50%',margin: '0 auto',boxShadow: '0 2px 13px rgba(0, 0, 0, 0.11)'}}>
              <div className="card-img-cover">
                <Card.Img src={Images.HomeWhy_4} />
              </div>
              </div>
              <Card.Body>
                <Card.Title>Anonymous & Private</Card.Title>
                <Card.Text>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor Lorem ipsum dolor
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} md={4} xs={12} className="mb-3">
            <Card>
              
<div className="" style={{width:'fit-content',borderRadius:'50%',margin: '0 auto',boxShadow: '0 2px 13px rgba(0, 0, 0, 0.11)'}}>
              <div className="card-img-cover">
                <Card.Img src={Images.HomeWhy_5} />
              </div>
              </div>
              <Card.Body>
                <Card.Title>Secure & Confidential</Card.Title>
                <Card.Text>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor Lorem ipsum dolor
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={4} md={4} xs={12} className="mb-3">
            <Card>
              
<div className="" style={{width:'fit-content',borderRadius:'50%',margin: '0 auto',boxShadow: '0 2px 13px rgba(0, 0, 0, 0.11)'}}>
              <div className="card-img-cover">
                <Card.Img src={Images.HomeWhy_6} />
              </div>
              </div>
              <Card.Body>
                <Card.Title>Accessible</Card.Title>
                <Card.Text>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor Lorem ipsum dolor
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
