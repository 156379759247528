/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const AtomCardLogo = ({
  type,
  typeMastercardClassName,
  typeMastercard = "https://generation-sessions.s3.amazonaws.com/2e5e02ef756cfc0eeb3b97a645915238/img/type-mastercard.svg",
}) => {
  return (
    <>
      {type === "mastercard" && (
        <img
          className={`atom-card-logo type-mastercard ${typeMastercardClassName}`}
          alt="Type mastercard"
          src={typeMastercard}
        />
      )}

      {type === "visa" && (
        <div className={`atom-card-logo type-visa ${typeMastercardClassName}`}>
          <div className="logo-content">
            <img
              className="logo"
              alt="Logo"
              src="https://generation-sessions.s3.amazonaws.com/2e5e02ef756cfc0eeb3b97a645915238/img/logo.svg"
            />
          </div>
        </div>
      )}
    </>
  );
};

AtomCardLogo.propTypes = {
  type: PropTypes.oneOf(["mastercard", "visa"]),
  typeMastercard: PropTypes.string,
};
