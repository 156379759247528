import React from "react";
import { Form } from "react-bootstrap";
export default function Input({
  label,
  inputType,
  isLabel=true,
  placeHolder,
  className = "",
  IsPassword = false,
  isCode = false,
}) {
  return (
    <Form.Group className={`mb-3 ${className}`} controlId="formFullName" >
      {isLabel?<Form.Label >{label}</Form.Label>:null}
      <div className="field-cover">
        {IsPassword ? (
          <>
            <Form.Control type={inputType} placeholder={placeHolder} />
            <div className="cross-icon" id="deselect-icon">
              <i className="fas fa-eye"></i>
            </div>
          </>
        ) : isCode ? (
          <div className="code_input">
            <Form.Control type={inputType} placeholder={placeHolder} style={{color:'#454238', paddingLeft:'16px',paddingRight:'15px'}}/>
          </div>
        ) : (
          <Form.Control type={inputType} placeholder={placeHolder} />
        )}
      </div>
    </Form.Group>
  );
}
