import React from "react";
import AdminLayout from "../../layouts/AdminLayout";
import Conversation from "./conversation";
import CustomerList from "./customerlist";
import "./meeting.scss";
import Images from "../../constants/Images";
import { Col, Container, DropdownToggle, Row } from "react-bootstrap";
import {AiOutlineSearch} from "react-icons/ai";
import {AiFillCheckCircle} from "react-icons/ai";
import {BsPlus} from "react-icons/bs";
import {BsEmojiSmile} from "react-icons/bs";
export const Meeting = () => {
  
  return (
    
    <AdminLayout>
      <div className="bg-white w-100 " style={{minHeight:'100vh'}}>
      {/* <div className="message">
        <div className="div-2"> */}
          

        <div className="">
                  <Container >
          <div className="w-100 d-flex justify-content-between align-item-center" style={{height:'70px'}}> 


            <div className="d-flex align-items-end">
                  <div className="contact-text">Contacts</div>
                      <div className="text-34">34</div>
            </div>


              <div className="friend-specialist-button">
                  <div className="friend-button">
                    <div className="friend-text">Friends</div>
                  </div>
                <div className="specialist-button">
                  <div className="specialist-text">Specialist</div>
                </div>
              </div>

             
          </div>
          </Container>
          
      </div>
              <Container style={{paddingTop:'20px'}}>
              <Container>
              <Row>
              <Col className="col-4" style={{boxShadow: '0px 4px 12px 0px #C8DFE84D', borderRadius:'12px'}}>

              <div className="search-button-div" style={{margin:'20px'}}>
                <AiOutlineSearch style={{color:'#B6C1C6'}} className="search-button-icon"/>
                <text type="input" style={{color: '#B6C1C6'}} className="search-text">Search</text>
              </div>
              
              <div className="main-user-div">

                <div className="first-user-div d-flex">
                  <img src={Images.girl_image_1} alt="girl" style={{width:'56px', height:'56px'}}/>
                  <div className="red-dot-div"></div>
                  <div style={{paddingLeft:'15px'}}>
                  <p className="jane-doe">Jane Doe</p>
                  <p className="hi-i-want">Hi, i want make enquiries about yo...</p>
                  </div>
                  <div style={{paddingLeft:'15px'}}>

                    <div className="d-flex gap-2">
                    <div className="new-div">
                      <p className="new-text">New</p>
                    </div>
                    <div className="text2-div">
                      <p className="text2">2</p>
                    </div>
                    </div>
                    <div className="time-text">
                      12:55 am
                    </div>
                  </div>
                </div>
                <div className="first-user-div d-flex">
                  <img src={Images.girl_image_2} alt="girl" style={{width:'56px', height:'56px'}}/>
                  <div className="grey-dot-div"></div>
                  <div style={{paddingLeft:'15px'}}>
                  <p className="jane-doe">Jane Doe</p>
                  <p className="hi-i-want">Hi, i want make enquiries about yo...</p>
                  </div>
                  <div style={{paddingLeft:'15px'}}>

                    <div className="d-flex gap-2">
                    <div className="new-div1">
                      <p className="new-text1">new</p>
                    </div>
                    <div className="text2-div1">
                      <p className="text2-div1-text">2</p>
                    </div>
                    </div>
                    <div className="time-text">
                      12:55 am
                    </div>
                  </div>
                </div>
                <div className="first-user-div d-flex">
                  <img src={Images.girl_image_3} alt="girl" style={{width:'56px', height:'56px'}}/>
                  <div className="red-dot-div"></div>
                  <div style={{paddingLeft:'15px'}}>
                  <p className="jane-doe">Jane Doe</p>
                  <p className="hi-i-want">Hi, i want make enquiries about yo...</p>
                  </div>
                  <div style={{paddingLeft:'15px'}}>

                    <div className="d-flex gap-2">
                    <div className="new-div">
                      <p className="new-text">New</p>
                    </div>
                    <div className="text2-div">
                      <p className="text2">2</p>
                    </div>
                    </div>
                    <div className="time-text">
                      12:55 am
                    </div>
                  </div>
                </div>
                <div className="first-user-div d-flex">
                  <img src={Images.girl_image_1} alt="girl" style={{width:'56px', height:'56px'}}/>
                  <div className="red-dot-div"></div>
                  <div style={{paddingLeft:'15px'}}>
                  <p className="jane-doe">Jane Doe</p>
                  <p className="hi-i-want">Hi, i want make enquiries about yo...</p>
                  </div>
                  <div style={{paddingLeft:'15px'}}>

                    <div className="d-flex gap-2">
                    <div className="new-div">
                      <p className="new-text">New</p>
                    </div>
                    <div className="text2-div">
                      <p className="text2">2</p>
                    </div>
                    </div>
                    <div className="time-text">
                      12:55 am
                    </div>
                  </div>
                </div>
                <div className="first-user-div d-flex">
                  <img src={Images.girl_image_1} alt="girl" style={{width:'56px', height:'56px'}}/>
                  <div className="red-dot-div"></div>
                  <div style={{paddingLeft:'15px'}}>
                  <p className="jane-doe">Jane Doe</p>
                  <p className="hi-i-want">Hi, i want make enquiries about yo...</p>
                  </div>
                  <div style={{paddingLeft:'15px'}}>

                    <div className="d-flex gap-2">
                    <div className="new-div">
                      <p className="new-text">New</p>
                    </div>
                    <div className="text2-div">
                      <p className="text2">2</p>
                    </div>
                    </div>
                    <div className="time-text">
                      12:55 am
                    </div>
                  </div>
                </div>
                <div className="first-user-div d-flex">
                  <img src={Images.girl_image_1} alt="girl" style={{width:'56px', height:'56px'}}/>
                  <div className="red-dot-div"></div>
                  <div style={{paddingLeft:'15px'}}>
                  <p className="jane-doe">Jane Doe</p>
                  <p className="hi-i-want">Hi, i want make enquiries about yo...</p>
                  </div>
                  <div style={{paddingLeft:'15px'}}>

                    <div className="d-flex gap-2">
                    <div className="new-div">
                      <p className="new-text">New</p>
                    </div>
                    <div className="text2-div">
                      <p className="text2">2</p>
                    </div>
                    </div>
                    <div className="time-text">
                      12:55 am
                    </div>
                  </div>
                </div>
                <div className="first-user-div d-flex">
                  <img src={Images.girl_image_1} alt="girl" style={{width:'56px', height:'56px'}}/>
                  <div className="red-dot-div"></div>
                  <div style={{paddingLeft:'15px'}}>
                  <p className="jane-doe">Jane Doe</p>
                  <p className="hi-i-want">Hi, i want make enquiries about yo...</p>
                  </div>
                  <div style={{paddingLeft:'15px'}}>

                    <div className="d-flex gap-2">
                    <div className="new-div">
                      <p className="new-text">New</p>
                    </div>
                    <div className="text2-div">
                      <p className="text2">2</p>
                    </div>
                    </div>
                    <div className="time-text">
                      12:55 am
                    </div>
                  </div>
                </div>
                <div className="first-user-div d-flex">
                  <img src={Images.girl_image_1} alt="girl" style={{width:'56px', height:'56px'}}/>
                  <div className="red-dot-div"></div>
                  <div style={{paddingLeft:'15px'}}>
                  <p className="jane-doe">Jane Doe</p>
                  <p className="hi-i-want">Hi, i want make enquiries about yo...</p>
                  </div>
                  <div style={{paddingLeft:'15px'}}>

                    <div className="d-flex gap-2">
                    <div className="new-div">
                      <p className="new-text">New</p>
                    </div>
                    <div className="text2-div">
                      <p className="text2">2</p>
                    </div>
                    </div>
                    <div className="time-text">
                      12:55 am
                    </div>
                  </div>
                </div>
                <div className="first-user-div d-flex">
                  <img src={Images.girl_image_1} alt="girl" style={{width:'56px', height:'56px'}}/>
                  <div className="red-dot-div"></div>
                  <div style={{paddingLeft:'15px'}}>
                  <p className="jane-doe">Jane Doe</p>
                  <p className="hi-i-want">Hi, i want make enquiries about yo...</p>
                  </div>
                  <div style={{paddingLeft:'15px'}}>

                    <div className="d-flex gap-2">
                    <div className="new-div">
                      <p className="new-text">New</p>
                    </div>
                    <div className="text2-div">
                      <p className="text2">2</p>
                    </div>
                    </div>
                    <div className="time-text">
                      12:55 am
                    </div>
                  </div>
                </div>
              </div>



              </Col>
              <Col className="col-8">
                <div style={{background:'#F4F9FB', boxShadow: '0px 4px 12px 0px #C8DFE84D'}}>
                <Container className="bg-white" style={{}}>
                  <div className="d-flex justify-content-between" style={{paddingTop:'15px', paddingLeft:'10px', paddingBottom:'15px'}}>
                    <div className="d-flex">
                    <img src={Images.girl_image_1} alt="girl" style={{width:'56px', height:'56px'}}/>
                    <div style={{paddingLeft:'15px'}}>
                      <p className="jane-doe-text-for-right">Jane Doe</p>
                      <div className="d-flex align-items-end">
                        <div className="red-dot"></div>
                        <p className="online-div">Online</p>
                        <p className="time-text-div">12:55 am</p>
                      </div>
                    </div>
                    </div>
                    <div>
                      <p className="view-profile-text">View Profile</p>
                    </div>
                  </div>
                </Container>
                <Container style={{background:'#F4F9FB'}}>
                  <Container>
                    <div className="w-100 d-flex justify-content-center" style={{paddingTop:'20px'}}>
                    <div className="bg-white" style={{width:'20%' , textAlign:'center', borderRadius:'6px', padding:'5px'}}>
                    <p style={{margin:'0'}}>12 August 2023</p>
                    </div>
                    </div>
                    
                    
                    
                    <div style={{paddingTop:'60px'}}>
                      <div className="blue-wali-main-div">
                      <div className="blue-div">
                        <p className="hello-i-want-text">Hello, I want to make enquiries about your product</p>
                      </div>
                      <p className="time-text-div-for-chat">12:55 am</p>
                      </div>

                      {/* <div className="pink-wali-main-div d-flex align-items-end flex-column"> */}
                        <div className="pink-div">
                          <p className="hello-janet">Hello Janet, thank you for reaching out</p>
                        </div>
                        <div className="d-flex align-items-center" style={{marginLeft:'auto'}}>
                        <p className="time-text-div-for-chat">12:55 am</p>
                        <AiFillCheckCircle style={{color:'#13BA37', width:'20px', height:'20px'}}/>
                        </div>
                      {/* </div> */}

                      <div className="pink-wali-main-div d-flex align-items-end flex-column">
                        <div className="pink-div1">
                          <p className="hello-janet">What do you need to know?</p>
                        </div>
                        <div className="d-flex align-items-center">
                        <p className="time-text-div-for-chat">12:55 am</p>
                        <AiFillCheckCircle style={{color:'#13BA37', width:'20px', height:'20px'}}/>
                        </div>
                      </div>

                      <div className="w-100 justify-content-center align-items-center">
                        <div className="bg-white" style={{width:'15%' , textAlign:'center', borderRadius:'6px'}}>
                          <p className="today-text">Today</p>
                        </div>
                      </div>

                      <div className="blue-wali-main-div">
                      <div className="blue-div">
                        <p className="hello-i-want-text">I want to know if the price is negotiable, i need about 2 Units</p>
                      </div>
                      <p className="time-text-div-for-chat">12:55 am</p>
                      </div>

                      <div className=" bg-white d-flex justify-content-between align-items-center" style={{width:'100%', height:'8vh'}}>
                        <div className="d-flex" style={{paddingLeft:'13px'}}>
                          <div className="plus-sign-div">
                          <svg width="0" height="0">
                            <linearGradient id="plus-gradient" x1="100%" y1="100%" x2="0%" y2="0%">
                              <stop stopColor="rgba(58, 111, 253, 0.05)" offset="0%" />
                              <stop stopColor="rgba(214, 58, 202, 0.05)" offset="100%" />
                            </linearGradient>
                          </svg>
                            <BsPlus  style={{ stroke: "url(#plus-gradient)", width:'24px', height:'24px' }}/>

                            </div> 
                            <input type="text" placeholder="Your message" className="your-msg"></input>
                        </div>
                        
                        <div className="d-flex justify-content-center align-items-center gap-2" style={{paddingRight:'13px'}}>
                          <BsEmojiSmile style={{color:'#BBCCD3', width:'24px'}}/>
                          <div className="send-button-div">
                            <button type="button" className="send-button">send</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Container>
                </Container>
                </div>
              </Col>
              </Row>


              </Container>
              </Container>




         {/* <Container style={{width:'100%', display:'flex'}}>
          <Row>
          <div className="group-3">
            <Col className="col-4">
            <div className="overlap-6">
              <div className="rectangle-2" />
              <div className="overlap-group-wrapper">
                <div className="overlap-7">
                  <div className="search-light-2">
                    <div className="overlap-group-3">
                      <div className="ellipse-6" />
                      <img
                        className="vector"
                        alt="Vector"
                        src="https://generation-sessions.s3.amazonaws.com/6351562fef042f5e2b70aaeef4214603/img/vector-109.svg"
                      />
                    </div>
                  </div>
                  <div className="text-wrapper-8">Search</div>
                </div>
              </div>
              <div className="group-4">
                <div className="overlap-8">
                  <p className="text-wrapper-9">
                    Hi, i want make enquiries about yo...
                  </p>
                  <div className="text-wrapper-10">Jane Doe</div>
                </div>
                <div className="text-wrapper-11">12:55 am</div>
                <div className="rectangle-3" />
                <div className="rectangle-4" />
                <div className="overlap-9">
                  <div className="text-wrapper-12">2</div>
                </div>
                <div className="group-5">
                  <div className="overlap-group-4">
                    <div className="text-wrapper-13">New</div>
                  </div>
                </div>
                <div className="group-wrapper">
                  <div className="group-6">
                    <div className="ellipse-7" />
                  </div>
                </div>
              </div>
              <p className="text-wrapper-14">
                Hi, i want make enquiries about yo...
              </p>
              <div className="text-wrapper-15">12:55 am</div>
              <div className="rectangle-5" />
              <div className="rectangle-6" />
              <div className="text-wrapper-16">Jane Doe</div>
              <div className="group-7">
                <div className="overlap-8">
                  <p className="text-wrapper-9">
                    Hi, i want make enquiries about yo...
                  </p>
                  <div className="text-wrapper-10">Jane Doe</div>
                </div>
                <div className="text-wrapper-11">12:55 am</div>
                <div className="rectangle-3" />
                <div className="rectangle-4" />
                <div className="overlap-9">
                  <div className="text-wrapper-12">2</div>
                </div>
                <div className="group-5">
                  <div className="overlap-group-4">
                    <div className="text-wrapper-13">New</div>
                  </div>
                </div>
                <div className="overlap-10">
                  <div className="group-6">
                    <div className="ellipse-7" />
                  </div>
                </div>
              </div>
              <div className="group-8">
                <div className="overlap-8">
                  <p className="text-wrapper-9">
                    Hi, i want make enquiries about yo...
                  </p>
                  <div className="text-wrapper-10">Jane Doe</div>
                </div>
                <div className="text-wrapper-11">12:55 am</div>
                <div className="rectangle-3" />
                <div className="rectangle-4" />
                <div className="overlap-11">
                  <div className="group-6">
                    <div className="ellipse-7" />
                  </div>
                </div>
              </div>
              <div className="group-9">
                <div className="overlap-8">
                  <p className="text-wrapper-9">
                    Hi, i want make enquiries about yo...
                  </p>
                  <div className="text-wrapper-10">Jane Doe</div>
                </div>
                <div className="text-wrapper-11">12:55 am</div>
                <div className="rectangle-3" />
                <div className="rectangle-4" />
                <div className="overlap-9">
                  <div className="text-wrapper-12">2</div>
                </div>
                <div className="group-5">
                  <div className="overlap-group-4">
                    <div className="text-wrapper-13">New</div>
                  </div>
                </div>
                <div className="overlap-12">
                  <div className="group-6">
                    <div className="ellipse-7" />
                  </div>
                </div>
              </div>
              <div className="group-10">
                <div className="overlap-8">
                  <p className="text-wrapper-9">
                    Hi, i want make enquiries about yo...
                  </p>
                  <div className="text-wrapper-10">Jane Doe</div>
                </div>
                <div className="text-wrapper-11">12:55 am</div>
                <div className="rectangle-3" />
                <div className="rectangle-4" />
                <div className="overlap-9">
                  <div className="text-wrapper-12">2</div>
                </div>
                <div className="group-5">
                  <div className="overlap-group-4">
                    <div className="text-wrapper-13">New</div>
                  </div>
                </div>
                <div className="overlap-13">
                  <div className="group-6">
                    <div className="ellipse-7" />
                  </div>
                </div>
              </div>
              <div className="group-11">
                <div className="overlap-8">
                  <p className="text-wrapper-9">
                    Hi, i want make enquiries about yo...
                  </p>
                  <div className="text-wrapper-10">Jane Doe</div>
                </div>
                <div className="text-wrapper-11">12:55 am</div>
                <div className="rectangle-3" />
                <div className="rectangle-4" />
                <div className="overlap-14">
                  <div className="group-6">
                    <div className="ellipse-7" />
                  </div>
                </div>
              </div>
              <div className="group-12">
                <div className="overlap-8">
                  <p className="text-wrapper-9">
                    Hi, i want make enquiries about yo...
                  </p>
                  <div className="text-wrapper-10">Jane Doe</div>
                </div>
                <div className="text-wrapper-11">12:55 am</div>
                <div className="rectangle-3" />
                <div className="rectangle-4" />
                <div className="overlap-15">
                  <div className="group-6">
                    <div className="ellipse-7" />
                  </div>
                </div>
              </div>
              <div className="group-13">
                <div className="overlap-8">
                  <p className="text-wrapper-9">
                    Hi, i want make enquiries about yo...
                  </p>
                  <div className="text-wrapper-10">Jane Doe</div>
                </div>
                <div className="text-wrapper-11">12:55 am</div>
                <div className="rectangle-3" />
                <div className="rectangle-4" />
                <div className="overlap-16">
                  <div className="group-6">
                    <div className="ellipse-7" />
                  </div>
                </div>
              </div>
              <img
                className="mask-group-2"
                alt="Mask group"
                src="https://generation-sessions.s3.amazonaws.com/6351562fef042f5e2b70aaeef4214603/img/mask-group@2x.png"
              />
              <div className="group-14">
                <div className="ellipse-8" />
              </div>
            </div>
            </Col>
            <Col className="col-8">
            <div className="overlap-17">
              <div className="rectangle-7" />
              <div className="rectangle-8" />
              <div className="text-wrapper-17">Send</div>

              <div className="group-15">
                <div className="overlap-18">
                  <div className="rectangle-9" />
                  <img
                    className="rectangle-10"
                    alt="Rectangle"
                    src="https://generation-sessions.s3.amazonaws.com/6351562fef042f5e2b70aaeef4214603/img/rectangle-4374.svg"
                  />
                  <div className="group-16">
                    <div className="overlap-group-5">
                      <div className="text-wrapper-18">12:55 am</div>
                      <div className="text-wrapper-19">Online</div>
                      <div className="text-wrapper-20">Jane Doe</div>
                      <div className="group-17">
                        <div className="ellipse-7" />
                      </div>
                    </div>
                    <div className="rectangle-11" />
                    <div className="rectangle-4" />
                    <div className="text-wrapper-21">2</div>
                    <img
                      className="ellipse-9"
                      alt="Ellipse"
                      src="https://generation-sessions.s3.amazonaws.com/6351562fef042f5e2b70aaeef4214603/img/ellipse-1079.svg"
                    />
                  </div>

                  <div className="text-wrapper-22">12:55 am</div>
                  <div className="text-wrapper-23">12:55 am</div>
                  <img
                    className="rectangle-12"
                    alt="Rectangle"
                    src="https://generation-sessions.s3.amazonaws.com/6351562fef042f5e2b70aaeef4214603/img/rectangle-4367.svg"
                  />
                  <img
                    className="rectangle-13"
                    alt="Rectangle"
                    src="https://generation-sessions.s3.amazonaws.com/6351562fef042f5e2b70aaeef4214603/img/rectangle-4370.svg"
                  />
                  <p className="text-wrapper-24">
                    Hello, I want to make enquiries about your product
                  </p>
                  <div className="text-wrapper-25">12:55 am</div>
                  <img
                    className="rectangle-14"
                    alt="Rectangle"
                    src="https://generation-sessions.s3.amazonaws.com/6351562fef042f5e2b70aaeef4214603/img/rectangle-4367.svg"
                  />
                  <p className="text-wrapper-26">
                    I want to know if the price is negotiable, i need about 2
                    Units
                  </p>
                  <p className="text-wrapper-27">
                    Hello Janet, thank you for reaching out
                  </p>
                  <div className="rectangle-15" />
                  <div className="rectangle-16" />
                  <div className="text-wrapper-28">12 August 2023</div>
                  <div className="rectangle-17" />
                  <div className="text-wrapper-29">Today</div>
                  <div className="ellipse-10" />

                  <div className="text-wrapper-30">12:55 am</div>
                  <img
                    className="rectangle-18"
                    alt="Rectangle"
                    src="https://generation-sessions.s3.amazonaws.com/6351562fef042f5e2b70aaeef4214603/img/rectangle-4371.svg"
                  />
                  <p className="text-wrapper-31">What do you need to know?</p>
                  <div className="ellipse-11" />

                  <div className="group-18">
                    <div className="overlap-19">
                      <div className="text-wrapper-32">Your message</div>
                    </div>
                  </div>
                  <div className="ellipse-12" />

                  <div className="rectangle-19" />
                  <div className="text-wrapper-33">Send</div>
                </div>
              </div>
            </div>
            </Col>
          </div>
          </Row>
          </Container> */}
        {/* </div>
      </div>*/}


    </div>
    </AdminLayout>
  );
};

export default Meeting;
