import React from "react";
import { Container, Form } from "react-bootstrap";
import Input from "../../components/Input";
import ButtonStyled from "../../components/ButtonStyled";
import Images from "../../constants/Images";
import { useNavigate } from "react-router-dom";
import AuthLayout from "../../layouts/AuthLayout";

const SignUpStepOne = () => {
  const navigate = useNavigate();

  return (
    <AuthLayout>
      <section
        className="LoginSection"
        style={{
          backgroundImage: `url(${Images.SignUpBG})`,
        }}
      >
        <Container fluid className="main-div">
          <div className="login_form">
            <div>
              <h1>
                Welcome<span>{" "}Back</span>
              </h1>
              <p className="text-center continue">Sign to Continue</p>
              <Form>
                <Input
                  label="First Name"
                  inputType="text"
                  placeHolder="Fahad"
                />
                <Input label="Last Name" inputType="text" placeHolder="Asif" />
                <Input
                  label="Email"
                  inputType="email"
                  placeHolder="xyz@gmail.com"
                />
                <Input
                  label="Password"
                  inputType="password"
                  placeHolder="*********"
                  IsPassword={true}
                />
                <ButtonStyled
                  title="Login"
                  handler={() => {
                    navigate("/sign-up-step-2");
                  }}
                />
              </Form>
              <p className="text-center register mb-3">
                You have an account?{" "}
                <a
                  href="/login"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/login");
                  }}
                >
                  Login
                </a>
              </p>
            </div>
          </div>
        </Container>
      </section>
    </AuthLayout>
  );
};

export default SignUpStepOne;
