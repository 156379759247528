/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { ProfileCircle4 } from "../../icons/ProfileCircle4";
import "./style.css";

export const InputFields = ({ fieldText = "First Name*", anser = "Kevin", property1, className }) => {
  return (
    <div className={`input-fields ${className}`}>
      <p className="first-name">
        <span className="text-wrapper">First Name</span>
        <span className="span">*</span>
      </p>
      <div className={`search-input property-1-${property1}`}>
        <div className="title">
          <ProfileCircle4 className="profile-circle" />
          <div className="kevin">{anser}</div>
        </div>
      </div>
    </div>
  );
};

InputFields.propTypes = {
  fieldText: PropTypes.string,
  anser: PropTypes.string,
  property1: PropTypes.bool,
};
