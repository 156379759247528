import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import Menu from "../../../components/Menu";
import ButtonStyled from "../../../components/ButtonStyled";
import { Container } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { Row, Col } from "react-bootstrap";
import "./journey.scss";

function Popup_5() {
  const [lgShow, setLgShow] = useState(false);

  return (
    <>
      <Button onClick={() => setLgShow(true)}>Large modal</Button>

      <Modal
        size="xl"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        className="journypopup"
      >
       
        <Modal.Body className="journypopup px-3 py-4">
          <Container>
            <Container className="d-flex headingjournal mb-4">
              <Row className="d-flex headingjournal">
                <Col md={4}>
              <h1 className="journalh1">Journal 1</h1>{" "}</Col><Col md={4}>
              <div className="datejournal">
                <span>Date</span>&nbsp;&nbsp; <span>10=May-2023</span>
              </div></Col>
              <Col md={4}><div className="d-flex downloadbutton py-0">
                <ButtonStyled title="Download" className="btn-detaile fontdown my-0" />
              </div></Col>
              </Row>
            </Container>

            <Container>
              <Container>
               
                <Row className="d-flex ">
                  <Col lg={6}>
                  <Card className="journycardpop border-0 mb-4">
                    <Card.Body className="journycardpop p-4">
                      <h4>TO=DO</h4>
                      <p className="textjourney" style={{fontFamily:'Poppins, sans-serif'}}> Lorem Ipsum arena iliamina losem loremLorem Ipsum arena iliamina losem lorLorem Ipsum arena iliamina losem loremLorem Ipsum arena iliamina losem loremLorem Ipsum arena iliamina losem lorLorem Ipsum arena iliamina losem  </p>
                    </Card.Body>
                  </Card>
                  </Col><Col lg={6}>
                  <Card className="journycard1pop border-0 mb-4">
                    <Card.Body className="journycard1pop p-4">
                      <h4>GOALS</h4>
                      <p className="textjourney" style={{fontFamily:'Poppins, sans-serif'}}> Lorem Ipsum arena iliamina losem loremLorem Ipsum arena iliamina losem lorLorem Ipsum arena iliamina losem loremLorem Ipsum arena iliamina losem loremLorem Ipsum arena iliamina losem lorLorem Ipsum arena iliamina losem </p>
                    </Card.Body>
                  </Card></Col>
                </Row>
                
              </Container>
              <Container>
              <h2 className="journalh1"> MEAL TRACKER</h2>
              <Row className="d-flex">
                <Col md={6}>
                <Card className="journycard2pop border-0 mb-4">
                  <Card.Body className="journycard2pop p-4">
                  <p className="textcard2"> Eggs. Greek yogurt. Coffee, Oatmeal. </p>
                  </Card.Body>
                </Card>
                </Col><Col md={6}>
                <Card className="journycard3pop border-0 mb-4">
                  <Card.Body className="journycard3pop p-4">
                    <p className="textcard2">Breakfast</p>
                  </Card.Body>
                </Card></Col>
              </Row>
              <Row className="d-flex">
              <Col md={6}>
                <Card className="journycard4pop border-0 mb-4">
                  <Card.Body className="journycard4pop p-4">
                  <p className="textcard2">Breakfast</p>
                  </Card.Body>
                </Card>
                </Col>
                <Col md={6}>
                <Card className="journycard5pop border-0 mb-4">
                  <Card.Body className="journycard5pop p-4">
                  <p className="textcard2">Breakfast</p>
                  </Card.Body>
                </Card>
                </Col>
              </Row>
              </Container>
              <Container>
              <Card className="mb-4 journycard7pop border-0">
                <Card.Body className="journycard7pop p-4">
                  <h4>TODAY I AM GRATEFUL FOR</h4>
                  <p className="textcard2">Lorem Ipsum arena iliamina losem loremLorem Ipsum arena iliamina losem lorLorem Ipsum arena iliamina losem loremLorem Ipsum arena iliamina losem loremLorem Ipsum arena iliamina losem lorLorem Ipsum arena iliamina losem </p>
                </Card.Body>
              </Card></Container>
            </Container>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Popup_5;
