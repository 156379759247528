import React from "react";
import AdminLayout from "../../layouts/AdminLayout";
import ButtonStyled from "../../components/ButtonStyled";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { Form } from "react-bootstrap";
import Input from "../../components/Input";
import { Image } from "react-bootstrap";
import Images from "../../constants/Images";
import {CgProfile} from "react-icons/cg";

import Switch from "react-switch";
import "./setting.scss";

function Setting() {


  const [isChecked, setIsChecked] = useState(false);

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };

  const [isSpecialistActive, setSpecialistActive] = useState(false);
  const style = {
    color: 'transparent',
    background: 'linear-gradient(127.52deg, #3A6FFD 24.3%, #D63ACA 80.5%)',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    MozBackgroundClip: 'text',
    MsBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  };
  return (
    <AdminLayout>
      <div className="bg-white whole-page-div w-100 pb-5">
      <Container>
      <div className="setting-nav p-4">
        <div>
          {" "}
          <h3 className="general-setting">General Settings</h3>
          <span className="upload-text">
            {" "}
            Update your photo and personal details here.
          </span>
        </div>
        <div className="d-flex   gap-4 every-changes-div">
          <div className="w-100">
          <p className=" every-changes">Every changes automatically saved</p>
          </div>
          <div className="preview-button">
               <div className=" appointmentheader1">
                  <span className=" Appointment1">Preview</span>
               </div>
               <div className=" appointmentheader2">
                  <span className=" Appointment2">Cancel</span>
               </div>
          </div>
          
        </div>
      </div>
      </Container>
     
      <Container className="d-flex justify-content-center gap-2 ">
        <Row>
        <div className="persinal-card col-lg-8 col-sm-12">
          <div className="personal-information-card">
          <Card className="personal-card">
            <Card.Body className="">
              <div className="login_form">
                <h4 className="personal-information-text">Personal Information</h4>
               
                <form className="w-100 first-last-label">
                  <Row>
                  <Col lg={6} className="pe-2">
                  <label style={{fontFamily:'Outfit', fontWeight:'500', font:'18px'}} className="w-100">
                    First Name
                    <span style={{color:'#E23434'}}>*</span>

                    <div className="text-field-with-profile w-100">
      <div className="profile-icon">
        <CgProfile style={{width:'24px', height:'24px', color:'#93989A', marginLeft:'10px'}}/>
      </div>
      <input type="text" placeholder="Kevin" className="profile-input-field"/>
                     </div>
                    
                  </label>
                  </Col>
                  <Col lg={6} className="">
                  <label style={{fontFamily:'Outfit', fontWeight:'500', font:'18px'}} className="w-100">
                    Last Name
                    <div className="text-field-with-profile w-100">
                      <div className="profile-icon">
                        <CgProfile style={{width:'24px', height:'24px', color:'#93989A', marginLeft:'10px'}}/>
                      </div>
                      <input type="text" placeholder="Write Here" className="profile-input-field1"/>
                     </div>
                    
                  </label>
                 </Col>
                 </Row>
                </form>
                <div  className="email-div">
                  <label style={{fontFamily:'Outfit', fontWeight:'500', font:'18px'}} className="email-address-label">
                    Email Address
                    <div className="text-field-with-profile-email">
                      <div className="profile-icon-email">
                        <img src={Images.envelope} alt="envelope" style={{width:'24px', height:'24px', marginLeft:'10px'}}/>
                        {/* <CgProfile style={{width:'24px', height:'24px', color:'#93989A', marginLeft:'10px'}}/> */}
                      </div>
                      <input type="text" placeholder="Sympathiq@gmail.com" className="profile-input-field1-email"/>
                     </div>
                    
                  </label>

                </div>
                



                <div  className="email-div">
                  <label style={{fontFamily:'Outfit', fontWeight:'500', font:'18px', width:'100%'}}>
                    Address
                    <div className="text-field-with-profile-email">
                      <div className="profile-icon-email">
                        <img src={Images.location} alt="location" style={{width:'24px', height:'24px', marginLeft:'10px'}}/>
                        {/* <CgProfile style={{width:'24px', height:'24px', color:'#93989A', marginLeft:'10px'}}/> */}
                      </div>
                      <input type="text" placeholder="California USA 123 Street " className="profile-input-field1-email"/>
                     </div>
                    
                  </label>

                </div>
<Row><Col sm={6}>
<label style={{fontFamily:'Outfit', fontWeight:'500', font:'18px', width:'100%'}}>
                    City</label>
                    <div className="text-field-with-profile-email ps-3">
                     
                      <input type="text" placeholder="   City " className="profile-input-field1-email"/>
                     </div>
                     </Col>             
                     <Col sm={6}>
<label style={{fontFamily:'Outfit', fontWeight:'500', font:'18px', width:'100%'}}>
                    PostCode</label>
                    <div className="text-field-with-profile-email ps-3">
                     
                      <input type="text" placeholder="   Post Code " className="profile-input-field1-email"/>
                     </div>
                     </Col><Col sm={6}>
<label style={{fontFamily:'Outfit', fontWeight:'500', font:'18px', width:'100%'}}>
                    Country</label>
                    <Form.Group
                  className="mb-3 form-group"
                  controlId="exampleFormControlSelect1"
                >
                  {/* <Form.Label className="form-group"></Form.Label> */}
                  <Form.Select style={{backgroundColor: '#F7F9FA', border:'1px solid #F7F9FA'}}>
                    <option value="Afghanistan">Afghanistan</option>
                    <option value="Albania">Albania</option>
                    <option value="Algeria">Algeria</option>
                    <option value="American Samoa">American Samoa</option>
                    <option value="Andorra">Andorra</option>
                    <option value="Angola">Angola</option>
                    <option value="Anguilla">Anguilla</option>
                    <option value="Antartica">Antarctica</option>
                    <option value="Antigua and Barbuda">
                      Antigua and Barbuda
                    </option>
                    <option value="Argentina">Argentina</option>
                    <option value="Armenia">Armenia</option>
                    <option value="Aruba">Aruba</option>
                    <option value="Australia">Australia</option>
                    <option value="Austria">Austria</option>
                    <option value="Azerbaijan">Azerbaijan</option>
                    <option value="Bahamas">Bahamas</option>
                    <option value="Bahrain">Bahrain</option>
                    <option value="Bangladesh">Bangladesh</option>
                    <option value="Barbados">Barbados</option>
                    <option value="Belarus">Belarus</option>
                    <option value="Belgium">Belgium</option>
                    <option value="Belize">Belize</option>
                    <option value="Benin">Benin</option>
                    <option value="Bermuda">Bermuda</option>
                    <option value="Bhutan">Bhutan</option>
                    <option value="Bolivia">Bolivia</option>
                    <option value="Bosnia and Herzegowina">
                      Bosnia and Herzegowina
                    </option>
                    <option value="Botswana">Botswana</option>
                    <option value="Bouvet Island">Bouvet Island</option>
                    <option value="Brazil">Brazil</option>
                    <option value="British Indian Ocean Territory">
                      British Indian Ocean Territory
                    </option>
                    <option value="Brunei Darussalam">Brunei Darussalam</option>
                    <option value="Bulgaria">Bulgaria</option>
                    <option value="Burkina Faso">Burkina Faso</option>
                    <option value="Burundi">Burundi</option>
                    <option value="Cambodia">Cambodia</option>
                    <option value="Cameroon">Cameroon</option>
                    <option value="Canada">Canada</option>
                    <option value="Cape Verde">Cape Verde</option>
                    <option value="Cayman Islands">Cayman Islands</option>
                    <option value="Central African Republic">
                      Central African Republic
                    </option>
                    <option value="Chad">Chad</option>
                    <option value="Chile">Chile</option>
                    <option value="China">China</option>
                    <option value="Christmas Island">Christmas Island</option>
                    <option value="Cocos Islands">
                      Cocos (Keeling) Islands
                    </option>
                    <option value="Colombia">Colombia</option>
                    <option value="Comoros">Comoros</option>
                    <option value="Congo">Congo</option>
                    <option value="Congo">
                      Congo, the Democratic Republic of the
                    </option>
                    <option value="Cook Islands">Cook Islands</option>
                    <option value="Costa Rica">Costa Rica</option>
                    <option value="Cota D'Ivoire">Cote d'Ivoire</option>
                    <option value="Croatia">Croatia (Hrvatska)</option>
                    <option value="Cuba">Cuba</option>
                    <option value="Cyprus">Cyprus</option>
                    <option value="Czech Republic">Czech Republic</option>
                    <option value="Denmark">Denmark</option>
                    <option value="Djibouti">Djibouti</option>
                    <option value="Dominica">Dominica</option>
                    <option value="Dominican Republic">
                      Dominican Republic
                    </option>
                    <option value="East Timor">East Timor</option>
                    <option value="Ecuador">Ecuador</option>
                    <option value="Egypt">Egypt</option>
                    <option value="El Salvador">El Salvador</option>
                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                    <option value="Eritrea">Eritrea</option>
                    <option value="Estonia">Estonia</option>
                    <option value="Ethiopia">Ethiopia</option>
                    <option value="Falkland Islands">
                      Falkland Islands (Malvinas)
                    </option>
                    <option value="Faroe Islands">Faroe Islands</option>
                    <option value="Fiji">Fiji</option>
                    <option value="Finland">Finland</option>
                    <option value="France">France</option>
                    <option value="France Metropolitan">
                      France, Metropolitan
                    </option>
                    <option value="French Guiana">French Guiana</option>
                    <option value="French Polynesia">French Polynesia</option>
                    <option value="French Southern Territories">
                      French Southern Territories
                    </option>
                    <option value="Gabon">Gabon</option>
                    <option value="Gambia">Gambia</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Germany">Germany</option>
                    <option value="Ghana">Ghana</option>
                    <option value="Gibraltar">Gibraltar</option>
                    <option value="Greece">Greece</option>
                    <option value="Greenland">Greenland</option>
                    <option value="Grenada">Grenada</option>
                    <option value="Guadeloupe">Guadeloupe</option>
                    <option value="Guam">Guam</option>
                    <option value="Guatemala">Guatemala</option>
                    <option value="Guinea">Guinea</option>
                    <option value="Guinea-Bissau">Guinea-Bissau</option>
                    <option value="Guyana">Guyana</option>
                    <option value="Haiti">Haiti</option>
                    <option value="Heard and McDonald Islands">
                      Heard and Mc Donald Islands
                    </option>
                    <option value="Holy See">
                      Holy See (Vatican City State)
                    </option>
                    <option value="Honduras">Honduras</option>
                    <option value="Hong Kong">Hong Kong</option>
                    <option value="Hungary">Hungary</option>
                    <option value="Iceland">Iceland</option>
                    <option value="India">India</option>
                    <option value="Indonesia">Indonesia</option>
                    <option value="Iran">Iran (Islamic Republic of)</option>
                    <option value="Iraq">Iraq</option>
                    <option value="Ireland">Ireland</option>
                    <option value="Israel">Israel</option>
                    <option value="Italy">Italy</option>
                    <option value="Jamaica">Jamaica</option>
                    <option value="Japan">Japan</option>
                    <option value="Jordan">Jordan</option>
                    <option value="Kazakhstan">Kazakhstan</option>
                    <option value="Kenya">Kenya</option>
                    <option value="Kiribati">Kiribati</option>
                    <option value="Democratic People's Republic of Korea">
                      Korea, Democratic People's Republic of
                    </option>
                    <option value="Korea">Korea, Republic of</option>
                    <option value="Kuwait">Kuwait</option>
                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                    <option value="Lao">
                      Lao People's Democratic Republic
                    </option>
                    <option value="Latvia">Latvia</option>
                    <option value="Lebanon" selected>
                      Lebanon
                    </option>
                    <option value="Lesotho">Lesotho</option>
                    <option value="Liberia">Liberia</option>
                    <option value="Libyan Arab Jamahiriya">
                      Libyan Arab Jamahiriya
                    </option>
                    <option value="Liechtenstein">Liechtenstein</option>
                    <option value="Lithuania">Lithuania</option>
                    <option value="Luxembourg">Luxembourg</option>
                    <option value="Macau">Macau</option>
                    <option value="Macedonia">
                      Macedonia, The Former Yugoslav Republic of
                    </option>
                    <option value="Madagascar">Madagascar</option>
                    <option value="Malawi">Malawi</option>
                    <option value="Malaysia">Malaysia</option>
                    <option value="Maldives">Maldives</option>
                    <option value="Mali">Mali</option>
                    <option value="Malta">Malta</option>
                    <option value="Marshall Islands">Marshall Islands</option>
                    <option value="Martinique">Martinique</option>
                    <option value="Mauritania">Mauritania</option>
                    <option value="Mauritius">Mauritius</option>
                    <option value="Mayotte">Mayotte</option>
                    <option value="Mexico">Mexico</option>
                    <option value="Micronesia">
                      Micronesia, Federated States of
                    </option>
                    <option value="Moldova">Moldova, Republic of</option>
                    <option value="Monaco">Monaco</option>
                    <option value="Mongolia">Mongolia</option>
                    <option value="Montserrat">Montserrat</option>
                    <option value="Morocco">Morocco</option>
                    <option value="Mozambique">Mozambique</option>
                    <option value="Myanmar">Myanmar</option>
                    <option value="Namibia">Namibia</option>
                    <option value="Nauru">Nauru</option>
                    <option value="Nepal">Nepal</option>
                    <option value="Netherlands">Netherlands</option>
                    <option value="Netherlands Antilles">
                      Netherlands Antilles
                    </option>
                    <option value="New Caledonia">New Caledonia</option>
                    <option value="New Zealand">New Zealand</option>
                    <option value="Nicaragua">Nicaragua</option>
                    <option value="Niger">Niger</option>
                    <option value="Nigeria">Nigeria</option>
                    <option value="Niue">Niue</option>
                    <option value="Norfolk Island">Norfolk Island</option>
                    <option value="Northern Mariana Islands">
                      Northern Mariana Islands
                    </option>
                    <option value="Norway">Norway</option>
                    <option value="Oman">Oman</option>
                    <option value="Pakistan">Pakistan</option>
                    <option value="Palau">Palau</option>
                    <option value="Panama">Panama</option>
                    <option value="Papua New Guinea">Papua New Guinea</option>
                    <option value="Paraguay">Paraguay</option>
                    <option value="Peru">Peru</option>
                    <option value="Philippines">Philippines</option>
                    <option value="Pitcairn">Pitcairn</option>
                    <option value="Poland">Poland</option>
                    <option value="Portugal">Portugal</option>
                    <option value="Puerto Rico">Puerto Rico</option>
                    <option value="Qatar">Qatar</option>
                    <option value="Reunion">Reunion</option>
                    <option value="Romania">Romania</option>
                    <option value="Russia">Russian Federation</option>
                    <option value="Rwanda">Rwanda</option>
                    <option value="Saint Kitts and Nevis">
                      Saint Kitts and Nevis
                    </option>
                    <option value="Saint LUCIA">Saint LUCIA</option>
                    <option value="Saint Vincent">
                      Saint Vincent and the Grenadines
                    </option>
                    <option value="Samoa">Samoa</option>
                    <option value="San Marino">San Marino</option>
                    <option value="Sao Tome and Principe">
                      Sao Tome and Principe
                    </option>
                    <option value="Saudi Arabia">Saudi Arabia</option>
                    <option value="Senegal">Senegal</option>
                    <option value="Seychelles">Seychelles</option>
                    <option value="Sierra">Sierra Leone</option>
                    <option value="Singapore">Singapore</option>
                    <option value="Slovakia">Slovakia (Slovak Republic)</option>
                    <option value="Slovenia">Slovenia</option>
                    <option value="Solomon Islands">Solomon Islands</option>
                    <option value="Somalia">Somalia</option>
                    <option value="South Africa">South Africa</option>
                    <option value="South Georgia">
                      South Georgia and the South Sandwich Islands
                    </option>
                    <option value="Span">Spain</option>
                    <option value="SriLanka">Sri Lanka</option>
                    <option value="St. Helena">St. Helena</option>
                    <option value="St. Pierre and Miguelon">
                      St. Pierre and Miquelon
                    </option>
                    <option value="Sudan">Sudan</option>
                    <option value="Suriname">Suriname</option>
                    <option value="Svalbard">
                      Svalbard and Jan Mayen Islands
                    </option>
                    <option value="Swaziland">Swaziland</option>
                    <option value="Sweden">Sweden</option>
                    <option value="Switzerland">Switzerland</option>
                    <option value="Syria">Syrian Arab Republic</option>
                    <option value="Taiwan">Taiwan, Province of China</option>
                    <option value="Tajikistan">Tajikistan</option>
                    <option value="Tanzania">
                      Tanzania, United Republic of
                    </option>
                    <option value="Thailand">Thailand</option>
                    <option value="Togo">Togo</option>
                    <option value="Tokelau">Tokelau</option>
                    <option value="Tonga">Tonga</option>
                    <option value="Trinidad and Tobago">
                      Trinidad and Tobago
                    </option>
                    <option value="Tunisia">Tunisia</option>
                    <option value="Turkey">Turkey</option>
                    <option value="Turkmenistan">Turkmenistan</option>
                    <option value="Turks and Caicos">
                      Turks and Caicos Islands
                    </option>
                    <option value="Tuvalu">Tuvalu</option>
                    <option value="Uganda">Uganda</option>
                    <option value="Ukraine">Ukraine</option>
                    <option value="United Arab Emirates">
                      United Arab Emirates
                    </option>
                    <option value="United Kingdom">United Kingdom</option>
                    <option value="United States">United States</option>
                    <option value="United States Minor Outlying Islands">
                      United States Minor Outlying Islands
                    </option>
                    <option value="Uruguay">Uruguay</option>
                    <option value="Uzbekistan">Uzbekistan</option>
                    <option value="Vanuatu">Vanuatu</option>
                    <option value="Venezuela">Venezuela</option>
                    <option value="Vietnam">Viet Nam</option>
                    <option value="Virgin Islands (British)">
                      Virgin Islands (British)
                    </option>
                    <option value="Virgin Islands (U.S)">
                      Virgin Islands (U.S.)
                    </option>
                    <option value="Wallis and Futana Islands">
                      Wallis and Futuna Islands
                    </option>
                    <option value="Western Sahara">Western Sahara</option>
                    <option value="Yemen">Yemen</option>
                    <option value="Serbia">Serbia</option>
                    <option value="Zambia">Zambia</option>
                    <option value="Zimbabwe">Zimbabwe</option>
                  </Form.Select>
                </Form.Group>
                     </Col>
</Row>

                {/* <div className="text-field-with-profile">
      <div className="profile-icon">
        <CgProfile style={{width:'24px', height:'24px', color:'#93989A', marginLeft:'10px'}}/>
      </div>
      <input type="text" placeholder="Kevin" className="profile-input-field"/>
    </div> */}

                {/* <Form>
                  <div className="d-flex">
                    <Input
                      label="First Name"
                      inputType="text"
                      placeHolder={<span><CgProfile /> Fahad</span>}
                    />
                    <Input
                      label="Last Name"
                      inputType="text"
                      placeHolder="Asif"
                    />
                  </div>

                  <Input
                    label="Email"
                    inputType="email"
                    placeHolder="xyz@gmail.com"
                  />
                  <Input
                    label="Address"
                    inputType="text"
                    placeHolder="California USA 123 Street "
                  />
                </Form> */}
              </div>
              <p className="interest-text">Interest</p>
             <div className="interest-main-div">
              <div className="d-flex gap-3 interest-div">
                    <div className="interest-button">
                        <span className="interest">Exercise</span>
                    </div>
                    <div className="interest-button">
                        <span className="interest">Exercise</span>
                    </div>
                    <div className="interest-button-fitness">
                        <span className="interest">FItnes</span>
                    </div>
                    <div className="interest-button">
                        <span className="interest">Olympic</span>
                    </div>
                    <div className="interest-button-medition">
                        <span className="interest">Medition</span>
                    </div>
              </div>
              <div className="   d-flex gap-3 interest-div1">
                     <div className="interest-button-fitness1">
                        <span className="interest">FItnes</span>
                     </div>
                     <div className="interest-button">
                        <span className="interest">Exercise</span>
                    </div>
                    <div className="interest-button">
                        <span className="interest">Exercise</span>
                    </div>
                    <div className="interest-button-addmore">
                        <span className="interest">Add more</span>
                        {/* <span className="interest">+</span> */}
                        <img src={Images.plus} alt="plus" style={{width:'24px',height:'24px'}}/>
                    </div>
              </div>
              </div>
            </Card.Body>
          </Card>
          </div>
         
        
        <div className="two-form-div">
        <p className="account-info">Account Info</p>
        <Card className="mt-3">
            <Card.Body className="">
              <div className="d-flex justify-content-between">
                {" "}
                <div className="personal-div"> 
                  <h5 className="personal-account-text">Personal Account</h5>

                  <span className="lorem-text">
                    Lorem Ipsum Ipsum lorem inchi upsim{" "}
                  </span>
                </div>
               
               
                {/* <Switch onChange={handleChange} checked={checked}  className="switch-class"/>  */}

                <label className={`switch ${isChecked ? 'checked' : ''}`}>
      <input type="checkbox" onClick={handleToggle} />
      <span className="slider"></span>
    </label>
             
                {/* <Form className="radio">
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                    label=""
                    className="custom-switch"
                    style={style}
                  />
                </Form> */}
              </div>
            </Card.Body>
          </Card>
          <Card className="  mt-3">
            <Card.Body className="">
              <div className="d-flex justify-content-between">
                {" "}
                <div>
                  <h5 className="personal-account-text">Personal Account</h5>

                  <span className="lorem-text">
                    Lorem Ipsum Ipsum lorem inchi upsim{" "}
                  </span>
                </div>
                {/* <Form className="radio">
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                    label=""
                  />
                </Form> */}
                 <label className={`switch ${isChecked ? 'checked' : ''}`}>
      <input type="checkbox" onClick={handleToggle} />
      <span className="slider"></span>
    </label>
              </div>
            </Card.Body>
          </Card>
        </div>
         
        </div>
        <div className="edit-card col-lg-4">
          <Card className="p-2 edit-photo-card">
          <div className="your-photo">
                <p className="your-photo-text">Your Photo</p>
                <hr className="your-photo-line"/>
              </div>
            <div className="d-flex your-photo-div">
              
              <Image src={Images.Myappointment_1} width={64} />
              <div className="edit-photo-div">
                <h4 className="edit-your-photo"> Edit your photo</h4>
                <span className="ctext">Delete</span>{" "}
                <span className="update">Update</span>
              </div>
            </div>
            <Card.Body className="upload-img">
              
              <img src={Images.cloud} alt="cloud" className="cloud"/>
              <br></br>
              <p style={{color: '#93989A'}} className="clicktodrop">Click to upload or drag and drop</p>
              <br></br>
              <div className="png-div">
              
              <p className="png" style={{color: '#93989A'}}>PNG, JPG or Gif</p>
              <p className="png" style={{color: '#93989A'}}>Max 20Mb</p>
              </div>
              
            </Card.Body>
          </Card>
          <div className="wallet-main-div">
          <Card className="mt-3 p-2  wallet-card">
            <div style={{padding:'22px'}}>
            <din className="d-flex justify-content-between">
              {" "}
              <h3 className="wallet">Wallet</h3> <p className="history"> see History</p>
            </din>
            <p className="balance"> Your Balance</p>
            <p className="price">$185,424.52</p>
            <img src={Images.visa} className="visa-img"/>
            <div className="d-flex justify-content-between pt-4">
              <div className=" appointmentheader">
                <span className=" Appointment"> Add New Card</span>
              </div>
              <img src={Images.add_card} alt="card" className="add-card"/>
            </div>
            
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M2 11.46V16.46C2 18.75 3.85 20.6 6.14 20.6H17.85C20.14 20.6 22 18.74 22 16.45V11.46C22 10.79 21.46 10.25 20.79 10.25H3.21C2.54 10.25 2 10.79 2 11.46ZM8 17.25H6C5.59 17.25 5.25 16.91 5.25 16.5C5.25 16.09 5.59 15.75 6 15.75H8C8.41 15.75 8.75 16.09 8.75 16.5C8.75 16.91 8.41 17.25 8 17.25ZM14.5 17.25H10.5C10.09 17.25 9.75 16.91 9.75 16.5C9.75 16.09 10.09 15.75 10.5 15.75H14.5C14.91 15.75 15.25 16.09 15.25 16.5C15.25 16.91 14.91 17.25 14.5 17.25Z"
                  fill="url(#paint0_linear_2923_6868)"
                />
                <path
                  d="M13.5 4.60844V7.53844C13.5 8.20844 12.96 8.74844 12.29 8.74844H3.21C2.53 8.74844 2 8.18844 2 7.51844C2.01 6.38844 2.46 5.35844 3.21 4.60844C3.96 3.85844 5 3.39844 6.14 3.39844H12.29C12.96 3.39844 13.5 3.93844 13.5 4.60844Z"
                  fill="url(#paint1_linear_2923_6868)"
                />
                <path
                  d="M19.97 2H17.03C15.76 2 15 2.76 15 4.03V6.97C15 8.24 15.76 9 17.03 9H19.97C21.24 9 22 8.24 22 6.97V4.03C22 2.76 21.24 2 19.97 2ZM20.91 5.93C20.81 6.03 20.66 6.1 20.5 6.11H19.09L19.1 7.5C19.09 7.67 19.03 7.81 18.91 7.93C18.81 8.03 18.66 8.1 18.5 8.1C18.17 8.1 17.9 7.83 17.9 7.5V6.1L16.5 6.11C16.17 6.11 15.9 5.83 15.9 5.5C15.9 5.17 16.17 4.9 16.5 4.9L17.9 4.91V3.51C17.9 3.18 18.17 2.9 18.5 2.9C18.83 2.9 19.1 3.18 19.1 3.51L19.09 4.9H20.5C20.83 4.9 21.1 5.17 21.1 5.5C21.09 5.67 21.02 5.81 20.91 5.93Z"
                  fill="url(#paint2_linear_2923_6868)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_2923_6868"
                    x1="6.26667"
                    y1="13.1653"
                    x2="12.4722"
                    y2="22.3746"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#3A6FFD" />
                    <stop offset="1" stop-color="#D63ACA" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_2923_6868"
                    x1="4.45333"
                    y1="4.90535"
                    x2="7.52072"
                    y2="9.96912"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#3A6FFD" />
                    <stop offset="1" stop-color="#D63ACA" />
                  </linearGradient>
                  <linearGradient
                    id="paint2_linear_2923_6868"
                    x1="13.6875"
                    y1="10.3125"
                    x2="30.75"
                    y2="-2.8125"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0.253839" stop-color="#6271EB" />
                    <stop
                      offset="0.633116"
                      stop-color="#6271EB"
                      stop-opacity="0"
                    />
                  </linearGradient>
                </defs>
              </svg> */}
            </div>
            <Card.Body></Card.Body>
          </Card>
          </div>
         
        </div>
        </Row>
      </Container>
      </div>
    </AdminLayout>
  );
}

export default Setting;
