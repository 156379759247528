import React from "react";
import { Download } from "./MarketPlace_Two/icons/Download";
import { ExpandDownLight } from "./MarketPlace_Two/icons/ExpandDownLight";
import { Heart } from "./MarketPlace_Two/icons/Heart";
import { SearchLight } from "./MarketPlace_Two/icons/SearchLight";


export const Products = () => {
  return (
    <div className="products">
      <div className="div">
        <header className="header">
          <div className="overlap-group">
            <div className="overlap">
              <div className="rectangle" />
              <div className="rectangle-2" />
              <div className="rectangle-3" />
              <div className="rectangle-4" />
              <div className="rectangle-5" />
              <div className="options">
                <div className="dashboard">
                  <img
                    className="group"
                    alt="Group"
                    src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/group-33839@2x.png"
                  />
                  <div className="text-wrapper">Dashboard</div>
                </div>
                <div className="option">
                  <Heart className="icon-instance-node" />
                  <div className="text-wrapper-2">Your Products</div>
                </div>
                <div className="option-2">
                  <img
                    className="icon-instance-node"
                    alt="Uil meeting board"
                    src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/uil-meeting-board.svg"
                  />
                  <div className="text-wrapper-3">Setting</div>
                </div>
              </div>
            </div>
            <div className="overlap-2">
              <div className="text-wrapper-4">Robert Davis</div>
              <ExpandDownLight className="expand-down-light" />
              <div className="group-wrapper">
                <div className="ellipse-wrapper">
                  <div className="ellipse" />
                </div>
              </div>
            </div>
            <img
              className="frame"
              alt="Frame"
              src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/frame-9.svg"
            />
          </div>
        </header>
        <div className="overlap-3">
          <div className="navbar">
            <div className="text-wrapper-5">Item Name</div>
            <div className="text-wrapper-6">Category</div>
            <div className="text-wrapper-7">Stock</div>
            <div className="text-wrapper-8">Price</div>
            <div className="text-wrapper-9">Action</div>
            <img
              className="img"
              alt="Rectangle"
              src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/rectangle-4335.svg"
            />
            <div className="tick">
              <div className="rectangle-6" />
            </div>
          </div>
          <div className="frame-2">
            <SearchLight className="icon-instance-node" color="#B6C1C6" />
            <div className="text-wrapper-10">Search in products</div>
          </div>
          <div className="rectangle-7" />
          <div className="overlap-wrapper">
            <div className="overlap-4">
              <div className="text-wrapper-11">Add Product</div>
              <div className="overlap-group-wrapper">
                <div className="overlap-group-2">
                  <div className="rectangle-8" />
                  <div className="rectangle-9" />
                </div>
              </div>
            </div>
          </div>
          <div className="div-wrapper">
            <div className="overlap-5">
              <div className="text-wrapper-12">Import CSV</div>
              <Download className="download-instance" />
            </div>
          </div>
          <div className="orders">
            <div className="group-2">
              <div className="text-wrapper-13">Vitamins and supplements</div>
              <div className="text-wrapper-14">220</div>
              <div className="text-wrapper-15">$799.00</div>
              <div className="rectangle-10" />
              <img
                className="mask-group"
                alt="Mask group"
                src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/mask-group-8@2x.png"
              />
              <div className="rectangle-wrapper">
                <div className="rectangle-6" />
              </div>
              <p className="we-are-the-best">
                <span className="span">We Are The Best </span>
                <span className="text-wrapper-16">Support for You</span>
              </p>
              <div className="buttons">
                <div className="overlap-6">
                  <div className="group-3">
                    <div className="overlap-group-3">
                      <img
                        className="vector"
                        alt="Vector"
                        src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/vector-8-8.svg"
                      />
                      <img
                        className="vector-2"
                        alt="Vector"
                        src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/vector-8-8.svg"
                      />
                      <img
                        className="rectangle-11"
                        alt="Rectangle"
                        src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/rectangle-41-8.svg"
                      />
                    </div>
                    <img
                      className="ellipse-2"
                      alt="Ellipse"
                      src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/ellipse-45-8.svg"
                    />
                  </div>
                </div>
                <div className="div-2">
                  <img
                    className="frame-3"
                    alt="Frame"
                    src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/frame-8.svg"
                  />
                </div>
              </div>
            </div>
            <div className="group-2">
              <div className="text-wrapper-13">Vitamins and supplements</div>
              <div className="text-wrapper-14">220</div>
              <div className="text-wrapper-15">$799.00</div>
              <div className="rectangle-10" />
              <img
                className="mask-group"
                alt="Mask group"
                src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/mask-group-8@2x.png"
              />
              <div className="rectangle-wrapper">
                <div className="rectangle-6" />
              </div>
              <p className="we-are-the-best">
                <span className="span">We Are The Best </span>
                <span className="text-wrapper-16">Support for You</span>
              </p>
              <div className="buttons">
                <div className="overlap-6">
                  <div className="group-3">
                    <div className="overlap-group-3">
                      <img
                        className="vector"
                        alt="Vector"
                        src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/vector-8-8.svg"
                      />
                      <img
                        className="vector-2"
                        alt="Vector"
                        src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/vector-8-8.svg"
                      />
                      <img
                        className="rectangle-11"
                        alt="Rectangle"
                        src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/rectangle-41-8.svg"
                      />
                    </div>
                    <img
                      className="ellipse-2"
                      alt="Ellipse"
                      src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/ellipse-45-8.svg"
                    />
                  </div>
                </div>
                <div className="div-2">
                  <img
                    className="frame-3"
                    alt="Frame"
                    src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/frame-7.svg"
                  />
                </div>
              </div>
            </div>
            <div className="group-2">
              <div className="text-wrapper-13">Vitamins and supplements</div>
              <div className="text-wrapper-14">220</div>
              <div className="text-wrapper-15">$799.00</div>
              <div className="rectangle-10" />
              <img
                className="mask-group"
                alt="Mask group"
                src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/mask-group-8@2x.png"
              />
              <div className="rectangle-wrapper">
                <div className="rectangle-6" />
              </div>
              <p className="we-are-the-best">
                <span className="span">We Are The Best </span>
                <span className="text-wrapper-16">Support for You</span>
              </p>
              <div className="buttons">
                <div className="overlap-6">
                  <div className="group-3">
                    <div className="overlap-group-3">
                      <img
                        className="vector"
                        alt="Vector"
                        src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/vector-8-8.svg"
                      />
                      <img
                        className="vector-2"
                        alt="Vector"
                        src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/vector-8-8.svg"
                      />
                      <img
                        className="rectangle-11"
                        alt="Rectangle"
                        src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/rectangle-41-8.svg"
                      />
                    </div>
                    <img
                      className="ellipse-2"
                      alt="Ellipse"
                      src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/ellipse-45-8.svg"
                    />
                  </div>
                </div>
                <div className="div-2">
                  <img
                    className="frame-3"
                    alt="Frame"
                    src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/frame-6.svg"
                  />
                </div>
              </div>
            </div>
            <div className="group-2">
              <div className="text-wrapper-13">Vitamins and supplements</div>
              <div className="text-wrapper-14">220</div>
              <div className="text-wrapper-15">$799.00</div>
              <div className="rectangle-10" />
              <img
                className="mask-group"
                alt="Mask group"
                src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/mask-group-8@2x.png"
              />
              <div className="rectangle-wrapper">
                <div className="rectangle-6" />
              </div>
              <p className="we-are-the-best">
                <span className="span">We Are The Best </span>
                <span className="text-wrapper-16">Support for You</span>
              </p>
              <div className="buttons">
                <div className="overlap-6">
                  <div className="group-3">
                    <div className="overlap-group-3">
                      <img
                        className="vector"
                        alt="Vector"
                        src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/vector-8-8.svg"
                      />
                      <img
                        className="vector-2"
                        alt="Vector"
                        src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/vector-8-8.svg"
                      />
                      <img
                        className="rectangle-11"
                        alt="Rectangle"
                        src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/rectangle-41-8.svg"
                      />
                    </div>
                    <img
                      className="ellipse-2"
                      alt="Ellipse"
                      src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/ellipse-45-8.svg"
                    />
                  </div>
                </div>
                <div className="div-2">
                  <img
                    className="frame-3"
                    alt="Frame"
                    src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/frame-5.svg"
                  />
                </div>
              </div>
            </div>
            <div className="group-2">
              <div className="text-wrapper-13">Vitamins and supplements</div>
              <div className="text-wrapper-14">220</div>
              <div className="text-wrapper-15">$799.00</div>
              <div className="rectangle-10" />
              <img
                className="mask-group"
                alt="Mask group"
                src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/mask-group-8@2x.png"
              />
              <div className="rectangle-wrapper">
                <div className="rectangle-6" />
              </div>
              <p className="we-are-the-best">
                <span className="span">We Are The Best </span>
                <span className="text-wrapper-16">Support for You</span>
              </p>
              <div className="buttons">
                <div className="overlap-6">
                  <div className="group-3">
                    <div className="overlap-group-3">
                      <img
                        className="vector"
                        alt="Vector"
                        src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/vector-8-8.svg"
                      />
                      <img
                        className="vector-2"
                        alt="Vector"
                        src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/vector-8-8.svg"
                      />
                      <img
                        className="rectangle-11"
                        alt="Rectangle"
                        src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/rectangle-41-8.svg"
                      />
                    </div>
                    <img
                      className="ellipse-2"
                      alt="Ellipse"
                      src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/ellipse-45-8.svg"
                    />
                  </div>
                </div>
                <div className="div-2">
                  <img
                    className="frame-3"
                    alt="Frame"
                    src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/frame-4.svg"
                  />
                </div>
              </div>
            </div>
            <div className="group-2">
              <div className="text-wrapper-13">Vitamins and supplements</div>
              <div className="text-wrapper-14">220</div>
              <div className="text-wrapper-15">$799.00</div>
              <div className="rectangle-10" />
              <img
                className="mask-group"
                alt="Mask group"
                src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/mask-group-8@2x.png"
              />
              <div className="rectangle-wrapper">
                <div className="rectangle-6" />
              </div>
              <p className="we-are-the-best">
                <span className="span">We Are The Best </span>
                <span className="text-wrapper-16">Support for You</span>
              </p>
              <div className="buttons">
                <div className="overlap-6">
                  <div className="group-3">
                    <div className="overlap-group-3">
                      <img
                        className="vector"
                        alt="Vector"
                        src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/vector-8-8.svg"
                      />
                      <img
                        className="vector-2"
                        alt="Vector"
                        src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/vector-8-8.svg"
                      />
                      <img
                        className="rectangle-11"
                        alt="Rectangle"
                        src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/rectangle-41-8.svg"
                      />
                    </div>
                    <img
                      className="ellipse-2"
                      alt="Ellipse"
                      src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/ellipse-45-8.svg"
                    />
                  </div>
                </div>
                <div className="div-2">
                  <img
                    className="frame-3"
                    alt="Frame"
                    src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/frame-3.svg"
                  />
                </div>
              </div>
            </div>
            <div className="group-2">
              <div className="text-wrapper-13">Vitamins and supplements</div>
              <div className="text-wrapper-14">220</div>
              <div className="text-wrapper-15">$799.00</div>
              <div className="rectangle-10" />
              <img
                className="mask-group"
                alt="Mask group"
                src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/mask-group-8@2x.png"
              />
              <div className="rectangle-wrapper">
                <div className="rectangle-6" />
              </div>
              <p className="we-are-the-best">
                <span className="span">We Are The Best </span>
                <span className="text-wrapper-16">Support for You</span>
              </p>
              <div className="buttons">
                <div className="overlap-6">
                  <div className="group-3">
                    <div className="overlap-group-3">
                      <img
                        className="vector"
                        alt="Vector"
                        src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/vector-8-8.svg"
                      />
                      <img
                        className="vector-2"
                        alt="Vector"
                        src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/vector-8-8.svg"
                      />
                      <img
                        className="rectangle-11"
                        alt="Rectangle"
                        src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/rectangle-41-8.svg"
                      />
                    </div>
                    <img
                      className="ellipse-2"
                      alt="Ellipse"
                      src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/ellipse-45-8.svg"
                    />
                  </div>
                </div>
                <div className="div-2">
                  <img
                    className="frame-3"
                    alt="Frame"
                    src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/frame-2.svg"
                  />
                </div>
              </div>
            </div>
            <div className="group-2">
              <div className="text-wrapper-13">Vitamins and supplements</div>
              <div className="text-wrapper-14">220</div>
              <div className="text-wrapper-15">$799.00</div>
              <div className="rectangle-10" />
              <img
                className="mask-group-2"
                alt="Mask group"
                src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/mask-group-1@2x.png"
              />
              <div className="rectangle-wrapper">
                <div className="rectangle-6" />
              </div>
              <p className="we-are-the-best">
                <span className="span">We Are The Best </span>
                <span className="text-wrapper-16">Support for You</span>
              </p>
              <div className="buttons">
                <div className="overlap-6">
                  <div className="group-3">
                    <div className="overlap-group-3">
                      <img
                        className="vector"
                        alt="Vector"
                        src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/vector-8-8.svg"
                      />
                      <img
                        className="vector-2"
                        alt="Vector"
                        src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/vector-8-8.svg"
                      />
                      <img
                        className="rectangle-11"
                        alt="Rectangle"
                        src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/rectangle-41-8.svg"
                      />
                    </div>
                    <img
                      className="ellipse-2"
                      alt="Ellipse"
                      src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/ellipse-45-8.svg"
                    />
                  </div>
                </div>
                <div className="div-2">
                  <img
                    className="frame-3"
                    alt="Frame"
                    src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/frame-1.svg"
                  />
                </div>
              </div>
            </div>
            <div className="group-2">
              <div className="text-wrapper-13">Vitamins and supplements</div>
              <div className="text-wrapper-14">220</div>
              <div className="text-wrapper-15">$799.00</div>
              <div className="rectangle-10" />
              <img
                className="mask-group-3"
                alt="Mask group"
                src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/mask-group@2x.png"
              />
              <div className="rectangle-wrapper">
                <div className="rectangle-6" />
              </div>
              <p className="we-are-the-best">
                <span className="span">We Are The Best </span>
                <span className="text-wrapper-16">Support for You</span>
              </p>
              <div className="buttons">
                <div className="overlap-6">
                  <div className="group-3">
                    <div className="overlap-group-4">
                      <img
                        className="vector-3"
                        alt="Vector"
                        src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/mask-group@2x.png"
                      />
                      <img
                        className="vector-4"
                        alt="Vector"
                        src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/mask-group@2x.png"
                      />
                      <img
                        className="rectangle-12"
                        alt="Rectangle"
                        src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/mask-group@2x.png"
                      />
                    </div>
                    <img
                      className="ellipse-3"
                      alt="Ellipse"
                      src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/mask-group@2x.png"
                    />
                  </div>
                </div>
                <div className="div-2" />
                <img
                  className="frame-4"
                  alt="Frame"
                  src="https://generation-sessions.s3.amazonaws.com/5e00d5998ea325441f6fd5da6f2d4718/img/mask-group@2x.png"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="text-wrapper-17">Products</div>
      </div>
    </div>
  );
};

export default Products;
