import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/auth/Home";
import Login from "./pages/user_flow/Login";
import Mood from "./pages/auth/Mood";
import SignUpStepOne from "./pages/user_flow/SignUpStepOne";
import SignUpStepTwo from "./pages/user_flow/SignUpStepTwo";
import SignUpStepThree from "./pages/user_flow/SignUpStepThree";
import SignUpStepFour from "./pages/specialist/SignUpStepFour";
import SignUpStepFive from "./pages/user_flow/SignUpStepFive";
import AdminLayout from "./layouts/AdminLayout";
import Market from "./pages/user_flow/market/Market";
import Dashboard from "./pages/user_flow/Dashboard";
import Forum from "./pages/user_flow/forum/Forum";
import MyAppointment from "./pages/user_flow/MyAppointment";
import MySpeciallist from "./pages/user_flow/MySpeciallist";
import MyJourny from "./pages/user_flow/MyJourny";
import Meeting from "./pages/user_flow/Meeting";
import Setting from "./pages/user_flow/Setting";
import Saved from "./pages/user_flow/Saved";
import Addition from "./pages/user_flow/addiction/Addiction";
import Linechart from "./components/LineGraph";
import DoctorProfile from "./pages/specialist/DoctorProfile";
import Myclient from "./pages/specialist/myClients/Myclient";
import CalenderAppoint from "./components/Chalender";
import Clientprofile from "./pages/specialist/clientProfile/Clientprofile";
import ClientAppointment from "./pages/user_flow/clientappointment/ClientAppointment";
import { BlogDetails } from "./pages/auth/BlogDetaile";
import BlogPage from "./pages/auth/Blog";
import Setting_2 from "./pages/specialist/SettingForSpecilist";
import Dashboard_2 from "./pages/specialist/dashboard2/DashboardForSpecial";
import Hero from "./View/MarketPlace/HeroScreen";
import MarketPlace from "./View/MarketPlace/MarketPlace_Two/MarketPlace_Two";
import MarketPlace_Three from "./View/MarketPlace/MarketPlace_Three";
import Order from "./View/MarketPlace/Order";
import Products from "./View/MarketPlace/Product";
import { Setting_3 } from "./View/MarketPlace/MarketSetting";
import AdddProduct from "./View/MarketPlace/AddProduct";
import AudioCall from "./View/Client/call/AudioCall";
import VideoCall from "./pages/specialist/videocall/VideoCall"
import Popup2 from "./View/MarketPlace/Popup/Popup2";
import Popup_3 from "./View/MarketPlace/Popup/Popup_3";
import Popup_4 from "./View/MarketPlace/Popup/Popup_4";
import Popup_5 from "./pages/specialist/journalpopup/journalpopup";
import Popup_6 from "./pages/specialist/joinscreenpopup/Joinscrnpopup";
import Forummain from "./pages/user_flow/forummain/Forummain";
import DoctorprofuserFlow from "./pages/user_flow/DoctorprofuserFlow";
import SavedSpecialist from "./pages/user_flow/SavedSpecialist";
import { Errorpage } from "./pages/error/Errorpage";
import { Loader } from "./pages/loader/Loader";
import { Bookappointment } from "./pages/user_flow/bookappointment/Bookappointment";
import { ChangeMethod } from "./pages/user_flow/change_payment_method/ChangeMethod";

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 0);
  }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Router>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/mood" element={<Mood />} />
            <Route path="/market" element={<Market />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/forum" element={<Forummain />} />
            <Route path="/my_profile" element={<MyAppointment />} />
            <Route path="/specialists" element={<MySpeciallist />} />
            <Route path="/journal" element={<MyJourny />} />
            <Route path="/settings" element={<Setting />} />
            <Route path="/messages" element={<Meeting />} />
            <Route path="/favorites" element={<Saved />} />
            <Route path="/main_forum" element={<Forum />} />
            <Route path="/specialist_profile_user" element={<DoctorprofuserFlow />} />
            <Route path="/specialist_profile_user" element={<DoctorprofuserFlow />} />
            <Route path="/favourite_specialists" element={<SavedSpecialist />} />
            <Route path="/book_appointment" element={<Bookappointment />} />
            <Route path="/change_payment_method" element={<ChangeMethod />} />


            <Route path="/sign-up" element={<SignUpStepOne />} />
            <Route path="/sign-up-step-2" element={<SignUpStepTwo />} />
            <Route path="/sign-up-step-3" element={<SignUpStepThree />} />
            <Route path="/sign-up-step-4" element={<SignUpStepFour />} />
            <Route path="/sign-up-step-5" element={<SignUpStepFive />} />
            <Route path="/admin" element={<AdminLayout />} />
            <Route path="/addiction" element={<Addition />} />
            <Route path="/linegraph" element={<Linechart />} />
            <Route path="/specialist_profile" element={<DoctorProfile />} />
            <Route path="/my_clients" element={<Myclient />} />
            <Route path="/chalender" element={<CalenderAppoint />} />
            <Route path="/client_profile" element={<Clientprofile />} />
            <Route path="/client_appointments" element={<ClientAppointment />} />
            <Route path="/blog_details" element={<BlogDetails />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/settings_specialist" element={<Setting_2 />} />
            <Route path="/dashboard_specialist" element={<Dashboard_2 />} />
            <Route path="/heroscreen" element={<Hero />} />
            <Route path="/market_2" element={<MarketPlace />} />
            <Route path="/market_3" element={<MarketPlace_Three />} />
            <Route path="/order" element={<Order />} />
            <Route path="/product" element={<Products />} />

            <Route path="/setting_3" element={<Setting_3 />} />
            <Route path="/add_product" element={<AdddProduct />} />
            <Route path="/audio_call" element={<AudioCall />} />
            <Route path="/video_call" element={<VideoCall />} />
            <Route path="/popup_2" element={<Popup2 />} />
            <Route path="/popup_3" element={<Popup_3 />} />
            <Route path="/popup_4" element={<Popup_4 />} />
            <Route path="/popup_5" element={<Popup_5 />} />
            <Route path="/popup_6" element={<Popup_6 />} />

            <Route path="*" element={<Errorpage />} />
          </Routes>
        </Router>
      )}
    </>
  );
};

export default App;
