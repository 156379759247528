import React, { useState, useRef } from 'react';
import { Button, Container, Card, Image ,Row, Col} from 'react-bootstrap';
import Images from '../../constants/Images';
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import ButtonStyled from '../ButtonStyled';

const MarketPlace = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1000 },
      items: 4,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1000, min: 800 },
      items: 3,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 800, min: 0 },
      items: 2, // Adjusted to 1 item for better visibility on smaller screens
      slidesToSlide: 1,
    },
  };

  const Slider = () => {
    const [index, setIndex] = useState(0);
    const ref = useRef(null);

    const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);
    };

    const onPrevClick = () => {
      ref.current.previous();
    };

    const onNextClick = () => {
      ref.current.next();
    };

    return (
      <section className="MarketPlace">
        <Container>
          <div className="d-flex flex-column " >
            <p className="text-start who">WHO WE ARE</p>
            <div className='d-flex'>
            <h1 className="text-start"><b>Market Place</b></h1>
            <div className="" style={{marginLeft:"auto"}}>
          
          {/* <Row  className='btn-position justify-content-end flex-driection-row no-gutters'> */}
           <Row>
             <Col > 
             <div className='buttonbackgroundhomepage'>
             <button class="btn-detaile">
              See all
              <i class="fas fa-arrow-right button-icon1"></i>
              
            </button>

            </div>
            </Col> 
           
            {/* <Col className=' prev col-lg-1'> */}
            {/* <Button className='btn-navigate'>
        <i className="fas fa-chevron-right"></i>
        </Button> */}
        <Col className='d-flex'>
          <ButtonStyled 
        handler={onPrevClick}
        title=''
     isLeft={true}
     className='btn-navigate'
        />
        {/* </Col>  <Col className=' next col-lg-1'> */}
        <ButtonStyled 
        handler={onNextClick}
        title=''
       isRight={true}
        className='btn-navigate'
        />
        </Col>
        </Row>
        {/* <Button className='btn-navigate'>
        <i className="fas fa-chevron-right"></i>
        </Button>
         */}
        {/* </Col> 
          </Row> */}
        
       
      </div>
            </div>
      
            <div className="separator"></div>
           
          </div>
        
          <div className="marketplace-carousel">
          <Carousel
            swipeable={true}
            draggable={false}
            showDots={false}
            indicators={false} // Turned off the default dots
            showArrows={false} // Turned off the default controls
            centerMode={false}
            responsive={responsive}
            ssr={true}
            infinite={true}
            autoPlay={false}
            autoPlaySpeed={4000}
            keyBoardControl={true}
            customTransition="transform 600ms ease-in-out"
            transitionDuration={1000}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
            className="pt-5 pb-5"
            onSelect={handleSelect}
            ref={ref}
          >
            <div className="hover">
              <Card>
                <Card.Img src={Images.Product_1} className="img-fluid width" alt="" />
                <Card.Body>
                  <div className='stars-div'>
                    <ul className="d-flex">
                      <li>
                        <i className="fas fa-star"></i>
                      </li>
                      <li>
                        <i className="fas fa-star"></i>
                      </li>
                      <li>
                        <i className="fas fa-star"></i>
                      </li>
                      <li>
                        <i className="fas fa-star"></i>
                      </li>
                      <li>
                        <i className="fas fa-star fal"></i>
                      </li>
                    </ul>
                  </div>
                  <h4 className="card-title">
                    XS™ Sports Protein Shakes – <br />
                    Rich Chocolate
                  </h4>
                  <div className='price-bucket-div'>
                    <p className="card-text">
                      <del>$53.99</del>
                      <span>$35.99</span>
                    </p>
                    <div>
                      <Image src={Images.Basket} className="card-image ms-3" alt="" />
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
            <div className="hover">
              <Card>
                <Card.Img src={Images.Product_2} className="img-fluid width" alt="" />
                <Card.Body>
                  <div className='stars-div'>
                    <ul className="d-flex">
                      <li>
                        <i className="fas fa-star"></i>
                      </li>
                      <li>
                        <i className="fas fa-star"></i>
                      </li>
                      <li>
                        <i className="fas fa-star"></i>
                      </li>
                      <li>
                        <i className="fas fa-star"></i>
                      </li>
                      <li>
                        <i className="fas fa-star fal"></i>
                      </li>
                    </ul>
                  </div>
                  <h4 className="card-title">
                    XS™ Sports Protein Shakes – <br />
                    Rich Chocolate
                  </h4>
                  <div className='price-bucket-div'>
                    <p className="card-text">
                      <del>$53.99</del>
                      <span>$35.99</span>
                    </p>
                    <div>
                      <Image src={Images.Basket} className="card-image ms-3" alt="" />
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
            <div className="hover">
              <Card>
                <Card.Img src={Images.Product_3} className="img-fluid width" alt="" />
                <Card.Body>
                  <div className='stars-div'>
                    <ul className="d-flex">
                      <li>
                        <i className="fas fa-star"></i>
                      </li>
                      <li>
                        <i className="fas fa-star"></i>
                      </li>
                      <li>
                        <i className="fas fa-star"></i>
                      </li>
                      <li>
                        <i className="fas fa-star"></i>
                      </li>
                      <li>
                        <i className="fas fa-star fal"></i>
                      </li>
                    </ul>
                  </div>
                  <h4 className="card-title">
                    XS™ Sports Protein Shakes – <br />
                    Rich Chocolate
                  </h4>
                  <div className='price-bucket-div'>
                    <p className="card-text">
                      <del>$53.99</del>
                      <span>$35.99</span>
                    </p>
                    <div>
                      <Image src={Images.Basket} className="card-image ms-3" alt="" />
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
            <div className="hover">
              <Card>
                <Card.Img src={Images.Product_4} className="img-fluid width" alt="" />
                <Card.Body>
                  <div className='stars-div'>
                    <ul className="d-flex">
                      <li>
                        <i className="fas fa-star"></i>
                      </li>
                      <li>
                        <i className="fas fa-star"></i>
                      </li>
                      <li>
                        <i className="fas fa-star"></i>
                      </li>
                      <li>
                        <i className="fas fa-star"></i>
                      </li>
                      <li>
                        <i className="fas fa-star fal"></i>
                      </li>
                    </ul>
                  </div>
                  <h4 className="card-title">
                    XS™ Sports Protein Shakes – <br />
                    Rich Chocolate
                  </h4>
                  <div className='price-bucket-div'>
                    <p className="card-text">
                      <del>$53.99</del>
                      <span>$35.99</span>
                    </p>
                    <div>
                      <Image src={Images.Basket} className="card-image ms-3" alt="" />
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          
          </Carousel>
          </div>
        </Container>
      </section>
    );
  };

  return <Slider />;
};

export default MarketPlace;
