import React from "react";
import Images from "../../constants/Images";


export const Hero = () => {
  return (
    <div className="hero">
      <div className="div-2">
        <div className="frame">
          <div className="info">
            <div className="frame-2">
            
              <div className="text-wrapper-2">Welcome to</div>
            </div>
            <div className="text-wrapper-3">Welcome Marketplace</div>
            <p className="p">
              Lorem ipsum dolor sit amet, consectetur adipiscelit, sed do eiusmod tempor Lorem ipsum dolor amet,
              consectetur elit, sed do eiusmod tempor Lorem ipsum dolor sit amet,
            </p>
          </div>
          <div className="frame-3">
            <div className="text-wrapper-4">Create</div>
          </div>
          <div className="frame-4">
            <div className="text-wrapper-5">Explore</div>
          </div>
        </div>
        <img
          className="image"
          alt="Image"
          src={Images.Product_1}
          />
      </div>
    </div>
  );
};

export default Hero;