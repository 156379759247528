/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const DropDown4 = ({ className }) => {
  return (
    <svg
      className={`drop-down-4 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M4.61536 7.69141L9.96188 12.3068L15.3846 7.69141"
        stroke="url(#paint0_linear_3481_1647)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <defs className="defs">
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_3481_1647"
          x1="6.91279"
          x2="9.45377"
          y1="8.99141"
          y2="13.5448"
        >
          <stop className="stop" stopColor="#3A6FFD" />
          <stop className="stop" offset="1" stopColor="#D63ACA" />
        </linearGradient>
      </defs>
    </svg>
  );
};
