import React from "react";
import Navigation from "./Navigation";
import Banner from './Home/Banner';

const Header = () => {
  return (
    <header id="header">
      <Navigation />
      <Banner />
    </header>
  );
};

export default Header;
