import React from "react";
import Images from "../../constants/Images";
import { Container, Row, Col, Card } from "react-bootstrap";

export const HelpSection = () => {
  return (
    <section className="HomeHelpSection" >
      <Container>
        <Row className="align-items-center">
          <Col lg={6} md={6} xs={12}>
            <p className="therapist">Your professional therapist</p>
            <h1>
              Here to Help You <br />
              <span>Find a Path Forward</span>
            </h1>
            <div className="separator"></div>
            <p className="effective">We believe that effective therapy is:</p>
            <ul>
              <li>About finding a therapist you “click” with.</li>
              <li>Tailored to your needs as an individual or a couple.</li>
              <li>Empowers you with science-backed skills and insights.</li>
              <li>Translates into real-life changes.</li>
            </ul>
          </Col>
          <Col lg={6} md={6} xs={12}>
            <div className="card_cover">
              <Card>
                <Card.Img src={Images.Therapy_1} />
                <Card.ImgOverlay>
                  <Card.Body>
                    <Card.Text>Therapy</Card.Text>
                     <Card.Title>
                      Managing Stress &amp; Anxiety
                    </Card.Title>
                  </Card.Body>
                </Card.ImgOverlay>
              </Card>
              <Card>
                <Card.Img src={Images.Therapy_2} className="card-img" />
                <Card.ImgOverlay>
                  <Card.Body>
                    <Card.Text>Therapy</Card.Text>
                     <Card.Title>
                      Managing Stress &amp; Anxiety
                    </Card.Title>
                  </Card.Body>
                </Card.ImgOverlay>
              </Card>
            </div>
            <div className="card_cover">
              <Card>
                <Card.Img src={Images.Therapy_3} className="card-img" />
                <Card.ImgOverlay>
                  <Card.Body>
                    <Card.Text>Therapy</Card.Text>
                     <Card.Title>
                      Managing Stress &amp; Anxiety
                    </Card.Title>
                  </Card.Body>
                </Card.ImgOverlay>
              </Card>
              <Card>
                <Card.Img src={Images.Therapy_4} className="card-img" />
                <Card.ImgOverlay>
                  <Card.Body>
                    <Card.Text>Therapy</Card.Text>
                     <Card.Title>
                      Managing Stress &amp; Anxiety
                    </Card.Title>
                  </Card.Body>
                </Card.ImgOverlay>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
