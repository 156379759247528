import React from "react";
import AdminLayout from "../../layouts/AdminLayout";
import Images from "../../constants/Images";
import { Row, Col, Image, Card } from "react-bootstrap";
import ForumNav from "./ForumNav";

// Create an array of data objects for your cards
const cardData = [
  
  {
    imgSrc: Images.Forum_2,
    title: "Family Issue",
    description: "Relationship & love.",
    views: "32+",
  },{
    imgSrc: Images.Forum_2,
    title: "Family Issue",
    description: "Describe your forum category.",
    views: "32+",
  },{
    imgSrc: Images.Forum_2,
    title: "Family Issue",
    description: "Describe your forum category.",
    views: "32+",
  },{
    imgSrc: Images.Forum_2,
    title: "Family Issue",
    description: "Describe your forum category.",
    views: "32+",
  },{
    imgSrc: Images.Forum_2,
    title: "Family Issue",
    description: "Describe your forum category.",
    views: "32+",
  },{
    imgSrc: Images.Forum_2,
    title: "Family Issue",
    description: "Describe your forum category.",
    views: "32+",
  },{
    imgSrc: Images.Forum_2,
    title: "Family Issue",
    description: "Describe your forum category.",
    views: "32+",
  },{
    imgSrc: Images.Forum_2,
    title: "Family Issue",
    description: "Describe your forum category.",
    views: "32+",
  },
  // Add more data objects as needed
];

function All() {
  return (
    <container className="forumcard  mb-0">
      <Row className="frow">
        {cardData.map((data, index) => (
          <Col lg={4} md={6} sm={12} key={index}>
            <div className="fCard" >
              <Card style={{border:'none', fontFamily:'outfit'}}>
                <Card.Img src={data.imgSrc} className="img-fluid width" alt="" />
                <div className="forumreplies">43 replies</div>
                <Card.Body className=" px-0">
                  <h4 className="card-title">{data.title}</h4>
                  <p style={{color:'#93989A'}}>{data.description}</p>
                  <hr style={{borderBottom:'1px solid gray'}}/>
                  <div className="d-flex mb-2">
                    <p className="card-text" style={{color:'#93989A', fontSize:'13px'}}>
                      <span style={{color:'black', fontSize:'16px'}}>{data.views}</span>&nbsp;Views
                    </p>
                    <div className="postion-relative " style={{marginTop:'-6px'}}>
                      <Image src={Images.fcard_1} className="fcard-image1 ms-3" alt="" />
                      <Image src={Images.fcard_2} className="fcard-image2 ms-3" alt="" />
                      <Image src={Images.fcard_2} className="fcard-image3 ms-3" alt="" />
                      <Image src={Images.fcard_2} className="fcard-image4 ms-3" alt="" />
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </Col>
        ))}
      </Row>
    </container>
  );
}

export default All;
