import React from "react";
import AdminLayout from "../../../layouts/AdminLayout";
import All from "../../../View/Forum/All";
import Images from "../../../constants/Images";
import ForumNav from "../../../View/Forum/ForumNav";
import { Navbar, Nav, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./forum.scss"; // Import your custom CSS file
import ButtonStyled from "../../../components/ButtonStyled";

function Forum() {
  return (
    <AdminLayout>
      <div className="bg-white w-100" style={{minHeight:'100vh'}}>
      <Container>
        <container>
          <img src={Images.Fashion_4} alt="" className="forum-banner" />
        </container>
        <container>
          <h3 className="mt-4">Forum Discussion</h3>
          <Navbar  className="">
    
    
    <Nav className="forummenu mb-2 mb-lg-0 ms-0 pt-1 w-100 " as="ul">
      
        <Nav.Item as="li">
        <Link className="nav-link colorgray" to="/forum">
            All
          </Link>
        </Nav.Item>
        <Nav.Item as="li">
        <Link className="nav-link colorgray" to="/main_forum">
          <div style={{marginTop:'-10px'}}>
          <ButtonStyled title="Addictions" className="factive px-2"  /></div>
          </Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Link className="nav-link colorgray" to="/cancers">
            Cancers
          </Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Link className="nav-link colorgray" to="/familyIssue">
            Family Issue
          </Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Link className="nav-link colorgray" to="/Grief">
            Grief
          </Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Link className="nav-link colorgray" to="/news">
            Relationship & love
          </Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Link className="nav-link colorgray" to="/relationship&love">
            Relationship & love
          </Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Link className="nav-link colorgray" to="/therapies">
            Therapies
          </Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Link className="nav-link colorgray" to="/violence">
            Violence
          </Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Link className="nav-link colorgray" to="/personaldevelopment&identity">
            Personal Development & Identity
          </Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Link className="nav-link colorgray" to="/psychological">
            Psychological Troubles
          </Link>
        </Nav.Item>
      </Nav>
    
  </Navbar>
          <Navbar expand="lg">
            <Container>
              <Navbar.Toggle aria-controls="main-menu">
                <span>Menu</span>
              </Navbar.Toggle>
              <Navbar.Collapse id="main-menu">
                <Nav className="forummenu mb-2 mb-lg-0" as="ul">
                  <Nav.Item as="li">
                    <Link className="nav-link " to="/Addiction">
                     <b> Sub Topics</b>
                    </Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Link className="nav-link colorgray" to="/cancers">
                      Lorem Ipsum
                    </Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Link className="nav-link colorgray" to="/familyIssue">
                      Lorem Ipsum
                    </Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Link className="nav-link colorgray" to="/Grief">
                      Lorem Ipsum
                    </Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Link className="nav-link colorgray" to="/news">
                      Lorem Ipsum & love
                    </Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Link className="nav-link colorgray" to="/therapies">
                      Lorem Ipsum
                    </Link>
                  </Nav.Item>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </container>
        <All />
      </Container>
      </div>
    </AdminLayout>
  );
}

export default Forum;
