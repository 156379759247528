import React from "react";
import { Col, Container, Image } from "react-bootstrap";
import Images from "../../constants/Images";

const MarketPlaceSection = () => {
  return (
    <section className="HomeWhySection">
      <Container>
        <p className="therapist" style={{fontStyle:'italic'}}>
          Our process
        </p>
        <h1>Therapy process</h1>
        <div className="separator"></div>
        <div className="therapyprocess">
          <Image src={Images.therapy_proc2} fluid className="w-100" />
          <div className="d-flex">
            <Col lg={3}>
              <div className="orientation">
                <h4>Orientation</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor Lorem ipsum dolor
                </p>
              </div>
            </Col>
            <Col lg={3}>
              <div className="identification">
                <h4>Identification</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor Lorem ipsum dolor
                </p>
              </div>
            </Col>
            <Col lg={3}>
              <div className="Exploration">
                <h4>Exploration</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor Lorem ipsum dolor
                </p>
              </div>
            </Col>
            <Col lg={3}>
              <div className="Resolution">
                <h4>Resolution</h4><p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor Lorem ipsum dolor
                </p>
              </div>
            </Col>
          </div>
        </div>
        {/* <Image src={Images.Portal} fluid className='w-100'/> */}
      </Container>
    </section>
  );
};

export default MarketPlaceSection;
