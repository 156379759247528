import { Button, Container, Form } from "react-bootstrap";
import AuthLayout from "../../layouts/AuthLayout";
import ButtonStyled from "../../components/ButtonStyled";
import Images from "../../constants/Images";
import { useNavigate } from "react-router-dom";

const SignUpStepFour = () => {
  const navigate = useNavigate();
  return (
    <AuthLayout>
      <main id="main">
        <section
          className="LoginSection"
          style={{
            backgroundImage: `url(${Images.SignUpBG})`,
          }}
        >
          <Container fluid className="main-div">
            <div className="login_form">
              <div>
                <h1>
                  Complete Your<span>{" "}Profile</span>
                </h1>
                <p className="text-center continue">Lorem Ipsum ipsum Lorem</p>
                <Form>
                  <Form.Group className="mb-3 form-group">
                    <Form.Label>Education level</Form.Label>
                    <Form.Select name="languages" id="lang">
                      <option value="javascript">Write here</option>
                      <option value="php">German</option>
                      <option value="java">Swedish</option>
                      <option value="golang">Italian</option>
                      <option value="python">Russian</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-3 form-group">
                    <Form.Label>Write Your Diploma</Form.Label>
                    <Form.Select name="languages" id="lang">
                      <option value="javascript">Write here</option>
                      <option value="php">German</option>
                      <option value="java">Swedish</option>
                      <option value="golang">Italian</option>
                      <option value="python">Russian</option>
                    </Form.Select>
                  </Form.Group>
                  <div className="d-flex justify-space-between" style={{alignItems:'center', justifyContent:'space-between',height:'46.7px'}}>
                    <p className="mt-3">Add your Id Card Picture</p>
                    {/* <Image src={Images}/> */}
                    <svg width="47" height="48" viewBox="0 0 47 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.333984" y="0.666016" width="46.6658" height="46.6671" rx="23.3329" fill="url(#paint0_linear_2181_14868)" fill-opacity="0.05"/>
<path d="M30.6346 19.9299C30.7073 19.8529 30.7949 19.7916 30.8922 19.7497C30.9894 19.7077 31.0941 19.6861 31.2 19.6861C31.3059 19.6861 31.4107 19.7077 31.5079 19.7497C31.6051 19.7916 31.6928 19.8529 31.7654 19.9299C32.0779 20.2549 32.0779 20.7816 31.7654 21.1066L22.1571 31.1141C21.7939 31.499 21.3558 31.8057 20.8698 32.0154C20.3838 32.225 19.8601 32.3331 19.3309 32.3331C18.8016 32.3331 18.2779 32.225 17.7919 32.0154C17.3059 31.8057 16.8678 31.499 16.5046 31.1141C14.9438 29.4891 14.9438 26.8533 16.5046 25.2274L24.9821 16.3974C25.2001 16.1665 25.4629 15.9824 25.7545 15.8567C26.0461 15.7309 26.3604 15.666 26.6779 15.666C26.9955 15.666 27.3098 15.7309 27.6014 15.8567C27.893 15.9824 28.1558 16.1665 28.3738 16.3974C29.3104 17.3724 29.3104 18.9541 28.3738 19.9299L21.0254 27.5808C20.9528 27.6576 20.8652 27.7189 20.768 27.7607C20.6709 27.8026 20.5662 27.8241 20.4604 27.8241C20.3547 27.8241 20.25 27.8026 20.1528 27.7607C20.0557 27.7189 19.9681 27.6576 19.8954 27.5808C19.7452 27.4217 19.6615 27.2112 19.6615 26.9924C19.6615 26.7736 19.7452 26.5631 19.8954 26.4041L27.2438 18.7516C27.3943 18.5925 27.4781 18.3818 27.4781 18.1628C27.4781 17.9439 27.3943 17.7332 27.2438 17.5741C27.1711 17.4971 27.0835 17.4357 26.9862 17.3938C26.889 17.3519 26.7842 17.3303 26.6784 17.3303C26.5725 17.3303 26.4677 17.3519 26.3705 17.3938C26.2733 17.4357 26.1856 17.4971 26.1129 17.5741L17.6338 26.4058C16.6979 27.3808 16.6979 28.9624 17.6338 29.9374C17.8517 30.1684 18.1146 30.3524 18.4062 30.4782C18.6978 30.604 19.012 30.6688 19.3296 30.6688C19.6472 30.6688 19.9614 30.604 20.253 30.4782C20.5446 30.3524 20.8075 30.1684 21.0254 29.9374L30.6346 19.9299Z" fill="url(#paint1_linear_2181_14868)"/>
<defs>
<linearGradient id="paint0_linear_2181_14868" x1="10.2894" y1="13.8106" x2="39.4561" y2="36.21" gradientUnits="userSpaceOnUse">
<stop stop-color="#3A6FFD"/>
<stop offset="1" stop-color="#D63ACA"/>
</linearGradient>
<linearGradient id="paint1_linear_2181_14868" x1="18.8894" y1="20.3606" x2="29.3061" y2="28.36" gradientUnits="userSpaceOnUse">
<stop stop-color="#3A6FFD"/>
<stop offset="1" stop-color="#D63ACA"/>
</linearGradient>
</defs>
</svg>

                  </div>
                  <div className="d-flex justify-space-between mt-3 mb-3" style={{alignItems:'center', justifyContent:'space-between',height:'46.7px'}}>
                    <p className="mt-3">Attach your certificate</p>
                    {/* <Image src={Images}/> */}
                    <div>
                    <svg width="47" height="48" viewBox="0 0 47 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.333984" y="0.666016" width="46.6658" height="46.6671" rx="23.3329" fill="url(#paint0_linear_2181_14868)" fill-opacity="0.05"/>
<path d="M30.6346 19.9299C30.7073 19.8529 30.7949 19.7916 30.8922 19.7497C30.9894 19.7077 31.0941 19.6861 31.2 19.6861C31.3059 19.6861 31.4107 19.7077 31.5079 19.7497C31.6051 19.7916 31.6928 19.8529 31.7654 19.9299C32.0779 20.2549 32.0779 20.7816 31.7654 21.1066L22.1571 31.1141C21.7939 31.499 21.3558 31.8057 20.8698 32.0154C20.3838 32.225 19.8601 32.3331 19.3309 32.3331C18.8016 32.3331 18.2779 32.225 17.7919 32.0154C17.3059 31.8057 16.8678 31.499 16.5046 31.1141C14.9438 29.4891 14.9438 26.8533 16.5046 25.2274L24.9821 16.3974C25.2001 16.1665 25.4629 15.9824 25.7545 15.8567C26.0461 15.7309 26.3604 15.666 26.6779 15.666C26.9955 15.666 27.3098 15.7309 27.6014 15.8567C27.893 15.9824 28.1558 16.1665 28.3738 16.3974C29.3104 17.3724 29.3104 18.9541 28.3738 19.9299L21.0254 27.5808C20.9528 27.6576 20.8652 27.7189 20.768 27.7607C20.6709 27.8026 20.5662 27.8241 20.4604 27.8241C20.3547 27.8241 20.25 27.8026 20.1528 27.7607C20.0557 27.7189 19.9681 27.6576 19.8954 27.5808C19.7452 27.4217 19.6615 27.2112 19.6615 26.9924C19.6615 26.7736 19.7452 26.5631 19.8954 26.4041L27.2438 18.7516C27.3943 18.5925 27.4781 18.3818 27.4781 18.1628C27.4781 17.9439 27.3943 17.7332 27.2438 17.5741C27.1711 17.4971 27.0835 17.4357 26.9862 17.3938C26.889 17.3519 26.7842 17.3303 26.6784 17.3303C26.5725 17.3303 26.4677 17.3519 26.3705 17.3938C26.2733 17.4357 26.1856 17.4971 26.1129 17.5741L17.6338 26.4058C16.6979 27.3808 16.6979 28.9624 17.6338 29.9374C17.8517 30.1684 18.1146 30.3524 18.4062 30.4782C18.6978 30.604 19.012 30.6688 19.3296 30.6688C19.6472 30.6688 19.9614 30.604 20.253 30.4782C20.5446 30.3524 20.8075 30.1684 21.0254 29.9374L30.6346 19.9299Z" fill="url(#paint1_linear_2181_14868)"/>
<defs>
<linearGradient id="paint0_linear_2181_14868" x1="10.2894" y1="13.8106" x2="39.4561" y2="36.21" gradientUnits="userSpaceOnUse">
<stop stop-color="#3A6FFD"/>
<stop offset="1" stop-color="#D63ACA"/>
</linearGradient>
<linearGradient id="paint1_linear_2181_14868" x1="18.8894" y1="20.3606" x2="29.3061" y2="28.36" gradientUnits="userSpaceOnUse">
<stop stop-color="#3A6FFD"/>
<stop offset="1" stop-color="#D63ACA"/>
</linearGradient>
</defs>
</svg></div>

                  </div>
                  <Button
                className="w-100" style={{backgroundColor:'#0000001A',border:'1px solid white',color:'#68838E', borderRadius:'1000px',height:'55px'}}
                
                onClick={() => {
                  navigate("/sign-up-step-5");
                }}
              >Complete </Button>
                  
                </Form>
              </div>
            </div>
          </Container>
        </section>
      </main>
    </AuthLayout>
  );
};

export default SignUpStepFour;
