/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const UploadLight = ({
  className,
  subtract = "https://generation-sessions.s3.amazonaws.com/f78f40393473e36d110752be385cbd69/img/subtract.svg",
  vector = "https://generation-sessions.s3.amazonaws.com/f78f40393473e36d110752be385cbd69/img/vector-10-2.svg",
}) => {
  return (
    <div className={`upload-light ${className}`}>
      <div className="overlap-group">
        <img className="subtract" alt="Subtract" src={subtract} />
        <img className="vector" alt="Vector" src={vector} />
      </div>
    </div>
  );
};

UploadLight.propTypes = {
  subtract: PropTypes.string,
  vector: PropTypes.string,
};
