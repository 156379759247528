import React from "react";
import AdminLayout from "../../layouts/AdminLayout";
import { Container } from "react-bootstrap";
import { FormControl } from "react-bootstrap";
import { Card } from "react-bootstrap";
import Profile from "../../components/Profile";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";

import Images from "../../constants/Images";
import ButtonStyled from "../../components/ButtonStyled";
import { Button } from "../../View/Myspecllist.js/components/Button";
import { ButtonWrapper } from "../../View/Myspecllist.js/components/ButtonWrapper";
import { DropDown } from "../../View/Myspecllist.js/icons/DropDown";
import { DropDown1 } from "../../View/Myspecllist.js/icons/DropDown1";
import { DropDown2 } from "../../View/Myspecllist.js/icons/DropDown2";
import { DropDown3 } from "../../View/Myspecllist.js/icons/DropDown3";
import { DropDown4 } from "../../View/Myspecllist.js/icons/DropDown4";
import { Search } from "../../View/Myspecllist.js/icons/Search";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import "./MySpecialist.scss";
<style>
  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@1,300&display=swap');
</style>
function MySpeciallist() {
  return (
    <div className="bg-white">
    <AdminLayout>
      <div className="bg-white pt-5 m-0">
      <Container className="specialist1 ">
        <div className="frame_12">
          <div className="heading-and-details">
            <div className="div">
              <div className="search-2">
                <div className="text-wrapper">Search</div>
                <Search className="search-instance" />
              </div>
              <Button 
                className="button-2"
                divClassName="design-component-instance-node"
                icon={<DropDown4 className="drop-down" />}
                leftIcon={false}
                text="Type of Specialist"
              />
            </div>
            <div className="buttons">
              <div className="buttons-2-main">
                    <div className="button-2-div1">
                        <Button
                          className="button-2-special"
                          divClassName="design-component-instance-nod"
                          icon={<DropDown3 className="drop-down" />}
                          leftIcon={false}
                          text="Location"
                        />
                        <Button
                          className="button-2-special"
                          divClassName="design-component-instance-nod"
                          icon={<DropDown2 className="drop-down" />}
                          leftIcon={false}
                          text="Language"
                        />
                    </div>
                    <div className="button-2-div2">
                          <Button
                                className="button-2-special"
                                divClassName="design-component-instance-nod"
                                icon={<DropDown1 className="drop-down" />}
                                leftIcon={false}
                                text="Gender"
                              />
                              <Button
                                className="button-2-special"
                                divClassName="design-component-instance-nod"
                                icon={<DropDown className="drop-down" />}
                                leftIcon={false}
                                text="Rates"
                              />
                    </div>       
              </div>
              <div style={{ flex: 1 }}></div>
              {/* <div> */}
                <ButtonWrapper
                  className="button-3"
                  divClassName="button-4"
                  showIcon={false}
                  text="Search"
                />
              {/* </div> */}
              
            </div>
          </div>
        </div>
      </Container>
      <Container className="bg-white">
        <Container>
        <div className="cardheader1 ">
          {" "}
          <h2 className="myspecialistheading mt-2"> Best For You</h2>{" "}<div>
          {/* <ButtonStyled title="See all" className="btn-detaile " isMore={true} /> */}
          <div className="btn-detaile-div" style={{marginRight:'0px'}}>
            
            <button class="btn-detaile">
              See all
              <i class="fas fa-arrow-right button-icon1"></i>
              
            </button>
            </div>
          </div>     </div>
          </Container>        <Container>
          <Row>
            <Col lg={4} className="best-for-you-form">
              <Card className="specilist-1 rounded-4" style={{border:'none'}}>
                <Card.Body>
                  <div className="d-flex">
                    {" "}
                    <Card.Img
                      src={Images.fcard_2}
                      className=" specialist-img1 "
                      alt=""
                    />
                    <div className="star">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <path
                          d="M2.9125 11L4.075 7.2L1 5H4.8L6 1L7.2 5H11L7.925 7.2L9.0875 11L6 8.65L2.9125 11Z"
                          fill="white"
                        />
                      </svg>
                      <span className="star-rating"> 4.5 </span>
                    </div>{" "}
                  </div>
                  <h4> Nelson Jhons</h4>
                  <span className="ctext-1"> Mental Therapist</span> <br></br>
                  <span className="ctext-1">Houston, USA 5+ Years of Exp</span>
                  <div className="tag-div">
                    <div className="tag1">
                      <span className="tag-text">Therapiest</span>
                    </div>
                    <div className="tag1">
                      <span className="tag-text">Good Surgeon</span>
                    </div>
                    <div className="tag1">
                      <span className="tag-text">Mental Therapist</span>
                    </div>
                    <div className="tag1">
                      <span className="tag-text">+9</span>
                    </div>
                  </div>
                  <Row className="mt-3">
                    <Col>
                      <Row>
                        <span className="specialist-price">Price</span>
                      </Row>
                      <Row>
                        <h5 className="price_for_specialist"> $ 120.00</h5>
                      </Row>
                    </Col>
                    <Col
                      className="specilistheader"
                      style={{ marginRight: "20px" }}
                    >
                      <span className="booking"> Book Appointment</span>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={4} className="best-for-you-form">
              <Card className="specilist-1 rounded-4" style={{border:'none'}}>
                <Card.Body>
                  <div className="d-flex">
                    {" "}
                    <Card.Img
                      src={Images.fcard_2}
                      className=" specialist-img1 "
                      alt=""
                    />
                    <div className="star">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <path
                          d="M2.9125 11L4.075 7.2L1 5H4.8L6 1L7.2 5H11L7.925 7.2L9.0875 11L6 8.65L2.9125 11Z"
                          fill="white"
                        />
                      </svg>
                      <span className="star-rating"> 4.5 </span>
                    </div>{" "}
                  </div>
                  <h4> Nelson Jhons</h4>
                  <span className="ctext-1"> Mental Therapist</span> <br></br>
                  <span className="ctext-1">Houston, USA 5+ Years of Exp</span>
                  <div className="tag-div">
                    <div className="tag1">
                      <span className="tag-text">Therapiest</span>
                    </div>
                    <div className="tag1">
                      <span className="tag-text">Good Surgeon</span>
                    </div>
                    <div className="tag1">
                      <span className="tag-text">Mental Therapist</span>
                    </div>
                    <div className="tag1">
                      <span className="tag-text">+9</span>
                    </div>
                  </div>
                  <Row className="mt-3">
                    <Col>
                      <Row>
                        <span className="specialist-price">Price</span>
                      </Row>
                      <Row>
                        <h5 className="price_for_specialist"> $ 120.00</h5>
                      </Row>
                    </Col>
                    <Col
                      className="specilistheader"
                      style={{ marginRight: "20px" }}
                    >
                      <span className="booking"> Book Appointment</span>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={4} className="best-for-you-form">
              <Card className="specilist-1 rounded-4" style={{border:'none'}}>
                <Card.Body>
                  <div className="d-flex">
                    {" "}
                    <Card.Img
                      src={Images.fcard_2}
                      className=" specialist-img1 "
                      alt=""
                    />
                    <div className="star">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <path
                          d="M2.9125 11L4.075 7.2L1 5H4.8L6 1L7.2 5H11L7.925 7.2L9.0875 11L6 8.65L2.9125 11Z"
                          fill="white"
                        />
                      </svg>
                      <span className="star-rating"> 4.5 </span>
                    </div>{" "}
                  </div>
                  <h4> Nelson Jhons</h4>
                  <span className="ctext-1"> Mental Therapist</span> <br></br>
                  <span className="ctext-1">Houston, USA 5+ Years of Exp</span>
                  <div className="tag-div">
                    <div className="tag1">
                      <span className="tag-text">Therapiest</span>
                    </div>
                    <div className="tag1">
                      <span className="tag-text">Good Surgeon</span>
                    </div>
                    <div className="tag1">
                      <span className="tag-text">Mental Therapist</span>
                    </div>
                    <div className="tag1">
                      <span className="tag-text">+9</span>
                    </div>
                  </div>
                  <Row className="mt-3">
                    <Col>
                      <Row>
                        <span className="specialist-price">Price</span>
                      </Row>
                      <Row>
                        <h5 className="price_for_specialist"> $ 120.00</h5>
                      </Row>
                    </Col>
                    <Col
                      className="specilistheader"
                      style={{ marginRight: "20px" }}
                    >
                      <span className="booking"> Book Appointment</span>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={4} className="best-for-you-form">
              <Card className="specilist-1 rounded-4" style={{border:'none'}}>
                <Card.Body>
                  <div className="d-flex">
                    {" "}
                    <Card.Img
                      src={Images.fcard_2}
                      className=" specialist-img1 "
                      alt=""
                    />
                    <div className="star">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <path
                          d="M2.9125 11L4.075 7.2L1 5H4.8L6 1L7.2 5H11L7.925 7.2L9.0875 11L6 8.65L2.9125 11Z"
                          fill="white"
                        />
                      </svg>
                      <span className="star-rating"> 4.5 </span>
                    </div>{" "}
                  </div>
                  <h4> Nelson Jhons</h4>
                  <span className="ctext-1"> Mental Therapist</span> <br></br>
                  <span className="ctext-1">Houston, USA 5+ Years of Exp</span>
                  <div className="tag-div">
                    <div className="tag1">
                      <span className="tag-text">Therapiest</span>
                    </div>
                    <div className="tag1">
                      <span className="tag-text">Good Surgeon</span>
                    </div>
                    <div className="tag1">
                      <span className="tag-text">Mental Therapist</span>
                    </div>
                    <div className="tag1">
                      <span className="tag-text">+9</span>
                    </div>
                  </div>
                  <Row className="mt-3">
                    <Col>
                      <Row>
                        <span className="specialist-price">Price</span>
                      </Row>
                      <Row>
                        <h5 className="price_for_specialist"> $ 120.00</h5>
                      </Row>
                    </Col>
                    <Col
                      className="specilistheader"
                      style={{ marginRight: "20px" }}
                    >
                      <span className="booking"> Book Appointment</span>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={4} className="best-for-you-form">
              <Card className="specilist-1 rounded-4" style={{border:'none'}}>
                <Card.Body>
                  <div className="d-flex">
                    {" "}
                    <Card.Img
                      src={Images.fcard_2}
                      className=" specialist-img1 "
                      alt=""
                    />
                    <div className="star">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <path
                          d="M2.9125 11L4.075 7.2L1 5H4.8L6 1L7.2 5H11L7.925 7.2L9.0875 11L6 8.65L2.9125 11Z"
                          fill="white"
                        />
                      </svg>
                      <span className="star-rating"> 4.5 </span>
                    </div>{" "}
                  </div>
                  <h4> Nelson Jhons</h4>
                  <span className="ctext-1"> Mental Therapist</span> <br></br>
                  <span className="ctext-1">Houston, USA 5+ Years of Exp</span>
                  <div className="tag-div">
                    <div className="tag1">
                      <span className="tag-text">Therapiest</span>
                    </div>
                    <div className="tag1">
                      <span className="tag-text">Good Surgeon</span>
                    </div>
                    <div className="tag1">
                      <span className="tag-text">Mental Therapist</span>
                    </div>
                    <div className="tag1">
                      <span className="tag-text">+9</span>
                    </div>
                  </div>
                  <Row className="mt-3">
                    <Col>
                      <Row>
                        <span className="specialist-price">Price</span>
                      </Row>
                      <Row>
                        <h5 className="price_for_specialist"> $ 120.00</h5>
                      </Row>
                    </Col>
                    <Col
                      className="specilistheader"
                      style={{ marginRight: "20px" }}
                    >
                      <span className="booking"> Book Appointment</span>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={4} className="best-for-you-form">
              <Card className="specilist-1 rounded-4" style={{border:'none'}}>
                <Card.Body>
                  <div className="d-flex">
                    {" "}
                    <Card.Img
                      src={Images.fcard_2}
                      className=" specialist-img1 "
                      alt=""
                    />
                    <div className="star">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <path
                          d="M2.9125 11L4.075 7.2L1 5H4.8L6 1L7.2 5H11L7.925 7.2L9.0875 11L6 8.65L2.9125 11Z"
                          fill="white"
                        />
                      </svg>
                      <span className="star-rating"> 4.5 </span>
                    </div>{" "}
                  </div>
                  <h4> Nelson Jhons</h4>
                  <span className="ctext-1"> Mental Therapist</span> <br></br>
                  <span className="ctext-1">Houston, USA 5+ Years of Exp</span>
                  <div className="tag-div">
                    <div className="tag1">
                      <span className="tag-text">Therapiest</span>
                    </div>
                    <div className="tag1">
                      <span className="tag-text">Good Surgeon</span>
                    </div>
                    <div className="tag1">
                      <span className="tag-text">Mental Therapist</span>
                    </div>
                    <div className="tag1">
                      <span className="tag-text">+9</span>
                    </div>
                  </div>
                  <Row className="mt-3">
                    <Col>
                      <Row>
                        <span className="specialist-price">Price</span>
                      </Row>
                      <Row>
                        <h5 className="price_for_specialist"> $ 120.00</h5>
                      </Row>
                    </Col>
                    <Col
                      className="specilistheader"
                      style={{ marginRight: "20px" }}
                    >
                      <span className="booking"> Book Appointment</span>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        <Container>
        <Container>
          <div className="cardheader1">
            {" "}
            <h2 className="myspecialistheading mt-2"> Trending Courses</h2>{" "}
            <div>
          {/* <ButtonStyled title="See all" className="btn-detaile " isMore={true} /> */}
          <div className="btn-detaile-div" style={{marginRight:'0px'}}>
            
            <button class="btn-detaile">
              See all
              <i class="fas fa-arrow-right button-icon1"></i>
              
            </button>
            </div>
          </div>   
          </div>
          </Container>
          <Row className="trendingCol">
            <Col>
              <Card className="rounded-4 trending1">
              <Row style={{width:'100%'}}>
              <div className="heart-icon-div">
                      <p className="heart-time">2h 4m 32s</p>
                      <div className={"heart-icon"}>
                          <FontAwesomeIcon
                            icon={faHeart}
                            style={{
                              color: "white",
                              backgroundColor: "rgba(19, 12, 26, 0.15)",
                              width: "15px",
                              height: "15px",
                              padding: "10px",
                              borderRadius: "50%",
                            }}
                          />
                        </div>
                  </div>
                </Row>
                
                <Card.Img
                  src={Images.Course_1}
                  className=" specialist-img12 "
                  alt=""
                />
                
                <Card.Body>
                
                
                  <h3>Managing Stress & Anxiety</h3>
                  <span style={{color:"#93989A"}}>Mental Therapy</span>
                  <Row className="mt-3">
                    <Col>
                      <Row>
                        <span className="specialist-price">Price</span>
                      </Row>
                      <Row>
                        <h5 className="price_for_specialist"> $ 120.00</h5>
                      </Row>
                    </Col>
                    <Col sm={3} className="specilistheader1">
                      <span className="booking"> Join Course</span>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col >
              <Card className="rounded-4 trending1">
              <Row style={{width:'100%'}}>
                <div className="heart-icon-div">
                      <p className="heart-time">2h 4m 32s</p>
                      <div className={"heart-icon"}>
                          <FontAwesomeIcon
                            icon={faHeart}
                            style={{
                              color: "white",
                              backgroundColor: "rgba(19, 12, 26, 0.15)",
                              width: "15px",
                              height: "15px",
                              padding: "10px",
                              borderRadius: "50%",
                            }}
                          />
                        </div>
                  </div>
                </Row>
                <Card.Img
                  src={Images.Course_2}
                  className=" specialist-img12 "
                  alt=""
                />
                <Card.Body>
                  <h3>Managing Stress & Anxiety</h3>
                  <span style={{color:"#93989A"}}>Mental Therapy</span>
                  <Row className="mt-3">
                    <Col>
                      <Row>
                        <span className="specialist-price">Price</span>
                      </Row>
                      <Row>
                        <h5 className="price_for_specialist"> $ 120.00</h5>
                      </Row>
                    </Col>
                    <Col sm={3} className="specilistheader1">
                      <span className="booking"> Join Course</span>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>

      <Container style={{marginBottom:'140px'}}> 
<Container>
        <div className="cardheader1">
          {" "}
          <h2 className="myspecialistheading mt-2"> Near By You</h2>{" "}
          <div>
          {/* <ButtonStyled title="See all" className="btn-detaile " isMore={true} /> */}
          <div className="btn-detaile-div" style={{marginRight:'0px'}}>
            
            <button class="btn-detaile">
              See all
              <i class="fas fa-arrow-right button-icon1"></i>
              
            </button>
            </div>
          </div>   
        </div>
        </Container>
        <div
          className="d-flex near-div"
        >
          <Col lg={3}>
            <Card className="specilist-2 rounded-4 m-3" style={{border:'none'}}>
              <Card.Body>
                <div className="d-flex near-card">
                  <Card.Img
                    src={Images.fcard_9}
                    className=" specialist-img1 "
                    alt=""
                  />
                  <div className="star">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        d="M2.9125 11L4.075 7.2L1 5H4.8L6 1L7.2 5H11L7.925 7.2L9.0875 11L6 8.65L2.9125 11Z"
                        fill="white"
                      />
                    </svg>
                    <span className="star-rating"> 4.5 </span>
                  </div>{" "}
                </div>
                <h4> Nelson Jhons</h4>
                <span className="ctext-1"> Mental Therapist</span> <br></br>
                <div className="specilistheader">
                  <span className="booking"> Book Appointment</span>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3}>
            <Card className="specilist-2 rounded-4  m-3" style={{border:'none'}}>
              <Card.Body>
                <div className="d-flex near-card">
                  <Card.Img
                    src={Images.fcard_9}
                    className=" specialist-img1 "
                    alt=""
                  />
                  <div className="star">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        d="M2.9125 11L4.075 7.2L1 5H4.8L6 1L7.2 5H11L7.925 7.2L9.0875 11L6 8.65L2.9125 11Z"
                        fill="white"
                      />
                    </svg>
                    <span className="star-rating"> 4.5 </span>
                  </div>{" "}
                </div>
                <h4> Nelson Jhons</h4>
                <span className="ctext-1"> Mental Therapist</span> <br></br>
                <div className="specilistheader">
                  <span className="booking"> Book Appointment</span>
                </div>
              </Card.Body>
            </Card>{" "}
          </Col>
          <Col lg={3}>
            <Card className="specilist-2 rounded-4  m-3" style={{border:'none'}}>
              <Card.Body>
                <div className="d-flex near-card">
                  <Card.Img
                    src={Images.fcard_9}
                    className=" specialist-img1 "
                    alt=""
                  />
                  <div className="star">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        d="M2.9125 11L4.075 7.2L1 5H4.8L6 1L7.2 5H11L7.925 7.2L9.0875 11L6 8.65L2.9125 11Z"
                        fill="white"
                      />
                    </svg>
                    <span className="star-rating"> 4.5 </span>
                  </div>{" "}
                </div>
                <h4> Nelson Jhons</h4>
                <span className="ctext-1"> Mental Therapist</span> <br></br>
                <div className="specilistheader">
                  <span className="booking"> Book Appointment</span>
                </div>
              </Card.Body>
            </Card>{" "}
          </Col>
          <Col lg={3}>
            <Card className="specilist-2 rounded-4  m-3" style={{border:'none'}}>
              <Card.Body>
                <div className="d-flex near-card">
                  <Card.Img
                    src={Images.fcard_9}
                    className=" specialist-img1 "
                    alt=""
                  />
                  <div className="star">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        d="M2.9125 11L4.075 7.2L1 5H4.8L6 1L7.2 5H11L7.925 7.2L9.0875 11L6 8.65L2.9125 11Z"
                        fill="white"
                      />
                    </svg>
                    <span className="star-rating"> 4.5 </span>
                  </div>{" "}
                </div>
                <h4> Nelson Jhons</h4>
                <span className="ctext-1"> Mental Therapist</span> <br></br>
                <div className="specilistheader">
                  <span className="booking"> Book Appointment</span>
                </div>
              </Card.Body>
            </Card>{" "}
          </Col>
        </div>

        </Container>
      </Container>
      </div>
    </AdminLayout>
    </div>
  );
}

export default MySpeciallist;
