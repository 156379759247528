import HomeBanner from "../assets/img/HomeBanner.png";
import HomeBody from "../assets/img/HomeBody.png";
import HomeCard_1 from "../assets/img/HomeCard_1.png";
import HomeCard_2 from "../assets/img/HomeCard_2.png";
import HomeCard_3 from "../assets/img/HomeCard_3.png";
import HomeCommunity from "../assets/img/HomeCommunity.png";
import HomeWhy_1 from "../assets/img/HomeWhy_1.png";
import HomeWhy_2 from "../assets/img/HomeWhy_2.png";
import HomeWhy_3 from "../assets/img/HomeWhy_3.png";
import HomeWhy_4 from "../assets/img/HomeWhy_4.png";
import HomeWhy_5 from "../assets/img/HomeWhy_5.png";
import HomeWhy_6 from "../assets/img/HomeWhy_6.png";
import Logo from "../assets/img/Logo.png";
import NavAuthor from "../assets/img/NavAuthor.png";
import News_1 from "../assets/img/News_1.png";
import News_2 from "../assets/img/News_2.png";
import News_3 from "../assets/img/News_3.png";
import Portal from "../assets/img/Portal.png";
import Product_1 from "../assets/img/Product_1.png";
import Product_2 from "../assets/img/Product_2.png";
import Product_3 from "../assets/img/Product_3.png";
import Product_4 from "../assets/img/Product_4.png";
import Product_5 from "../assets/img/Product_5.png";
import Product_6 from "../assets/img/Product_6.png";
import QuickTestBackground from "../assets/img/QuickTestBackground.png";
import SignUpBG from "../assets/img/SignUpBG.png";
import Specialist_1 from "../assets/img/Specialist_1.png";
import Specialist_2 from "../assets/img/Specialist_2.png";
import Specialist_3 from "../assets/img/Specialist_3.png";
import Specialist_4 from "../assets/img/Specialist_4.png";
import Testimonial_1 from "../assets/img/Testimonial_1.png";
import Therapy_1 from "../assets/img/Therapy_1.png";
import Therapy_2 from "../assets/img/Therapy_2.png";
import Therapy_3 from "../assets/img/Therapy_3.png";
import Therapy_4 from "../assets/img/Therapy_4.png";
import VideoPoster from "../assets/img/VideoPoster.png";
import Basket from "../assets/img/Basket.png";
import loginIcon from "../assets/img/loginMainImage.png";
import loginBackground from "../assets/img/loginBackground.png";
// import Forum_1 from "../assets/img/Froum_1.png"
import Forum_2 from "../assets/img/Forum_2.png";
import Forum_3 from "../assets/img/Forum_3.png";
// import Forum_4 from "../assets/img/Forum_4.png"
import Forum_5 from "../assets/img/Forum_5.png";
import Forum_6 from "../assets/img/Forum_6.png";
// import Forum_7 from "../assets/img/Forum_7.png"
import Forum_8 from "../assets/img/Forum_8.png";
import Forum_9 from "../assets/img/Forum_9.png";

import Fashion_1 from "../assets/img/Fashion_1.png";
import Fashion_2 from "../assets/img/Fashion_2.png";
import Fashion_3 from "../assets/img/Fashion_3.png";
import Froum_1 from "../assets/img/Forum_1.png";
import Forum_4 from "../assets/img/forum_4.png";
import Forum_7 from "../assets/img/forum_7.png";
import fcard_1 from "../assets/img/fcard_1.png";
import fcard_2 from "../assets/img/fcard_2.png";
import fcard_3 from "../assets/img/fcard_3.png";
import fcard_4 from "../assets/img/fcard_4.png";
import fcard_5 from "../assets/img/fcard_5.png";
import fcard_6 from "../assets/img/fcard_6.png";
import fcard_7 from "../assets/img/fcard_7.png";
import fcard_8 from "../assets/img/fcard_8.png";
import Fashion_4 from "../assets/img/Fashion_4.png";
import Addiction_1 from "../assets/img/Addiction_1.png";
import Addiction_2 from "../assets/img/Addiction_2.png";
import Addiction_3 from "../assets/img/Addiction_3.png";
import Addi_p1 from "../assets/img/Addi_p1.png";
import Course_1 from "../assets/img/Course_1.png";
import Course_2 from "../assets/img/Course_2.png";
import Addi_p2 from "../assets/img/Addi_p2.png";
import Myappointment_1 from "../assets/img/Myappointment_1.png";
import Map_1 from "../assets/img/Map_1.png";
import Market_1 from "../assets/img/Market_1.png";
import Market_3 from "../assets/img/Market_3.png";
import card_1 from "../assets/img/card_1.png";
import Article_1 from "../assets/img/Article_1.png";
import therapy_proc from "../assets/img/therapy_proc.png";
import therapy_proc2 from "../assets/img/therapy_proc2.png";

import symapathiq_logo from "../assets/img/sympathiq_logo.png";
import notfound from "../assets/img/notfound.png";
import fcard_9 from "../assets/img/fcard_9.png";
import meetingcard1 from "../assets/img/meetingcard1.png";
import meetingcard2 from "../assets/img/meetingcard2.png";
import meetingcard3 from "../assets/img/meetingcard3.png";
import cloud from "../assets/img/cloud.png";
import visa from "../assets/img/visa.png";
import add_card from "../assets/img/add_card.png";
import plus from "../assets/img/plus.png";
import envelope from "../assets/img/envelope.png";
import location from "../assets/img/location.png";
import age from "../assets/img/age.png";
import profile from "../assets/img/profile.png";
import addoffice from "../assets/img/addoffice.png";
import addoffice1 from "../assets/img/addoffice1.png";
import userprof from "../assets/img/Userprofvid.png"
import calloptions from "../assets/img/calloptions.png";
import graph from "../assets/img/graph.png";
import map from "../assets/img/map.png";
import graph_for_doctor from "../assets/img/graph_for_doctor.png";
import gradient from "../assets/img/gradient.png";
import girlprof from "../assets/img/girlprof.png";
import happymood from "../assets/img/Happy.png";
import enjoymood from "../assets/img/enjoymood.png";
import Satisfiedmood from "../assets/img/satisfiedmood.png";
import funnymood from "../assets/img/funnymood.png";
import excitedmood from "../assets/img/excitedmood.png";
import normal from "../assets/img/normalmood.png";
import video_call from "../assets/img/video_call.png";
import girl_image_1 from "../assets/img/girl_image_1.png";
import girl_image_2 from "../assets/img/girl_image_2.png";
import girl_image_3 from "../assets/img/girl_image_3.png";


export default {
  therapy_proc2,
  girl_image_1,
  girl_image_2,
  girl_image_3,
  enjoymood,Satisfiedmood,funnymood,excitedmood,normal,
  happymood,
  video_call,
  girlprof,
  graph_for_doctor,
  gradient,
  girlprof,
  calloptions,
  userprof,
  map,
  graph,
  addoffice,
  addoffice1,
  profile,
  age,  
  location,
  envelope,
  plus,
  add_card,
  visa,
  cloud,
  meetingcard1,
  meetingcard2,
  meetingcard3,
  HomeBanner,
  symapathiq_logo,
  notfound,
  therapy_proc,
  HomeBody,
  HomeCard_1,
  HomeCard_2,
  HomeCard_3,
  HomeCommunity,
  HomeWhy_1,
  HomeWhy_2,
  HomeWhy_3,
  HomeWhy_4,
  HomeWhy_5,
  HomeWhy_6,
  Logo,
  NavAuthor,
  News_1,
  News_2,
  News_3,
  Portal,
  Product_1,
  Product_2,
  Product_3,
  Product_4,
  Product_5,
  Product_6,
  QuickTestBackground,
  SignUpBG,
  Specialist_1,
  Specialist_2,
  Specialist_3,
  Specialist_4,
  Testimonial_1,
  Therapy_1,
  Therapy_2,
  Therapy_3,
  Therapy_4,
  VideoPoster,
  Basket,
  loginIcon,
  loginBackground,
  Froum_1,
  Forum_2,
  Forum_3,
  Forum_4,
  Forum_5,
  Forum_6,
  Forum_7,
  Forum_8,
  Forum_9,
  Fashion_1,
  Fashion_2,
  Fashion_3,
  Fashion_4,
  fcard_1,
  fcard_2,
  Addiction_1,
  Addiction_2,
  Addiction_3,
  Addi_p1,
  Addi_p2,
  Myappointment_1,
  Course_1,
  Course_2,
  Map_1,
  fcard_3,
  fcard_4,
  fcard_5,
  fcard_6,
  fcard_7,
  fcard_8,
  Market_1,
  card_1,
  Market_3,
  Article_1,
  fcard_9

};
