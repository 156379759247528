import React from "react";
import { createRoot } from "react-dom/client";
import "./assets/css/style.scss";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

import App from "./App";

createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
