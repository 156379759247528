import React from "react";
import AdminLayout from "../../layouts/AdminLayout";
import ButtonStyled from "../../components/ButtonStyled";
import Images from "../../constants/Images";
import { Card, Container, Image, Row, Col } from "react-bootstrap";
import {BsArrowUpRight} from "react-icons/bs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
function Dashboard() {
  return (
    <AdminLayout>
      <div className=" dashboard_1" style={{minHeight:'100vh'}}>
        <Container>
          <div className="d-flex justify-content-between pt-4">
            {" "}
            <div className="Irina-text">
              {" "}
              <h1 className="heyIrina">
                <span>Hey!</span> Irina Duisimbekova
              </h1>
              <span className="ctext-lorem">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore
              </span>
            </div>
          </div>
          <div className="d-flex justify-content-between  mt-5 trending-div">
            <h3 className="Head">&nbsp;&nbsp;Activity</h3>
            <div className="btn-detaile-div">
            {/* <ButtonStyled title="Today" className="btn-detaile" isDown={true} /> */}
            <button class="btn-detaile">
              Today 
              <i class="fas fa-chevron-down button-icon"></i>
            </button>
            </div>
            
          </div>
          <Container className="d-flex mt-4 gap-1 ActivityYourFriend">
            <Col lg={4}>
              <Card className="dashboard-card rounded-4 " style={{ border:'none'}}>
                <Card.Body className="d-flex">
                  <div>
                    <Image
                      src={Images.fcard_9}
                      alt="img"
                      width={80}
                      className="my-3 mr-1 activity-pic"
                      style={{ marginRight: "5px", marginLeft: "-5px" }}
                    />
                  </div>
                  <div className="activity-info">
                    <h4 style={{margin:'0'}}> Your Friends</h4>
                    <p className="new">New +3</p>
                    <div className="d-flex">
                      <span className="ctext"> SInce Last Moth</span>
                      <h4 className="activity-count">02</h4>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={4}>
              <Card className="dashboard-card rounded-4 " style={{ border:'none'}}>
                <Card.Body className="d-flex">
                  <div>
                    <Image
                      src={Images.fcard_9}
                      alt="img"
                      width={80}
                      className="my-3 mr-1 activity-pic"
                      style={{ marginRight: "5px", marginLeft: "-5px" }}
                    />
                  </div>
                  <div className="activity-info ">
                    <h4 style={{margin:'0'}}> Courses</h4>
                    <p className="new" style={{ color: "red" }}>
                      Lower 34%
                    </p>
                    <div className="d-flex">
                      <span className="ctext"> Since Last Moth</span>
                      <h4 className="activity-count">10</h4>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={4}>
              <Card className="dashboard-card rounded-4 " style={{ border:'none'}}>
                <Card.Body className="d-flex">
                  <div>
                    <Image
                      src={Images.fcard_9}
                      alt="img"
                      width={80}
                      className="my-3 activity-pic"
                      style={{ marginRight: "5px", marginLeft: "-5px" }}
                    />
                  </div>
                  <div className="activity-info">
                    <h4 style={{margin:'0'}}>Appointments</h4>
                    <p className="new">New +3</p>
                    <div className="d-flex">
                      <span className="ctext"> SInce Last Moth</span>
                      <h4 className="activity-count">05</h4>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Container>
          <Container className="">
            <div className="d-flex justify-content-between  mt-5 trending-div">
              <h3 className="Head">{` Your Mood`}</h3>
                <div className="btn-detaile-div">
            {/* <ButtonStyled title="Today" className="btn-detaile" isDown={true} /> */}
            <button class="btn-detaile">
            Today 
              <i class="fas fa-chevron-down button-icon"></i>
       
            </button>
            </div>
            </div>
            <Card className="mb-5 mt-5 rounded-4 chart_1 " style={{ border:'none',boxShadow: '0 2px 13px rgba(0, 0, 0, 0.101)'}}>
              <Card.Body>
                <div className=" mood-chart border-bottom ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                    style={{
                      position: "relative",
                      left: "36%",
                      top: "38%",
                      zIndex: 2,
                    }}
                  >
                    <path
                      d="M9.92317 18.7053C14.8923 18.7053 18.9205 14.6758 18.9205 9.70518C18.9205 4.73456 14.8923 0.705078 9.92317 0.705078C4.95407 0.705078 0.925812 4.73456 0.925812 9.70518C0.925812 14.6758 4.95407 18.7053 9.92317 18.7053Z"
                      fill="#FFD18D"
                    />
                    <path
                      d="M6.92368 13.2261C3.18852 10.6873 3.11661 5.97359 4.9017 2.22461C3.76237 2.98986 2.81314 4.00567 2.12663 5.19433C1.44013 6.38298 1.03455 7.71297 0.940918 9.08253C0.84729 10.4521 1.0681 11.8249 1.58645 13.096C2.1048 14.3671 2.90695 15.5027 3.93153 16.416C4.95611 17.3292 6.17597 17.996 7.49776 18.3651C8.81954 18.7343 10.2082 18.7961 11.5576 18.5458C12.9069 18.2956 14.1812 17.7399 15.2828 16.9212C16.3844 16.1026 17.2842 15.0428 17.9134 13.8228C16.1993 14.7282 14.2716 15.1509 12.336 15.0459C10.4004 14.9408 8.52974 14.3118 6.92368 13.2261Z"
                      fill="#FFC570"
                    />
                    <path
                      d="M15.9544 9.5486C15.9552 10.0116 15.8188 10.4644 15.5622 10.8498C15.3057 11.2351 14.9406 11.5357 14.5133 11.7134C14.0859 11.8912 13.6154 11.9381 13.1614 11.8483C12.7074 11.7585 12.2902 11.536 11.9626 11.2089C11.635 10.8818 11.4118 10.4649 11.3212 10.0109C11.2306 9.55687 11.2767 9.08617 11.4536 8.65836C11.6305 8.23054 11.9303 7.86484 12.3151 7.60754C12.6999 7.35023 13.1523 7.21289 13.6152 7.21289C14.2348 7.21289 14.8292 7.45884 15.2678 7.89675C15.7063 8.33466 15.9533 8.92874 15.9544 9.5486Z"
                      fill="url(#paint0_linear_1917_16110)"
                    />
                    <path
                      d="M13.2472 10.8221C13.7798 10.8221 14.2116 10.3901 14.2116 9.85733C14.2116 9.32451 13.7798 8.89258 13.2472 8.89258C12.7145 8.89258 12.2827 9.32451 12.2827 9.85733C12.2827 10.3901 12.7145 10.8221 13.2472 10.8221Z"
                      fill="white"
                    />
                    <path
                      d="M14.5805 8.70896C14.5805 8.76921 14.5627 8.82811 14.5292 8.87821C14.4957 8.92831 14.4482 8.96736 14.3925 8.99042C14.3369 9.01348 14.2756 9.01951 14.2166 9.00776C14.1575 8.996 14.1032 8.96699 14.0606 8.92438C14.018 8.88177 13.989 8.82749 13.9773 8.76839C13.9655 8.70929 13.9715 8.64804 13.9946 8.59237C14.0176 8.5367 14.0567 8.48912 14.1068 8.45564C14.1568 8.42216 14.2157 8.4043 14.276 8.4043C14.3567 8.4043 14.4342 8.4364 14.4913 8.49353C14.5484 8.55066 14.5805 8.62815 14.5805 8.70896Z"
                      fill="white"
                    />
                    <path
                      d="M14.7912 10.6963C14.7912 10.749 14.7756 10.8005 14.7463 10.8444C14.717 10.8882 14.6754 10.9224 14.6267 10.9425C14.578 10.9627 14.5244 10.968 14.4727 10.9577C14.421 10.9474 14.3735 10.922 14.3363 10.8848C14.299 10.8475 14.2736 10.8 14.2633 10.7483C14.253 10.6966 14.2583 10.643 14.2785 10.5942C14.2987 10.5455 14.3328 10.5039 14.3766 10.4746C14.4205 10.4453 14.472 10.4297 14.5247 10.4297C14.5954 10.4297 14.6632 10.4578 14.7131 10.5078C14.7631 10.5578 14.7912 10.6256 14.7912 10.6963Z"
                      fill="white"
                    />
                    <path
                      d="M16.5259 8.55779L16.3863 8.42239C16.2653 8.29492 16.1367 8.17481 16.0014 8.06272C15.5743 7.67877 15.0604 7.40432 14.5039 7.263C14.1967 7.19871 13.8814 7.18159 13.5691 7.21222C13.3131 7.24283 13.0618 7.30391 12.8203 7.39417C12.5927 7.47417 12.3765 7.58371 12.1774 7.71999C12.3631 7.56298 12.5713 7.43462 12.795 7.33916C13.0375 7.23057 13.2937 7.15513 13.5564 7.1149C13.8802 7.0698 14.2094 7.08125 14.5293 7.14875C15.1014 7.28833 15.6249 7.58085 16.0437 7.99502C16.2086 8.14735 16.3144 8.29968 16.399 8.38854L16.5259 8.55779Z"
                      fill="url(#paint1_linear_1917_16110)"
                    />
                    <path
                      d="M16.6783 6.99221L16.4626 6.79757C16.3855 6.71647 16.3036 6.64017 16.2172 6.56907L15.8661 6.3025C15.1748 5.8389 14.3544 5.60634 13.5227 5.63817C12.5582 5.68472 12.076 5.84551 12.0295 5.73549C12.0083 5.68895 12.1056 5.58317 12.3467 5.46892C12.7144 5.30971 13.1094 5.22348 13.51 5.21504C14.4294 5.18283 15.3246 5.51423 16.0015 6.13747L16.3145 6.47598C16.3871 6.56396 16.4549 6.65577 16.5176 6.75102L16.6783 6.99221Z"
                      fill="url(#paint2_linear_1917_16110)"
                    />
                    <path
                      d="M3.81882 9.5486C3.81798 10.0118 3.95458 10.4647 4.21129 10.8502C4.46801 11.2356 4.8333 11.5362 5.26091 11.7138C5.68852 11.8915 6.15921 11.9382 6.61337 11.848C7.06753 11.7579 7.48473 11.5349 7.81213 11.2074C8.13953 10.8799 8.36241 10.4626 8.45254 10.0083C8.54266 9.554 8.49598 9.08316 8.3184 8.65542C8.14083 8.22768 7.84035 7.86228 7.45502 7.60548C7.06968 7.34869 6.61684 7.21206 6.15382 7.21289C5.53454 7.21289 4.94063 7.45898 4.50273 7.89701C4.06483 8.33504 3.81882 8.92913 3.81882 9.5486Z"
                      fill="url(#paint3_linear_1917_16110)"
                    />
                    <path
                      d="M5.55726 9.85342C5.55642 10.0452 5.61252 10.233 5.71845 10.3929C5.82438 10.5528 5.97538 10.6777 6.15231 10.7517C6.32924 10.8256 6.52414 10.8454 6.71232 10.8085C6.90051 10.7716 7.07351 10.6797 7.2094 10.5443C7.3453 10.409 7.43798 10.2363 7.4757 10.0483C7.51342 9.86018 7.49449 9.66513 7.42129 9.48782C7.3481 9.31052 7.22395 9.15894 7.06456 9.05227C6.90517 8.94561 6.71771 8.88867 6.52595 8.88867C6.26977 8.88867 6.02402 8.99018 5.84248 9.17098C5.66093 9.35179 5.55838 9.59717 5.55726 9.85342Z"
                      fill="white"
                    />
                    <path
                      d="M5.18994 8.70896C5.18994 8.76921 5.2078 8.82811 5.24127 8.87821C5.27473 8.92831 5.3223 8.96736 5.37795 8.99042C5.43361 9.01348 5.49484 9.01951 5.55392 9.00776C5.613 8.996 5.66727 8.96699 5.70986 8.92438C5.75246 8.88177 5.78147 8.82749 5.79322 8.76839C5.80497 8.70929 5.79894 8.64804 5.77589 8.59237C5.75284 8.5367 5.7138 8.48912 5.66371 8.45564C5.61363 8.42216 5.55474 8.4043 5.4945 8.4043C5.41373 8.4043 5.33626 8.4364 5.27914 8.49353C5.22203 8.55066 5.18994 8.62815 5.18994 8.70896Z"
                      fill="white"
                    />
                    <path
                      d="M4.96021 10.6963C4.96021 10.749 4.97583 10.8005 5.00512 10.8444C5.0344 10.8882 5.07602 10.9224 5.12472 10.9425C5.17341 10.9627 5.227 10.968 5.27869 10.9577C5.33039 10.9474 5.37787 10.922 5.41514 10.8848C5.45241 10.8475 5.47779 10.8 5.48807 10.7483C5.49836 10.6966 5.49308 10.643 5.47291 10.5942C5.45274 10.5455 5.41858 10.5039 5.37475 10.4746C5.33093 10.4453 5.27941 10.4297 5.2267 10.4297C5.15602 10.4297 5.08823 10.4578 5.03826 10.5078C4.98828 10.5578 4.96021 10.6256 4.96021 10.6963Z"
                      fill="white"
                    />
                    <path
                      d="M3.24811 8.55792L3.38347 8.42251C3.50449 8.29504 3.63303 8.17494 3.76841 8.06285C4.19645 7.67801 4.71192 7.40349 5.27008 7.26312C5.57729 7.19863 5.89255 7.18151 6.20493 7.21235C6.46067 7.24395 6.71191 7.30501 6.95365 7.39429C7.17975 7.47481 7.39446 7.58433 7.59239 7.72011C7.40665 7.5631 7.19848 7.43474 6.9748 7.33929C6.73355 7.23105 6.47889 7.15563 6.21762 7.11502C5.89381 7.06975 5.56459 7.0812 5.2447 7.14887C4.67217 7.28745 4.14846 7.58011 3.73034 7.99515C3.60317 8.11944 3.48313 8.25082 3.37078 8.38866L3.24811 8.55792Z"
                      fill="url(#paint4_linear_1917_16110)"
                    />
                    <path
                      d="M3.09152 6.99229L3.30726 6.79765C3.38588 6.71664 3.46922 6.64034 3.55683 6.56916L3.9037 6.30258C4.59753 5.83753 5.42109 5.60491 6.25562 5.63826C7.22007 5.6848 7.69807 5.84559 7.7446 5.73558C7.7446 5.68903 7.66846 5.58325 7.42735 5.469C7.06107 5.31013 6.66744 5.2239 6.26831 5.21512C5.34748 5.18225 4.4508 5.51367 3.77256 6.13756L3.45954 6.47607C3.38699 6.56404 3.31921 6.65586 3.2565 6.75111L3.09152 6.99229Z"
                      fill="url(#paint5_linear_1917_16110)"
                    />
                    <path
                      d="M11.4078 12.7422C11.4078 13.8381 10.7691 15.116 9.97804 15.116C9.18702 15.116 8.54828 13.8466 8.54828 12.7422H11.4078Z"
                      fill="#FF5757"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_1917_16110"
                        x1="12.274"
                        y1="8.53106"
                        x2="15.1987"
                        y2="10.7765"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#3A6FFD" />
                        <stop offset="1" stop-color="#D63ACA" />
                      </linearGradient>
                      <linearGradient
                        id="paint1_linear_1917_16110"
                        x1="13.1051"
                        y1="7.50191"
                        x2="13.8062"
                        y2="9.09505"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#3A6FFD" />
                        <stop offset="1" stop-color="#D63ACA" />
                      </linearGradient>
                      <linearGradient
                        id="paint2_linear_1917_16110"
                        x1="13.019"
                        y1="5.71406"
                        x2="13.9377"
                        y2="7.55859"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#3A6FFD" />
                        <stop offset="1" stop-color="#D63ACA" />
                      </linearGradient>
                      <linearGradient
                        id="paint3_linear_1917_16110"
                        x1="4.81689"
                        y1="8.53106"
                        x2="7.74159"
                        y2="10.7765"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#3A6FFD" />
                        <stop offset="1" stop-color="#D63ACA" />
                      </linearGradient>
                      <linearGradient
                        id="paint4_linear_1917_16110"
                        x1="4.17489"
                        y1="7.50195"
                        x2="4.87665"
                        y2="9.09468"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#3A6FFD" />
                        <stop offset="1" stop-color="#D63ACA" />
                      </linearGradient>
                      <linearGradient
                        id="paint5_linear_1917_16110"
                        x1="4.08418"
                        y1="5.71409"
                        x2="5.00277"
                        y2="7.55889"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#3A6FFD" />
                        <stop offset="1" stop-color="#D63ACA" />
                      </linearGradient>
                    </defs>
                  </svg>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="99%"
                    height="297"
                    viewBox="0 0 1032 297"
                    fill="none"
                  >
                    <path
                      d="M-581.528 -32.4098L-710.005 -46.5354V281.037H1121.89V34.0129L991.482 79.3693L991.482 79.3694L991.47 79.3733L864.171 120.743L863.986 120.803L863.802 120.746L730.749 79.4135L601.618 93.9254L601.552 93.9329L601.486 93.9259L464.453 79.4059L335.253 90.7688L335.166 90.7765L335.08 90.7592L205.823 64.8237L205.775 64.8142L205.73 64.7974L72.746 15.3387L-54.3583 53.1563L-54.4243 53.1759L-54.493 53.1803L-185.709 61.5575L-185.82 61.5646L-185.927 61.532L-317.027 21.3019L-446.164 33.7626L-446.335 33.7791L-446.49 33.7034L-581.528 -32.4098Z"
                      stroke="url(#paint0_linear_1917_16105)"
                      stroke-width="1.21769"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_1917_16105"
                        x1="731.434"
                        y1="45.4142"
                        x2="637.187"
                        y2="448.879"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#3A6FFD" />
                        <stop offset="1" stop-color="#D63ACA" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30%"
                    height="30%"
                    viewBox="0 0 267 159"
                    fill="none"
                    style={{ position: "relative", left: "25%", top: "-75%" }}
                  >
                    <g filter="url(#filter0_d_1917_16111)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M53.5292 25.002C52.1841 25.002 51.0938 26.0923 51.0938 27.4374V39.7412L43.5087 47.2765C43.0293 47.7527 43.0293 48.5281 43.5087 49.0043L51.0938 56.5396V95.6291C51.0938 96.9741 52.1841 98.0645 53.5292 98.0645H221.571C222.916 98.0645 224.006 96.9741 224.006 95.6291V27.4374C224.006 26.0923 222.916 25.002 221.571 25.002H53.5292Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_1917_16111"
                        x="0.529648"
                        y="0.64794"
                        width="266.096"
                        height="158.302"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood
                          flood-opacity="0"
                          result="BackgroundImageFix"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="18.2655" />
                        <feGaussianBlur stdDeviation="21.3098" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0.597048 0 0 0 0 0.663834 0 0 0 0 0.735757 0 0 0 0.2 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_1917_16111"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_1917_16111"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                </div>
                <div className="w-100">
                  <span className="date">Jan 15</span>{" "}
                  <span className="date">Jan 16</span>{" "}
                  <span className="date">Jan 17</span>{" "}
                  <span className="date">Jan 18</span>{" "}
                  <span className="date">Jan 19</span>{" "}
                  <span className="date">Jan 20</span>
                  <span className="date">Jan 21</span>
                </div>
              </Card.Body>
            </Card>
          </Container>
          <div className="d-flex justify-content-between trending-div">
            <h3 className="Head">&nbsp;&nbsp; Recommend For You</h3>
            <div className="btn-detaile-div">
            {/* <ButtonStyled title="Today" className="btn-detaile" isDown={true} /> */}
            <button class="btn-detaile">
              See all
              <i class="fas fa-arrow-right button-icon1"></i>
              
            </button>
            </div>  
          </div>
          <Container className="d-flex gap-4 RecommendContainer" >
            <Card className="specilist-1 rounded-4" style={{ border:'none'}}>
              <Card.Body>
                <div className="d-flex" >
                  {" "}
                  <Card.Img
                    src={Images.fcard_2}
                    className=" specialist-img1 "
                    alt=""
                  />
                  <div className="star">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        d="M2.9125 11L4.075 7.2L1 5H4.8L6 1L7.2 5H11L7.925 7.2L9.0875 11L6 8.65L2.9125 11Z"
                        fill="white"
                      />
                    </svg>
                    <span className="star-rating"> 4.5 </span>
                  </div>{" "}
                </div>
                <h4> Nelson Jhons</h4>
                <span className="ctext-1"> Mental Therapist</span> <br></br>
                <span className="ctext-1">Houston, USA 5+ Years of Exp</span>
                <div className="tag-div">
                  <div className="tag1">
                    <span className="tag-text">Therapiest</span>
                  </div>
                  <div className="tag1">
                    <span className="tag-text">Good Surgeon</span>
                  </div>
                  <div className="tag1">
                    <span className="tag-text">Mental Therapist</span>
                  </div>
                  <div className="tag1">
                    <span className="tag-text">+9</span>
                  </div>
                </div>
                <Row className="mt-3">
                  <Col>
                    <Row>
                      <span className="specialist-price">Price</span>
                    </Row>
                    <Row>
                      <h5 className="price_for_specialist"> $ 120.00</h5>
                    </Row>
                  </Col>
                  <Col
                    className="specilistheader"
                    style={{ marginRight: "10px" }}
                  >
                    <Link style={{textDecoration:'none'}} to="/specialist_profile_user">
                    <span className="booking"> Book Appointment</span></Link>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card className="specilist-1 rounded-4" style={{ border:'none'}}>
              <Card.Body>
                <div className="d-flex">
                  {" "}
                  <Card.Img
                    src={Images.fcard_2}
                    className=" specialist-img1 "
                    alt=""
                  />
                  <div className="star">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        d="M2.9125 11L4.075 7.2L1 5H4.8L6 1L7.2 5H11L7.925 7.2L9.0875 11L6 8.65L2.9125 11Z"
                        fill="white"
                      />
                    </svg>
                    <span className="star-rating"> 4.5 </span>
                  </div>{" "}
                </div>
                <h4> Nelson Jhons</h4>
                <span className="ctext-1"> Mental Therapist</span> <br></br>
                <span className="ctext-1">Houston, USA 5+ Years of Exp</span>
                <div className="tag-div">
                  <div className="tag1">
                    <span className="tag-text">Therapiest</span>
                  </div>
                  <div className="tag1">
                    <span className="tag-text">Good Surgeon</span>
                  </div>
                  <div className="tag1">
                    <span className="tag-text">Mental Therapist</span>
                  </div>
                  <div className="tag1">
                    <span className="tag-text">+9</span>
                  </div>
                </div>
                <Row className="mt-3">
                  <Col>
                    <Row>
                      <span className="specialist-price">Price</span>
                    </Row>
                    <Row>
                      <h5 className="price_for_specialist"> $ 120.00</h5>
                    </Row>
                  </Col>
                  <Col
                    className="specilistheader"
                    style={{ marginRight: "10px" }}
                  >
                    <Link style={{textDecoration:'none'}} to="/specialist_profile_user">
                    <span className="booking"> Book Appointment</span></Link>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card className="specilist-1 rounded-4" style={{ border:'none'}}>
              <Card.Body>
                <div className="d-flex">
                  {" "}
                  <Card.Img
                    src={Images.fcard_2}
                    className=" specialist-img1 "
                    alt=""
                  />
                  <div className="star">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        d="M2.9125 11L4.075 7.2L1 5H4.8L6 1L7.2 5H11L7.925 7.2L9.0875 11L6 8.65L2.9125 11Z"
                        fill="white"
                      />
                    </svg>
                    <span className="star-rating"> 4.5 </span>
                  </div>{" "}
                </div>
                <h4> Nelson Jhons</h4>
                <span className="ctext-1"> Mental Therapist</span> <br></br>
                <span className="ctext-1">Houston, USA 5+ Years of Exp</span>
                <div className="tag-div">
                  <div className="tag1">
                    <span className="tag-text">Therapiest</span>
                  </div>
                  <div className="tag1">
                    <span className="tag-text">Good Surgeon</span>
                  </div>
                  <div className="tag1">
                    <span className="tag-text">Mental Therapist</span>
                  </div>
                  <div className="tag1">
                    <span className="tag-text">+9</span>
                  </div>
                </div>
                <Row className="mt-3">
                  <Col>
                    <Row>
                      <span className="specialist-price">Price</span>
                    </Row>
                    <Row>
                      <h5 className="price_for_specialist"> $ 120.00</h5>
                    </Row>
                  </Col>
                  <Col
                    className="specilistheader"
                    style={{ marginRight: "10px" }}
                  >
                    <Link style={{textDecoration:'none'}} to="/specialist_profile_user">
                    <span className="booking"> Book Appointment</span></Link>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Container>
          <div className="d-flex justify-content-between mb-2 mt-3 trending-div">
            <h3 className="Head">&nbsp;&nbsp; Trending Courses</h3>
            <div className="btn-detaile-div">
            {/* <ButtonStyled title="Today" className="btn-detaile" isDown={true} /> */}
            <button class="btn-detaile">
              See all
              <i class="fas fa-arrow-right button-icon1"></i>
              
            </button>
            </div>
          </div>
          <Container className="d-flex flex-md-col gap-3 mb-5 trending-courses-container">
            <Col md={12} lg={6} >
              <Card className="rounded-4 trending-card" style={{ border:'none'}}>
              <Row style={{width:'100%'}}>
              <div className="heart-icon-div">
                      <p className="heart-time">2h 4m 32s</p>
                      <div className={"heart-icon"}>
                          <FontAwesomeIcon
                            icon={faHeart}
                            style={{
                              color: "white",
                              backgroundColor: "rgba(19, 12, 26, 0.15)",
                              width: "15px",
                              height: "15px",
                              padding: "10px",
                              borderRadius: "50%",
                            }}
                          />
                        </div>
                  </div>
                </Row>
                <Card.Img
                  src={Images.Course_1}
                  className=" specialist-img1 "
                  alt=""
                />
                <Card.Body>
                  <h3>Managing Stress & Anxiety</h3>
                  <span style={{color:"#93989A"}}>Mental Therapy</span>
                  <Row className="mt-3">
                    <Col>
                      <Row>
                        <span className="specialist-price">Price</span>
                      </Row>
                      <Row>
                        <h5 className="price_for_specialist"> $ 120.00</h5>
                      </Row>
                    </Col>
                    <Col sm={3} className="specilistheader" >
                      <span className="booking"> Join Course</span>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col  md={12} lg={6}>
              <Card className="rounded-4 trending-card" style={{ border:'none'}}>
              <Row style={{width:'100%'}}>
              <div className="heart-icon-div">
                      <p className="heart-time">2h 4m 32s</p>
                      <div className={"heart-icon"}>
                          <FontAwesomeIcon
                            icon={faHeart}
                            style={{
                              color: "white",
                              backgroundColor: "rgba(19, 12, 26, 0.15)",
                              width: "15px",
                              height: "15px",
                              padding: "10px",
                              borderRadius: "50%",
                            }}
                          />
                        </div>
                  </div>
                </Row>
                <Card.Img
                  src={Images.Course_2}
                  className=" specialist-img1 "
                  alt=""
                />
                <Card.Body>
                  <h3>Managing Stress & Anxiety</h3>
                  <span style={{color:"#93989A"}}>Mental Therapy</span>
                  <Row className="mt-3">
                    <Col>
                      <Row>
                        <span className="specialist-price">Price</span>
                      </Row>
                      <Row>
                        <h5 className="price_for_specialist"> $ 120.00</h5>
                      </Row>
                    </Col>
                    <Col sm={3} className="specilistheader">
                      <span className="booking"> Join Course</span>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Container>
        </Container>
      </div>
    </AdminLayout>
  );
}

export default Dashboard;
