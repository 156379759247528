import React from "react";
import Images from "../../constants/Images";

export const Order = () => {
  return (
    <div className="order">
      <div className="div">
        <div className="overlap">
        <svg xmlns="http://www.w3.org/2000/svg" width="193" height="41" viewBox="0 0 193 41" fill="none" className="frame">
  <g clip-path="url(#clip0_2877_7801)">
    <path d="M185.527 30.2649C191.711 24.8327 196.478 15.2617 189.65 6.72545C190.552 5.30274 191.969 1.68128 189.263 0.387909C186.815 -0.776129 185.14 1.29327 184.625 2.84532C183.337 2.0693 179.987 0.517247 176.379 0.775922C172.772 0.517247 169.422 2.0693 168.134 2.84532C167.618 1.29327 165.943 -0.776129 163.496 0.387909C160.79 1.68128 162.207 5.30274 163.109 6.72545C153.06 19.4005 168.52 34.6624 176.379 34.533C177.024 34.533 177.797 34.4037 178.57 34.2743C180.373 36.3437 182.564 38.2838 184.754 39.5772C189.65 42.4226 192.484 39.3185 191.582 35.9557C191.196 34.145 187.846 31.6876 185.527 30.2649Z" fill="url(#paint0_linear_2877_7801)"/>
    <path d="M188.361 35.5677C188.646 35.5677 188.876 35.336 188.876 35.0503C188.876 34.7646 188.646 34.533 188.361 34.533C188.076 34.533 187.846 34.7646 187.846 35.0503C187.846 35.336 188.076 35.5677 188.361 35.5677Z" fill="white"/>
    <path d="M188.748 37.6372C189.032 37.6372 189.263 37.4056 189.263 37.1199C189.263 36.8342 189.032 36.6025 188.748 36.6025C188.463 36.6025 188.232 36.8342 188.232 37.1199C188.232 37.4056 188.463 37.6372 188.748 37.6372Z" fill="white"/>
    <path d="M186.558 37.3784C186.843 37.3784 187.074 37.1468 187.074 36.8611C187.074 36.5754 186.843 36.3438 186.558 36.3438C186.274 36.3438 186.043 36.5754 186.043 36.8611C186.043 37.1468 186.274 37.3784 186.558 37.3784Z" fill="white"/>
    <path d="M182.694 17.0724C183.982 22.7632 178.957 24.1859 176.38 24.1859C172.902 24.1859 170.067 22.2459 169.81 18.3657C169.681 14.4856 173.675 10.0881 176.38 10.0881C179.086 10.2175 182.436 13.3216 182.694 17.0724Z" fill="white"/>
    <path d="M180.374 18.4951C180.889 20.0471 178.312 20.4351 177.281 22.7632C177.281 21.8578 173.932 19.7884 173.932 18.7537C173.932 17.5897 176.251 17.331 177.281 17.331C178.183 17.2017 180.245 17.4604 180.374 18.4951Z" fill="url(#paint1_linear_2877_7801)"/>
    <path d="M17.9085 19.7885C17.2644 19.1419 16.4913 18.6245 15.7183 18.1072C14.9453 17.5898 14.0434 17.2018 13.2704 16.9431C11.982 16.4258 10.8224 16.0378 9.92056 15.5204C9.01869 15.003 8.3745 14.615 7.98798 14.0977C7.60147 13.5803 7.34379 13.063 7.34379 12.4163C7.34379 11.7696 7.60147 11.1229 8.11682 10.7349C8.63218 10.2176 9.40521 9.95889 10.5648 9.95889C11.3378 9.95889 12.1108 10.0882 12.755 10.4762C13.3992 10.7349 13.9146 11.2523 14.4299 11.7696C15.3318 12.675 16.749 12.9336 17.9085 12.287L19.97 11.1229C19.4546 10.2176 18.8104 9.3122 18.0374 8.40684C17.2644 7.50147 16.2336 6.85479 14.9453 6.2081C13.6569 5.56141 12.2397 5.30273 10.4359 5.30273C8.76101 5.30273 7.34379 5.56141 5.92657 6.2081C4.50935 6.85479 3.47864 7.63081 2.70561 8.79485C2.06141 9.82955 1.6749 11.1229 1.6749 12.675C1.6749 13.9683 1.93258 15.0031 2.44793 15.9084C2.96328 16.8138 3.47864 17.5898 4.25167 18.2365C5.0247 18.8832 5.79773 19.4005 6.57076 19.7885C7.34379 20.1766 8.11682 20.4352 8.76101 20.6939C9.92056 21.2113 10.9513 21.5993 11.8531 21.9873C12.6262 22.3753 13.2704 22.8926 13.6569 23.41C14.0434 23.9273 14.3011 24.574 14.3011 25.4794C14.3011 26.3848 13.9146 27.1608 13.2704 27.8075C12.6262 28.1955 11.7243 28.4542 10.5648 28.4542C9.66288 28.4542 8.76101 28.1955 7.98798 27.8075C7.21495 27.4195 6.44192 26.7728 5.79773 26.1261C4.63818 24.8327 2.57677 24.4447 1.15955 25.4794L0 26.3848C0.644192 27.6781 1.41722 28.8422 2.44793 29.8769C3.47864 30.9116 4.76702 31.8169 6.18425 32.4636C7.60147 33.1103 9.14753 33.369 10.6936 33.369C11.8531 33.369 13.0127 33.2396 14.1722 32.8516C15.3318 32.4636 16.2336 31.9463 17.1355 31.2996C18.0374 30.6529 18.6816 29.7475 19.1969 28.7128C19.7123 27.6781 19.97 26.5141 19.97 25.2207C19.97 24.0567 19.8411 23.022 19.4546 22.1166C19.0681 21.0819 18.5527 20.4352 17.9085 19.7885Z" fill="#1F1E28"/>
    <path d="M29.8913 25.8673L25.5108 15.0029H19.9707L27.1857 30.7821L22.8052 40.9998H25.8973C27.0568 40.9998 28.0875 40.3531 28.6029 39.189L39.2965 15.0029H34.1429L29.8913 25.8673Z" fill="#1F1E28"/>
    <path d="M64.9349 15.391C64.1618 14.8737 63.1311 14.615 61.7139 14.615C60.5544 14.615 59.3948 14.8737 58.4929 15.391C57.5911 15.9084 56.818 16.6844 56.1738 17.5898C55.7873 16.5551 55.1431 15.779 54.3701 15.391C53.4682 14.8737 52.4375 14.615 51.1491 14.615C49.9896 14.615 48.9589 14.8737 48.1859 15.391C47.284 15.9084 46.6398 16.6844 46.1244 17.5898V15.003H41.3574V32.5929H46.1244V21.8579C46.1244 21.0819 46.2533 20.4352 46.511 19.9178C46.7686 19.4005 47.1552 19.0125 47.5417 18.7538C48.057 18.4951 48.5724 18.3658 49.0877 18.3658C49.9896 18.3658 50.6338 18.6245 51.0203 19.2711C51.4068 19.9178 51.6645 20.8232 51.6645 21.9872V32.7222H56.6892V21.9872C56.6892 21.2112 56.818 20.5645 57.0757 20.0472C57.3334 19.5298 57.7199 19.1418 58.1064 18.8831C58.6218 18.6245 59.1371 18.4951 59.6525 18.4951C60.5544 18.4951 61.1985 18.7538 61.5851 19.4005C61.9716 20.0472 62.2292 20.9525 62.2292 22.1166V32.8516H67.1251V21.5992C67.1251 20.1765 66.8674 19.0125 66.4809 17.9778C66.3521 16.6844 65.7079 15.9084 64.9349 15.391Z" fill="#1F1E28"/>
    <path d="M85.1621 15.5203C83.8737 14.7443 82.4565 14.4856 81.0392 14.4856C79.4932 14.4856 78.2048 14.8736 77.1741 15.6496C76.2722 16.167 75.628 16.943 75.1127 17.9777V15.1323H70.2168V41.1291H75.1127V29.7474C75.628 30.6528 76.401 31.4288 77.1741 32.0755C78.3336 32.8515 79.622 33.2395 81.0392 33.2395C82.4565 33.2395 83.8737 32.8515 85.1621 32.2048C86.4504 31.4288 87.4811 30.3941 88.2542 28.9714C89.0272 27.5487 89.4137 25.8673 89.4137 23.9272C89.4137 21.8578 89.0272 20.1764 88.2542 18.8831C87.4811 17.331 86.4504 16.167 85.1621 15.5203ZM83.616 26.3846C83.2295 27.1607 82.5853 27.678 81.9411 28.066C81.1681 28.4541 80.395 28.5834 79.622 28.5834C78.849 28.5834 78.2048 28.4541 77.4317 28.066C76.7876 27.678 76.1434 27.1607 75.7568 26.3846C75.3703 25.6086 75.1127 24.7033 75.1127 23.6686C75.1127 22.6339 75.3703 21.7285 75.7568 20.9525C76.1434 20.1764 76.7876 19.6591 77.4317 19.2711C78.0759 18.8831 78.849 18.7537 79.622 18.7537C80.5239 18.7537 81.2969 18.8831 81.9411 19.2711C82.7141 19.6591 83.2295 20.1764 83.616 20.9525C84.0025 21.7285 84.2602 22.6339 84.2602 23.6686C84.2602 24.7033 84.1314 25.738 83.616 26.3846Z" fill="#1F1E28"/>
    <path d="M104.745 15.1323C103.586 14.7443 102.297 14.4856 100.751 14.4856C99.0762 14.4856 97.659 14.7443 96.3706 15.1323C95.0822 15.5203 93.9227 16.0376 93.0208 16.555L94.8245 19.9178C95.0822 19.7884 95.4687 19.5298 95.9841 19.2711C96.4994 19.0124 97.1436 18.7537 97.9167 18.6244C98.6897 18.4951 99.4627 18.3657 100.236 18.3657C100.751 18.3657 101.266 18.3657 101.653 18.4951C102.04 18.6244 102.426 18.7537 102.813 19.0124C103.07 19.2711 103.328 19.5298 103.457 19.9178C103.586 20.3058 103.714 20.6938 103.714 21.2111V23.0219C103.07 22.6339 102.426 22.3752 101.524 22.1165C100.622 21.8578 99.7204 21.7285 98.5609 21.7285C96.3706 21.7285 94.6957 22.2458 93.5362 23.2805C92.3766 24.3152 91.7324 25.6086 91.7324 27.29C91.7324 28.4541 91.9901 29.4888 92.6343 30.3941C93.1497 31.1701 93.9227 31.8168 94.9534 32.3342C95.8553 32.7222 96.886 32.9809 98.0455 32.9809C99.0762 32.9809 100.107 32.8515 101.138 32.4635C102.168 32.0755 102.941 31.5582 103.586 30.7821C103.714 30.6528 103.843 30.5235 103.972 30.2648V32.7222H108.739V20.3058C108.739 19.1417 108.353 18.107 107.708 17.2017C106.807 16.167 105.905 15.6496 104.745 15.1323ZM103.199 27.678C102.813 28.3247 102.297 28.7127 101.653 29.1007C101.009 29.3594 100.365 29.6181 99.5916 29.6181C99.0762 29.6181 98.5609 29.4888 98.1743 29.3594C97.7878 29.2301 97.4013 28.8421 97.1436 28.4541C96.886 28.066 96.7571 27.678 96.7571 27.1607C96.7571 26.6433 96.886 26.2553 97.1436 25.8673C97.4013 25.4793 97.7878 25.2206 98.1743 25.0913C98.6897 24.9619 99.3339 24.8326 99.9781 24.8326C100.88 24.8326 101.782 24.9619 102.684 25.2206C103.07 25.3499 103.457 25.4793 103.714 25.6086C103.714 26.3846 103.586 27.0313 103.199 27.678Z" fill="#1F1E28"/>
    <path d="M144.041 32.5928H148.808V17.331C148.808 16.0376 147.777 15.0029 146.489 15.0029H144.041V32.5928Z" fill="#1F1E28"/>
    <path d="M138.63 15.1323C137.728 14.6149 136.568 14.3563 135.28 14.3563C133.863 14.3563 132.703 14.7443 131.801 15.391C131.028 15.9083 130.384 16.6843 129.997 17.4604V4.52661H125.102V32.5929H129.997V21.8578C129.997 21.0818 130.126 20.4351 130.384 19.9178C130.642 19.4004 131.028 19.0124 131.543 18.6244C132.059 18.3657 132.703 18.2364 133.347 18.2364C134.378 18.2364 135.151 18.4951 135.537 19.1418C135.924 19.7884 136.182 20.6938 136.182 21.8578V32.5929H141.078V21.2112C141.078 19.7884 140.82 18.4951 140.433 17.4604C140.176 16.4257 139.531 15.6496 138.63 15.1323Z" fill="#1F1E28"/>
    <path d="M118.918 27.0315V19.1419H122.01V15.0031H118.918V10.9937C118.918 9.82962 118.016 8.79492 116.728 8.79492H114.151V14.8738H111.188V19.0126H114.151V27.6782C114.151 30.265 116.341 32.4637 118.918 32.4637H122.525V27.6782H119.562C119.175 27.8075 118.918 27.4195 118.918 27.0315Z" fill="#1F1E28"/>
    <path d="M146.36 13.5804C147.854 13.5804 149.066 12.3643 149.066 10.8643C149.066 9.36423 147.854 8.14819 146.36 8.14819C144.866 8.14819 143.654 9.36423 143.654 10.8643C143.654 12.3643 144.866 13.5804 146.36 13.5804Z" fill="#1F1E28"/>
  </g>
  <defs>
    <linearGradient id="paint0_linear_2877_7801" x1="166.898" y1="11.4959" x2="190.563" y2="26.3299" gradientUnits="userSpaceOnUse">
      <stop stop-color="#3A6FFD"/>
      <stop offset="1" stop-color="#D63ACA"/>
    </linearGradient>
    <linearGradient id="paint1_linear_2877_7801" x1="175.32" y1="18.839" x2="178.84" y2="22.0603" gradientUnits="userSpaceOnUse">
      <stop stop-color="#3A6FFD"/>
      <stop offset="1" stop-color="#D63ACA"/>
    </linearGradient>
    <clipPath id="clip0_2877_7801">
      <rect width="193" height="41" fill="white"/>
    </clipPath>
  </defs>
</svg>
          <div className="return-to-bag">RETURN TO BAG</div>
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none" className="vector">
  <path d="M7 1L1 7L7 13" stroke="#1F1E28"/>
</svg>
        </div>
        <div className="overlap-group">
          <div className="rectangle" />
          <div className="text-wrapper">Order information</div>
          <p className="p">There is 1 item in your cart:</p>
          <div className="rectangle-2" />
          <p className="text-wrapper-2">XS™ Sports Protein Shakes – Rich Chocolate</p>
          <div className="rectangle-3" />
          <div className="rectangle-4" />
          <div className="text-wrapper-3">B YOGA</div>
          <img
            className="mask-group"
            alt="Mask group"
            src={Images.Market_1}
          />
          <div className="text-wrapper-4">$128.00</div>
          <div className="text-wrapper-5">
            Subtotal...........................................................................
          </div>
          <div className="text-wrapper-6">
            Standard Ground..............................................................
          </div>
          <div className="text-wrapper-7">FREE</div>
          <div className="text-wrapper-8">$128.00</div>
          <p className="total">
            <span className="span">Total</span>
            <span className="text-wrapper-9">..............................................................</span>
          </p>
          <div className="group">
          <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41" fill="none">
  <circle cx="20.5" cy="20.5" r="20" stroke="#E3F0F6"/>
  <path d="M18.1582 23.6321L18.1582 20.9479" stroke="#041625" stroke-linecap="round"/>
  <path d="M21.7363 23.6321L21.7363 20.9479" stroke="#041625" stroke-linecap="round"/>
  <path d="M11 15.5789H28.8948V15.5789C27.9794 15.5789 27.5217 15.5789 27.1597 15.7259C26.6594 15.9291 26.2626 16.3259 26.0594 16.8262C25.9124 17.1882 25.9124 17.6459 25.9124 18.5613V25C25.9124 26.8856 25.9124 27.8284 25.3266 28.4142C24.7408 29 23.798 29 21.9124 29H17.9825C16.0969 29 15.154 29 14.5683 28.4142C13.9825 27.8284 13.9825 26.8856 13.9825 25V18.5613C13.9825 17.6459 13.9825 17.1882 13.8355 16.8262C13.6323 16.3259 13.2354 15.9291 12.7351 15.7259C12.3731 15.5789 11.9154 15.5789 11 15.5789V15.5789Z" stroke="#041625" stroke-linecap="round"/>
  <path d="M18.2183 12.3316C18.3203 12.2365 18.545 12.1524 18.8575 12.0924C19.17 12.0325 19.5529 12 19.9468 12C20.3408 12 20.7237 12.0325 21.0362 12.0924C21.3487 12.1524 21.5734 12.2365 21.6754 12.3316" stroke="#041625" stroke-linecap="round"/>
</svg>
          </div>
        </div>
        <div className="overlap-2">
          <div className="text-wrapper-10">1. Shipping</div>
          <div className="rectangle-6" />
          <div className="overlap-wrapper">
            <div className="div-wrapper">
              <div className="text-wrapper-11">First Name*</div>
            </div>
          </div>
          <div className="overlap-group-wrapper">
            <div className="div-wrapper">
              <div className="text-wrapper-11">Last name*</div>
            </div>
          </div>
          <div className="group-3">
            <div className="div-wrapper">
              <div className="text-wrapper-11">Address*</div>
            </div>
          </div>
          <div className="group-4">
            <div className="div-wrapper">
              <div className="text-wrapper-11">Phone (for order questions)*</div>
            </div>
          </div>
          <div className="group-5">
            <div className="overlap-3">
              <div className="text-wrapper-12">Olexandro1967@gmail.com</div>
              <div className="text-wrapper-13">Email</div>
            </div>
          </div>
          <div className="text-wrapper-14">CONTACT INFORMATION</div>
          <div className="overlap-4">
            <p className="by-selecting-this">
              <span className="text-wrapper-15">
                By selecting this checkbox, you consent to receive other emails from us (tips, stories, newsletters, and
                more). Refer to our{" "}
              </span>
              <a href="https://goop.com/web-terms-of-use/" rel="noopener noreferrer" target="_blank">
                <span className="text-wrapper-16">Terms of Use</span>
              </a>
              <span className="text-wrapper-15">, </span>
              <a href="https://goop.com/privacy-cookies-policy/" rel="noopener noreferrer" target="_blank">
                <span className="text-wrapper-16">Privacy</span>
              </a>
              <span className="text-wrapper-15"> and </span>
              <a href="https://goop.com/privacy-cookies-policy/" rel="noopener noreferrer" target="_blank">
                <span className="text-wrapper-16">Cookie Policy</span>
              </a>
              <span className="text-wrapper-15">.</span>
            </p>
            <p className="yep-i-want-to">
              <span className="text-wrapper-15">
                Yep! I want to receive texts a few times a month updating me about goop&#39;s latest drops, sales, and
                more. Refer to our{" "}
              </span>
              <a href="https://goop.com/web-terms-of-use/" rel="noopener noreferrer" target="_blank">
                <span className="text-wrapper-16">Terms of Use</span>
              </a>
              <span className="text-wrapper-15">.</span>
            </p>
            <div className="group-6">
              <div className="continue-wrapper">
                <div className="continue">CONTINUE</div>
              </div>
            </div>
          </div>
          <div className="line-wrapper">
            <img
              className="line"
              alt="Line"
              src="https://generation-sessions.s3.amazonaws.com/27501f8a11ae985a08f77066bb4a36c6/img/line-1-1.svg"
            />
          </div>
          <div className="img-wrapper">
            <img
              className="line"
              alt="Line"
              src="https://generation-sessions.s3.amazonaws.com/27501f8a11ae985a08f77066bb4a36c6/img/line-1-1.svg"
            />
          </div>
        </div>
        <div className="overlap-5">
          <div className="text-wrapper-17">2. Payment</div>
        </div>
        <div className="overlap-6">
          <div className="text-wrapper-17">2. Review</div>
        </div>
      </div>
    </div>
  );
};
 export default Order;






