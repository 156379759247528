import React from "react";
import AdminLayout from "../../layouts/AdminLayout";
import { Container } from "react-bootstrap";
import Images from "../../constants/Images";
import ButtonStyled from "../../components/ButtonStyled";

import { Row, Col, Image, Card } from "react-bootstrap";
import Linechart from "../../components/LineGraph";
import "./doctorprofuserflow.scss";
import { DropDown1 } from "../../View/Myspecllist.js/icons/DropDown1";
import { DropDown2 } from "../../View/Myspecllist.js/icons/DropDown2";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import { Link } from "react-router-dom";

function DoctorprofuserFlow() {
  return (
    <AdminLayout>
      <div className="bg-white" style={{minHeight:'100vh', margin:'0'}} >
      <Container className="pt-4">
        <Row>
          <Col lg={4}>
            <Card className="appoint-1 pt-5 pb-4" style={{border:'none', boxShadow: '0px 4px 30px 0px #0000000D', borderRadius:'15px'}}>
              <Card.Img
                src={Images.Myappointment_1}
                className=" myappoint-img "
                alt=""
              />
              <div className="star-div">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    d="M2.9125 11L4.075 7.2L1 5H4.8L6 1L7.2 5H11L7.925 7.2L9.0875 11L6 8.65L2.9125 11Z"
                    fill="white"
                  />
                </svg>
                <span className="star-rating-4"> 4.5 </span>
              </div>
              <Card.Body>
                <div className="m-3">
                  {" "}
                  <h4 className="card-title">$ 120.00 / 180.00</h4>
                  <span className="ctext">Price</span>
                </div>
                <div className="m-3">
                  <div className="d-flex justify-content-center" style={{gap:'10px', paddingBottom:'10px'}}>
                  <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <path
                      d="M20.42 9.50047C20.28 9.49047 20.1 9.48047 19.9 9.48047C19.51 9.48047 19.2 9.79047 19.2 10.1805V14.8305C19.2 15.2205 19.51 15.5305 19.9 15.5305C20.1 15.5305 20.27 15.5205 20.44 15.5105C22 15.3305 22 14.2305 22 13.4305V11.5705C22 10.7705 22 9.67047 20.42 9.50047Z"
                      fill="url(#paint0_linear_2830_939)"
                    />
                    <path
                      d="M12.93 5.28906H7.35C3.25 5.28906 2 6.53906 2 10.6391V14.3591C2 18.4591 3.25 19.7091 7.35 19.7091H12.93C17.03 19.7091 18.28 18.4591 18.28 14.3591V10.6391C18.28 6.53906 17.03 5.28906 12.93 5.28906ZM7.1 14.6791C7.02 15.0191 6.71 15.2491 6.37 15.2491C6.31 15.2491 6.25 15.2391 6.19 15.2291C5.79 15.1291 5.54 14.7191 5.64 14.3191C5.94 13.1291 5.94 11.8691 5.64 10.6791C5.54 10.2791 5.78 9.86906 6.19 9.76906C6.59 9.66906 7 9.91906 7.1 10.3191C7.46 11.7491 7.46 13.2491 7.1 14.6791Z"
                      fill="url(#paint1_linear_2830_939)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_2830_939"
                        x1="19.7973"
                        y1="11.1846"
                        x2="22.2674"
                        y2="12.0625"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#3A6FFD" />
                        <stop offset="1" stop-color="#D63ACA" />
                      </linearGradient>
                      <linearGradient
                        id="paint1_linear_2830_939"
                        x1="5.47307"
                        y1="9.3507"
                        x2="14.7073"
                        y2="17.3573"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#3A6FFD" />
                        <stop offset="1" stop-color="#D63ACA" />
                      </linearGradient>
                    </defs>
                  </svg>
                  </div>
                  <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <path
                      d="M17 2.5H7C4.24 2.5 2 4.73 2 7.48V13.46V14.46C2 17.21 4.24 19.44 7 19.44H8.5C8.77 19.44 9.13 19.62 9.3 19.84L10.8 21.83C11.46 22.71 12.54 22.71 13.2 21.83L14.7 19.84C14.89 19.59 15.19 19.44 15.5 19.44H17C19.76 19.44 22 17.21 22 14.46V7.48C22 4.73 19.76 2.5 17 2.5ZM8 12.5C7.44 12.5 7 12.05 7 11.5C7 10.95 7.45 10.5 8 10.5C8.55 10.5 9 10.95 9 11.5C9 12.05 8.56 12.5 8 12.5ZM12 12.5C11.44 12.5 11 12.05 11 11.5C11 10.95 11.45 10.5 12 10.5C12.55 10.5 13 10.95 13 11.5C13 12.05 12.56 12.5 12 12.5ZM16 12.5C15.44 12.5 15 12.05 15 11.5C15 10.95 15.45 10.5 16 10.5C16.55 10.5 17 10.95 17 11.5C17 12.05 16.56 12.5 16 12.5Z"
                      fill="url(#paint0_linear_2830_2572)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_2830_2572"
                        x1="6.26667"
                        y1="8.13052"
                        x2="18.762"
                        y2="17.7318"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#3A6FFD" />
                        <stop offset="1" stop-color="#D63ACA" />
                      </linearGradient>
                    </defs>
                  </svg>
                  </div>
                  <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <path
                      d="M17.62 11.2516C17.19 11.2516 16.85 10.9016 16.85 10.4816C16.85 10.1116 16.48 9.34156 15.86 8.67156C15.25 8.02156 14.58 7.64156 14.02 7.64156C13.59 7.64156 13.25 7.29156 13.25 6.87156C13.25 6.45156 13.6 6.10156 14.02 6.10156C15.02 6.10156 16.07 6.64156 16.99 7.61156C17.85 8.52156 18.4 9.65156 18.4 10.4716C18.4 10.9016 18.05 11.2516 17.62 11.2516Z"
                      fill="url(#paint0_linear_2830_1214)"
                    />
                    <path
                      d="M21.23 11.25C20.8 11.25 20.46 10.9 20.46 10.48C20.46 6.93 17.57 4.05 14.03 4.05C13.6 4.05 13.26 3.7 13.26 3.28C13.26 2.86 13.6 2.5 14.02 2.5C18.42 2.5 22 6.08 22 10.48C22 10.9 21.65 11.25 21.23 11.25Z"
                      fill="url(#paint1_linear_2830_1214)"
                    />
                    <path
                      d="M11.05 15.45L9.2 17.3C8.81 17.69 8.19 17.69 7.79 17.31C7.68 17.2 7.57 17.1 7.46 16.99C6.43 15.95 5.5 14.86 4.67 13.72C3.85 12.58 3.19 11.44 2.71 10.31C2.24 9.17 2 8.08 2 7.04C2 6.36 2.12 5.71 2.36 5.11C2.6 4.5 2.98 3.94 3.51 3.44C4.15 2.81 4.85 2.5 5.59 2.5C5.87 2.5 6.15 2.56 6.4 2.68C6.66 2.8 6.89 2.98 7.07 3.24L9.39 6.51C9.57 6.76 9.7 6.99 9.79 7.21C9.88 7.42 9.93 7.63 9.93 7.82C9.93 8.06 9.86 8.3 9.72 8.53C9.59 8.76 9.4 9 9.16 9.24L8.4 10.03C8.29 10.14 8.24 10.27 8.24 10.43C8.24 10.51 8.25 10.58 8.27 10.66C8.3 10.74 8.33 10.8 8.35 10.86C8.53 11.19 8.84 11.62 9.28 12.14C9.73 12.66 10.21 13.19 10.73 13.72C10.83 13.82 10.94 13.92 11.04 14.02C11.44 14.41 11.45 15.05 11.05 15.45Z"
                      fill="url(#paint2_linear_2830_1214)"
                    />
                    <path
                      d="M21.97 18.8291C21.97 19.1091 21.92 19.3991 21.82 19.6791C21.79 19.7591 21.76 19.8391 21.72 19.9191C21.55 20.2791 21.33 20.6191 21.04 20.9391C20.55 21.4791 20.01 21.8691 19.4 22.1191C19.39 22.1191 19.38 22.1291 19.37 22.1291C18.78 22.3691 18.14 22.4991 17.45 22.4991C16.43 22.4991 15.34 22.2591 14.19 21.7691C13.04 21.2791 11.89 20.6191 10.75 19.7891C10.36 19.4991 9.96998 19.2091 9.59998 18.8991L12.87 15.6291C13.15 15.8391 13.4 15.9991 13.61 16.1091C13.66 16.1291 13.72 16.1591 13.79 16.1891C13.87 16.2191 13.95 16.2291 14.04 16.2291C14.21 16.2291 14.34 16.1691 14.45 16.0591L15.21 15.3091C15.46 15.0591 15.7 14.8691 15.93 14.7491C16.16 14.6091 16.39 14.5391 16.64 14.5391C16.83 14.5391 17.03 14.5791 17.25 14.6691C17.47 14.7591 17.7 14.8891 17.95 15.0591L21.26 17.4091C21.52 17.5891 21.7 17.7991 21.81 18.0491C21.91 18.2991 21.97 18.5491 21.97 18.8291Z"
                      fill="url(#paint3_linear_2830_1214)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_2830_1214"
                        x1="14.3487"
                        y1="7.55215"
                        x2="17.5674"
                        y2="10.0241"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#3A6FFD" />
                        <stop offset="1" stop-color="#D63ACA" />
                      </linearGradient>
                      <linearGradient
                        id="paint1_linear_2830_1214"
                        x1="15.1245"
                        y1="4.96458"
                        x2="20.5917"
                        y2="9.15854"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#3A6FFD" />
                        <stop offset="1" stop-color="#D63ACA" />
                      </linearGradient>
                      <linearGradient
                        id="paint2_linear_2830_1214"
                        x1="3.99361"
                        y1="6.75141"
                        x2="11.5669"
                        y2="10.3525"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#3A6FFD" />
                        <stop offset="1" stop-color="#D63ACA" />
                      </linearGradient>
                      <linearGradient
                        id="paint3_linear_2830_1214"
                        x1="12.2389"
                        y1="16.7811"
                        x2="17.3087"
                        y2="22.8319"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#3A6FFD" />
                        <stop offset="1" stop-color="#D63ACA" />
                      </linearGradient>
                    </defs>
                  </svg>
                  </div>
                  </div>
                  <Link style={{textDecoration:'none'}} to="/book_appointment">
                  <div className="book-appointment-div">
                    <span className="booking"> Book Appointment</span>
                  </div></Link>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={8}>
            <Card style={{border:'none'}}>
              <Card.Body>
                <div className="mb-3 ">
                  <h3>Dr. Nelson Jhons</h3>
                  <span className="ctext">
                    Licensed Professional Counsellor MED, LPC, NCC
                  </span>
                </div>
                <div className="d-flex colorida-div">
                  <Card.Img
                    src={Images.map}
                    className="map-image "
                    alt=""
                  />
                  <div className="" style={{padding   :'10px'}}>
                    <h2 className="colorida-text">Colorida, United States</h2>
                    <span className="colorida-explanation">
                      Lorem Ipsum arena iliamina losem loremLorem Ipsum arena
                      iliamina losem lorLorem Ipsum arena iliamina losem lorem
                    </span>
                  </div>
                </div>
                <h2 className="specialities-text">Specialities</h2>
                <div className="tag-div">
                  <div className="tag1">
                    <span className="tag-text">Therapiest</span>
                  </div>
                  <div className="tag">
                    <span className="tag-text">Good Surgeon</span>
                  </div>
                  <div className="tag">
                    <span className="tag-text">Mental Therapist</span>
                  </div>

                  <div className="tag1">
                    <span className="tag-text">Therapiest</span>
                  </div>
                  <div className="tag">
                    <span className="tag-text">Good Surgeon</span>
                  </div>
                  <div className="tag">
                    <span className="tag-text">Mental Therapist</span>
                  </div>
                </div>
                <Row>
                  <Col lg={4}>
                    <h2 className="qualification-text"> Qualification</h2>
                    <p className="license-text">
                      Licence{" "}
                      <span className="loremipsem123"> / loremipsum 123431445 </span>
                    </p>
                  </Col>
                  <Col>
                    <h2 className="explanation-text">Experience</h2>
                    <span className="year10experience">
                      {" "}
                      10 Years of Experience (110+ Consultations)
                    </span>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row style={{paddingTop:'30px'}}>
          <Col lg={4}>
            <Card className="px-3 mt-0" style={{border:'none',boxShadow: '0px 4px 30px 0px #0000000D', borderRadius:'15px', paddingBottom:'20px'}}>
              <Card.Body className="d-flex justify-content-between align-items-center">
                <h2 className="office-text">Office</h2>
                <span className="photo24-text" style={{color: '#68838E'}}>24 Photo</span>
              </Card.Body>
              <Card.Img
                src={Images.Forum_5}
                className=" specialist-img1 "
                alt=""
              />
            </Card>
          </Col>
          <Col lg={8}>
            {" "}
            <Card className="p-2" style={{border:'none'}}>
              <Card.Body>
                <h2>About</h2>
                <span className="about-explanation">
                  {" "}
                  Lorem Ipsum arena iliamina losem loremLorem Ipsum arena
                  iliamina losem lorLorem Ipsum arena iliamina losem loremLorem
                  Ipsum arena iliamina losem loremLorem Ipsum arena iliamina
                  losem lorLorem Ipsum arena iliamina losem loremLorem Ipsum
                  arena iliamina losem loremLorem Ipsum arena iliamina losem
                  lorLorem Ipsum arena iliamina losem loremLorem Ipsum arena
                  iliamina losem loremLorem Ipsum arena iliamina losem lorLorem
                  Ipsum arena iliamina losem loremLorem Ipsum arena iliamina
                  losem loremLorem Ipsum arena iliamina losem lorLorem Ipsum
                  arena iliamina losem loremLorem Ipsum arena iliamina losem
                  loremLorem Ipsum arena iliamina losem lorLorem Ipsum arena
                  iliamina losem loremLorem Ipsum arena iliamina losem
                  loremLorem
                </span>
                <br></br>
                <div style={{paddingTop:'10px'}}>
                <span className="booking"> Learn More</span>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Col>
        <div className="d-flex justify-content-between" style={{paddingBottom:'20px'}}>
        <div className='d-flex text-center justify-content-center align-content-center'>
        <p className="review-text">Reviews</p>
        </div>
        
                  <div className="see-all-div">
                    <span className="booking"> See all</span>
                    <i class="fas fa-arrow-right button-icon1"></i>
                  </div>
        </div>
        </Col>
        
        <div className="d-flex" style={{paddingBottom:'220px'}}>
          <Col lg={6}>
            <Card className="m-2" style={{borderRadius:'20px',border:'none', boxShadow: '0px 4px 30px 0px #0000000D', paddingLeft:'10px'}}>
              <Card.Body className="reviewcard">
                <div style={{paddingBottom:'19px'}}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M14.3135 6.10164L10.2146 5.4746L8.37738 1.55543C8.24016 1.26277 7.76072 1.26277 7.6235 1.55543L5.78683 5.4746L1.68796 6.10164C1.35129 6.15339 1.21685 6.56344 1.45296 6.80547L4.43073 9.86225L3.72684 14.1837C3.67073 14.527 4.03684 14.7846 4.33962 14.616L8.00072 12.5896L11.6618 14.6165C11.9618 14.7835 12.3313 14.5309 12.2746 14.1842L11.5707 9.8628L14.5485 6.80603C14.7846 6.56344 14.6496 6.15339 14.3135 6.10164Z"
                      fill="#FFC107"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M14.3135 6.10164L10.2146 5.4746L8.37738 1.55543C8.24016 1.26277 7.76072 1.26277 7.6235 1.55543L5.78683 5.4746L1.68796 6.10164C1.35129 6.15339 1.21685 6.56344 1.45296 6.80547L4.43073 9.86225L3.72684 14.1837C3.67073 14.527 4.03684 14.7846 4.33962 14.616L8.00072 12.5896L11.6618 14.6165C11.9618 14.7835 12.3313 14.5309 12.2746 14.1842L11.5707 9.8628L14.5485 6.80603C14.7846 6.56344 14.6496 6.15339 14.3135 6.10164Z"
                      fill="#FFC107"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M14.3135 6.10164L10.2146 5.4746L8.37738 1.55543C8.24016 1.26277 7.76072 1.26277 7.6235 1.55543L5.78683 5.4746L1.68796 6.10164C1.35129 6.15339 1.21685 6.56344 1.45296 6.80547L4.43073 9.86225L3.72684 14.1837C3.67073 14.527 4.03684 14.7846 4.33962 14.616L8.00072 12.5896L11.6618 14.6165C11.9618 14.7835 12.3313 14.5309 12.2746 14.1842L11.5707 9.8628L14.5485 6.80603C14.7846 6.56344 14.6496 6.15339 14.3135 6.10164Z"
                      fill="#FFC107"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M14.3135 6.10164L10.2146 5.4746L8.37738 1.55543C8.24016 1.26277 7.76072 1.26277 7.6235 1.55543L5.78683 5.4746L1.68796 6.10164C1.35129 6.15339 1.21685 6.56344 1.45296 6.80547L4.43073 9.86225L3.72684 14.1837C3.67073 14.527 4.03684 14.7846 4.33962 14.616L8.00072 12.5896L11.6618 14.6165C11.9618 14.7835 12.3313 14.5309 12.2746 14.1842L11.5707 9.8628L14.5485 6.80603C14.7846 6.56344 14.6496 6.15339 14.3135 6.10164Z"
                      fill="#FFC107"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M14.3135 6.10164L10.2146 5.4746L8.37738 1.55543C8.24016 1.26277 7.76072 1.26277 7.6235 1.55543L5.78683 5.4746L1.68796 6.10164C1.35129 6.15339 1.21685 6.56344 1.45296 6.80547L4.43073 9.86225L3.72684 14.1837C3.67073 14.527 4.03684 14.7846 4.33962 14.616L8.00072 12.5896L11.6618 14.6165C11.9618 14.7835 12.3313 14.5309 12.2746 14.1842L11.5707 9.8628L14.5485 6.80603C14.7846 6.56344 14.6496 6.15339 14.3135 6.10164Z"
                      fill="#FFC107"
                    />
                  </svg>
                </div>
                <h5 className="review-explanation">
                  Lorem ipsum dolor sit amettetur adipiscing elit, sed do
                  eiusmod tempor ncnt ut labore et dolore magna aliqua. Ut enim
                  ad minim veniamsed do eiusmod tempor,
                </h5>
                <div className="d-flex justify-content-end">
                  <Image src={Images.fcard_3} className="fcard_3" alt="" />
                  <div className="" style={{paddingLeft:'10px'}}>
                    <h5 className="nelson-anam"> Nelson Anam </h5>
                    {/* <div className="specialist"> */}
                      <p className="text_1234">@12334</p>
                    {/* </div> */}
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6}>
            <Card className="reviewcard m-2" style={{borderRadius:'20px',border:'none', boxShadow: '0px 4px 30px 0px #0000000D', paddingLeft:'10px'}}>
              <Card.Body>
                <div style={{paddingBottom:'19px'}}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M14.3135 6.10164L10.2146 5.4746L8.37738 1.55543C8.24016 1.26277 7.76072 1.26277 7.6235 1.55543L5.78683 5.4746L1.68796 6.10164C1.35129 6.15339 1.21685 6.56344 1.45296 6.80547L4.43073 9.86225L3.72684 14.1837C3.67073 14.527 4.03684 14.7846 4.33962 14.616L8.00072 12.5896L11.6618 14.6165C11.9618 14.7835 12.3313 14.5309 12.2746 14.1842L11.5707 9.8628L14.5485 6.80603C14.7846 6.56344 14.6496 6.15339 14.3135 6.10164Z"
                      fill="#FFC107"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M14.3135 6.10164L10.2146 5.4746L8.37738 1.55543C8.24016 1.26277 7.76072 1.26277 7.6235 1.55543L5.78683 5.4746L1.68796 6.10164C1.35129 6.15339 1.21685 6.56344 1.45296 6.80547L4.43073 9.86225L3.72684 14.1837C3.67073 14.527 4.03684 14.7846 4.33962 14.616L8.00072 12.5896L11.6618 14.6165C11.9618 14.7835 12.3313 14.5309 12.2746 14.1842L11.5707 9.8628L14.5485 6.80603C14.7846 6.56344 14.6496 6.15339 14.3135 6.10164Z"
                      fill="#FFC107"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M14.3135 6.10164L10.2146 5.4746L8.37738 1.55543C8.24016 1.26277 7.76072 1.26277 7.6235 1.55543L5.78683 5.4746L1.68796 6.10164C1.35129 6.15339 1.21685 6.56344 1.45296 6.80547L4.43073 9.86225L3.72684 14.1837C3.67073 14.527 4.03684 14.7846 4.33962 14.616L8.00072 12.5896L11.6618 14.6165C11.9618 14.7835 12.3313 14.5309 12.2746 14.1842L11.5707 9.8628L14.5485 6.80603C14.7846 6.56344 14.6496 6.15339 14.3135 6.10164Z"
                      fill="#FFC107"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M14.3135 6.10164L10.2146 5.4746L8.37738 1.55543C8.24016 1.26277 7.76072 1.26277 7.6235 1.55543L5.78683 5.4746L1.68796 6.10164C1.35129 6.15339 1.21685 6.56344 1.45296 6.80547L4.43073 9.86225L3.72684 14.1837C3.67073 14.527 4.03684 14.7846 4.33962 14.616L8.00072 12.5896L11.6618 14.6165C11.9618 14.7835 12.3313 14.5309 12.2746 14.1842L11.5707 9.8628L14.5485 6.80603C14.7846 6.56344 14.6496 6.15339 14.3135 6.10164Z"
                      fill="#FFC107"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M14.3135 6.10164L10.2146 5.4746L8.37738 1.55543C8.24016 1.26277 7.76072 1.26277 7.6235 1.55543L5.78683 5.4746L1.68796 6.10164C1.35129 6.15339 1.21685 6.56344 1.45296 6.80547L4.43073 9.86225L3.72684 14.1837C3.67073 14.527 4.03684 14.7846 4.33962 14.616L8.00072 12.5896L11.6618 14.6165C11.9618 14.7835 12.3313 14.5309 12.2746 14.1842L11.5707 9.8628L14.5485 6.80603C14.7846 6.56344 14.6496 6.15339 14.3135 6.10164Z"
                      fill="#FFC107"
                    />
                  </svg>
                </div>
                <h5 className="review-explanation">
                  Lorem ipsum dolor sit amettetur adipiscing elit, sed do
                  eiusmod tempor ncnt ut labore et dolore magna aliqua. Ut enim
                  ad minim veniamsed do eiusmod tempor,
                </h5>
                <div className="d-flex justify-content-end">
                  <Image src={Images.fcard_3} className="fcard_3" alt="" />
                  <div className="" style={{paddingLeft:'10px'}}>
                    <h5 className="nelson-anam"> Nelson Anam </h5>
                    {/* <div className="specialist"> */}
                      {/* {" "} */}
                      <p className="text_1234">@12334</p>
                    {/* </div> */}
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </div>
      </Container>
      </div>
    </AdminLayout>
  );
}

export default DoctorprofuserFlow;
