import React, { useState } from "react";
import { BsThreeDotsVertical } from 'react-icons/bs';
import Images from "../../../constants/Images";
import AdminLayout from "../../../layouts/AdminLayout";
import ForumNav from "../../../View/Forum/ForumNav";
import { Row, Col, Image, Card,Navbar, Nav, Container  } from "react-bootstrap";

import { Link } from "react-router-dom";
import ButtonStyled from "../../../components/ButtonStyled";
import Input from "../../../components/Input";
import { FormControl,Dropdown } from "react-bootstrap";
import "./addiction.scss";
function Addiction() {
  const [newComment, setNewComment] = useState(''); 
  const handleCommentInputChange = (e) => {
    setNewComment(e.target.value);
  };

  const [selectedComment, setSelectedComment] = useState(null); 
  // Function to add a new comment to the card
  const addNewComment = (cardIndex) => {
    if (newComment.trim() === '') {
      return; // Do not add an empty comment
    }

    // Create a copy of cardData
    const updatedCardData = [...cardData];
    // Add the new comment to the specified card
    updatedCardData[cardIndex].comments.push({
      author: "Your Name", // You can change this to the actual author
      comment: newComment,
    });
    // Update the cardData state
    setCardData(updatedCardData);
    // Clear the input field
    setNewComment('');
  };

  const handleEditClick = () => {
    if (selectedComment) {
      
      const updatedCardData = [...cardData];
      const editedCommentIndex = updatedCardData.findIndex((card) => card.comments.includes(selectedComment));
      if (editedCommentIndex !== -1) {
        const editedComment = { ...selectedComment };
        editedComment.comment = "Edited Comment"; // Replace with the edited comment text
        updatedCardData[editedCommentIndex].comments = updatedCardData[editedCommentIndex].comments.map((comment) =>
          comment === selectedComment ? editedComment : comment
        );
        setCardData(updatedCardData);
        setSelectedComment(null);
      }
    }
  };
  const handleDeleteClick = () => {
    console.log('not if selected',selectedComment)

    if (selectedComment) {
      console.log('selected',selectedComment)
      const updatedCardData = cardData.map((card) => {
        const updatedComments = card.comments.filter((comment) => comment !== selectedComment);
        return { ...card, comments: updatedComments };
      });
  
      setCardData(updatedCardData);
      setSelectedComment(null);
    }
  };

  const [cardData, setCardData] = useState([
    {
      author: "Arena Valison",
      gender: "Male",
      image: Images.Addi_p1,
      image1:Images.Addiction_1,
      title: "How To Solve your Problem in 6 Ways",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      comments: [
        {
          author: "Smith S.",
          comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun",
        },
        {
          author: "Smith S.",
          comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun",
        },
        // Add more comments as needed
      ],
      likes: '3.5k',
      dislikes: 0,
    },
    {
      author: "Arena Valison",
      gender: "Male",
      image: Images.Addi_p1,
      image1:Images.Addiction_2,
      title: "How To Solve your Problem in 6 Ways",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      comments: [
        {
          author: "Smith S.",
          comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun",
        },
        // Add more comments as needed
      ],
      likes: '2k',
      dislikes: 0,
    }, {
      author: "Arena Valison",
      gender: "Male",
      image: Images.Addi_p2,
      image1:Images.Addiction_3,
      title: "How To Solve your Problem in 6 Ways",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      comments: [
        {
          author: "Smith S.",
          comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun",
        },
        // Add more comments as needed
      ],
      likes: '89',
      dislikes: 0,
    }, {
      author: "Arena Valison",
      gender: "Male",
      image: Images.Addi_p2,
      image1:Images.Addiction_2,
      title: "How To Solve your Problem in 6 Ways",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      comments: [
        {
          author: "Smith S.",
          comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun",
        },
        // Add more comments as needed
      ],
      likes: '3.1k',
      dislikes: 0,
    },
  ]);
  return (
    <AdminLayout>
    <div className="w-100 bg-white mt-0 pt-0" style={{minHeight:'100vh'}}>
      <Container >
      <Container>
          <h3 className="pt-4">Forum Discussion</h3>
          <Navbar  className="">
    
    
    <Nav className="forummenu mb-2 mb-lg-0 ms-0 pt-1 w-100 " as="ul">
      
        <Nav.Item as="li">
        <Link className="nav-link colorgray" to="/forum">
            All
          </Link>
        </Nav.Item>
        <Nav.Item as="li">
        <Link className="nav-link colorgray" to="/main_forum">
          <div style={{marginTop:'-10px'}}>
          <ButtonStyled title="Addictions" className="factive px-2"  /></div>
          </Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Link className="nav-link colorgray" to="/cancers">
            Cancers
          </Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Link className="nav-link colorgray" to="/familyIssue">
            Family Issue
          </Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Link className="nav-link colorgray" to="/Grief">
            Grief
          </Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Link className="nav-link colorgray" to="/news">
            Relationship & love
          </Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Link className="nav-link colorgray" to="/relationship&love">
            Relationship & love
          </Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Link className="nav-link colorgray" to="/therapies">
            Therapies
          </Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Link className="nav-link colorgray" to="/violence">
            Violence
          </Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Link className="nav-link colorgray" to="/personaldevelopment&identity">
            Personal Development & Identity
          </Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Link className="nav-link colorgray" to="/psychological">
            Psychological Troubles
          </Link>
        </Nav.Item>
      </Nav>
    
  </Navbar>
          <Navbar expand="lg">
            <Container>
              <Navbar.Toggle aria-controls="main-menu">
                <span>Menu</span>
              </Navbar.Toggle>
              <Navbar.Collapse id="main-menu">
                <Nav className="forummenu mb-2 mb-lg-0" as="ul">
                  <Nav.Item as="li">
                    <Link className="nav-link " to="/Addiction">
                     <b> Sub Topics</b>
                    </Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Link className="nav-link colorgray" to="/cancers">
                      Lorem Ipsum
                    </Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Link className="nav-link colorgray" to="/familyIssue">
                      Lorem Ipsum
                    </Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Link className="nav-link colorgray" to="/Grief">
                      Lorem Ipsum
                    </Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Link className="nav-link colorgray" to="/news">
                      Lorem Ipsum & love
                    </Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Link className="nav-link colorgray" to="/therapies">
                      Lorem Ipsum
                    </Link>
                  </Nav.Item>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </Container>

        <Container>
  {cardData.map((card, cardIndex) => (
    <div className="fCard" key={cardIndex}>
      <Card className="fcard1 me-1">
        <Card.Body>
          <div className="d-flex justify-content-between">
            <Col className="d-flex">
              <Image
                src={card.image}
                className="addi-img-1 me-2"
                width={54}
                height={54}
                alt=""
              />
              <div className="">
                <h4
                  style={{
                    fontWeight: '600',
                    fontSize: '19px',
                    lineHeight: '23.4px',
                    letterSpacing: '1%',
                    fontFamily: 'Outfit',
                  }}
                >
                  {card.author}
                </h4>
                <div className="d-flex" style={{ height: '16px' }}>
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 15 15"
                    fill="none"
                  >
                    <path
                      d="M12.5 2.5V6.25H11.25V4.64062L8.76562 7.10938C8.96354 7.40104 9.11458 7.71083 9.21875 8.03875C9.32292 8.36708 9.375 8.70833 9.375 9.0625C9.375 10.0208 9.04167 10.8333 8.375 11.5C7.70833 12.1667 6.89583 12.5 5.9375 12.5C4.97917 12.5 4.16667 12.1667 3.5 11.5C2.83333 10.8333 2.5 10.0208 2.5 9.0625C2.5 8.10417 2.83333 7.29167 3.5 6.625C4.16667 5.95833 4.97917 5.625 5.9375 5.625C6.28125 5.625 6.61979 5.67458 6.95312 5.77375C7.28646 5.8725 7.59375 6.02604 7.875 6.23438L10.3594 3.75H8.75V2.5H12.5ZM5.9375 6.875C5.33333 6.875 4.81771 7.08854 4.39062 7.51562C3.96354 7.94271 3.75 8.45833 3.75 9.0625C3.75 9.66667 3.96354 10.1823 4.39062 10.6094C4.81771 11.0365 5.33333 11.25 5.9375 11.25C6.54167 11.25 7.05729 11.0365 7.48438 10.6094C7.91146 10.1823 8.125 9.66667 8.125 9.0625C8.125 8.45833 7.91146 7.94271 7.48438 7.51562C7.05729 7.08854 6.54167 6.875 5.9375 6.875Z"
                      fill="#93989A"
                    />
                  </svg>{" "}
                  <p
                    style={{
                      color: '#93989A',
                      fontWeight: '500',
                      fontSize: '13px',
                      lineHeight: '15.6px',
                      letterSpacing: '1%',
                      fontFamily: 'Outfit',
                    }}
                  >
                    {card.gender}
                  </p>
                </div>
              </div>
            </Col>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                d="M16 10C17.1046 10 18 9.10457 18 8C18 6.89543 17.1046 6 16 6C14.8954 6 14 6.89543 14 8C14 9.10457 14.8954 10 16 10Z"
                fill="#68838E"
              />
              <path
                d="M16 18C17.1046 18 18 17.1046 18 16C18 14.8954 17.1046 14 16 14C14.8954 14 14 14.8954 14 16C14 17.1046 14.8954 18 16 18Z"
                fill="#68838E"
              />
              <path
                d="M16 26C17.1046 26 18 25.1046 18 24C18 22.8954 17.1046 22 16 22C14.8954 22 14 22.8954 14 24C14 25.1046 14.8954 26 16 26Z"
                fill="#68838E"
              />
            </svg>
          </div>
        </Card.Body>
        <Card.Body className="py-0">
        <Card.Img
                src={card.image1}
                className="img-fluid width"
                alt=""
              />
            </Card.Body>
            <Card.Body>
              <h4 className="card-title" style={{ fontFamily: 'Outfit' }}>
                {card.title}
              </h4>
              <p className="ctext" card-title style={{ fontWeight: '300', fontSize: '15px', lineHeight: '20.8px', letterSpacing: '1%', fontFamily: 'Outfit' }}>
                {card.content}
              </p>

              
              <div className="addi-2 px-0 pb-0">
              {card.comments.map((comment, commentIndex) => (
                <div className="d-flex flex-row" key={commentIndex}>
            <div className="" style={{width:'98%'}}>
    
                <div className="d-flex" style={{ marginBottom: '8px', gap: '6px' }}>
                  <Image
                    src={Images.Addi_p2}
                    className="addi-img-1"
                    alt=""
                    width={38}
                    height={38}
                  />
                  <h5 className="my-2" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '20.8px', letterSpacing: '1%', fontFamily: 'Outfit' }}>
                    {comment.author}
                  </h5>
                </div>
                <p className="ctext" style={{ fontWeight: '300', fontSize: '14px', lineHeight: '20.8px', letterSpacing: '1%', fontFamily: 'Outfit' }}>
                  {comment.comment}
                </p>
                
              </div>
              {comment.author === "Your Name" && (
                <div className='d-flex' style={{width:'2%', alignItems:'center'}}>
                <Dropdown>
                <Dropdown.Toggle
                  variant="dark"
                  id="dropdown-basic"
                  className="custom-dropdown-toggle"
                  style={{
                    backgroundColor: "white",
                    color: "black",
                    border: "none",
                   width:'fit-content',
                   marginLeft:'-10px'

                    
                  }}
                >
                              <BsThreeDotsVertical/>
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item onClick={handleEditClick(commentIndex)}> <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.0529 5.03322L4.21122 12.2749C3.95289 12.5499 3.70289 13.0916 3.65289 13.4666L3.34455 16.1666C3.23622 17.1416 3.93622 17.8082 4.90289 17.6416L7.58622 17.1832C7.96122 17.1166 8.48622 16.8416 8.74455 16.5582L15.5862 9.31655C16.7696 8.06655 17.3029 6.64155 15.4612 4.89989C13.6279 3.17489 12.2362 3.78322 11.0529 5.03322Z" stroke="#010101" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.91211 6.24219C10.2704 8.54219 12.1371 10.3005 14.4538 10.5339" stroke="#010101" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
&nbsp; Edit</Dropdown.Item>
                                <Dropdown.Item onClick={handleDeleteClick}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21 5.98047C17.67 5.65047 14.32 5.48047 10.98 5.48047C9 5.48047 7.02 5.58047 5.04 5.78047L3 5.98047" stroke="#101010" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97" stroke="#101010" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.8499 9.14062L18.1999 19.2106C18.0899 20.7806 17.9999 22.0006 15.2099 22.0006H8.7899C5.9999 22.0006 5.9099 20.7806 5.7999 19.2106L5.1499 9.14062" stroke="#101010" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.3301 16.5H13.6601" stroke="#101010" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.5 12.5H14.5" stroke="#101010" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
&nbsp;Delete</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                
                </div>
              )}
              </div>
              ))}
              </div>
          
              <Container className="px-0">
                <Row>
                  <Col lg={2} className="d-flex mt-4">
                    {" "}
                    <div className="like">
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        fill="none"
                      >
                        <path
                          d="M3.33341 17.9486H4.16675V7.11523H3.33341C2.89139 7.11523 2.46746 7.29083 2.1549 7.60339C1.84234 7.91595 1.66675 8.33987 1.66675 8.7819V16.2819C1.66675 16.7239 1.84234 17.1479 2.1549 17.4604C2.46746 17.773 2.89139 17.9486 3.33341 17.9486ZM16.6667 7.11523H10.8334L11.7684 4.30857C11.8518 4.05808 11.8746 3.79136 11.8347 3.53036C11.7949 3.26937 11.6936 3.02158 11.5392 2.8074C11.3849 2.59321 11.1818 2.41877 10.9468 2.29843C10.7118 2.17809 10.4516 2.1153 10.1876 2.11523H10.0001L5.83341 6.6469V17.9486H15.0001L18.2601 10.7852L18.3334 10.4486V8.7819C18.3334 8.33987 18.1578 7.91595 17.8453 7.60339C17.5327 7.29083 17.1088 7.11523 16.6667 7.11523Z"
                          fill="url(#paint0_linear_2749_7113)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_2749_7113"
                            x1="5.2223"
                            y1="6.57496"
                            x2="15.2376"
                            y2="14.6715"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#3A6FFD" />
                            <stop offset="1" stop-color="#D63ACA" />
                          </linearGradient>
                        </defs>
                      </svg>
                      <span className="count">{card.likes}</span>
                    </div>
                    <div className="dislike">
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        fill="none"
                      >
                        <path
                          d="M15.8333 12.9492V2.94922H19.1666V12.9492H15.8333ZM12.4999 2.94922C12.9419 2.94922 13.3659 3.12481 13.6784 3.43737C13.991 3.74993 14.1666 4.17386 14.1666 4.61589V12.9492C14.1666 13.4076 13.9833 13.8242 13.6749 14.1242L8.19159 19.6159L7.30825 18.7326C7.08325 18.5076 6.94159 18.1992 6.94159 17.8492L6.96659 17.5909L7.75825 13.7826H2.49992C2.05789 13.7826 1.63397 13.607 1.32141 13.2944C1.00885 12.9818 0.833252 12.5579 0.833252 12.1159V10.4492C0.833252 10.2326 0.874919 10.0326 0.949919 9.84089L3.46659 3.96589C3.71659 3.36589 4.30825 2.94922 4.99992 2.94922H12.4999ZM12.4999 4.61589H4.97492L2.49992 10.4492V12.1159H9.81659L8.87492 16.5492L12.4999 12.9242V4.61589Z"
                          fill="#68838E"
                        />
                      </svg>
                    </div>
                  </Col>
                  <Col lg={8} className="comment">
                  <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        style={{
                          position: "relative",
                          top: "30px",
                          left: "10px",
                        }}
                      >
                        <path
                          d="M7.08341 15.4915H6.66675C3.33341 15.4915 1.66675 14.6999 1.66675 10.7415V6.7832C1.66675 3.61654 3.33341 2.0332 6.66675 2.0332H13.3334C16.6667 2.0332 18.3334 3.61654 18.3334 6.7832V10.7415C18.3334 13.9082 16.6667 15.4915 13.3334 15.4915H12.9167C12.6584 15.4915 12.4084 15.6103 12.2501 15.8082L11.0001 17.3915C10.4501 18.0882 9.55008 18.0882 9.00008 17.3915L7.75008 15.8082C7.61675 15.634 7.30841 15.4915 7.08341 15.4915Z"
                          stroke="#68838E"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M13.3303 9.15755H13.3378"
                          stroke="#68838E"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M9.99632 9.15755H10.0038"
                          stroke="#68838E"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M6.66209 9.15755H6.66957"
                          stroke="#68838E"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <FormControl
                        type="text"
                        placeholder="Add Your Comment"
                        className="comment-input"
                        value={newComment}
                        onChange={handleCommentInputChange}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            addNewComment(cardIndex);
                          }
                        }}
                      />
                    </Col>
                    <Col lg={2} className="mt-4 f-btn d-flex">
                      <div className="d-flex">
                        <span className="px-0" style={{ marginTop: 'auto', marginBottom: 'auto' }}>73</span>
                        <ButtonStyled title="" isLeft={true} className="btn-f" />
                      </div>
                      <div className="d-flex rightlarge">
                        <ButtonStyled
                          title=""
                          isRight={true}
                          className="btn-fa "
                        />
                        <span className="count" style={{ marginTop: 'auto', marginBottom: 'auto' }}>123</span>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>
          </div>
        
      ))}
    </Container>
  











        
        
      </Container>
      </div>
    </AdminLayout>
  );
}

export default Addiction;
