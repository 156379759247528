/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Forget3 = ({ className }) => {
  return (
    <svg
      className={`forget-3 ${className}`}
      fill="none"
      height="27"
      viewBox="0 0 26 27"
      width="26"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M13.0007 5.375C7.58398 5.375 2.95815 8.74417 1.08398 13.5C2.95815 18.2558 7.58398 21.625 13.0007 21.625C18.4173 21.625 23.0431 18.2558 24.9173 13.5C23.0431 8.74417 18.4173 5.375 13.0007 5.375ZM13.0007 18.9167C10.0107 18.9167 7.58398 16.49 7.58398 13.5C7.58398 10.51 10.0107 8.08333 13.0007 8.08333C15.9907 8.08333 18.4173 10.51 18.4173 13.5C18.4173 16.49 15.9907 18.9167 13.0007 18.9167ZM13.0007 10.25C11.2023 10.25 9.75065 11.7017 9.75065 13.5C9.75065 15.2983 11.2023 16.75 13.0007 16.75C14.799 16.75 16.2507 15.2983 16.2507 13.5C16.2507 11.7017 14.799 10.25 13.0007 10.25Z"
        fill="url(#paint0_linear_3216_6600)"
      />
      <defs className="defs">
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_3216_6600"
          x1="6.16843"
          x2="16.6065"
          y1="9.95208"
          y2="21.7096"
        >
          <stop className="stop" stopColor="#3A6FFD" />
          <stop className="stop" offset="1" stopColor="#D63ACA" />
        </linearGradient>
      </defs>
    </svg>
  );
};
