import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import Images from "../../constants/Images";

const JoinCommunitySection = () => {
  return (
    <section className="HomeBestSection mt-5">
      <Container>
        <Row className="align-items-center">
          <Col lg={6} md={6} xs={12} ofs>
            <div className="mask">
              <Image src={Images.HomeCommunity} fluid className="w-100" />
            </div>
          </Col>
          <Col lg={{ span: 5, offset: 1 }} md={{ span: 5, offset: 1 }} xs={12}>
            <p className="therapist " style={{fontStyle:'italic'}}>
              Your professional therapist
            </p>
            <h1>
              Join Our{" "}
              <span>
                Forum <br />
                Community
              </span>
            </h1>
            <div className="separator"></div>
            <p className="effective">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor Lorem ipsum dolor sit amet, consectetur adipiscing
              elit, sed do eiusmod tempor Lorem ipsum dolor sit amet, adipiscing
              elit, sed do eiusmod tempor Lorem ipsum dolor sit amet,
              consectetur adipiscing elit, sed do eiusmod tempor
            </p>
            <a href="#" className="ReadMoreBtn btn">
              Register Yourself{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
              >
                <path
                  d="M10.9082 1.11134L10.9082 0.461344L11.5582 0.461344L11.5582 1.11134L10.9082 1.11134ZM1.5711 11.3677C1.31725 11.6215 0.905698 11.6215 0.651857 11.3677C0.398016 11.1139 0.398016 10.7023 0.651857 10.4485L1.5711 11.3677ZM0.685531 0.461344H10.9082L10.9082 1.76134H0.685531L0.685531 0.461344ZM11.5582 1.11134L11.5582 11.334H10.2582L10.2582 1.11134L11.5582 1.11134ZM11.3678 1.57096L1.5711 11.3677L0.651857 10.4485L10.4486 0.651724L11.3678 1.57096Z"
                  fill="#3B3A43"
                />
              </svg>
            </a>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default JoinCommunitySection;
