import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { InputFields } from "./components/InputFields"

function Popup_3() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        
        </Modal.Header>
        <Modal.Body  className='rounded-4' >
        <div className="login_1">
      <div className="heading">
        <img
          className="login-marketplace"
          alt="Login marketplace"
          src="https://generation-sessions.s3.amazonaws.com/d1c4e9ff428ed422454c2f431d50dc2f/img/login-marketplace@2x.png"
        />
        <div className="div">SIgn to continue</div>
      </div>
      <div className="iput-fields">
        <InputFields
          className="INPUT-FIELDS-instance"
          divClassName="design-component-instance-node"
          divClassNameOverride="design-component-instance-node"
          inputFieldText="Write Your name"
          inputFields="non-active"
          inputFieldsClassName="INPUT-FIELDS-2"
          inputNameText="Name"
          showForgetPassword={false}
          showIcon={false}
        />
        <InputFields
          className="INPUT-FIELDS-instance"
          divClassName="design-component-instance-node"
          divClassName1="INPUT-FIELDS-3"
          divClassNameOverride="design-component-instance-node"
          inputFieldText="Write Your email"
          inputFields="non-active"
          inputFieldsClassName="INPUT-FIELDS-2"
          inputNameText="Email"
          showIcon={false}
        />
      </div>
      <div className="button">
        <div className="text-wrapper-2">Login</div>
      </div>
    </div>
        </Modal.Body >
       
      </Modal>
    </>
  );
}

export default Popup_3;