/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Search = ({ className }) => {
  return (
    <svg
      className={`search ${className}`}
      fill="none"
      height="30"
      viewBox="0 0 30 30"
      width="30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M24.3389 23.5674L24.3399 23.5683C24.3906 23.6186 24.4309 23.6785 24.4584 23.7445C24.4859 23.8104 24.5 23.8812 24.5 23.9526C24.5 24.0241 24.4859 24.0949 24.4584 24.1608C24.4309 24.2268 24.3906 24.2867 24.3399 24.337L24.337 24.3399C24.2867 24.3906 24.2268 24.4309 24.1608 24.4584C24.0949 24.4859 24.0241 24.5 23.9526 24.5C23.8812 24.5 23.8104 24.4859 23.7445 24.4584C23.6785 24.4309 23.6186 24.3906 23.5683 24.3399L23.5674 24.3389L20.0372 20.7984L19.7237 20.484L19.3745 20.7581C18.0074 21.8312 16.3192 22.4135 14.5813 22.4113H14.5807C13.0319 22.4113 11.5179 21.952 10.2302 21.0916C8.94243 20.2312 7.93876 19.0082 7.34608 17.5773C6.75339 16.1464 6.59832 14.572 6.90047 13.053C7.20261 11.534 7.94841 10.1387 9.04355 9.04355C10.1387 7.94841 11.534 7.20261 13.053 6.90047C14.572 6.59832 16.1464 6.75339 17.5773 7.34608C19.0082 7.93876 20.2312 8.94243 21.0916 10.2302C21.952 11.5179 22.4113 13.0319 22.4113 14.5807V14.5813C22.4135 16.3192 21.8312 18.0074 20.7581 19.3745L20.484 19.7237L20.7984 20.0372L24.3389 23.5674ZM8.96991 10.8317C8.22843 11.9414 7.83267 13.246 7.83267 14.5807C7.83267 16.3703 8.54361 18.0867 9.80911 19.3522C11.0746 20.6177 12.791 21.3286 14.5807 21.3286C15.9153 21.3286 17.2199 20.9329 18.3296 20.1914C19.4393 19.4499 20.3042 18.396 20.815 17.163C21.3257 15.93 21.4594 14.5732 21.199 13.2642C20.9386 11.9552 20.2959 10.7528 19.3522 9.80911C18.4085 8.86538 17.2061 8.2227 15.8971 7.96233C14.5881 7.70195 13.2313 7.83559 11.9983 8.34633C10.7653 8.85706 9.71138 9.72197 8.96991 10.8317Z"
        fill="#FDFCFC"
        stroke="url(#paint0_linear_2145_18314)"
      />
      <defs className="defs">
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_2145_18314"
          x1="10.25"
          x2="21.9688"
          y1="11.5313"
          y2="20.5313"
        >
          <stop className="stop" stopColor="#3A6FFD" />
          <stop className="stop" offset="1" stopColor="#D63ACA" />
        </linearGradient>
      </defs>
    </svg>
  );
};
