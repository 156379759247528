import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Form } from "react-bootstrap";


import Input from "../../components/Input";

import AuthLayout from "../../layouts/AuthLayout";
import Images from "../../constants/Images";
import ButtonStyled from "../../components/ButtonStyled";

const Login = () => {
  const navigate = useNavigate();

  return (
    <AuthLayout>
      <section
        className="LoginSection"
        style={{
          backgroundImage: `url(${Images.loginBackground})`,
        }}
      >
        <Container className="main-div">
          <div className="logo_div">
            <img src={Images.Logo} alt="" className="logo-login"/>
          </div>
          <div className="login_form">
            <div>
              <h1>
                Welcome<span>{" "}Back</span>
              </h1>
              <p className="text-center continue">Sign to Continue</p>
              <Form>
                <Input
                  inputType="email"
                  placeHolder="xyz@gmail.com"
                  label="Email"
                />
                <Input
                  placeHolder="********"
                  IsPassword={true}
                  label="Password"
                  inputType="password"
                />
                <div className="mb-3 text-end">
                  <small>
                    <Link
                      to="/forgetPassword"
                      className="text-dark font-weight-bolder"
                    >
                      Forget Password?
                    </Link>
                  </small>
                </div>
                <ButtonStyled
                  title="Login"
                  handler={() => {
                    navigate("/mood");
                  }}
                />
              </Form>
              <p className="text-center register mb-3">
                You have an account?{" "}
                <a
                  href="/sign-up"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/sign-up");
                  }}
                >
                  Register
                </a>
              </p>
            </div>
          </div>
        </Container>
      </section>
    </AuthLayout>
  );
};

export default Login;
