/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Cloud = ({ className }) => {
  return (
    <svg
      className={`cloud ${className}`}
      fill="none"
      height="22"
      viewBox="0 0 22 22"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M6.66526 11.9258C6.17943 11.6783 5.63859 11.5499 5.08859 11.5499C0.798594 11.8524 0.798594 18.0949 5.08859 18.3974H15.2544C16.4919 18.4066 17.6836 17.9483 18.5911 17.1141C21.6069 14.4833 19.9936 9.19411 16.0244 8.68994C14.5944 0.100773 2.19193 3.36411 5.13443 11.5499"
        stroke="#93989A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
      <path
        className="path"
        d="M14.5273 9.09336C15.004 8.85503 15.5265 8.7267 16.0582 8.71753"
        stroke="#93989A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
    </svg>
  );
};
