import React from "react";
import { Frame } from "../../../pages/auth/components/Frame";
import { Col } from "react-bootstrap";
import {BiArrowBack} from "react-icons/bi"
import Images from "../../../constants/Images";
import "./audiocall.scss";
function AudioCall() {
  return (
    <div className="audio-main">
      <div className="BG">
      <div className="top-navbar d-flex" style={{alignItems:'center', paddingTop:'1.46vw'}}>
          <div className="left-div d-flex">
          <div className="back-icon"><BiArrowBack/></div>
          <div className="backscreenname">Appointments</div>
          </div>
          <div className="right-div">
            <div className="notify-icon"><svg width="9.842105263157894vh" height="7.0651340996168583vh" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M26.0206 16.9102C22.7106 16.9102 20.0206 19.6002 20.0206 22.9102V25.8002C20.0206 26.4102 19.7606 27.3402 19.4506 27.8602L18.3006 29.7702C17.5906 30.9502 18.0806 32.2602 19.3806 32.7002C23.6906 34.1402 28.3406 34.1402 32.6506 32.7002C33.8606 32.3002 34.3906 30.8702 33.7306 29.7702L32.5806 27.8602C32.2806 27.3402 32.0206 26.4102 32.0206 25.8002V22.9102C32.0206 19.6102 29.3206 16.9102 26.0206 16.9102Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
          <path d="M27.8699 17.1995C27.5599 17.1095 27.2399 17.0395 26.9099 16.9995C25.9499 16.8795 25.0299 16.9495 24.1699 17.1995C24.4599 16.4595 25.1799 15.9395 26.0199 15.9395C26.8599 15.9395 27.5799 16.4595 27.8699 17.1995Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M29.0195 33.0605C29.0195 34.7105 27.6695 36.0605 26.0195 36.0605C25.1995 36.0605 24.4395 35.7205 23.8995 35.1805C23.3595 34.6405 23.0195 33.8805 23.0195 33.0605" stroke="white" stroke-width="1.5" stroke-miterlimit="10"/>
          <circle cx="35" cy="17" r="3" fill="#E23434"/>
          </svg>
          </div>
            <div className="profile">
              <img src={Images.userprof} alt="" />
            </div>
          </div>
        </div>
      

        <div className="shedule-meeting-card">
          <div className="profile-and-details">
            <div className="pixture" />
            <div className="title-artist">
              <div className="Nelson-jhons-text">Nelson Jhons</div>
              <div className="mental-therapy-text">Mental Therapy</div>
            </div>
          </div>
          <div className="input-fields">
            <div className="text-10-22">10:22s</div>
          </div>
        </div>
        {/* <div className="" style={{ marginTop: "-280px", marginLeft: "-300px" }}>
          <Col lg={6}>
            <Frame line="https://generation-sessions.s3.amazonaws.com/80bef03fe4012f84f42a2870bc4a75ab/img/line-2-1@2x.png" />
          </Col>
        </div> */}
         <div className="last-div">
            <img src={Images.video_call} alt="" className="imageoptions "  />
            
          </div>
      </div>
    </div>
  );
}

export default AudioCall;
