import React from "react";
import { CrouseCard } from "../../../View/Dashboard/components/CrouseCard";
import AdminLayout from "../../../layouts/AdminLayout";
import { Card, Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import Images from "../../../constants/Images";
import "./dashboard.scss";

export const Dashboard_2 = () => {
  return (
    <AdminLayout>
      <div className="dashboard ">
        <div className="div-2 py-5">
        <Container>
          <div className="frame-2 ">
            
          <div className="d-flex justify-content-between  mt-1 trending-div w-100">
            <h3 className="Head">&nbsp;&nbsp;Activity</h3>
            <div className="btn-detaile-div ">
            {/* <ButtonStyled title="Today" className="btn-detaile" isDown={true} /> */}
            <button class="btn-detaile">
              Today 
              <i class="fas fa-chevron-down button-icon"></i>
            </button>
            </div>
            
          </div>
          <Container>
            <div className="frame-3 ps-2">
            <Row className="w-100 mb-0">
              <Col sm={12} lg={6} className="my-2 mx-0 px-2">
              <div className="card " style={{ border:'none',boxShadow: '0 2px 13px rgba(0, 0, 0, 0.101)'}}>
                <div className="frame-4" style={{ marginRight: "auto" }}>
                  <img
                    className="pixture-2"
                    alt="Pixture"
                    src="https://generation-sessions.s3.amazonaws.com/2c9887bec40df5317cc60f30ff0f677d/img/pixture-3@2x.png"
                  />
                  <div className="title-artist">
                    <div className="heading-2">
                      <div className="text-wrapper-9">Your Income</div>
                      <div className="text-wrapper-10">New 22%</div>
                    </div>
                    <div className="text-wrapper-11" style={{color:'#68838E'}}>SInce Last Moth</div>
                  </div>
                </div>
                <div className="text-wrapper-12">$12300</div>
              </div></Col><Col sm={12} lg={6} className="my-2 mx-0 px-2">
              <div className="card" style={{ border:'none',boxShadow: '0 2px 13px rgba(0, 0, 0, 0.101)'}}>
                <div className="frame-4" style={{ marginRight: "auto" }}>
                  <img
                    className="pixture-2"
                    alt="Pixture"
                    src="https://generation-sessions.s3.amazonaws.com/2c9887bec40df5317cc60f30ff0f677d/img/pixture-2@2x.png"
                  />
                  <div className="title-artist">
                    <div className="heading-2">
                      <div className="text-wrapper-9">Appointments</div>
                      <div className="text-wrapper-10">New 22%</div>
                    </div>
                    <div className="text-wrapper-11" style={{color:'#68838E'}}>Today</div>
                  </div>
                </div>
                <div className="text-wrapper-12">12</div>
              </div>
              </Col>
              </Row>
            </div>
            <div className="frame-3 ps-2">
            <Row className="w-100 mt-0">
              <Col sm={12} lg={6} className="mb-2 mt-1 px-2">
              <div className="card" style={{ border:'none',boxShadow: '0 2px 13px rgba(0, 0, 0, 0.101)'}}>
                <div className="frame-4" style={{ marginRight: "auto" }}>
                  <img
                    className="pixture-2"
                    alt="Pixture"
                    src="https://generation-sessions.s3.amazonaws.com/2c9887bec40df5317cc60f30ff0f677d/img/pixture-1@2x.png"
                  />
                  <div className="title-artist">
                    <div className="heading-2">
                      <div className="text-wrapper-9">Course Sold Today</div>
                      <div className="text-wrapper-10">New 22%</div>
                    </div>
                    <div className="text-wrapper-11" style={{color:'#68838E'}}>SInce Last Moth</div>
                  </div>
                </div>
                <div className="text-wrapper-12">2300</div>
              </div></Col>
              <Col sm={12} lg={6} className="mb-2 mt-1 px-2">
              <div className="card" style={{ border:'none',boxShadow: '0 2px 13px rgba(0, 0, 0, 0.101)'}}>
                <div className="frame-4" style={{ marginRight: "auto" }}>
                  <img
                    className="pixture-2"
                    alt="Pixture"
                    src="https://generation-sessions.s3.amazonaws.com/2c9887bec40df5317cc60f30ff0f677d/img/pixture@2x.png"
                  />
                  <div className="title-artist">
                    <div className="heading-2">
                      <div className="text-wrapper-9">Your Clients</div>
                      <div className="text-wrapper-10">New 22%</div>
                    </div>
                    <div className="text-wrapper-11" style={{color:'#68838E'}}>SInce Last Moth</div>
                  </div>
                </div>
                <div className="text-wrapper-12">2300</div>
              </div>
              </Col>
              </Row>
            </div>
            </Container>
          </div></Container>
          <Container>
      <div className="trending-course-2">
      <div className="d-flex justify-content-between  mt-3 trending-div w-100">
            <h3 className="Head">&nbsp;&nbsp;Your Appoinments</h3>
            <div className="btn-detaile-div ">
            {/* <ButtonStyled title="Today" className="btn-detaile" isDown={true} /> */}
            <button class="btn-detaile">
              See all 
              <i class="fas fa-arrow-right button-icon1"></i>
            </button>
            </div>
            
          </div>
          <Container>
        <Row className="pe-3">
          <Col lg={4} md={6} sm={12} className="">
            <img
              className="w-100 "
              alt="Schedule meeting card"
              src={Images.meetingcard1}
            />
          </Col>
          <Col lg={4} md={6} sm={12} className="">
            <img
              className="w-100"
              alt="Schedule meeting card"
              src={Images.meetingcard2}
            />
          </Col>
          <Col lg={4} md={6} sm={12} className="">
            <img
              className="w-100"
              alt="Schedule meeting card"
              src={Images.meetingcard3}
            />
          </Col>
        </Row></Container>
      </div>
    </Container>

          <Container className=" pe-0">
            <Container>
          <div className="trending-course ">
          <div className="d-flex justify-content-between  my-3 me-2 trending-div w-100">
            <h3 className="Head">Your Courses</h3>
            <div className="btn-detaile-div ">
            {/* <ButtonStyled title="Today" className="btn-detaile" isDown={true} /> */}
            <button class="btn-detaile">
              Create 
              
            </button>
            </div>
            
          </div>



            <Row className="dtrendingCol gap-0  w-100 " style={{width:'100%'}}>
            <Col className="my-1 " lg={6}>
              <Card className="rounded-4 dtrending1 p-2 pb-0" style={{ border:'none',boxShadow: '0 2px 13px rgba(0, 0, 0, 0.101)'}}>
              <Row style={{width:'100%'}} className="">
              <div className="dheart-icon-div pe-5 " style={{marginRight:'-6px',marginTop:'16px'}}>
                      <p className="dheart-time">2h 4m 32s</p>
                      <div className="heart-icon">
                          <FontAwesomeIcon
                            icon={faHeart}
                            style={{
                              color: "white",
                              backgroundColor: "rgba(19, 12, 26, 0.15)",
                              width: "18px",
                              height: "18px",
                              padding: "7px",
                              borderRadius: "50%",
                            }}
                          />
                        </div>
                  </div>
                </Row>
               
                <Card.Img
                  src={Images.Course_1}
                  className=" dash-img12 "
                  alt=""
                />
                <Card.Body className="w-100 pb-2 pt-2 ps-2">
                
                
                  <p className="headingcardcources">Managing Stress & Anxiety<br/>
                  <span style={{color:"#93989A"}}>Mental Therapy</span></p>
                  <Row className="mt-2">
                    <Col>
                      <Row>
                        <span className="specialist-price">Price</span>
                      </Row>
                      <Row>
                        <h5 className="price"> $ 120.00</h5>
                      </Row>
                    </Col>
                    {/* <Col sm={3} className="specilistheader1"> */}
                      {/* <span className="booking"> Join Course</span> */}
                    {/* </Col> */}
                  </Row>
                </Card.Body>
                
              </Card>
            </Col>
            <Col className="my-1 " lg={6}>
              <Card className="rounded-4 dtrending1 p-2 pb-0" style={{ border:'none',boxShadow: '0 2px 13px rgba(0, 0, 0, 0.101)'}}>
              <Row style={{width:'100%'}} className="">
              <div className="dheart-icon-div pe-5 " style={{marginRight:'-6px',marginTop:'16px'}}>
                      <p className="dheart-time">2h 4m 32s</p>
                      <div className="heart-icon">
                          <FontAwesomeIcon
                            icon={faHeart}
                            style={{
                              color: "white",
                              backgroundColor: "rgba(19, 12, 26, 0.15)",
                              width: "18px",
                              height: "18px",
                              padding: "7px",
                              borderRadius: "50%",
                            }}
                          />
                        </div>
                  </div>
                </Row>
               
                <Card.Img
                  src={Images.Course_1}
                  className=" dash-img12 "
                  alt=""
                />
                <Card.Body className="w-100 pb-2 pt-2 ps-2">
                
                
                  <p className="headingcardcources">Managing Stress & Anxiety<br/>
                  <span style={{color:"#93989A"}}>Mental Therapy</span></p>
                  <Row className="mt-2">
                    <Col>
                      <Row>
                        <span className="specialist-price">Price</span>
                      </Row>
                      <Row>
                        <h5 className="price"> $ 120.00</h5>
                      </Row>
                    </Col>
                    {/* <Col sm={3} className="specilistheader1"> */}
                      {/* <span className="booking"> Join Course</span> */}
                    {/* </Col> */}
                  </Row>
                </Card.Body>
                
              </Card>
            </Col>
          </Row>
          
  
          </div>
          </Container>
          </Container>
        </div>
      </div>
    </AdminLayout>
  );
};

export default Dashboard_2;
