import React, { useState } from "react";
import { Container, Form } from "react-bootstrap";
import Images from "../../constants/Images";
import AuthLayout from "../../layouts/AuthLayout";
import GroupedOptions from "../../components/GroupedOptions";

const Mood = () => {
  const [values, setValues] = useState([
    {
      text: "Bored",
      image: Images.happymood,
      type: "checkbox",
      name: "userInterest[]",
    },
    {
      text: "Happy",
      image: Images.enjoymood,
      type: "checkbox",
      name: "userInterest[]",
    },
    {
      text: "Excited",
      image: Images.excitedmood,
      type: "checkbox",
      name: "userInterest[]",
    },
    {
      text: "Funny",
      image: Images.funnymood,
      type: "checkbox",
      name: "userInterest[]",
    },
    {
      text: "Satisfied",
      image: Images.Satisfiedmood,
      type: "checkbox",
      name: "userInterest[]",
    },
    {
      text: "Normal",
      image: Images.normal,
      type: "checkbox",
      name: "userInterest[]",
    },
    {
      text: "Extremely Happy",
      image: Images.enjoymood,
      type: "checkbox",
      name: "userInterest[]",
    },
    {
      text: "Nervous",
      image: Images.excitedmood,
      type: "checkbox",
      name: "userInterest[]",
    },
    {
      text: "Funny",
      image: Images.funnymood,
      type: "checkbox",
      name: "userInterest[]",
    },
    {
      text: "Extremely Happy",
      image: Images.enjoymood,
      type: "checkbox",
      name: "userInterest[]",
    },
    {
      text: "Excited",
      image: Images.excitedmood,
      type: "checkbox",
      name: "userInterest[]",
    },
  ]);
  return (
    <AuthLayout>
      <section
        className="LoginSection"
        style={{
          backgroundImage: `url(${Images.loginBackground})`,
        }}
      >
        <Container className="main-div">
          <div className="logo_div">
            <img src={Images.Logo} alt="" className="logo-login"/>
          </div>
          <div
            className="login_form"
            style={{
              width: "73%",backgroundColor:'none'
            }}
          >
            <div>
              <h1>
                Choose Your<span> Mood</span>
              </h1>
              <p className="text-center continue" style={{fontFamily: 'Jost',
fontSize: '18px',
fontWeight: '400',
lineHeight: '26px',
letterSpacing: '0px',
textAlign:'center'
}}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                <br /> sed do eiusmod tempor incididunt ut labore
              </p>
              <Form.Group
                className="mb-3 form-group"
                controlId="exampleFormControlSelect1"
              >
                <GroupedOptions values={values} isImage={true} />
              </Form.Group>
            </div>
          </div>
        </Container>
      </section>
    </AuthLayout>
  );
};

export default Mood;
