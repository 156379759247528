import React from "react";
import AdminLayout from "../../../layouts/AdminLayout";
import { Container, Table } from "react-bootstrap";
import ButtonStyled from "../../../components/ButtonStyled";
import { Button, Image } from "react-bootstrap";
import Images from "../../../constants/Images";
import "./market.scss"; // Import your CSS file

function Market() {
  return (
    <AdminLayout>
      <div className="market">
        <Container>
          <div className="d-flex justify-content-between mt-3">
            <h2 >Recent Orders</h2>
            <div className="d-flex">
              <p style={{ color: "#44BFE5", marginTop: "10px" }}>
                Go to Orders Page
              </p>{" "}
              &nbsp;{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                style={{ marginTop: "8px" }}
              >
                <g clip-path="url(#clip0_2022_13332)">
                  <path
                    d="M1 15H15"
                    stroke="#44BFE5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11 19L15 15"
                    stroke="#44BFE5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11 11L15 15"
                    stroke="#44BFE5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2022_13332">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div
            style={{
              boxShadow: "0px 0px 20px rgba(170, 195, 192, 0.384)",
              paddingBottom: "70px",
              borderRadius: "20px",
              paddingLeft:"15px",paddingRight:"15px",
            }}
          >
            <Table
              responsive
              bordered
              className="table table-borderless tablemarket table-curved"
            >
              
              <thead className="tableheading">
                <tr >
                  <th scope="col" className=" " style={{ fontStyle: "normal", fontWeight: "normal",color:'#879BA4',width:'80px',  }}>
                    <div className="line ">
                    Item Name
                    </div>
                  </th>
                  <th
                    scope="col"
                    style={{ fontStyle: "normal", fontWeight: "normal",color:'#879BA4'}}
                  >
                    <div className="item-name line">
                    Qty
                    </div>
                  </th>
                  <th
                    scope="col"
                    style={{ fontStyle: "normal", fontWeight: "normal" ,color:'#879BA4' }}
                  >
                    <div className="item-name line">
                    Order Date
                    </div>
                  </th>
                  <th
                    scope="col"
                    style={{ fontStyle: "normal", fontWeight: "normal",color:'#879BA4'  }}
                  >
                    <div className="item-name line">
                    Amount
                    </div>
                  </th>
                  <th
                    scope="col"
                    style={{ fontStyle: "normal", fontWeight: "normal" ,color:'#879BA4' }}
                  >
                    
                    Status
                   
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  className="rounded-5 hover-effect" style={
{borderRadius:'50%'}
                  }
                  
                >
                 
                  <td className="quick_text">
                    <div className="line item-name">
                      <Image src={Images.Market_1}></Image>
                      XS™ Sports Protein Shakes – Ri... &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                  </td>
                  <td className="quick_text">
                    <div className="line item-name">1</div>
                  </td>
                  <td className="quick_text">
                    <div className="line" style={{color:'#879BA4'}}>January 20, 2022</div>
                  </td>
                  <td className="quick_text">
                    <div className="line item-name">$799.00</div>
                  </td>
                  <td className="quick_text">
                    <Button className="pending">Pending</Button>
                  </td>
                </tr>
                <tr className="rounded-4 hover-effect">
                  <td className="quick_text">
                    <div className="line item-name">
                      <Image src={Images.Market_1}></Image>
                      XS™ Sports Protein Shakes – Ri...
                    </div>
                  </td>
                  <td className="quick_text">
                    <div className="line item-name">1 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                  </td>
                  <td className="quick_text">
                    <div className="line" style={{color:'#879BA4'}}>January 20, 2022</div>
                  </td>
                  <td className="quick_text">
                    <div className="line item-name">$799.00</div>
                  </td>
                  <td className="quick_text">
                    {" "}
                    <Button className="pending">Pending</Button>
                  </td>
                </tr>
                <tr className="rounded-4 hover-effect">
                  <td className="quick_text">
                    <div className="line item-name">
                      <Image src={Images.Market_1}></Image>
                      XS™ Sports Protein Shakes – Ri...
                    </div>
                  </td>
                  <td className="quick_text">
                    <div className="line item-name">1</div>
                  </td>
                  <td className="quick_text">
                    <div className="line item-name" style={{color:'#879BA4'}}>January 20, 2022</div>
                  </td>
                  <td className="quick_text">
                    <div className="line item-name">$799.00</div>
                  </td>
                  <td className="quick_text">
                    {" "}
                    <Button className="inprocess">In Process</Button>
                  </td>
                </tr>
                <tr className="rounded-4 hover-effect">
                  <td className="quick_text">
                    <div className="line item-name">
                      <Image src={Images.Market_1}></Image>
                      XS™ Sports Protein Shakes – Ri...
                    </div>
                  </td>
                  <td className="quick_text">
                    <div className="line item-name">1</div>
                  </td>
                  <td className="quick_text">
                    <div className="line item-name"style={{color:'#879BA4'}}>January 20, 2022</div>
                  </td>
                  <td className="quick_text">
                    <div className="line item-name">$799.00</div>
                  </td>
                  <td className="quick_text ">
                    {" "}
                    <Button className="approved">Approved</Button>
                  </td>
                </tr>
                {/* Other table rows */}
              </tbody>
              
            </Table>
          </div>
        </Container>
      </div>
    </AdminLayout>
  );
}

export default Market;
