import React from "react";
import AdminLayout from "../../../layouts/AdminLayout";
import All from "../../../View/Forum/All";
import Images from "../../../constants/Images";
import ForumNav from "../../../View/Forum/ForumNav";
import "./style.scss";
import {
  Navbar,
  Nav,
  Image,
  Container,
  Dropdown,
  Col,
  Row,
} from "react-bootstrap";
import { Link } from "react-router-dom";
function Forummain() {
  return (
    <AdminLayout>
      <div className="bg-white w-100 pb-3" style={{minHeight:'100vh'}}>
      <Container>
        <Row>
          {" "}
          <Col sm={8}>
            <img src={Images.Fashion_1} alt="" className="forum-banner1" />{" "}
          </Col>
          <Col sm={4}>
            <img src={Images.Fashion_2} alt="" className="forum-banner2" />{" "}
          </Col>
        </Row>
      </Container>
      <Container className="pb-0 mb-0">
      <container>
          <h3 className="mt-4">Forum Discussion</h3>
          <ForumNav />
         
        </container>
        <All />
      </Container>
      <Container className="mb-5 mt-0 " >
        <Row style={{marginTop:'-40px'}}>
          {" "}
          <Col md={12}>
            <img src={Images.Fashion_3} alt="" className="forum-banner3" />{" "}
          </Col>
        </Row>
      </Container>
      </div>
    </AdminLayout>
  );
}

export default Forummain;
