/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Youtube1 = ({ className }) => {
  return (
    <svg
      className={`youtube-1 ${className}`}
      fill="none"
      height="18"
      viewBox="0 0 18 18"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_1277_11488)">
        <path
          className="path"
          d="M17.6415 4.63909C17.5397 4.25615 17.3392 3.90666 17.0599 3.62558C16.7806 3.34451 16.4324 3.14172 16.0501 3.0375C14.6429 2.65909 9.01785 2.65909 9.01785 2.65909C9.01785 2.65909 3.39285 2.65909 1.98558 3.0375C1.6033 3.14172 1.2551 3.34451 0.975819 3.62558C0.69654 3.90666 0.495982 4.25615 0.394216 4.63909C0.0178528 6.0525 0.0178528 9 0.0178528 9C0.0178528 9 0.0178528 11.9475 0.394216 13.3609C0.495982 13.7438 0.69654 14.0933 0.975819 14.3744C1.2551 14.6555 1.6033 14.8583 1.98558 14.9625C3.39285 15.3409 9.01785 15.3409 9.01785 15.3409C9.01785 15.3409 14.6429 15.3409 16.0501 14.9625C16.4324 14.8583 16.7806 14.6555 17.0599 14.3744C17.3392 14.0933 17.5397 13.7438 17.6415 13.3609C18.0179 11.9475 18.0179 9 18.0179 9C18.0179 9 18.0179 6.0525 17.6415 4.63909Z"
          fill="#FF0302"
        />
        <path className="path" d="M7.17696 11.6765V6.32355L11.8815 9.00002L7.17696 11.6765Z" fill="#FEFEFE" />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_1277_11488">
          <rect className="rect" fill="white" height="18" width="18" />
        </clipPath>
      </defs>
    </svg>
  );
};
