import React from 'react'
import { Dropdown, Image } from 'react-bootstrap'
import Images from '../constants/Images'

function Profile() {
  return (
 
    <Dropdown  id="AuthorInfo" className='profile' style={{border:'none'}}>
    <Dropdown.Toggle>
    <div className='picBorder'>
      <Image src={Images.NavAuthor} fluid/>
      </div>
      <span className='p-2'>Robert Davis</span>
      <div className="status-indicator"></div>
    </Dropdown.Toggle>
    <Dropdown.Menu>
      <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
      <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
      <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
  )
}

export default Profile
