import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import ButtonStyled from "../../components/ButtonStyled";

function ForumNav() {
  return (
    <Navbar  className="">
    
    
      <Nav className="forummenu mb-2 mb-lg-0 ms-0 pt-1 w-100 " as="ul">
        
          <Nav.Item as="li">
          <Link className="nav-link colorgray" to="/forum">
            <div style={{marginTop:'-10px'}}>
            <ButtonStyled title="All" className="factive"  /></div>
            </Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Link className="nav-link colorgray" to="/main_forum">
             
             
              Addictions
            </Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Link className="nav-link colorgray" to="/cancers">
              Cancers
            </Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Link className="nav-link colorgray" to="/familyIssue">
              Family Issue
            </Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Link className="nav-link colorgray" to="/Grief">
              Grief
            </Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Link className="nav-link colorgray" to="/news">
              Relationship & love
            </Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Link className="nav-link colorgray" to="/relationship&love">
              Relationship & love
            </Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Link className="nav-link colorgray" to="/therapies">
              Therapies
            </Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Link className="nav-link colorgray" to="/violence">
              Violence
            </Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Link className="nav-link colorgray" to="/personaldevelopment&identity">
              Personal Development & Identity
            </Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Link className="nav-link colorgray" to="/psychological">
              Psychological Troubles
            </Link>
          </Nav.Item>
        </Nav>
      
    </Navbar>
  );
}

export default ForumNav;
