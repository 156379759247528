import React, { useState } from "react";
import AdminLayout from "../../layouts/AdminLayout";
import ButtonStyled from "../../components/ButtonStyled";
import { Col, Row, Container } from "react-bootstrap";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Routes,
} from "react-router-dom";
import SavedProduct from "../../View/Saved/SavedProduct";
import SavedSpecialist from "./SavedSpecialist";
import OurSpecialistsSection from "../../components/Home/OurSpecialistsSection";
import MarketPlace from "../../components/Home/MarketPlace";
import "./style.scss";
<style>
  @import
  url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;400;500;600;700;900&family=Jost:wght@100&family=Montserrat&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
</style>;

function Saved() {
  const [isProductsActive, setProductsActive] = useState(false); // State for Products button
  const [isSpecialistActive, setSpecialistActive] = useState(false); // State for Specialist button

  // Handler functions to toggle the button state
  const handleProductsClick = () => {
    setProductsActive(!isProductsActive);
    setSpecialistActive(false);
  };

  const handleSpecialistClick = () => {
    setProductsActive(false);
    setSpecialistActive(!isSpecialistActive);
  };

  console.log(
    "Products button class:",
    isProductsActive ? "products active" : "products"
  );
  console.log(
    "Specialist button class:",
    isSpecialistActive ? "specialist active" : "specialist"
  );
const role='specialist';
  return (
    <AdminLayout>
      <div className="saved-div" style={{ backgroundColor: "white" }}>
        <Container>
          <Row className="d-flex mb-5">
            <Col sm={12} md={3} className="saved-col">
              <h1 style={{ paddingLeft: "25px" }} className="Saved-heading">
                Saved
              </h1>
            </Col>
            <Col sm={12} md={4} className="d-flex btn-positions">
            <Link to='/favorites'>     <button className="button-product" >Products</button></Link>
       {role=='specialist'?<Link to='/favourite_specialists'>      <button className="button-product ms-4" style={{background:'#68838E0D',color:'#68838E'}}>Friends</button></Link> :       <Link to='/favourite_specialists'>      <button className="button-product ms-4" style={{background:'#68838E0D',color:'#68838E'}}>Specialists</button></Link> 
}
       
              {/* <Link to="/savedProduct">
                <ButtonStyled
                  title="Products"
                  className={isProductsActive ? "products active" : "products"}
                  onClick={handleProductsClick}
                />
              </Link>
              <Link to="/favourite_specialists">
                <ButtonStyled
                  title={<span>Specialist</span>}
                  className={
                    isSpecialistActive ? "specialist" : "specialist active"
                  }
                  onClick={handleSpecialistClick}
                />
              </Link> */}
            </Col>
          </Row>

          <Container>
            <SavedProduct />
            
          </Container>
        </Container>
      </div>
    </AdminLayout>
  );
}

export default Saved;
