/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Article = ({
  className,
  maskGroup = "https://generation-sessions.s3.amazonaws.com/afa96db6e9e1a2c4ef7266442ae274fd/img/mask-group-3@2x.png",
}) => {
  return (
    <div className={`article ${className}`}>
      <img className="mask-group" alt="Mask group" src={maskGroup} />
      <div className="frame_1">
        <div className="div">
          <div className="text-wrapper">12.11.2022</div>
          <p className="p">Identifying And Treating Pain From Nerve Tension</p>
          <p className="text-wrapper-2">
            Lorem ipsum dolor sit amet, consectetur <br></br>adipiscing elit, sed do eiusmod tempor Lorem ipsum dolor sit ame
            Lorem ipsum dolor sit amet,<br></br> consectetur adipiscing elit, sed do eiusmod <br></br>tempor Lorem ipsum dolor sit amet...
          </p>
        </div>
        <div className="group">
          <div className="overlap-group">
            <div className="rectangle" />
            <div className="text-wrapper-3">Read more</div>
            <img
              className="vector"
              alt="Vector"
              src="https://generation-sessions.s3.amazonaws.com/afa96db6e9e1a2c4ef7266442ae274fd/img/vector-14-3.svg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Article.propTypes = {
  maskGroup: PropTypes.string,
};
