import React from "react";
import { AtomCardLogo } from "./MarketPlace_Two/components/AtomCardLogo";
import { Button } from "./MarketPlace_Two/components/Button";
import { InputFields } from "./MarketPlace_Two/components/InputFields";
import { Cloud } from "./MarketPlace_Two/icons/Cloud";
import { ExpandDownLight } from "./MarketPlace_Two/icons/ExpandDownLight";
import { Heart } from "./MarketPlace_Two/icons/Heart";
import { Location1 } from "./MarketPlace_Two/icons/Location1";
import { ProfileCircle4 } from "./MarketPlace_Two/icons/ProfileCircle4";
import { Sms1 } from "./MarketPlace_Two/icons/Sms1";


export const Setting_3 = () => {
  return (
    <div className="setting_3">
      <div className="div-2">
        <header className="header">
          <div className="overlap-group">
            <div className="overlap">
              <div className="rectangle" />
              <div className="rectangle-2" />
              <div className="rectangle-3" />
              <div className="rectangle-4" />
              <div className="rectangle-5" />
              <div className="options">
                <div className="dashboard">
                  <img
                    className="group"
                    alt="Group"
                    src="https://generation-sessions.s3.amazonaws.com/2e5e02ef756cfc0eeb3b97a645915238/img/group-33839@2x.png"
                  />
                  <div className="text-wrapper-2">Dashboard</div>
                </div>
                <div className="option">
                  <Heart className="icon-instance-node" />
                  <div className="text-wrapper-3">Your Products</div>
                </div>
                <div className="option-2">
                  <img
                    className="icon-instance-node"
                    alt="Uil meeting board"
                    src="https://generation-sessions.s3.amazonaws.com/2e5e02ef756cfc0eeb3b97a645915238/img/uil-meeting-board.svg"
                  />
                  <div className="text-wrapper-4">Setting</div>
                </div>
              </div>
            </div>
            <div className="overlap-2">
              <div className="text-wrapper-5">Robert Davis</div>
              <ExpandDownLight className="expand-down-light" />
              <div className="group-wrapper">
                <div className="ellipse-wrapper">
                  <div className="ellipse" />
                </div>
              </div>
            </div>
            <img
              className="frame"
              alt="Frame"
              src="https://generation-sessions.s3.amazonaws.com/2e5e02ef756cfc0eeb3b97a645915238/img/frame.svg"
            />
          </div>
        </header>
        <div className="personal-info">
          <div className="text-wrapper-6">Personal Information</div>
          <div className="first-last-name">
            <InputFields anser="Kevin" className="input-fields-instance" property1={false} />
            <div className="input-fields-2">
              <div className="text-wrapper-7">Last Name</div>
              <div className="title-wrapper">
                <div className="title-2">
                  <ProfileCircle4 className="icon-instance-node" />
                  <div className="kevin-2">Write Here</div>
                </div>
              </div>
            </div>
            <div className="input-fields-2">
              <div className="text-wrapper-7">Store Name</div>
              <div className="title-wrapper">
                <div className="title-2">
                  <ProfileCircle4 className="icon-instance-node" />
                  <div className="kevin-2">Write Here</div>
                </div>
              </div>
            </div>
          </div>
          <div className="frame-2">
            <div className="input-fields-2">
              <div className="text-wrapper-7">Store Name</div>
              <div className="title-wrapper">
                <div className="title-2">
                  <ProfileCircle4 className="icon-instance-node" />
                  <div className="kevin-2">Write Here</div>
                </div>
              </div>
            </div>
            <div className="input-fields-2">
              <div className="text-wrapper-7">Email Address</div>
              <div className="title-wrapper">
                <div className="title-2">
                  <Sms1 className="icon-instance-node" />
                  <div className="kevin-2">Sympathiq@gmail.com</div>
                </div>
              </div>
            </div>
          </div>
          <div className="input-fields-3">
            <div className="text-wrapper-7">Address</div>
            <div className="title-wrapper">
              <div className="title-2">
                <Location1 className="icon-instance-node" />
                <div className="kevin-2">California USA 123 Street</div>
              </div>
            </div>
          </div>
          <div className="frame-3">
            <div className="input-fields-2">
              <div className="text-wrapper-7">Postal Code</div>
              <div className="title-wrapper">
                <div className="title-2">
                  <div className="kevin-2">00000</div>
                </div>
              </div>
            </div>
            <div className="input-fields-2">
              <div className="text-wrapper-7">Timezone</div>
              <div className="title-wrapper">
                <div className="title-2">
                  <div className="kevin-2">United kingdom</div>
                </div>
              </div>
            </div>
          </div>
          <div className="input-fields-3">
            <div className="text-wrapper-7">Tell About Yourself</div>
            <div className="div-wrapper">
              <div className="title-3">
                <p className="lorem-ispum-erina">
                  Lorem ispum erina iarma hianna&nbsp;&nbsp;hye jbfewne sbfnskfn ejbgf lorek udns o nwiuefn uiefhjoan .
                  casjnfojn ajsnLorem ispum erina iarma hianna&nbsp;&nbsp;hye jbfewne sbfnskfn ejbgf lorek udns o
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="personal-info-2">
          <div className="frame-4">
            <div className="text-wrapper-8">Add Your Account Information</div>
            <Button className="button-instance" divClassName="design-component-instance-node" text="Add" type="ghost" />
          </div>
          <div className="frame-3">
            <div className="input-fields-2">
              <div className="text-wrapper-7">Name on Card</div>
              <div className="title-wrapper">
                <div className="title-2">
                  <div className="kevin-2">Uanice</div>
                </div>
              </div>
            </div>
            <div className="input-fields-2">
              <div className="text-wrapper-7">Card Number</div>
              <div className="title-wrapper">
                <div className="title-2">
                  <AtomCardLogo
                    type="mastercard"
                    typeMastercard="https://generation-sessions.s3.amazonaws.com/2e5e02ef756cfc0eeb3b97a645915238/img/atom-card-logo.svg"
                    typeMastercardClassName="atom-card-logo"
                  />
                  <div className="kevin-2">XXXX XXXXX XXXX</div>
                </div>
              </div>
            </div>
          </div>
          <div className="frame-3">
            <div className="input-fields-2">
              <div className="text-wrapper-7">Expiry Date</div>
              <div className="title-wrapper">
                <div className="title-2">
                  <div className="kevin-2">Uanice</div>
                </div>
              </div>
            </div>
            <div className="input-fields-2">
              <div className="text-wrapper-7">CVV/CVC</div>
              <div className="title-wrapper">
                <div className="title-2">
                  <div className="kevin-2">Uanice</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="frame-5">
          <div className="text-wrapper-9">Your Photo</div>
          <img
            className="line"
            alt="Line"
            src="https://generation-sessions.s3.amazonaws.com/2e5e02ef756cfc0eeb3b97a645915238/img/line-2.svg"
          />
          <div className="frame-6">
            <img
              className="img"
              alt="Ellipse"
              src="https://generation-sessions.s3.amazonaws.com/2e5e02ef756cfc0eeb3b97a645915238/img/ellipse-1023@2x.png"
            />
            <div className="info-action">
              <div className="text-wrapper-10">Edit your photo</div>
              <div className="action">
                <div className="text-wrapper-11">Delete</div>
                <div className="text-wrapper-12">Update</div>
              </div>
            </div>
          </div>
          <div className="frame-wrapper">
            <div className="frame-7">
              <div className="cloud-wrapper">
                <Cloud className="cloud-instance" />
              </div>
              <div className="content">
                <p className="p">Click to upload or drag and drop</p>
                <p className="PNG-JPG-or-gif-max">
                  PNG, JPG or Gif
                  <br />
                  Max 20Mb
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Setting_3;
