import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';

const localizer = momentLocalizer(moment);

const events = [
  {
    title: 'Event 1',
    start: new Date(2023, 7, 10, 10, 0), // August 10th, 2023, 10:00 AM
    end: new Date(2023, 7, 10, 12, 0),  // August 10th, 2023, 12:00 PM
  },
  // More events...
];

const workHours = {
  start: new Date().setHours(8, 0, 0), // 8:00 AM
  end: new Date().setHours(24, 0, 0), // 12:00 AM (midnight)
};

const CalendarAppoint = () => (
  <div>
    <Calendar
      localizer={localizer}
      events={events}
      startAccessor="start"
      endAccessor="end"
      views={['week']}
      step={60} // 1 hour step for time slots
      min={workHours.start} // Set the minimum visible date/time
      max={workHours.end}   // Set the maximum visible date/time
      timeslots={1} // Show hourly slots
      defaultView="week" // Default view when the calendar loads
      components={{
        timeSlotWrapper: CustomTimeSlotWrapper,
      }}
    />
  </div>
);

const CustomTimeSlotWrapper = ({ children, value }) => {
  const hour = moment(value).hour();
  return (
    <div className={hour >= 8 ? 'hour-slot' : 'hidden-slot'}>
      {children}
    </div>
  );
};

export default CalendarAppoint;
