/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const CircleRightAltLight = ({ color = "#EFD20D", className }) => {
  return (
    <svg
      className={`circle-right-alt-light ${className}`}
      fill="none"
      height="28"
      viewBox="0 0 28 28"
      width="28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path className="path" d="M11.6673 12.8335L16.334 17.5002L11.6673 22.1668" stroke={color} />
      <path
        className="path"
        d="M11.2824 7.17889C8.67559 7.52814 6.43782 8.36584 5.03351 9.51815C3.6292 10.6705 3.16619 12.0489 3.74056 13.3673C4.31493 14.6858 5.88258 15.8431 8.11818 16.599C10.3538 17.3549 13.0856 17.6513 15.7468 17.4268"
        stroke={color}
        strokeLinecap="round"
      />
      <path
        className="path"
        d="M23.0933 14.875C23.8986 14.1776 24.3722 13.3969 24.4775 12.5934C24.5829 11.7898 24.3171 10.9849 23.7007 10.2409C23.0844 9.49693 22.134 8.8338 20.9231 8.30284C19.7122 7.77188 18.2733 7.38731 16.7176 7.17889"
        stroke={color}
        strokeLinecap="round"
      />
    </svg>
  );
};

CircleRightAltLight.propTypes = {
  color: PropTypes.string,
};
