import React from "react";
import { Frame } from "./components/Frame";
import { FrameWrapper } from "./components/FrameWrapper";

import { Basket } from "./icons/Basket";
import { Basket12 } from "./icons/Basket12";
import { CircleRightAltLight } from "./icons/CircleRightAltLight";

import { Favorite } from "./icons/Favorite";
import { Youtube1 } from "./icons/Youtube1";
import Images from "../../../constants/Images";
import Navigation from "../../../components/Navigation";



export const MarketPlace = () => {
  return (
    <div className="market-place">
      <div className="div-2">
        <div className="hero">
          <div className="div-wrapper">
          
          </div>
        </div>
        <div className="ellipse-3" />
        <img
          className="img-2"
          alt="Img"
          src= {Images.Market_2}
        />
      <Navigation/>
        <div className="footer">
          <div className="footer-2">
            <div className="overlap-3">
              <p className="element-SYMPATHIQ">
                <span className="text-wrapper-8">© 2015-2022 </span>
                <span className="text-wrapper-9">SYMPATHIQ</span>
                <span className="text-wrapper-8">, All Rights Reserved.</span>
              </p>
              <div className="rectangle-7" />
            </div>
            <div className="overlap-4">
              <div className="privacy-policy">PRIVACY POLICY</div>
              <div className="overlap-wrapper">
                <div className="vector-wrapper">
                  <img
                    className="vector-2"
                    alt="Vector"
                    src="https://generation-sessions.s3.amazonaws.com/5e66f080cc2b7aa215e8b54c595b9855/img/vector-3.svg"
                  />
                </div>
              </div>
              <div className="rectangle-8" />
            </div>
            <div className="overlap-5">
              <div className="cookie-policy">COOKIE POLICY</div>
              <div className="text-wrapper-10">sales@bioagrotop.ru</div>
            </div>
            <p className="home-marketplace-2">
              <span className="span">Home&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className="text-wrapper-11">
                Marketplace&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                Forum&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Specialists&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Courses&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;News
              </span>
            </p>
            <div className="overlap-6">
              <p className="text-wrapper-12">Join the thousands that have become happier!</p>
              <div className="overlap-group-wrapper">
                <div className="overlap-group-2">
                  <div className="text-wrapper-13">Register</div>
                  <img
                    className="vector-3"
                    alt="Vector"
                    src="https://generation-sessions.s3.amazonaws.com/5e66f080cc2b7aa215e8b54c595b9855/img/vector-15.svg"
                  />
                </div>
              </div>
            </div>
            <div className="text-wrapper-14">Адрес компании..................................</div>
            <div className="group-2">
              <div className="subtract-wrapper">
                <img
                  className="subtract"
                  alt="Subtract"
                  src="https://generation-sessions.s3.amazonaws.com/5e66f080cc2b7aa215e8b54c595b9855/img/subtract-1.svg"
                />
              </div>
            </div>
            <img
              className="vector-4"
              alt="Vector"
              src="https://generation-sessions.s3.amazonaws.com/5e66f080cc2b7aa215e8b54c595b9855/img/vector-189.svg"
            />
            <div className="rectangle-9" />
            <div className="rectangle-10" />
            <p className="text-wrapper-15">+33 695 12 03 33</p>
            <img
              className="vector-5"
              alt="Vector"
              src="https://generation-sessions.s3.amazonaws.com/5e66f080cc2b7aa215e8b54c595b9855/img/vector.svg"
            />
            <img
              className="group-3"
              alt="Group"
              src="https://generation-sessions.s3.amazonaws.com/5e66f080cc2b7aa215e8b54c595b9855/img/group@2x.png"
            />
            <img
              className="vector-6"
              alt="Vector"
              src="https://generation-sessions.s3.amazonaws.com/5e66f080cc2b7aa215e8b54c595b9855/img/vector-1.svg"
            />
            <img
              className="subtract-2"
              alt="Subtract"
              src="https://generation-sessions.s3.amazonaws.com/5e66f080cc2b7aa215e8b54c595b9855/img/subtract-2.svg"
            />
            <Youtube1 className="you-tube" />
            <img
              className="frame-7"
              alt="Frame"
              src="https://generation-sessions.s3.amazonaws.com/5e66f080cc2b7aa215e8b54c595b9855/img/frame-1.svg"
            />
          </div>
        </div>
        <div className="group-4">
          <div className="overlap-7">
            <div className="rectangle-11" />
            <div className="group-5">
              <p className="ALL-WELLNESS-SEX">
                <span className="text-wrapper-16">ALL WELLNESS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <span className="text-wrapper-17">
                  SEX TOYS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;AROMATHERAPY&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;FOOD &amp;
                  DRINK&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ORAL
                  CARE&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;WORKOUT&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TOOLS &amp;
                  DEVICES&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;VITAMINS &amp;
                  SUPPLEMENTS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;IMMUNITY&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;MINDFULNESS
                </span>
              </p>
              <div className="rectangle-12" />
            </div>
          </div>
        </div>
        <div className="group-6">
          <div className="rectangle-13" />
          <div className="overlap-8">
            <div className="rectangle-14" />
            <div className="group-7">
              <div className="group-8">
                <div className="overlap-group-3">
                  <div className="overlap-9">
                    <div className="ellipse-4" />
                    <Favorite className="favorite-6" color="white" />
                    <img
                      className="image"
                      alt="Image"
                      src={Images.Product_6}
                    />
                  </div>
                  <div className="overlap-10">
                    <div className="overlap-11">
                      <p className="text-wrapper-18">XS™ Sports Protein Shakes – Rich Chocolate</p>
                      <div className="basket-wrapper">
                        <Basket className="icon-instance-node" />
                      </div>
                    </div>
                    <div className="text-wrapper-19">$53.99</div>
                    <div className="text-wrapper-20">$35.99</div>
                  </div>
                  <img
                    className="group-9"
                    alt="Group"
                    src="https://generation-sessions.s3.amazonaws.com/5e66f080cc2b7aa215e8b54c595b9855/img/group-180-11@2x.png"
                  />
                </div>
              </div>
              <div className="group-10">
                <div className="overlap-12">
                  <div className="div-3" />
                  <div className="group-11">
                    <div className="group-12">
                      <div className="overlap-group-4">
                        <div className="text-wrapper-21">-40%</div>
                      </div>
                    </div>
                  </div>
                  <div className="ellipse-5" />
                  <Favorite className="favorite-6" color="#1F1E28" />
                  <img
                    className="image-2"
                    alt="Image"
                    src= {Images.Product_1}
                  />
                </div>
                <div className="overlap-13">
                  <div className="overlap-11">
                    <p className="text-wrapper-22">XS™ Sports Protein Shakes – Rich Chocolate</p>
                    <div className="group-13">
                      <Basket className="icon-instance-node" />
                    </div>
                  </div>
                  <div className="text-wrapper-19">$53.99</div>
                  <div className="text-wrapper-20">$35.99</div>
                </div>
                <img
                  className="group-14"
                  alt="Group"
                  src="https://generation-sessions.s3.amazonaws.com/5e66f080cc2b7aa215e8b54c595b9855/img/group-180-11@2x.png"
                />
              </div>
              <div className="group-15">
                <div className="overlap-12">
                  <div className="div-3" />
                  <div className="group-11">
                    <div className="group-12">
                      <div className="overlap-group-5">
                        <div className="text-wrapper-21">-40%</div>
                      </div>
                    </div>
                  </div>
                  <div className="ellipse-5" />
                  <Favorite className="favorite-6" color="#1F1E28" />
                  <img
                    className="image-2"
                    alt="Image"
                    src={Images.Product_6}
                  />
                </div>
                <div className="overlap-13">
                  <div className="overlap-11">
                    <p className="text-wrapper-22">XS™ Sports Protein Shakes – Rich Chocolate</p>
                    <div className="group-13">
                      <Basket12 className="icon-instance-node" />
                    </div>
                  </div>
                  <div className="text-wrapper-19">$53.99</div>
                  <div className="text-wrapper-20">$35.99</div>
                </div>
                <img
                  className="group-14"
                  alt="Group"
                  src="https://generation-sessions.s3.amazonaws.com/5e66f080cc2b7aa215e8b54c595b9855/img/group-180-11@2x.png"
                />
              </div>
              <div className="group-16">
                <div className="div-3">
                  <img
                    className="image-3"
                    alt="Image"
                    src={Images.Product_4}
                  />
                  <div className="ellipse-5" />
                  <Favorite className="favorite-6" color="#1F1E28" />
                  <div className="group-11">
                    <div className="group-12">
                      <div className="overlap-group-6">
                        <div className="text-wrapper-21">-40%</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="overlap-13">
                  <div className="overlap-11">
                    <p className="text-wrapper-22">XS™ Sports Protein Shakes – Rich Chocolate</p>
                    <div className="group-13">
                      <Basket className="icon-instance-node" />
                    </div>
                  </div>
                  <div className="text-wrapper-19">$53.99</div>
                  <div className="text-wrapper-20">$35.99</div>
                </div>
                <img
                  className="group-14"
                  alt="Group"
                  src="https://generation-sessions.s3.amazonaws.com/5e66f080cc2b7aa215e8b54c595b9855/img/group-180-11@2x.png"
                />
              </div>
              <div className="group-17">
                <div className="div-3">
                  <div className="group-11">
                    <div className="group-12">
                      <div className="overlap-group-7">
                        <div className="text-wrapper-21">-40%</div>
                      </div>
                    </div>
                  </div>
                  <div className="overlap-14">
                    <div className="ellipse-6" />
                    <Favorite className="favorite-1" color="#1F1E28" />
                    <img
                      className="image-4"
                      alt="Image"
                      src={Images.Product_5}
                    />
                  </div>
                </div>
                <div className="overlap-13">
                  <div className="overlap-11">
                    <p className="text-wrapper-22">XS™ Sports Protein Shakes – Rich Chocolate</p>
                    <div className="group-13">
                      <Basket12 className="icon-instance-node" />
                    </div>
                  </div>
                  <div className="text-wrapper-19">$53.99</div>
                  <div className="text-wrapper-20">$35.99</div>
                </div>
                <img
                  className="group-14"
                  alt="Group"
                  src="https://generation-sessions.s3.amazonaws.com/5e66f080cc2b7aa215e8b54c595b9855/img/group-180-11@2x.png"
                />
              </div>
              <div className="group-18">
                <div className="div-3">
                  <img
                    className="image-5"
                    alt="Image"
                    src={Images.Product_3}
                  />
                  <div className="ellipse-5" />
                  <Favorite className="favorite-6" color="#1F1E28" />
                  <div className="group-11">
                    <div className="group-12">
                      <div className="overlap-group-8">
                        <div className="text-wrapper-21">-40%</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="overlap-13">
                  <div className="overlap-11">
                    <p className="text-wrapper-22">XS™ Sports Protein Shakes – Rich Chocolate</p>
                    <div className="group-13">
                      <Basket12 className="icon-instance-node" />
                    </div>
                  </div>
                  <div className="text-wrapper-19">$53.99</div>
                  <div className="text-wrapper-20">$35.99</div>
                </div>
                <img
                  className="group-14"
                  alt="Group"
                  src="https://generation-sessions.s3.amazonaws.com/5e66f080cc2b7aa215e8b54c595b9855/img/group-180-11@2x.png"
                />
              </div>
              <div className="group-19">
                <div className="overlap-12">
                  <div className="div-3" />
                  <div className="group-11">
                    <div className="group-12">
                      <div className="overlap-group-9">
                        <div className="text-wrapper-21">-40%</div>
                      </div>
                    </div>
                  </div>
                  <div className="ellipse-5" />
                  <Favorite className="favorite-6" color="#1F1E28" />
                  <img
                    className="image-2"
                    alt="Image"
                    src={Images.Product_2}
                  />
                </div>
                <div className="overlap-13">
                  <div className="overlap-11">
                    <p className="text-wrapper-22">XS™ Sports Protein Shakes – Rich Chocolate</p>
                    <div className="group-13">
                      <Basket12 className="icon-instance-node" />
                    </div>
                  </div>
                  <div className="text-wrapper-19">$53.99</div>
                  <div className="text-wrapper-20">$35.99</div>
                </div>
                <img
                  className="group-14"
                  alt="Group"
                  src="https://generation-sessions.s3.amazonaws.com/5e66f080cc2b7aa215e8b54c595b9855/img/group-180-11@2x.png"
                />
              </div>
              <div className="group-20">
                <div className="div-3">
                  <div className="group-11">
                    <div className="group-12">
                      <div className="overlap-group-10">
                        <div className="text-wrapper-21">-40%</div>
                      </div>
                    </div>
                  </div>
                  <div className="overlap-14">
                    <div className="ellipse-6" />
                    <Favorite className="favorite-1" color="#1F1E28" />
                    <img
                      className="image-4"
                      alt="Image"
                      src={Images.Product_1}
                    />
                  </div>
                </div>
                <div className="overlap-13">
                  <div className="overlap-11">
                    <p className="text-wrapper-22">XS™ Sports Protein Shakes – Rich Chocolate</p>
                    <div className="group-13">
                      <Basket12 className="icon-instance-node" />
                    </div>
                  </div>
                  <div className="text-wrapper-19">$53.99</div>
                  <div className="text-wrapper-20">$35.99</div>
                </div>
                <img
                  className="group-14"
                  alt="Group"
                  src="https://generation-sessions.s3.amazonaws.com/5e66f080cc2b7aa215e8b54c595b9855/img/group-180-11@2x.png"
                />
              </div>
              <div className="group-21">
                <div className="div-3">
                  <img
                    className="image-5"
                    alt="Image"
                    src={Images.Product_1}
                  />
                  <div className="ellipse-5" />
                  <Favorite className="favorite-6" color="#1F1E28" />
                  <div className="group-11">
                    <div className="group-12">
                      <div className="overlap-group-11">
                        <div className="text-wrapper-21">-40%</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="overlap-13">
                  <div className="overlap-11">
                    <p className="text-wrapper-22">XS™ Sports Protein Shakes – Rich Chocolate</p>
                    <div className="group-13">
                      <Basket12 className="icon-instance-node" />
                    </div>
                  </div>
                  <div className="text-wrapper-19">$53.99</div>
                  <div className="text-wrapper-20">$35.99</div>
                </div>
                <img
                  className="group-14"
                  alt="Group"
                  src="https://generation-sessions.s3.amazonaws.com/5e66f080cc2b7aa215e8b54c595b9855/img/group-180-11@2x.png"
                />
              </div>
              <div className="group-22">
                <div className="overlap-12">
                  <div className="div-3" />
                  <div className="group-11">
                    <div className="group-12">
                      <div className="overlap-group-12">
                        <div className="text-wrapper-21">-40%</div>
                      </div>
                    </div>
                  </div>
                  <div className="ellipse-5" />
                  <Favorite className="favorite-6" color="#1F1E28" />
                  <img
                    className="image-2"
                    alt="Image"
                    src={Images.Product_3}
                  />
                </div>
                <div className="overlap-13">
                  <div className="overlap-11">
                    <p className="text-wrapper-22">XS™ Sports Protein Shakes – Rich Chocolate</p>
                    <div className="group-13">
                      <Basket12 className="icon-instance-node" />
                    </div>
                  </div>
                  <div className="text-wrapper-19">$53.99</div>
                  <div className="text-wrapper-20">$35.99</div>
                </div>
                <img
                  className="group-14"
                  alt="Group"
                  src="https://generation-sessions.s3.amazonaws.com/5e66f080cc2b7aa215e8b54c595b9855/img/group-180-11@2x.png"
                />
              </div>
              <div className="group-23">
                <div className="div-3">
                  <div className="group-11">
                    <div className="group-12">
                      <div className="overlap-group-13">
                        <div className="text-wrapper-21">-40%</div>
                      </div>
                    </div>
                  </div>
                  <div className="overlap-14">
                    <div className="ellipse-6" />
                    <Favorite className="favorite-1" color="#1F1E28" />
                    <img
                      className="image-4"
                      alt="Image"
                      src={Images.Product_4}
                    />
                  </div>
                </div>
                <div className="overlap-13">
                  <div className="overlap-11">
                    <p className="text-wrapper-22">XS™ Sports Protein Shakes – Rich Chocolate</p>
                    <div className="group-13">
                      <Basket12 className="icon-instance-node" />
                    </div>
                  </div>
                  <div className="text-wrapper-19">$53.99</div>
                  <div className="text-wrapper-20">$35.99</div>
                </div>
                <img
                  className="group-14"
                  alt="Group"
                  src="https://generation-sessions.s3.amazonaws.com/5e66f080cc2b7aa215e8b54c595b9855/img/group-180-11@2x.png"
                />
              </div>
              <div className="group-24">
                <div className="div-3">
                  <img
                    className="image-5"
                    alt="Image"
                    src={Images.Product_5}
                  />
                  <div className="ellipse-5" />
                  <Favorite className="favorite-6" color="#1F1E28" />
                  <div className="group-11">
                    <div className="group-12">
                      <div className="overlap-group-14">
                        <div className="text-wrapper-21">-40%</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="overlap-13">
                  <div className="overlap-11">
                    <p className="text-wrapper-22">XS™ Sports Protein Shakes – Rich Chocolate</p>
                    <div className="group-13">
                      <Basket12 className="icon-instance-node" />
                    </div>
                  </div>
                  <div className="text-wrapper-19">$53.99</div>
                  <div className="text-wrapper-20">$35.99</div>
                </div>
                <img
                  className="group-14"
                  alt="Group"
                  src="https://generation-sessions.s3.amazonaws.com/5e66f080cc2b7aa215e8b54c595b9855/img/group-180-11@2x.png"
                />
              </div>
            </div>
          </div>
          <div className="group-25">
            <div className="group-26">
              <div className="overlap-15">
                <div className="group-27">
                  <div className="text-wrapper-23">Price</div>
                  <img
                    className="vector-7"
                    alt="Vector"
                    src="https://generation-sessions.s3.amazonaws.com/5e66f080cc2b7aa215e8b54c595b9855/img/vector-10.svg"
                  />
                  <div className="group-28">
                    <div className="group-29">
                      <div className="overlap-16">
                        <div className="text-wrapper-24">3000</div>
                      </div>
                      <div className="overlap-group-15">
                        <div className="text-wrapper-24">100000</div>
                      </div>
                      <div className="text-wrapper-25">-</div>
                    </div>
                    <div className="rectangle-wrapper">
                      <img
                        className="rectangle-15"
                        alt="Rectangle"
                        src="https://generation-sessions.s3.amazonaws.com/5e66f080cc2b7aa215e8b54c595b9855/img/rectangle-81.png"
                      />
                    </div>
                  </div>
                </div>
                <div className="group-30">
                  <div className="rectangle-16" />
                </div>
                <div className="group-31">
                  <div className="rectangle-16" />
                </div>
              </div>
            </div>
            <div className="overlap-17">
              <div className="group-32">
                <div className="group-33">
                  <div className="text-wrapper-26">Brands</div>
                  <img
                    className="vector-7"
                    alt="Vector"
                    src="https://generation-sessions.s3.amazonaws.com/5e66f080cc2b7aa215e8b54c595b9855/img/vector-10.svg"
                  />
                  <div className="group-34">
                    <div className="rectangle-17" />
                    <div className="line-wrapper">
                      <img
                        className="line"
                        alt="Line"
                        src="https://generation-sessions.s3.amazonaws.com/5e66f080cc2b7aa215e8b54c595b9855/img/line-1.svg"
                      />
                    </div>
                    <div className="rectangle-18" />
                    <div className="rectangle-19" />
                    <div className="rectangle-20" />
                    <div className="rectangle-21" />
                    <div className="rectangle-22" />
                    <div className="rectangle-23" />
                    <div className="rectangle-24" />
                    <div className="rectangle-25" />
                    <div className="rectangle-26" />
                    <div className="rectangle-27" />
                    <div className="group-35">
                      <div className="agent-provocateur-wrapper">
                        <p className="agent-provocateur">
                          Agent Provocateur(1)
                          <br />
                          Arcwave(1)
                          <br />B Yoga(2)
                          <br />
                          Bala(9)
                          <br />
                          Baloo(1)
                          <br />
                          Bios Lighting(1)
                          <br />
                          BIOS Lighting(1)
                          <br />
                          Coco de Mer(4)
                          <br />
                          Cocofloss(1)
                          <br />
                          Crave(1)
                          <br />
                          CW&amp;T(1)
                          <br />
                          DAME(4)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rectangle-28" />
              <div className="rectangle-29" />
            </div>
          </div>
          <div className="group-36">
            <Frame className="frame-76" divClassName="frame-instance" />
            <Frame className="frame-76-instance" divClassName="frame-8" text="3" />
            <Frame className="frame-9" divClassName="frame-8" text="15" />
            <FrameWrapper
              className="frame-77"
              frame="https://generation-sessions.s3.amazonaws.com/5e66f080cc2b7aa215e8b54c595b9855/img/frame-77-1.svg"
            />
            <div className="img-wrapper">
              <img
                className="vector-8"
                alt="Vector"
                src="https://generation-sessions.s3.amazonaws.com/5e66f080cc2b7aa215e8b54c595b9855/img/vector-9-1.svg"
              />
            </div>
            <div className="frame-10">
              <div className="text-wrapper-27">2</div>
            </div>
            <div className="text-wrapper-28">...</div>
            <div className="text-wrapper-29">Показать еще 12 товаров</div>
            <CircleRightAltLight className="circle-right-alt" color="#14A4E1" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketPlace;