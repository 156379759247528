import React, { useEffect, useState } from "react";
import { Header } from "../components/Admin/Header";
import Footer from "../components/Admin/Footer";
import { Link, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Container, Dropdown, Nav } from "react-bootstrap";
import { ThreeDots } from "react-loader-spinner";
import ButtonStyled from "../components/ButtonStyled";
import { FormControl } from "react-bootstrap";
import Profile from "../components/Profile";
import Images from "../constants/Images";
import NavBar from "react-bootstrap/NavBar";
import { Navbar, Collapse } from "react-bootstrap";
import { InputGroup } from 'react-bootstrap';

import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";
import { Row, Col } from "react-bootstrap"; // Import Bootstrap's Row and Col components
import { Sidebarphone } from "../components/Admin/sidebarphone/Sidebarphone";
import {AiOutlineSearch} from "react-icons/ai";

function AdminLayout({ children }) {
  const location = useLocation();
  const [PageLoader, setPageLoader] = useState(true);
  const [Admin, setAdmin] = useState();

  return (
    <div>
      <>
        <Header />

        <section className="home-section" id="main_content">
          <NavBar  style={{boxShadow: '0px 4px 4px 0px #E2EBEF4D', border:'none'}}>
          <Sidebarphone />
            <Container >
             
              <NavbarCollapse >
                <Row xs={12} md={12} style={{ width: "100%",height:'auto' }}>
                  {/* Create a row for mobile and tablet */}
                  <Col xs={12} md={5} lg={7} style={{marginTop:'5px'}}>
                    {/* Full width search bar on mobile and tablet */}
                    {/* <div> */}
                      {/* <AiOutlineSearch/> */}
                    {/* <FormControl
                      type="text"
                      placeholder="Search items, collections, and users"
                      className="admin-search-input"
                      id="custombutton"  
                      
                    /> */}
                    
                    <InputGroup>
                          <div className="search-icon">
                            <AiOutlineSearch style={{fontSize:'18px'}}/>
                          </div>
                          <FormControl
                            type="text"
                            placeholder="       Search items, collections, and users"
                            className="admin-search-input"
                            id="custombutton"
                          />
                          
                          
                    </InputGroup>

                      {/* <div class="form-field">
                        <span class="icon"><AiOutlineSearch/></span>
                        Search items, collections, and users
                      </div> */}
                    {/* </div> */}
                    
                    
                  </Col>
                  <Col
                    xs={12}
                    md={7}
                    lg={5}
                    className="text-center mt-2 mt-md-0 d-flex" style={{justifyContent:'end'}}
                  >
                    {/* Centered elements on mobile and tablet */}
                    <div className="me-3 icon-container">
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="42"
                        height="40"
                        viewBox="0 0 32 24"
                        fill="none"
                        className=" headerIcon"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="22"
                          viewBox="0 0 18 22"
                          fill="none"
                          className="text-align-center"
                        >
                          <path
                            d="M4.44784 7.96942C4.76219 5.14032 7.15349 3 10 3V3C12.8465 3 15.2378 5.14032 15.5522 7.96942L15.804 10.2356C15.8072 10.2645 15.8088 10.279 15.8104 10.2933C15.9394 11.4169 16.3051 12.5005 16.8836 13.4725C16.8909 13.4849 16.8984 13.4973 16.9133 13.5222L17.4914 14.4856C18.0159 15.3599 18.2782 15.797 18.2216 16.1559C18.1839 16.3946 18.061 16.6117 17.8757 16.7668C17.5971 17 17.0873 17 16.0678 17H3.93223C2.91268 17 2.40291 17 2.12434 16.7668C1.93897 16.6117 1.81609 16.3946 1.77841 16.1559C1.72179 15.797 1.98407 15.3599 2.50862 14.4856L3.08665 13.5222C3.10161 13.4973 3.10909 13.4849 3.11644 13.4725C3.69488 12.5005 4.06064 11.4169 4.18959 10.2933C4.19123 10.279 4.19283 10.2645 4.19604 10.2356L4.44784 7.96942Z"
                            stroke="#879BA4"
                          />
                          <path
                            d="M7.10222 17.4059C7.27315 18.1501 7.64978 18.8077 8.17372 19.2767C8.69765 19.7458 9.3396 20 10 20C10.6604 20 11.3024 19.7458 11.8263 19.2767C12.3502 18.8077 12.7269 18.1501 12.8978 17.4059"
                            stroke="#879BA4"
                            stroke-linecap="round"
                          />
                        </svg>
                      </svg>
                    </div>
                    <div className="me-3 icon-container">
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="44"
                        height="30"
                        viewBox="0 2 32 30"
                        fill="none"
                        className=" headerIcon1"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <path
                            d="M8 12L8 8C8 5.79086 9.79086 4 12 4V4C14.2091 4 16 5.79086 16 8L16 12"
                            stroke="#232527"
                            stroke-linecap="round"
                          />
                          <path
                            d="M3.69435 12.6678C3.83942 10.9269 3.91196 10.0565 4.48605 9.52824C5.06013 9 5.9336 9 7.68053 9H16.3195C18.0664 9 18.9399 9 19.514 9.52824C20.088 10.0565 20.1606 10.9269 20.3057 12.6678L20.8195 18.8339C20.904 19.8474 20.9462 20.3542 20.6491 20.6771C20.352 21 19.8435 21 18.8264 21H5.1736C4.15655 21 3.64802 21 3.35092 20.6771C3.05382 20.3542 3.09605 19.8474 3.18051 18.8339L3.69435 12.6678Z"
                            stroke="#232527"
                          />
                        </svg>
                      </svg>
                      <div class="notification-badge">2</div>
                    </div>
                    <div style={{overflow:'clip'}}>
                      <Profile />
                    </div>
                  </Col>
                </Row>
              </NavbarCollapse>
            </Container>
          </NavBar>

          <main> {children}</main>
        </section>
        <ToastContainer />
        <Footer />
      </>
    </div>
  );
}

export default AdminLayout;
