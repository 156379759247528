/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Icon81 } from "../../icons/Icon81";
import "./style.css";

export const Button = ({
  leftIcon = true,
  rightIcon = true,
  text = "Enter your Text",
  className,
  divClassName,
  icon = <Icon81 className="icon" color="#F6FAFB" />,
}) => {
  return (
    <button className={`button ${className}`}>
      {leftIcon && <Icon81 className="icon" color="#F6FAFB" />}

      <div className={`enter-your-text ${divClassName}`}>{text}</div>
      {rightIcon && <>{icon}</>}
    </button>
  );
};

Button.propTypes = {
  leftIcon: PropTypes.bool,
  rightIcon: PropTypes.bool,
  text: PropTypes.string,
};
