import React from "react";
import { Button } from "../../View/components/Button";
import { InputFields } from "../../View/components/InputFields";
import { PropertyBank } from "../../View/components/PropertyBank";
import { VariantsToggle } from "../../View/components/VariantsToggle";
import { Add } from "../../components/icons/Add";
import { Add2 } from "../../components/icons/Add2";

import { CardAdd } from "../../components/icons/CardAdd";

import { Cloud } from "../../components/icons/Cloud";

import { Location } from "../../components/icons/Location";

import { Sms1 } from "../../components/icons/Sms1";
import AdminLayout from "../../layouts/AdminLayout";
import ButtonStyled from "../../components/ButtonStyled";
import { Col, Container,Row } from "react-bootstrap";
import { useState } from "react";
import "./settingforspecialist.scss";
import {CgProfile} from "react-icons/cg";
import { Card } from "react-bootstrap";
import Images from "../../constants/Images";
import { Image } from "react-bootstrap";
import {BsChevronDown} from "react-icons/bs";
import "../user_flow/setting.scss";


export const Setting_2 = () => {
  const [isSpecialistActive, setSpecialistActive] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };
  return (
    <AdminLayout>
      <div className="bg-white">
      <Container style={{paddingBottom:'20px'}}>
            <Container>
          <div className="setting-nav p-4">
            <div>
              {" "}
              <h3 className="general-setting">General Settings</h3>
              <span className="upload-text">
                {" "}
                Update your photo and personal details here.
              </span>
            </div>
            <div className="d-flex   gap-4 every-changes-div">
              <div className="w-100">
              <p className=" every-changes">Every changes automatically saved</p>
              </div>
              <div className="preview-button">
                  <div className=" appointmentheader1">
                      <span className=" Appointment1">Preview</span>
                  </div>
                  <div className=" appointmentheader2">
                      <span className=" Appointment2">Cancel</span>
                  </div>
              </div>
              
            </div>
          </div>
          </Container>


          <Container className="d-flex justify-content-center gap-1 ">
        <Row className="w-100">
        <div className="ps-2 persinal-card col-lg-8 col-sm-12 pe-2">
          <div className="personal-information-card">
          <Card>
            <Card.Body className="">
              <div className="personal-information-form-for-setting_2">
                <h4 className="personal-information-text">Personal Information</h4>
                <div style={{paddingTop:'15px'}} className="specialist-setting2-div">
                    <p className="specialist-wala-text">Specialist</p>
                    <div className="mt-3   d-flex gap-3 interest-wala-div">
                          
                          <div className="interest-button">
                              <span className="interest">Exercise</span>
                          </div>
                          <div className="interest-button">
                              <span className="interest">Exercise</span>
                          </div>
                          <div className="interest-button-fitness1">
                              <span className="interest">FItnes</span>
                          </div>
                          <div className="interest-button-addmore">
                              <span className="interest">Add more</span>
                              {/* <span className="interest">+</span> */}
                              <img src={Images.plus} alt="plus" style={{width:'24px',height:'24px'}}/>
                          </div>
                    </div>
                </div>
                <form className="w-100 first-last-label" style={{paddingTop:'20px'}}>
                <Row>
                  <Col lg={6} className="pe-2">
                  <label style={{fontFamily:'Outfit', fontWeight:'500', font:'18px'}} className="w-100">
                    First Name
                    <span style={{color:'#E23434'}}>*</span>

                    <div className="text-field-with-profile w-100">
      <div className="profile-icon">
        <CgProfile style={{width:'24px', height:'24px', color:'#93989A', marginLeft:'10px'}}/>
      </div>
      <input type="text" placeholder="Kevin" className="profile-input-field"/>
                     </div>
                    
                  </label>
                  </Col>
                  <Col lg={6} className="">
                  <label style={{fontFamily:'Outfit', fontWeight:'500', font:'18px'}} className="w-100">
                    Last Name
                    <div className="text-field-with-profile w-100">
                      <div className="profile-icon">
                        <CgProfile style={{width:'24px', height:'24px', color:'#93989A', marginLeft:'10px'}}/>
                      </div>
                      <input type="text" placeholder="Write Here" className="profile-input-field1"/>
                     </div>
                    
                  </label>
                 </Col>
                 </Row>
                </form>

                <div  className="email-div" style={{paddingTop:'20px'}}>
                  <label style={{fontFamily:'Outfit', fontWeight:'500', font:'18px', width:'100%'}}>
                    Email Address
                    <div className="text-field-with-profile-email">
                      <div className="profile-icon-email">
                        <img src={Images.envelope} alt="envelope" style={{width:'24px', height:'24px', marginLeft:'10px'}}/>
                        {/* <CgProfile style={{width:'24px', height:'24px', color:'#93989A', marginLeft:'10px'}}/> */}
                      </div>
                      <input type="text" placeholder="Sympathiq@gmail.com" className="profile-input-field1-email"/>
                     </div>
                    
                  </label>

                </div>
                



                <div  className="email-div" style={{paddingTop:'20px'}}>
                  <label style={{fontFamily:'Outfit', fontWeight:'500', font:'18px', width:'100%'}}>
                    Address
                    <div className="text-field-with-profile-email">
                      <div className="profile-icon-email">
                        <img src={Images.location} alt="location" style={{width:'24px', height:'24px', marginLeft:'10px'}}/>
                        {/* <CgProfile style={{width:'24px', height:'24px', color:'#93989A', marginLeft:'10px'}}/> */}
                      </div>
                      <input type="text" placeholder="California USA 123 Street " className="profile-input-field1-email"/>
                     </div>
                    
                  </label>

                </div>



                <form className="w-100 first-last-label" style={{paddingTop:'20px'}}>

                <Row>
                  <Col lg={6} className="pe-2">
                  <label style={{fontFamily:'Outfit', fontWeight:'500', font:'18px'}} className="w-100">
                  Postal Code
                   

                    <div className="text-field-with-profile w-100">
      <div className="profile-icon">
        
      </div>
      <input type="text" placeholder="00000" className="profile-input-field1"/>
                     </div>
                    
                  </label>
                  </Col>
                  <Col lg={6} className="">
                  <label style={{fontFamily:'Outfit', fontWeight:'500', font:'18px'}} className="w-100">
                    Timezone
                    <div className="text-field-with-profile w-100">
                      <div className="profile-icon">
                        {/* <CgProfile style={{width:'24px', height:'24px', color:'#93989A', marginLeft:'10px'}}/> */}
                      </div>
                      <input type="text" placeholder="United Kingdom" className="profile-input-field1"/>
                     </div>
                    
                  </label>
                 </Col>
                 </Row>
                  {/* <label style={{fontFamily:'Outfit', fontWeight:'500', font:'18px'}}>
                    Postal Code
                    
                    <div className="text-field-with-profile">
      <div className="profile-icon">
        {/* <CgProfile style={{width:'24px', height:'24px', color:'#93989A', marginLeft:'10px'}}/> 
      </div>
      <input type="text" placeholder="00000" className="profile-input-field-postalcode"/>
                     </div>
                    
                  </label>

                  <label style={{fontFamily:'Outfit', fontWeight:'500', font:'18px', paddingLeft:'5px'}}>
                    Timezone
                    <div className="text-field-with-profile">
                      <div className="profile-icon">
                        {/* <CgProfile style={{width:'24px', height:'24px', color:'#93989A', marginLeft:'10px'}}/> 
                      </div>
                      <input type="text" placeholder="United Kingdom" className="profile-input-field1"/>
                     </div>
                    
                  </label> */}
                </form>





                <div  className="email-div" style={{paddingTop:'20px'}}>
                  <label style={{fontFamily:'Outfit', fontWeight:'500', font:'18px', width:'100%'}}>
                    Add Your Price
                    <div className="text-field-with-profile-email">
                      <div className="profile-icon-email">
                        {/* <img src={Images.envelope} alt="envelope" style={{width:'24px', height:'24px', marginLeft:'10px'}}/> */}
                        {/* <CgProfile style={{width:'24px', height:'24px', color:'#93989A', marginLeft:'10px'}}/> */}
                      </div>
                      <input type="text" placeholder="$100 - 200" className="profile-input-field1-email"/>
                     </div>
                    
                  </label>

                </div>






                <div  className="email-div" style={{paddingTop:'20px'}}>
                  <label style={{fontFamily:'Outfit', fontWeight:'500', font:'18px', width:'100%'}} className="tell-yourself-label">
                    Tell About Yourself
                    <div className="text-field-with-profile-email-tell-yourself">
                      <div className="profile-icon-email">
                        {/* <img src={Images.envelope} alt="envelope" style={{width:'24px', height:'24px', marginLeft:'10px'}}/> */}
                        {/* <CgProfile style={{width:'24px', height:'24px', color:'#93989A', marginLeft:'10px'}}/> */}
                      </div>
                     <textarea placeholder="Lorem ispum erina iarma hianna  hye jbfewne sbfnskfn ejbgf lorek udns o nwiuefn uiefhjoan .&#10;casjnfojn ajsnLorem ispum erina iarma hianna  hye jbfewne sbfnskfn ejbgf lorek udns o" className="tell-yourself-textarea"></textarea>
                     </div>
                    
                  </label>

                </div>





                <form className="w-100 first-last-label" style={{paddingTop:'20px'}}>
                  <Row>
                <Col lg={6} className="">
                  <label style={{fontFamily:'Outfit', fontWeight:'500', font:'18px'}} className="w-100">
                    Experience
                    <div className="text-field-with-profile w-100">
                      <input type="text" placeholder="Senior Level" className="profile-input-field-senior-level w-100"/>
                      <div className="profile-icon">
                        <BsChevronDown style={{color:'#68838E'}}/>
                      </div>
                     </div>
                    
                  </label></Col>
<Col lg={6}>
                  <label style={{ fontFamily: 'Outfit', fontWeight: '500', font: '18px' }} className="w-100">
  Type of consultation
  <div className="custom-select text-field-with-profile w-100" style={{minWidth:'330px'}}>
    <select className="profile-input-field-video w-100 ps-2" >
      <option value="video">Video</option> 
      <option value="chat">Chat</option>
      <option value="voice">Voice</option>
    </select>
    <div className="profile-icon">
      <BsChevronDown style={{ color: '#68838E' }} />
    </div>
  </div>
</label>
</Col>
</Row>
                </form>



             

                    <p className="specialist-wala-text" style={{paddingTop:'20px'}}>Interest</p>
                    <div className="mt-3   d-flex gap-3 interest-wala-div">
                          
                          <div className="interest-button">
                              <span className="interest">Exercise</span>
                          </div>
                          <div className="interest-button">
                              <span className="interest">Exercise</span>
                          </div>
                          <div className="interest-button-fitness1">
                              <span className="interest">FItnes</span>
                          </div>
                          <div className="interest-button-addmore">
                              <span className="interest">Add more</span>
                              {/* <span className="interest">+</span> */}
                              <img src={Images.plus} alt="plus" style={{width:'24px',height:'24px'}}/>
                          </div>
                    </div>



              <div className="d-flex justify-content-between" style={{paddingTop:'20px', paddingBottom:'40px'}}>
                {" "}
                <div className="personal-div"> 
                  <h5 className="personal-account-text">Notification</h5>

                  <span className="lorem-text">
                    Lorem Ipsum Ipsum lorem inchi upsim{" "}
                  </span>
                </div>
               
                <label className={`switch ${isChecked ? 'checked' : ''}`}>
                  <input type="checkbox" onClick={handleToggle} />
                  <span className="slider"></span>
                </label>
             
              </div>

              </div>
              
            </Card.Body>
          </Card>
          </div>
         
        
       






        </div>
        <div className="edit-card col-lg-4">
          <Card className="p-2 edit-photo-card">
          <div className="your-photo">
                <p className="your-photo-text">Your Photo</p>
                <hr className="your-photo-line"/>
              </div>
            <div className="d-flex your-photo-div">
              
              <Image src={Images.Myappointment_1} width={64} />
              <div className="edit-photo-div">
                <h4 className="edit-your-photo"> Edit your photo</h4>
                <span className="ctext">Delete</span>{" "}
                <span className="update">Update</span>
              </div>
            </div>
            <Card.Body className="upload-img">
             
              <img src={Images.cloud} alt="cloud" className="cloud"/>
              <br></br>
              <p style={{color: '#93989A'}} className="clicktodrop">Click to upload or drag and drop</p>
              <br></br>
              <div className="png-div">
              
              <p className="png" style={{color: '#93989A'}}>PNG, JPG or Gif</p>
              <p className="png" style={{color: '#93989A'}}>Max 20Mb</p>
              </div>
              
            </Card.Body>
          </Card>
          <div style={{paddingTop:'10px'}}>
          <Card className="p-2 edit-photo-card">
          <div className="your-photo">
                <div className="d-flex justify-content-between">
                  <p className="your-photo-text">Add Office Picture</p>
                  <p className="ctext" style={{paddingRight:'10px'}}>Delete</p>
                </div>
                <hr className="your-photo-line"/>
                <div className="d-flex justify-content-between">
                  <img src={Images.addoffice1} className="add-office-pic"/>
                  <img src={Images.addoffice} className="add-office-pic"/>
                  <img src={Images.addoffice} className="add-office-pic"/>
                  <img src={Images.addoffice} className="add-office-pic"/>
                </div>
              </div>
            
            <Card.Body className="upload-img">
             
              <img src={Images.cloud} alt="cloud" className="cloud"/>
              <br></br>
              <p style={{color: '#93989A'}} className="clicktodrop">Click to upload or drag and drop</p>
              <br></br>
              <div className="png-div">
              
              <p className="png" style={{color: '#93989A'}}>PNG, JPG or Gif</p>
              <p className="png" style={{color: '#93989A'}}>Max 20Mb</p>
              </div>
              
            </Card.Body>
          </Card>
          </div>
          <div className="wallet-main-div1">
          <Card className="mt-3 p-2  wallet-card">
            <div style={{padding:'22px'}}>
            <din className="d-flex justify-content-between">
              {" "}
              <h3 className="wallet">Wallet</h3> <p className="history"> see History</p>
            </din>
            <p className="balance"> Your Balance</p>
            <p className="price">$185,424.52</p>
            <img src={Images.visa} className="visa-img"/>
            <div className="d-flex justify-content-between pt-4">
              <div className=" appointmentheader">
                <span className=" Appointment"> Add New Card</span>
              </div>
              <img src={Images.add_card} alt="card" className="add-card"/>
            </div>
            
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M2 11.46V16.46C2 18.75 3.85 20.6 6.14 20.6H17.85C20.14 20.6 22 18.74 22 16.45V11.46C22 10.79 21.46 10.25 20.79 10.25H3.21C2.54 10.25 2 10.79 2 11.46ZM8 17.25H6C5.59 17.25 5.25 16.91 5.25 16.5C5.25 16.09 5.59 15.75 6 15.75H8C8.41 15.75 8.75 16.09 8.75 16.5C8.75 16.91 8.41 17.25 8 17.25ZM14.5 17.25H10.5C10.09 17.25 9.75 16.91 9.75 16.5C9.75 16.09 10.09 15.75 10.5 15.75H14.5C14.91 15.75 15.25 16.09 15.25 16.5C15.25 16.91 14.91 17.25 14.5 17.25Z"
                  fill="url(#paint0_linear_2923_6868)"
                />
                <path
                  d="M13.5 4.60844V7.53844C13.5 8.20844 12.96 8.74844 12.29 8.74844H3.21C2.53 8.74844 2 8.18844 2 7.51844C2.01 6.38844 2.46 5.35844 3.21 4.60844C3.96 3.85844 5 3.39844 6.14 3.39844H12.29C12.96 3.39844 13.5 3.93844 13.5 4.60844Z"
                  fill="url(#paint1_linear_2923_6868)"
                />
                <path
                  d="M19.97 2H17.03C15.76 2 15 2.76 15 4.03V6.97C15 8.24 15.76 9 17.03 9H19.97C21.24 9 22 8.24 22 6.97V4.03C22 2.76 21.24 2 19.97 2ZM20.91 5.93C20.81 6.03 20.66 6.1 20.5 6.11H19.09L19.1 7.5C19.09 7.67 19.03 7.81 18.91 7.93C18.81 8.03 18.66 8.1 18.5 8.1C18.17 8.1 17.9 7.83 17.9 7.5V6.1L16.5 6.11C16.17 6.11 15.9 5.83 15.9 5.5C15.9 5.17 16.17 4.9 16.5 4.9L17.9 4.91V3.51C17.9 3.18 18.17 2.9 18.5 2.9C18.83 2.9 19.1 3.18 19.1 3.51L19.09 4.9H20.5C20.83 4.9 21.1 5.17 21.1 5.5C21.09 5.67 21.02 5.81 20.91 5.93Z"
                  fill="url(#paint2_linear_2923_6868)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_2923_6868"
                    x1="6.26667"
                    y1="13.1653"
                    x2="12.4722"
                    y2="22.3746"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#3A6FFD" />
                    <stop offset="1" stop-color="#D63ACA" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_2923_6868"
                    x1="4.45333"
                    y1="4.90535"
                    x2="7.52072"
                    y2="9.96912"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#3A6FFD" />
                    <stop offset="1" stop-color="#D63ACA" />
                  </linearGradient>
                  <linearGradient
                    id="paint2_linear_2923_6868"
                    x1="13.6875"
                    y1="10.3125"
                    x2="30.75"
                    y2="-2.8125"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0.253839" stop-color="#6271EB" />
                    <stop
                      offset="0.633116"
                      stop-color="#6271EB"
                      stop-opacity="0"
                    />
                  </linearGradient>
                </defs>
              </svg> */}
            </div>
            <Card.Body></Card.Body>
          </Card>
          </div>
         
        </div>
        </Row>
      </Container>

          
       </Container>
       </div>
    </AdminLayout>
  );
};

export default Setting_2;
