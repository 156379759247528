/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const SearchLight = ({ color = "#B6C1C6", className }) => {
  return (
    <svg
      className={`search-light ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle className="circle" cx="11" cy="11" r="6" stroke={color} />
      <path className="path" d="M20 20L17 17" stroke={color} strokeLinecap="round" />
    </svg>
  );
};

SearchLight.propTypes = {
  color: PropTypes.string,
};
