import { useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import Images from "../../constants/Images";
import GroupedOptions from "../../components/GroupedOptions";
import ButtonStyled from "../../components/ButtonStyled";
import AuthLayout from "../../layouts/AuthLayout";
import { useNavigate } from "react-router-dom";
import GroupedOptions1 from "../../components/Groupedoptions1";

const SignUpStepFive = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState([
    {
      text: "Cognitive Psychology",
      image: null,
      type: "checkbox",
      name: "userInterest[]",
    },
    {
      text: "Development Psychology",
      image: null,
      type: "checkbox",
      name: "userInterest[]",
    },
    {
      text: "Clinical Psychology",
      image: null,
      type: "checkbox",
      name: "userInterest[]",
    },
    {
      text: "Cognitive Psychology",
      image: null,
      type: "checkbox",
      name: "userInterest[]",
    },
    {
      text: "Development Psychology",
      image: null,
      type: "checkbox",
      name: "userInterest[]",
    },
    {
      text: "Clinical Psychology",
      image: Images.Logo,
      type: "checkbox",
      name: "userInterest[]",
    },
    {
      text: "Cognitive Psychology",
      image: null,
      type: "checkbox",
      name: "userInterest[]",
    },
    {
      text: "Development Psychology",
      image: null,
      type: "checkbox",
      name: "userInterest[]",
    },
    {
      text: "Clinical Psychology",
      image: null,
      type: "checkbox",
      name: "userInterest[]",
    },
    {
      text: "Clinical Psychology",
      image: null,
      type: "checkbox",
      name: "userInterest[]",
    },
    {
      text: "Cognitive Psychology",
      image: null,
      type: "checkbox",
      name: "userInterest[]",
    },
    {
      text: "Development Psychology",
      image: null,
      type: "checkbox",
      name: "userInterest[]",
    },
    {
      text: "Cognitive Psychology",
      image: null,
      type: "checkbox",
      name: "userInterest[]",
    },
  ]);

  return (
    <AuthLayout>
      <section
        className="LoginSection"
        style={{
          backgroundImage: `url(${Images.SignUpBG})`,
        }}
      >
        <Container className="main-div">
          <div
            className="login_form"
            style={{
              width: "70%",
            }}
          >
            <div>
              <h1>
                Area of<span> Interest</span>
              </h1>
              <p className="text-center continue">Lorem Ipsum Lorem Ipsum</p>
              <Form.Group
                className="mb-3 form-group"
                controlId="exampleFormControlSelect1"
              >
                <GroupedOptions1 values={values} isImage={false} /> 
              </Form.Group>
              <Button
                className="w-100" style={{backgroundColor:'#0000001A',border:'1px solid white',color:'#68838E', borderRadius:'1000px',height:'55px'}}
                
                onClick={() => {
                  navigate("/mood");
                }}
              >Finish</Button>
            </div>
          </div>
        </Container>
      </section>
    </AuthLayout>
  );
};

export default SignUpStepFive;
