import React, { useState, useEffect } from "react";
import { Nav, NavDropdown, Row, Col } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import Images from "../../constants/Images";
import routes from "../../utils/Routes";

export const Header = () => {
  const location = useLocation();
  const initialSelectedButton = routes.findIndex((route) =>
    location.pathname.startsWith(route.path)
  );
  const [selectedButton, setSelectedButton] = useState(initialSelectedButton);
  const [hoveredButton, setHoveredButton] = useState(null); // Add state for hovered button

  const handleButtonClick = (buttonKey) => {
    setSelectedButton(buttonKey);
    console.log(buttonKey);
  };

  const handleMouseEnter = (buttonKey) => {
    setHoveredButton(buttonKey);
  };

  const handleMouseLeave = () => {
    setHoveredButton(null);
  };

  const getAdminRoutesChild = (routes) => {
    return routes.map((prop, key) => {
      if (prop.show === "yes") {
        if (prop?.subMenu) {
          return (
            <NavDropdown
              key={key}
              renderMenuOnMount
              as="li"
              title={prop.name}
              id={`basic-nav-dropdown-${key}`}
            >
              {getAdminRoutesChild(prop.subMenu)}
            </NavDropdown>
          );
        } else {
          return (
            <Nav.Item as="li" key={key}>
              <NavLink
                exact
                to={prop.path}
                className={`nav-link ${
                  (selectedButton === key || hoveredButton === key) // Check for hover state
                    ? "selected"
                    : "notselected"
                }`}
                onClick={() => handleButtonClick(key)}
                onMouseEnter={() => handleMouseEnter(key)} // Handle mouse enter
                onMouseLeave={handleMouseLeave} // Handle mouse leave
              >
                <Row className="justify-content-center">
                  <Col className="col-2">
                    {(selectedButton === key || hoveredButton === key) // Check for hover state
                      ? prop.icon
                      : prop.icon1}
                  </Col>
                  <Col className="content col-8">{prop.name}</Col>
                </Row>
              </NavLink>
            </Nav.Item>
          );
        }
      }
    });
  };

  return (
    <>
      <div className="sidebar">
        <div className="logo-details">
          <img src={Images.symapathiq_logo} alt="" />
        </div>
        <Nav id="main_menu" as="ul" className="me-auto flex-column">
          {getAdminRoutesChild(routes)}
        </Nav>
      </div>
    </>
  );
};
